import { Control, useWatch } from 'react-hook-form';
import { Thematic } from '@notacami/core/thematic';
import { FindTheNoteOnTheNeckConfig } from '../../types';
import { SubmitButton } from '../../../submit-button';
import { useTranslation } from '../../../../../hooks/use-translation';

type SubmitButtonProps = {
  thematic: Thematic;
  control: Control<FindTheNoteOnTheNeckConfig>;
};

export function ConfigurationModalSubmitButton({
  control,
  thematic,
}: SubmitButtonProps) {
  const { t } = useTranslation();

  const selectableStrings = useWatch({ control, name: 'selectableStrings' });

  const startGameNotAllowed = !selectableStrings.some(
    (string) => string.selected,
  );

  return (
    <SubmitButton thematic={thematic} disabled={startGameNotAllowed}>
      {t('button.apply')}
    </SubmitButton>
  );
}
