import { DetailedHTMLProps, LiHTMLAttributes } from 'react';

type LiProps = DetailedHTMLProps<
  LiHTMLAttributes<HTMLLIElement>,
  HTMLLIElement
>;

export function Li(props: LiProps) {
  return <li className="list-disc" {...props} />;
}
