import { FormFieldContainerLayout } from '@notacami/ui';
import { useContext } from 'react';
import { useTranslation } from '../../../../hooks/use-translation';
import { DeviceIdFromUrlContext } from '../../device-id.context';
import { Badge } from '../../../ui/badge';

export function DeviceSection() {
  const deviceId = useContext(DeviceIdFromUrlContext);
  const { t } = useTranslation();

  return (
    <FormFieldContainerLayout
      id="mic-tutorial-state-field"
      label={t('pages.settings.admin.device-id-from-url.title')}
      input={
        <>
          {deviceId === null ? (
            <Badge surfaceId="darker">{t('not-defined')}</Badge>
          ) : (
            <Badge surfaceId="soft-invert">{deviceId}</Badge>
          )}
        </>
      }
    />
  );
}
