import { cn } from '@notacami/core/css';
import { getRangeNumberByLength } from '@notacami/core/utils';

export function FrequencyHorizontalMarkers() {
  const NB_MARKERS = 7;

  return (
    <div className="absolute flex h-full w-full flex-col justify-between">
      {getRangeNumberByLength(NB_MARKERS).map((index) => (
        <div
          key={index}
          className={cn(
            'relative h-px w-full bg-neutral-400/30 text-neutral-200',
          )}
        />
      ))}
    </div>
  );
}
