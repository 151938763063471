import { getNoteFromRootAndInterval } from '@notacami/core/notes';
import {
  FindTheNoteFromRootAndIntervalAnswer,
  FindTheNoteFromRootAndIntervalQuestion,
} from '../../../types';

export function computeCorrectAnswer(
  question: FindTheNoteFromRootAndIntervalQuestion,
): FindTheNoteFromRootAndIntervalAnswer {
  const correctAnswer = getNoteFromRootAndInterval(
    question.givenNote,
    question.givenInterval,
  );

  return correctAnswer;
}
