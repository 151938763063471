export function getIconSizeByButtonSize(
  size:
    | 'sm'
    | 'default'
    | 'lg'
    | 'icon'
    | 'icon-sm'
    | 'icon-xs'
    | null
    | undefined,
) {
  switch (size) {
    case 'icon-xs':
      return 15;
    case 'icon-sm':
    case 'sm':
      return 18;
    case 'default':
    case 'icon':
      return 24;
    case 'lg':
    default:
      return 30;
  }
}
