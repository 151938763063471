import { forwardRef } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { HelpCircle, X } from 'lucide-react';
import { Button, MotionSurface, Surface } from '@notacami/ui';
import { Thematic } from '@notacami/core/thematic';
import { getColorVariantByThematic } from '@notacami/core/design';
import { useTranslation } from '../../../../../hooks/use-translation';

type HelpButtonProps = {
  onClick: () => void;
  isOpen: boolean;
  hasAnswerHelpText: boolean;
  thematic: Thematic;
};

export const HelpButton = forwardRef<HTMLDivElement, HelpButtonProps>(
  ({ hasAnswerHelpText, isOpen, onClick, thematic }, ref) => {
    const { t } = useTranslation();

    return (
      <motion.div
        className="relative"
        ref={ref}
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 5 }}
      >
        <div className="absolute -bottom-2 -top-2 left-8 flex overflow-hidden">
          <MotionSurface
            initial={{ x: '-100%' }}
            animate={{ x: hasAnswerHelpText ? 0 : '-100%' }}
            transition={{ delay: hasAnswerHelpText ? 0 : 0.5 }}
            onClick={hasAnswerHelpText ? onClick : undefined}
            surfaceId={hasAnswerHelpText ? 'ultra-light' : 'light'}
            className="my-3 mr-2 flex items-center justify-center rounded-r-full pl-6 pr-4 text-xs font-semibold leading-none"
          >
            <AnimatePresence>
              {!isOpen && hasAnswerHelpText ? (
                <motion.div
                  className="absolute -right-1 -top-1"
                  initial={{ opacity: 0, y: 2 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 2 }}
                >
                  {hasAnswerHelpText ? (
                    <Surface
                      colorVariant={getColorVariantByThematic(thematic)}
                      className="shadow-xs flex h-5 w-5 animate-bounce items-center justify-center rounded-full font-semibold"
                    >
                      !
                    </Surface>
                  ) : null}
                </motion.div>
              ) : null}
            </AnimatePresence>
            {t('exercise.help')}
          </MotionSurface>
        </div>
        <Button
          onClick={hasAnswerHelpText ? onClick : undefined}
          colorVariant={hasAnswerHelpText ? 'neutral-invert' : 'neutral'}
          className="relative overflow-hidden"
          disabled={!hasAnswerHelpText}
          size="icon"
          variant="rounded"
        >
          <AnimatePresence mode="popLayout">
            {isOpen ? (
              <motion.div
                key="close"
                initial={{ opacity: 0, y: 32 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -32 }}
              >
                <X />
              </motion.div>
            ) : (
              <motion.div
                key="help"
                initial={{ opacity: 0, y: 32 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -32 }}
              >
                <HelpCircle />
              </motion.div>
            )}
          </AnimatePresence>
        </Button>
      </motion.div>
    );
  },
);
HelpButton.displayName = 'AnswerTip';
