import { Trans } from 'react-i18next';
import { Mic } from 'lucide-react';
import { Thematic } from '@notacami/core/thematic';
import { GainInput } from './gain-input';
import { VuMeter } from './vu-meter/vu-meter';

type GainInputStepContentProps = {
  thematic: Thematic;
};

export function GainInputStepContent({ thematic }: GainInputStepContentProps) {
  return (
    <div className="vertical-content-distribution-base items-center">
      <Mic
        strokeWidth={1.6}
        className="h-12 w-12 stroke-neutral-600 dark:stroke-neutral-300"
      />
      <p className="max-w-xs text-center">
        <Trans
          i18nKey="tutorial.mic-tutorial.gain-setting-step.description"
          components={[<strong key={0} />]}
        />
      </p>
      <VuMeter />
      <GainInput thematic={thematic} />
    </div>
  );
}
