import { Note } from 'tonal';
import { getNoteListChroma } from './get-note-list-chroma';

export function getNotePitchClassToDisplay(
  initialNote: string,
  forcedNotes: string[],
): string {
  const initialNotePitchClass = Note.pitchClass(initialNote);
  const initialNoteChroma = Note.chroma(initialNote);
  const noteListChroma = getNoteListChroma(forcedNotes);

  const notePitchClassToDisplay =
    noteListChroma.find(({ chroma }) => initialNoteChroma === chroma)
      ?.notePitchClass || initialNotePitchClass;

  return notePitchClassToDisplay;
}
