import { useContext } from 'react';
import { useStore } from 'zustand';
import { AnimatePresence } from 'motion/react';
import { LessonContext } from '../../../services/lesson';
import { LessonSteps } from './main-steps/steps/lesson-steps';
import { LessonEndConfirmationState } from './main-steps/end-confimation/lesson-end-confirmation-state';
import { LessonEndSummaryState } from './main-steps/end-summary/lesson-end-summary-state';
import { LessonMicOnboarding } from './main-steps/mic-onboarding/lesson-mic-onboarding';
import { LessonIntroductionState } from './main-steps/introduction/lesson-introduction-state';

export function LessonMainSteps() {
  const store = useContext(LessonContext);

  const mainStep = useStore(store, (state) => state.mainStep);

  const isInMicOnboarding = useStore(store, (state) => state.isInMicOnboarding);

  return (
    <AnimatePresence>
      {mainStep === 'introduction' ? (
        <LessonIntroductionState key="introduction" />
      ) : null}
      <LessonMicOnboarding key="mic-onboarding" />
      {mainStep === 'steps' ||
      (mainStep === 'end-confirmation' && !isInMicOnboarding) ? (
        <LessonSteps key="steps" />
      ) : null}
      {mainStep === 'end-summary' ? (
        <LessonEndSummaryState key="end-summary" />
      ) : null}
      <LessonEndConfirmationState key="end-confirmation" />
    </AnimatePresence>
  );
}
