import { createStore } from 'zustand';
import { TuningId, TuningInfo } from '@notacami/core/tuning';
import { NoteNotation } from '@notacami/core/note-notation';
import { Fretboard } from '../../../services/fretboard';
import { LanguagePreference } from '../../../services/i18n';
import { ThemePreference } from '../../../services/theme-preference';
import { getFretboardAndTuningInfoByTuningId } from './compute-fretboard-and-tuning-info-by-tuning-id';

export type PreferencesProps = {
  admin: boolean;
  fretboard: Fretboard;
  leftHanded: boolean;
  noteNotation: NoteNotation;
  referenceFrequency: number;
  tuningInfo: TuningInfo;
  themePreference: ThemePreference;
  languagePreference: LanguagePreference;
};

export type PreferencesState = PreferencesProps & {
  setNoteNotation: (noteNotation: NoteNotation) => void;
  setTuning: (tuningId: TuningId) => void;
  setFrenquencyReference: (referenceFrequency: number) => void;
  setLeftHanded: (leftHanded: boolean) => void;
  setThemePreference: (theme: ThemePreference) => void;
  setLanguagePreference: (language: LanguagePreference) => void;
  unlockAdmin: () => void;
  lockAdmin: () => void;
};

export type PreferencesStore = ReturnType<typeof createPreferencesStore>;

export const createPreferencesStore = (initProps: PreferencesProps) => {
  return createStore<PreferencesState>()((set) => ({
    ...initProps,
    setNoteNotation: (noteNotation: NoteNotation) => set({ noteNotation }),
    setTuning: (tuningId: TuningId) => {
      const { fretboard, tuningInfo } =
        getFretboardAndTuningInfoByTuningId(tuningId);
      set({
        fretboard,
        tuningInfo,
      });
    },
    setFrenquencyReference: (referenceFrequency: number) =>
      set({ referenceFrequency }),
    setLeftHanded: (leftHanded: boolean) => set({ leftHanded }),
    setThemePreference: (theme: ThemePreference) =>
      set({ themePreference: theme }),
    setLanguagePreference: (language: LanguagePreference) =>
      set({ languagePreference: language }),
    unlockAdmin: () => set({ admin: true }),
    lockAdmin: () => set({ admin: false }),
  }));
};
