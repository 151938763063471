import { Thematic } from '@notacami/core/thematic';
import { getColorVariantByThematic } from '@notacami/core/design';
import { Button } from '@notacami/ui';
import { useTranslation } from '../../../../../hooks/use-translation';
import { useSafeAreaInsets } from '../../../../../hooks/use-safe-area-insets';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '../../../../ui/drawer';

type LessonEndConfirmationModalProps = {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onConfirm: () => void;
  onCancel: () => void;
  thematic: Thematic;
};

export function LessonEndConfirmationModal({
  open,
  onOpenChange,
  onConfirm,
  onCancel,
  thematic,
}: LessonEndConfirmationModalProps) {
  const { t } = useTranslation();
  const insets = useSafeAreaInsets();

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent insets={insets}>
        <DrawerHeader>
          <DrawerTitle>
            {t('components.end-confirmation-modal.title')}
          </DrawerTitle>
          <DrawerDescription>
            {t('components.end-confirmation-modal.description')}
          </DrawerDescription>
        </DrawerHeader>
        <DrawerFooter>
          <div className="flex justify-end gap-2">
            <Button
              className="mr-2"
              colorVariant="neutral"
              onClick={onCancel}
              variant="outline"
            >
              {t('button.no')}
            </Button>
            <Button
              onClick={onConfirm}
              colorVariant={getColorVariantByThematic(thematic)}
            >
              {t('button.yes')}
            </Button>
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
