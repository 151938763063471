import { cn } from '@notacami/core/css';

type TunerHeadCentsOffIndicatorProps = {
  symbol: string;
  variant: 'green' | 'red' | 'idle';
};

function getIndicatorClassNames(variant: 'green' | 'red' | 'idle') {
  switch (variant) {
    case 'green':
      return 'bg-green-500 border-green-300 shadow-green-400';
    case 'red':
      return 'bg-red-500 border-red-300 shadow-red-400';
    case 'idle':
    default:
      return 'bg-neutral-700 border-neutral-300 shadow-white';
  }
}

export function TunerHeadCentsOffIndicator({
  symbol,
  variant,
}: TunerHeadCentsOffIndicatorProps) {
  return (
    <div className="flex flex-col items-center">
      <div className="text-lg text-neutral-600">{symbol}</div>
      <div
        className={cn(
          'mb-4 h-2 w-10 rounded-full border shadow-md transition-all',
          getIndicatorClassNames(variant),
        )}
      ></div>
    </div>
  );
}
