import { FindTheNoteOnTheNeckProgressPayload } from '../../../../../services/progress/find-the-note-on-the-neck/types';
import { IProgressService } from '../../../../../services/progress/progress.types';
import {
  FindTheNoteOnTheNeckAnswer,
  FindTheNoteOnTheNeckConfig,
  FindTheNoteOnTheNeckQuestion,
  FindTheNoteOnTheNeckQuestionMeta,
  FindTheNoteOnTheNeckResultMeta,
} from '../../types';

export const getProgressDataAction = async (
  progressService: IProgressService<
    FindTheNoteOnTheNeckQuestion,
    FindTheNoteOnTheNeckAnswer,
    FindTheNoteOnTheNeckQuestionMeta,
    FindTheNoteOnTheNeckResultMeta,
    FindTheNoteOnTheNeckProgressPayload
  >,
  config: FindTheNoteOnTheNeckConfig,
) => {
  return progressService.getProgressDataByTuningId(config.tuningInfo.id);
};
