import { ReactNode, forwardRef } from 'react';
import { motion } from 'motion/react';
import { cn } from '@notacami/core/css';
import { Surface } from '@notacami/ui';

type AnswerHelpProps = {
  children: ReactNode;
  className?: string;
};

export const AnswerHelp = forwardRef<HTMLDivElement, AnswerHelpProps>(
  ({ children, className }, ref) => {
    return (
      <motion.div
        ref={ref}
        className={cn('flex justify-center', className)}
        initial={{ y: 8, opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        exit={{ opacity: 0, y: 8 }}
      >
        <Surface
          className="flex items-center justify-center gap-2 rounded-2xl px-3 py-1 text-left text-sm"
          colorVariant="neutral-invert"
        >
          {children}
        </Surface>
      </motion.div>
    );
  },
);
AnswerHelp.displayName = 'AnswerTip';
