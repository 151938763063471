import { Chord } from 'tonal';

export function getChordInfoByTonicTypeAndBass(
  type: string,
  tonic: string,
  bass: string | undefined,
) {
  const chordInfo = Chord.getChord(type, tonic, bass);
  return chordInfo;
}
