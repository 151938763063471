import { useEffect, useRef } from 'react';
import { Button, ButtonProps } from '@notacami/ui';
import { useTimer } from '@notacami/core/hooks';

type ButtonTimerProps = {
  buttonProps: ButtonProps;
  forInMs: number;
  paused?: boolean;
};

export function ButtonTimer({
  buttonProps,
  forInMs,
  paused = false,
}: ButtonTimerProps) {
  const { time } = useTimer(forInMs, paused);
  const buttonAlreadyClickedByTimer = useRef(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (
      time === 0 &&
      buttonProps.onClick &&
      buttonRef.current &&
      !buttonAlreadyClickedByTimer.current
    ) {
      buttonAlreadyClickedByTimer.current = true;
      buttonRef.current.click();
    }
  }, [time, buttonProps, buttonAlreadyClickedByTimer]);

  const percent = 100 - (time * 100) / forInMs;

  return (
    <div className="relative">
      <Button {...buttonProps} ref={buttonRef} variant="rounded" size="icon" />
      {percent !== undefined ? (
        <div className="absolute bottom-1 left-4 right-4 h-1 rounded-full bg-white/30">
          <div
            className="absolute h-full rounded-full bg-white"
            style={{ width: `${percent}%` }}
          ></div>
        </div>
      ) : null}
    </div>
  );
}
