import { cn } from '@notacami/core/css';
import { getNeckCellBaseClassnames } from './get-neck-cell-base-classnames';

type NoteSelectProps = {
  isOpenString: boolean;
  isVertical: boolean;
  className?: string;
};

export function PlayingAreaCell({
  isOpenString,
  isVertical,
  className,
}: NoteSelectProps) {
  return (
    <div className={cn('animate-pulse', className)}>
      <div
        className={cn(
          ...getNeckCellBaseClassnames(isVertical, isOpenString),
          'bg-neutral-500/70 dark:bg-neutral-500/70',
        )}
      />
    </div>
  );
}
