import {
  FindTheNoteOnTheNeckTurnQuestion,
  FindTheNoteOnTheNeckTurnResult,
} from '../../../components/business/find-the-note-on-the-neck/types';
import { AbstractProgressEntry } from '../abstract-progress-entry';
import { StoredProgressEntryOutcome } from '../progress.types';
import {
  FindTheNoteOnTheNeckProgressPayload,
  FindTheNoteOnTheNeckProgressStoredPayload,
} from './types';

export function fromStoredEntry(
  entry: StoredProgressEntryOutcome<FindTheNoteOnTheNeckProgressStoredPayload>,
) {
  return new ProgressEntry(entry[0], entry[1]);
}

export function fromResult(
  _question: FindTheNoteOnTheNeckTurnQuestion,
  result: FindTheNoteOnTheNeckTurnResult,
) {
  return new ProgressEntry(result.userAnswer, 1);
}

class ProgressEntry extends AbstractProgressEntry<
  FindTheNoteOnTheNeckProgressPayload,
  FindTheNoteOnTheNeckProgressStoredPayload
> {
  public toOutcome() {
    return {
      id: `${this.payload[0]}-${this.payload[1]}`,
      payload: this.payload,
      numberOfCorrectAnswer: this.numberOfCorrectAnswer,
    };
  }

  public toStoreOutcome() {
    return [this.payload, this.numberOfCorrectAnswer] satisfies [
      FindTheNoteOnTheNeckProgressStoredPayload,
      number,
    ];
  }
}
