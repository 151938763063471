import { cn } from '@notacami/core/css';
import { getRangeNumberByLength } from '@notacami/core/utils';
import { FretSymbol } from '@notacami/core/fretboard';
import { Sizes } from './guitar-neck.constants';

type FretboardSymbolProps = {
  isOpenString: boolean;
  isVertical: boolean;
  symbol: FretSymbol;
};

export function FretboardSymbol({
  isOpenString,
  isVertical,
  symbol,
}: FretboardSymbolProps) {
  return (
    <div
      className={cn(
        'flex items-center justify-center gap-1',
        isOpenString && !isVertical && Sizes.OPEN_STRING_CELL_BY_STRING.h.w,
        isOpenString && isVertical && Sizes.OPEN_STRING_CELL_BY_STRING.v.h,
        !isOpenString && !isVertical && Sizes.NECK_FRET_CELL_BY_STRING.h.w,
        !isOpenString && isVertical && Sizes.NECK_FRET_CELL_BY_STRING.v.h,
        isVertical && 'flex-row',
        !isVertical && 'flex-col',
        isVertical && Sizes.SYMBOL_TRACK.v.w,
        !isVertical && Sizes.SYMBOL_TRACK.h.h,
      )}
    >
      {getRangeNumberByLength(symbol).map((index) => (
        <div
          className="h-3 w-3 rounded-full bg-neutral-700 dark:bg-neutral-500"
          key={index}
        />
      ))}
    </div>
  );
}
