import { getIntervalInterpolationInfo } from '@notacami/core/intervals';
import { useTranslation } from '../../hooks/use-translation';

export function useIntervalTranslation() {
  const { t } = useTranslation();
  function translate(interval: string) {
    const intervalTranspolationInfo = getIntervalInterpolationInfo(interval);
    return t('interval-name', intervalTranspolationInfo);
  }
  return { i: translate };
}
