import { Note } from 'tonal';
import { PositionWithChromaAndMidi, PositionWithInterval } from '../fretboard';

export function getChromaAndMidiPositionsByRootNoteNameAndIntervalPositions(
  rootNoteName: string,
  intervalPositions: PositionWithInterval[],
): PositionWithChromaAndMidi[] {
  return intervalPositions.map((positionWithInterval) => {
    const transposedNoteName = Note.transpose(
      rootNoteName,
      positionWithInterval.interval,
    );
    const chroma = Note.chroma(transposedNoteName);
    const midi = Note.midi(transposedNoteName);
    if (midi === null) {
      throw new Error(`Midi not found for ${transposedNoteName} note name`);
    }
    return { position: positionWithInterval.position, chroma, midi };
  });
}
