import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router';
import { Button } from '@notacami/ui';

export const WrappedLink = forwardRef<
  HTMLButtonElement,
  LinkProps & { buttonVariant: 'outline' | 'link'; buttonSize?: 'icon' }
>(({ buttonVariant, buttonSize, ...rest }, ref) => {
  return (
    <Button
      asChild
      colorVariant="neutral"
      ref={ref}
      size={buttonSize}
      variant={buttonVariant}
    >
      <Link {...rest} />
    </Button>
  );
});
