import { ReactNode, forwardRef } from 'react';
import { cn } from '@notacami/core/css';
import { MotionSurface } from '@notacami/ui';
import { ConditionalWrapper } from '@notacami/core/components';
import { useSafeAreaInsets } from '../../hooks/use-safe-area-insets';

type QuizLayoutProps = {
  answer: ReactNode;
  leftAction?: ReactNode;
  progress?: ReactNode;
  question?: ReactNode;
  rightAction?: ReactNode;
  bottomRightAction?: ReactNode;
  bottomLeftAction?: ReactNode;
  resizeWhenDrawerOpened?: boolean;
};

export const QuizLayout = forwardRef<HTMLDivElement, QuizLayoutProps>(
  (
    {
      answer,
      bottomLeftAction,
      bottomRightAction,
      leftAction,
      progress,
      question,
      resizeWhenDrawerOpened = false,
      rightAction,
    },
    ref,
  ) => {
    const { bottom, left, right, top } = useSafeAreaInsets();
    const hasBottomContent = bottomLeftAction !== undefined;
    return (
      <ConditionalWrapper
        items={[
          {
            condition: !resizeWhenDrawerOpened,
            wrapper: (children, index) => (
              <div
                data-testid="quiz-layout"
                key={index}
                className="fixed z-50 p-2"
                style={{ bottom, left, right, top }}
              >
                {children}
              </div>
            ),
          },
          {
            condition: resizeWhenDrawerOpened,
            wrapper: (children, index) => (
              <div
                data-testid="quiz-layout"
                key={index}
                className="fixed z-50 p-2"
                vaul-drawer-wrapper=""
                style={{ bottom, left, right, top }}
              >
                {children}
              </div>
            ),
          },
        ]}
      >
        <MotionSurface
          surfaceId="light"
          initial={{ y: 5, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          exit={{
            y: 5,
            opacity: 0,
          }}
          ref={ref}
          className="relative flex h-full flex-col justify-between overflow-hidden rounded-3xl pt-3"
        >
          <div className="flex flex-col gap-4 px-3">
            <div className="flex justify-between gap-4">
              <div className="flex min-w-12 justify-start">{leftAction}</div>
              <div className="flex grow items-center justify-center">
                {progress}
              </div>
              <div className="flex min-w-12 justify-end gap-2">
                {rightAction}
              </div>
            </div>
            <div className="z-10">{question}</div>
          </div>
          <div
            className={cn(
              'relative grow overflow-y-scroll',
              hasBottomContent && 'pb-14',
            )}
          >
            {answer}
          </div>
          {bottomLeftAction ? (
            <div className="absolute bottom-3 left-3">{bottomLeftAction}</div>
          ) : null}
          {bottomRightAction ? (
            <div className="absolute bottom-3 right-3">{bottomRightAction}</div>
          ) : null}
        </MotionSurface>
      </ConditionalWrapper>
    );
  },
);
