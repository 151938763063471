import { cn } from '@notacami/core/css';
import { getRangeNumberByLength } from '@notacami/core/utils';
import { StringCell } from './string-cell';
import { BuildCellsComponent } from './guitar-neck.types';

type StringCellsProps<AdditionalCellProps> = {
  buildCellsComponent?: BuildCellsComponent<AdditionalCellProps>;
  isVertical: boolean;
  numberOfFrets: number;
  stringIndex: number;
  leftHanded: boolean;
  additionalCellProps: AdditionalCellProps;
};

export function StringCells<AdditionalCellProps>({
  buildCellsComponent,
  isVertical,
  numberOfFrets,
  stringIndex,
  leftHanded,
  additionalCellProps,
}: StringCellsProps<AdditionalCellProps>) {
  return (
    <div
      className={cn(
        !isVertical && !leftHanded && 'flex-row',
        !isVertical && leftHanded && 'flex-row-reverse',
        isVertical && 'flex-col',
        'flex',
      )}
    >
      {getRangeNumberByLength(numberOfFrets + 1).map((currentStringIndex) => (
        <StringCell
          isOpenString={currentStringIndex === 0}
          key={`${stringIndex}-${currentStringIndex}`}
          fretIndex={currentStringIndex}
          stringIndex={stringIndex}
          isVertical={isVertical}
          buildCellsComponent={buildCellsComponent}
          leftHanded={leftHanded}
          additionalCellProps={additionalCellProps}
        />
      ))}
    </div>
  );
}
