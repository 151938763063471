import { isPositionEqual } from '@notacami/core/fretboard';
import { BuildCellsComponentProps } from '@notacami/ui-business/guitar-neck';
import { NeckCellInterval } from '@notacami/ui-business/neck-cells';
import { PositionWithInterval } from '../../../../../services/fretboard';

type AnswerSectionResultCellProps = BuildCellsComponentProps & {
  positions: PositionWithInterval[];
};

export function AnswerSectionResultCell({
  isOpenString,
  position,
  isVertical,
  positions,
}: AnswerSectionResultCellProps) {
  const intervalPosition = positions.find((positionWithInterval) =>
    isPositionEqual(positionWithInterval.position, position),
  );

  if (intervalPosition === undefined) {
    return null;
  }

  return (
    <NeckCellInterval
      isOpenString={isOpenString}
      isVertical={isVertical}
      interval={intervalPosition.interval ?? ''}
      position={position}
    />
  );
}
