import { FC, ReactNode } from 'react';
import { Title } from '@notacami/ui';
import { ExpandableCardTitleProps } from './expandable-card';

export function createSimpleExpandableCardTitle(
  icon: ReactNode,
  text: string,
): FC<ExpandableCardTitleProps> {
  return function SimpleExpandableCardTitle() {
    return (
      <Title left={icon} level={3} tagName="h2">
        {text}
      </Title>
    );
  };
}
