import { ReactNode } from 'react';
import { Persona } from '../services/persona';
import { PersonaProvider } from '../components/business/persona/persona.context';
import { useGetInitialPersonaFromUrl } from './use-get-initial-persona-from-url';

type PersonaBootProps = {
  persona?: Persona;
  children: ReactNode;
};

export function PersonaBoot({
  persona: initialPersona,
  children,
}: PersonaBootProps) {
  const initialPersonaFromUrl = useGetInitialPersonaFromUrl();

  return (
    <PersonaProvider persona={initialPersona ?? initialPersonaFromUrl}>
      {children}
    </PersonaProvider>
  );
}
