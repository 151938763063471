import { NoteEvent } from '@notacami/core/composer';
import { FretboardNoteDetails } from '../fretboard';
import { EventInPart } from './composer.types';
import { createPartEvents } from './create-part-events';

export function computeNotesToPlay(
  noteEvents: NoteEvent[],
  fretboardNoteDetails: FretboardNoteDetails,
): EventInPart[] {
  return createPartEvents(noteEvents, fretboardNoteDetails);
}
