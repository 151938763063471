import { motion } from 'motion/react';
import { useForm } from 'react-hook-form';
import { useStore } from 'zustand';
import { ReactNode } from 'react';
import { usePrevious } from 'react-use';
import { sleep } from '@notacami/core/testing';
import { PageLayout } from '../../page-layout';
import { PageUrls } from '../../../../utils/routes.contants';
import { useTryToResumeOrPrompt } from '../../../../hooks/use-try-to-resume-or-prompt';
import { useMicErrorDialogStore } from '../../mic-error-dialog.store';
import { MicError } from '../../../../services/pitch-detection';
import { ConsumersIds } from '../../../../services/consumer';
import {
  ExerciseStoreApi,
  QuizMode,
} from '../../../../services/exercise/exercise.types';
import { PageId } from '../../../../utils/page.constants';
import { SubmitButton } from '../../submit-button';
import { useTranslation } from '../../../../hooks/use-translation';
import { useHideTabBar } from '../../../ui/tab-bar.hooks';
import { BackLink } from '../../../ui/back-link';
import { ConfigurationForm } from './configuration/configuration-form';

export type FormValues = {
  duration: number;
  quizMode: QuizMode;
};

type ConfigurationStateProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
> = {
  title: ReactNode;
  instructions?: ReactNode;
  isShowingQuizModeSelector?: boolean;
  parentPageId: PageId;
  store: ExerciseStoreApi<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
  >;
};

export function ConfigurationState<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
>({
  title,
  instructions,
  isShowingQuizModeSelector = true,
  parentPageId,
  store,
}: ConfigurationStateProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload
>) {
  useHideTabBar();
  const { t } = useTranslation();
  const openMicErrorDialog = useMicErrorDialogStore(
    (state) => state.openDialog,
  );

  const duration = useStore(store, (state) => state.duration);
  const quizMode = useStore(store, (state) => state.quizMode);
  const thematic = useStore(store, (state) => state.thematic);
  const isInConfiguration = useStore(
    store,
    (state) => state.mainStep === 'configuration',
  );
  const isInEnd = useStore(store, (state) => state.mainStep === 'end');
  const previouslyIsInEnd = usePrevious(isInEnd);
  const launchQuiz = useStore(store, (state) => state.launchQuiz);
  const launchTutorial = useStore(store, (state) => state.launchTutorial);
  const updateDurationAndQuizMode = useStore(
    store,
    (state) => state.updateDurationAndQuizMode,
  );

  const { control, handleSubmit, getValues } = useForm<FormValues>({
    defaultValues: {
      duration,
      quizMode,
    },
  });

  const handleResumeFail = (micError: MicError) => {
    openMicErrorDialog(micError);
  };

  const handleResumeSucceed = () => {
    handleConfigAndDurationAndQuizModeChange(getValues());
    launchQuiz();
  };

  const handleNeedToPrompt = () => {
    handleConfigAndDurationAndQuizModeChange(getValues());
    launchTutorial('mic-tutorial');
  };

  const { execute, isPending } = useTryToResumeOrPrompt({
    consumerId: ConsumersIds.CONFIGURATION_STATE_TRY,
    needToPrompt: handleNeedToPrompt,
    resumeSucceed: handleResumeSucceed,
    resumeFail: handleResumeFail,
  });

  const onSubmit = async (data: FormValues) => {
    if (isPending || !isInConfiguration) return;

    await sleep(100);

    if (data.quizMode === 'guitar') {
      execute();
    }

    if (data.quizMode === 'app') {
      handleConfigAndDurationAndQuizModeChange(data);
      launchQuiz();
    }
  };

  const handleConfigAndDurationAndQuizModeChange = ({
    quizMode,
    duration,
  }: FormValues) => {
    updateDurationAndQuizMode(duration, quizMode);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="surface-app w-dvh block h-dvh"
    >
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: isInConfiguration ? 1 : 0 }}
        transition={{ delay: previouslyIsInEnd ? 0.15 : 0 }}
      >
        <PageLayout
          content={
            <ConfigurationForm
              control={control}
              title={title}
              instructions={instructions}
              isShowingQuizModeSelector={isShowingQuizModeSelector}
            />
          }
          leftAction={<BackLink to={PageUrls[parentPageId]} />}
          bottomAction={
            <SubmitButton thematic={thematic} pending={isPending}>
              {t('button.start')}
            </SubmitButton>
          }
        />
      </motion.div>
    </form>
  );
}
