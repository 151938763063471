import { getClassnamesByColorVariant } from '@notacami/core/design';
import { NoteStatus } from '@notacami/core/fretboard';

export function getNoteColorClassnamesByStatus(status: NoteStatus) {
  switch (status) {
    case 'success':
      return getClassnamesByColorVariant('emerald');
    case 'error':
      return getClassnamesByColorVariant('red');
    case 'info':
      return getClassnamesByColorVariant('indigo');
    default:
      return getClassnamesByColorVariant('neutral');
  }
}
