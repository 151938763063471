import { compose, sortBy, uniq } from 'lodash/fp';
import { Note } from 'tonal';
import {
  EventInPart,
  GroupNoteStartEvent,
  NoteStartEvent,
} from '@notacami/core/composer';
import { ListeningStep } from './teacher.types';

function getTimeListUniqAndSorted(timeList: number[]) {
  return compose(uniq, sortBy([(time) => time]))(timeList);
}

export function createListeningSteps(
  eventInParts: EventInPart[],
): ListeningStep[] {
  const noteStartEvents = eventInParts.filter(
    (event) => event.type === 'note-start',
  );

  const groupNoteStartEvents = eventInParts.filter(
    (event) => event.type === 'group-note-start',
  );

  const timeListFromNoteStartEvents = noteStartEvents.map(({ time }) => time);

  const notesTimeList = getTimeListUniqAndSorted(timeListFromNoteStartEvents);

  const listeningSteps = notesTimeList
    .map(
      computeListeningStepsWithoutRepetitionInfoByNoteTimeList(
        noteStartEvents,
        groupNoteStartEvents,
      ),
    )
    .filter((step: ListeningStep | null): step is ListeningStep => {
      return step !== null;
    });

  return listeningSteps;
}

function computeListeningStepsWithoutRepetitionInfoByNoteTimeList(
  noteStartEvents: NoteStartEvent[],
  groupNoteStartEvents: GroupNoteStartEvent[],
) {
  return (time: number, index: number): ListeningStep => {
    const notes = noteStartEvents
      .filter((noteStartEvent) => noteStartEvent.time === time)
      .map((noteStartEvent) => ({
        noteChroma: Note.chroma(noteStartEvent.noteNameToPlay),
        noteIdInPartNoteStartEvents: noteStartEvent.id,
        noteGroupId: noteStartEvent.groupId,
        position: noteStartEvent.position,
      }));

    const firstNoteGroupId = notes[0].noteGroupId;

    const groupNote = groupNoteStartEvents.find(
      ({ id }) => id === firstNoteGroupId,
    ) as GroupNoteStartEvent;

    return {
      notes,
      stepIndex: index,
      groupNote,
    };
  };
}
