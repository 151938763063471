import { create } from 'zustand';
import { MicError } from '../../services/pitch-detection';

type State = {
  micError: MicError | null;
  closeDialog: () => void;
  openDialog: (micError: MicError) => void;
};

const INITIAL_STATE = {
  micError: null,
};

export const useMicErrorDialogStore = create<State>((set) => ({
  ...INITIAL_STATE,
  closeDialog: () => set({ micError: null }),
  openDialog: (micError: MicError) => set({ micError }),
}));
