import { ReactNode } from 'react';
type ConditionalWrapperProps = {
  items: {
    condition: boolean;
    wrapper: (child: JSX.Element, index: number) => ReactNode;
  }[];
  children: JSX.Element;
};

export const ConditionalWrapper = ({
  items,
  children,
}: ConditionalWrapperProps) =>
  items.map(
    ({ condition, wrapper }, index) => condition && wrapper(children, index),
  );
