import { cn } from '@notacami/core/css';
import { getRangeNumberByLength } from '@notacami/core/utils';
import { StringCells } from './string-cells';
import { BuildCellsComponent } from './guitar-neck.types';

type NeckCellsProps<AdditionalCellProps> = {
  buildCellsComponent?: BuildCellsComponent<AdditionalCellProps>;
  isVertical: boolean;
  leftHanded: boolean;
  numberOfFrets: number;
  numberOfStrings: number;
  additionalCellProps: AdditionalCellProps;
};

export function NeckCells<AdditionalCellProps>({
  buildCellsComponent,
  isVertical,
  leftHanded,
  numberOfFrets,
  numberOfStrings,
  additionalCellProps,
}: NeckCellsProps<AdditionalCellProps>) {
  return (
    <div
      className={cn(
        !isVertical && 'flex-col-reverse',
        isVertical && !leftHanded && 'flex-row',
        isVertical && leftHanded && 'flex-row-reverse',
        'absolute top-0 flex border-transparent',
      )}
    >
      {getRangeNumberByLength(numberOfStrings).map((stringIndex) => (
        <StringCells
          buildCellsComponent={buildCellsComponent}
          isVertical={isVertical}
          key={stringIndex}
          numberOfFrets={numberOfFrets}
          stringIndex={stringIndex}
          leftHanded={leftHanded}
          additionalCellProps={additionalCellProps}
        />
      ))}
    </div>
  );
}
