import { useContext } from 'react';
import { useStore } from 'zustand';
import { EndConfirmationState } from '../exercise/states/end-confimation/end-confirmation-state';
import { EndState } from '../exercise/states/end';
import { PlayingState } from '../exercise/states/playing';
import { ConfigurationState } from '../exercise/states/configuration';
import { useTranslation } from '../../../hooks/use-translation';
import { ExerciseConfigurationTitle } from '../exercise/states/configuration/exercise-configuration-title';
import { useTextIntervalsWithSeparation } from '../../../services/intervals';
import { FindTheNoteFromRootAndIntervalExerciseContext } from './store/context';
import { Results } from './states/end/results';
import { QuestionSection } from './states/playing/question-section';
import { AnswerSection } from './states/playing/answer-section';
import { ConfigurationModal } from './states/playing/configuration-modal';
import { FindTheNoteFromRootAndIntervalConfig } from './types';

type StatesType = {
  availableIntervals: string[] | null;
  defaultConfig: FindTheNoteFromRootAndIntervalConfig;
};

export function States({ availableIntervals, defaultConfig }: StatesType) {
  const store = useContext(FindTheNoteFromRootAndIntervalExerciseContext);
  const thematic = useStore(store, (state) => state.thematic);
  const { translate } = useTextIntervalsWithSeparation();
  const { t } = useTranslation();

  return (
    <>
      <ConfigurationState
        title={
          <ExerciseConfigurationTitle
            thematic={thematic}
            lessonType="quiz-exercise"
            specificLabels={
              availableIntervals !== null
                ? translate(availableIntervals)
                : [t('all-intervals')]
            }
          >
            {t('pages.FIND_THE_NOTE_FROM_ROOT_AND_INTERVAL.title')}
          </ExerciseConfigurationTitle>
        }
        parentPageId="INTERVALS"
        store={store}
        isShowingQuizModeSelector={false}
      />
      <EndState store={store}>
        <Results availableIntervals={availableIntervals} />
      </EndState>
      <PlayingState
        store={store}
        questionSection={<QuestionSection />}
        answerSection={<AnswerSection />}
        configSection={
          <ConfigurationModal
            availableIntervals={availableIntervals}
            defaultConfig={defaultConfig}
          />
        }
      />
      <EndConfirmationState store={store} />
    </>
  );
}
