import { Select } from '@notacami/ui';
import { getScaleList } from '@notacami/core/scales';
import { useTranslation } from '../../hooks/use-translation';

type ScaleSelectorProps = {
  defaultValue: string;
  onChange: (id: string) => void;
};

export function ScaleSelectorTonal({
  defaultValue,
  onChange,
}: ScaleSelectorProps) {
  const { t } = useTranslation();

  const handleChange = (value: string) => {
    onChange(value);
  };

  const options = getScaleList().map((name) => ({
    id: name,
    label: t(`scale.name.${name}`),
    value: name,
  }));

  return (
    <form className="flex items-center justify-center gap-4">
      <label htmlFor="scale-selector" className="block text-sm">
        {t('fields.scale.label')}
      </label>
      <Select
        className="max-w-44"
        name="scale-selector"
        onChange={handleChange}
        options={options}
        value={defaultValue}
      />
    </form>
  );
}
