import {
  getClassnamesByColorVariant,
  getColorVariantByThematic,
} from '@notacami/core/design';
import { cn } from '@notacami/core/css';
import { Thematic } from '@notacami/core/thematic';

type StepperItemProps = {
  step: string;
  thematic: Thematic;
};

/**
 * symbols represent:
 * o : current step
 * v : previous step
 * - : next step
 * x : unreachable step
 */

export function StepperItem({ step, thematic }: StepperItemProps) {
  const colorVariant = getColorVariantByThematic(thematic);
  const classnames = getClassnamesByColorVariant(colorVariant);
  return (
    <div
      className={cn(
        'rounded-full transition-all',
        step === 'o' && [classnames.background, 'h-3 w-3'],
        step === 'v' && 'h-2 w-2 bg-neutral-600 dark:bg-neutral-300',
        step === '-' && 'h-2 w-2 bg-neutral-400',
        step === 'x' && 'h-2 w-2 bg-neutral-300 dark:bg-neutral-700/80',
      )}
    ></div>
  );
}
