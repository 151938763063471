import { AnimatePresence } from 'motion/react';
import { ColorVariant } from '@notacami/core/design';
import { GroupNoteStartEvent } from '@notacami/core/composer';
import { BadgeMotion } from '../../../../ui/badge-motion';
import { TranslationKey } from '../../../../../services/i18n';
import { useNoteNotation } from '../../../../../services/note-notation/use-note-notation';
import { useTranslation } from '../../../../../hooks/use-translation';

type GroupNoteBadgeProps = {
  playingGroupNote: GroupNoteStartEvent | null;
};

function getBadgeColorVariant(type: 'chord' | 'scale'): ColorVariant {
  switch (type) {
    case 'chord':
      return 'yellow';
    case 'scale':
      return 'emerald';
  }
}

export function GroupNoteBadge({ playingGroupNote }: GroupNoteBadgeProps) {
  const { n } = useNoteNotation();
  const { t } = useTranslation();
  return (
    <AnimatePresence mode="popLayout">
      {playingGroupNote !== null && playingGroupNote.groupType !== null ? (
        <BadgeMotion
          initial={{ y: 5, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -5, opacity: 0 }}
          colorVariant={getBadgeColorVariant(playingGroupNote.groupType)}
          key={playingGroupNote.type}
        >
          {playingGroupNote !== null && playingGroupNote.groupType === 'chord'
            ? playingGroupNote.valueToDisplay
            : null}
          {playingGroupNote !== null && playingGroupNote.groupType === 'scale'
            ? `${n(playingGroupNote.valueToDisplay.tonicPitchClass)} ${t(`scale.name.${playingGroupNote.valueToDisplay.type}` as TranslationKey)}`
            : null}
        </BadgeMotion>
      ) : null}
    </AnimatePresence>
  );
}
