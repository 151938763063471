import { SurfaceId } from './design.types';

export function getClassnamesBySurfaceId(surfaceId: SurfaceId) {
  switch (surfaceId) {
    case 'app':
      return {
        background: 'surface-app',
        title: 'text-color-title',
        text: 'text-color-regular',
        ring: 'ring-color-regular',
      };
    case 'overlay':
      return {
        background: 'surface-overlay',
        title: 'text-color-title',
        text: 'text-color-regular',
        ring: 'ring-color-regular',
      };
    case 'ultra-light':
      return {
        background: 'surface-ultra-light',
        title: 'text-darker',
        text: 'text-dark',
        ring: 'ring-color-dark',
      };
    case 'light':
      return {
        background: 'surface-light',
        title: 'text-color-title',
        text: 'text-color-regular',
        ring: 'ring-color-regular',
      };
    case 'lighter':
      return {
        background: 'surface-lighter',
        title: 'text-color-title',
        text: 'text-color-regular',
        ring: 'ring-color-regular',
      };
    case 'darker':
      return {
        background: 'surface-darker',
        title: 'text-color-title',
        text: 'text-color-regular',
        ring: 'ring-color-regular',
      };
    case 'soft-invert':
      return {
        background: 'surface-soft-invert',
        title: 'text-color-title-inverted',
        text: 'text-color-regular-inverted',
        ring: 'ring-color-regular-inverted',
      };
    case 'invert':
      return {
        background: 'surface-invert',
        title: 'text-color-title-inverted',
        text: 'text-color-regular-inverted',
        ring: 'ring-color-regular-inverted',
      };
  }
}
