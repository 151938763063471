import { useTranslation } from '../../hooks/use-translation';
import { PageUrls } from '../../utils/routes.contants';
import { PageLayout } from '../../components/business/page-layout';
import { SettingsGeneral } from '../../components/business/settings/general';
import { TitleWithTextAbove } from '../../components/business/title-with-text-above';
import { BackLink } from '../../components/ui/back-link';

export function SettingsGeneralPage() {
  const { t } = useTranslation();

  return (
    <PageLayout
      title={
        <TitleWithTextAbove
          textAbove={t('pages.settings.title')}
          titleProps={{
            tagName: 'h1',
            level: 2,
            children: t('pages.settings.general.title'),
          }}
        />
      }
      leftAction={<BackLink to={PageUrls.SETTINGS} />}
      content={<SettingsGeneral />}
    />
  );
}
