import { FindTheNoteFromRootAndIntervalProgressPayload } from '../../../../../services/progress/find-the-note-from-root-and-interval/types';
import { IProgressService } from '../../../../../services/progress/progress.types';
import {
  FindTheNoteFromRootAndIntervalAnswer,
  FindTheNoteFromRootAndIntervalQuestion,
  FindTheNoteFromRootAndIntervalQuestionMeta,
  FindTheNoteFromRootAndIntervalResultMeta,
} from '../../types';

export const getProgressDataAction = async (
  progressService: IProgressService<
    FindTheNoteFromRootAndIntervalQuestion,
    FindTheNoteFromRootAndIntervalAnswer,
    FindTheNoteFromRootAndIntervalQuestionMeta,
    FindTheNoteFromRootAndIntervalResultMeta,
    FindTheNoteFromRootAndIntervalProgressPayload
  >,
) => {
  return progressService.getProgressDataByTuningId('not-related-to-tuning');
};
