import { StateCreator, createStore } from 'zustand';
import { Thematic } from '@notacami/core/thematic';
import { IProgressService } from '../progress/progress.types';
import { DEFAULT_QUIZ_DURATION } from './exercise.constants';
import {
  ComputeQuizTurnQuestionAction,
  ComputeQuizTurnResultAction,
  CreateExerciseStoreProps,
  ExerciseState,
  GetProgressDataAction,
  MainSlice,
  ManageProgressDataAction,
  QuizMode,
  QuizSlice,
} from './exercise.types';
import { storeLog } from './store-log';

function getMainSlice<
  Config,
  Question,
  Answer,
  QuestionMeta,
  ResultMeta,
  ProgressPayload,
>(
  thematic: Thematic,
  manageProgressData: ManageProgressDataAction<
    Config,
    Question,
    Answer,
    QuestionMeta,
    ResultMeta,
    ProgressPayload
  >,
  config: Config,
  progressService: IProgressService<
    Question,
    Answer,
    QuestionMeta,
    ResultMeta,
    ProgressPayload
  >,
): StateCreator<
  ExerciseState<
    Config,
    Question,
    Answer,
    QuestionMeta,
    ResultMeta,
    ProgressPayload
  >,
  [],
  [],
  MainSlice<Config>
> {
  return (set, get) => ({
    config,
    mainStep: 'configuration',
    thematic,
    quizMode: 'app',
    duration: DEFAULT_QUIZ_DURATION,
    launchQuiz: async () => {
      storeLog.actionStart('launchQuiz');
      set(INITIAL_QUIZ_STATE);
      await get()._prepareNextTurn();
      set({ mainStep: 'quiz' });
      storeLog.actionEnd('launchQuiz');
    },
    launchTutorial: (tutorialId: string) => {
      storeLog.actionStart('launchTutorial');
      set({ tutorialId, mainStep: 'tutorial' });
      storeLog.actionEnd('launchTutorial');
    },
    pauseQuiz: () => {
      storeLog.actionStart('pauseQuiz');
      set({ mainStep: 'pause' });
      storeLog.actionEnd('pauseQuiz');
    },
    resumeQuiz: () => {
      storeLog.actionStart('resumeQuiz');
      set({ mainStep: 'quiz' });
      storeLog.actionEnd('resumeQuiz');
    },
    leaveTutorial: () => {
      storeLog.actionStart('leaveTutorial');
      set({ tutorialId: undefined });
      get().launchQuiz();
      storeLog.actionEnd('leaveTutorial');
    },
    askLeaveQuiz: () => {
      storeLog.actionStart('askLeaveQuiz');
      set({ mainStep: 'end-confirmation' });
      storeLog.actionEnd('askLeaveQuiz');
    },
    cancelLeaveQuiz: () => {
      storeLog.actionStart('cancelLeaveQuiz');
      set({ mainStep: 'quiz' });
      storeLog.actionEnd('cancelLeaveQuiz');
    },
    confirmLeaveQuiz: async () => {
      storeLog.actionStart('confirmLeaveQuiz');
      await get()._persistProgression();
      set({ mainStep: 'configuration' });
      storeLog.actionEnd('confirmLeaveQuiz');
    },
    returnToConfigurationStepFromEndStep: () => {
      storeLog.actionStart('returnToConfigurationStepFromEndStep');
      set({ mainStep: 'configuration' });
      storeLog.actionEnd('returnToConfigurationStepFromEndStep');
    },
    updateConfigAndDurationAndQuizMode: (
      config: Config,
      duration: number,
      quizMode: QuizMode,
    ) => {
      storeLog.actionStart('updateConfigAndDurationAndQuizMode');
      set({
        config,
        duration,
        quizMode,
      });
      storeLog.actionEnd('updateConfigAndDurationAndQuizMode');
    },
    updateDurationAndQuizMode: (duration: number, quizMode: QuizMode) => {
      storeLog.actionStart('updateDurationAndQuizMode');
      set({
        duration,
        quizMode,
      });
      storeLog.actionEnd('updateDurationAndQuizMode');
    },
    updateConfig: (config: Config) => {
      storeLog.actionStart('updateConfig');
      set({
        config,
      });
      storeLog.actionEnd('updateConfig');
    },
    openConfig: () => {
      storeLog.actionStart('openConfig');
      set({ mainStep: 'config' });
      storeLog.actionEnd('openConfig');
    },
    closeConfig: () => {
      storeLog.actionStart('closeConfig');
      set({ mainStep: 'quiz' });
      storeLog.actionEnd('closeConfig');
    },
    _persistProgression: async () => {
      const config = get().config;
      const results = get().results;
      const questions = get().questions;
      const sessionPracticeTime = Math.round(get().sessionPracticeTime / 1000);

      await manageProgressData(
        progressService,
        config,
        questions,
        results,
        sessionPracticeTime,
      );
    },
  });
}

const INITIAL_QUIZ_STATE = {
  sessionPracticeTime: 0,
  numberOfTurnErrors: 0,
  currentTurn: 0,
  results: [],
  questions: [],
  isFinish: false,
  answerTipText: null,
  previousProgressData: null,
  currentProgressData: null,
};

function getQuizSlice<
  Config,
  Question,
  Answer,
  QuestionMeta,
  ResultMeta,
  ProgressPayload,
>(
  computeQuestion: ComputeQuizTurnQuestionAction<
    Config,
    Question,
    Answer,
    QuestionMeta
  >,
  computeResult: ComputeQuizTurnResultAction<
    Config,
    Question,
    QuestionMeta,
    Answer,
    ResultMeta
  >,
  getProgressData: GetProgressDataAction<
    Config,
    Question,
    Answer,
    QuestionMeta,
    ResultMeta,
    ProgressPayload
  >,
  progressService: IProgressService<
    Question,
    Answer,
    QuestionMeta,
    ResultMeta,
    ProgressPayload
  >,
): StateCreator<
  ExerciseState<
    Config,
    Question,
    Answer,
    QuestionMeta,
    ResultMeta,
    ProgressPayload
  >,
  [],
  [],
  QuizSlice<Question, Answer, QuestionMeta, ResultMeta, ProgressPayload>
> {
  return (set, get) => ({
    turnStep: 'play',
    ...INITIAL_QUIZ_STATE,
    updateSessionPracticeTime: (sessionPracticeTime: number) => {
      storeLog.actionStart('updateSessionPracticeTime');
      set({ sessionPracticeTime });
      storeLog.actionEnd('updateSessionPracticeTime');
    },
    _prepareNextTurn: async () => {
      set((state) => {
        return {
          turnStep: 'play',
          currentTurn: state.currentTurn + 1,
          questions: [
            ...state.questions,
            computeQuestion(state.config, state.questions, state.quizMode),
          ],
          answerTipText: null,
          numberOfTurnErrors: 0,
        };
      });
      if (get().currentTurn === 1) {
        const progressData = await getProgressData(
          progressService,
          get().config,
        );
        set({ previousProgressData: progressData });
      }
    },
    timeIsUp: () => {
      storeLog.actionStart('timeIsUp');
      set({ isFinish: true });
      storeLog.actionEnd('timeIsUp');
    },
    showAnswerTip: (answerTipText: string) => {
      storeLog.actionStart('showAnswerTip');
      set({ answerTipText });
      storeLog.actionEnd('showAnswerTip');
    },
    hideAnswerTip: () => {
      storeLog.actionStart('hideAnswerTip');
      set({ answerTipText: null });
      storeLog.actionEnd('hideAnswerTip');
    },
    addError: (currentUserAnswer: Answer) => {
      storeLog.actionStart('addError');
      const numberOfTurnErrors = get().numberOfTurnErrors;
      const currentTurn = get().currentTurn;
      const currentQuestion = get().questions[currentTurn - 1];
      const nextNumberOfTurnErrors = numberOfTurnErrors + 1;
      set({
        numberOfTurnErrors: nextNumberOfTurnErrors,
      });

      if (nextNumberOfTurnErrors === currentQuestion.numberOfErrorsAllowed) {
        get().submitAnswer(currentUserAnswer);
      }
      storeLog.actionEnd('addError');
    },
    submitAnswer: (userAnswer: Answer) => {
      storeLog.actionStart('submitAnswer');
      set((state) => {
        return {
          turnStep: 'result',
          results: [
            ...state.results,
            computeResult(
              state.config,
              state.questions[state.currentTurn - 1],
              userAnswer,
            ),
          ],
        };
      });
      storeLog.actionEnd('submitAnswer');
    },
    goToNextStep: async () => {
      storeLog.actionStart('goToNextStep');
      const isFinish = get().isFinish;

      if (!isFinish) {
        get()._prepareNextTurn();
      } else {
        await get()._persistProgression();

        const progressData = await getProgressData(
          progressService,
          get().config,
        );

        set({ currentProgressData: progressData });
        set({ mainStep: 'end' });
      }
      storeLog.actionEnd('goToNextStep');
    },
    skipQuestion: () => {
      storeLog.actionStart('skipQuestion');
      if (get().turnStep === 'result') {
        return;
      }
      const currentQuestions = get().questions;
      const numberOfQuestions = get().questions.length;
      const questions = structuredClone(currentQuestions).slice(
        0,
        numberOfQuestions - 1,
      );
      set((state) => ({
        questions,
        currentTurn: state.currentTurn - 1,
      }));
      get().goToNextStep();
      storeLog.actionEnd('skipQuestion');
    },
  });
}

export function createExerciseStore<
  Config,
  Question,
  Answer,
  QuestionMeta,
  ResultMeta,
  ProgressPayload,
>(
  props: CreateExerciseStoreProps<
    Config,
    Question,
    Answer,
    QuestionMeta,
    ResultMeta,
    ProgressPayload
  >,
) {
  storeLog.createStore();
  return createStore<
    ExerciseState<
      Config,
      Question,
      Answer,
      QuestionMeta,
      ResultMeta,
      ProgressPayload
    >
  >()((...a) => ({
    ...getMainSlice<
      Config,
      Question,
      Answer,
      QuestionMeta,
      ResultMeta,
      ProgressPayload
    >(
      props.thematic,
      props.manageProgressData,
      props.config,
      props.progressService,
    )(...a),
    ...getQuizSlice<
      Config,
      Question,
      Answer,
      QuestionMeta,
      ResultMeta,
      ProgressPayload
    >(
      props.computeQuizTurnQuestion,
      props.computeQuizTurnResult,
      props.getProgressData,
      props.progressService,
    )(...a),
  }));
}
