import { ReactNode } from 'react';
import { cn } from '@notacami/core/css';
import { Thematic } from '@notacami/core/thematic';
import {
  getColorVariantByThematic,
  getForegroundColorByThematic,
} from '@notacami/core/design';
import { LessonType } from '@notacami/core/lesson';
import { useTranslation } from '../../hooks/use-translation';
import { NavigationButtonContainer } from '../ui/navigation-button-container';
import { Badge } from '../ui/badge';
import { ThematicPageLinkIcon } from './thematic-page-link-icon';

type ThematicPageLinkItemProps = {
  children: ReactNode;
  isExercise: boolean;
  specificLabels?: string[];
  thematic: Thematic;
  to?: string;
  type: LessonType;
};

export function ThematicPageLinkItem({
  children,
  isExercise,
  specificLabels,
  thematic,
  to,
  type,
}: ThematicPageLinkItemProps) {
  const { t } = useTranslation();
  const thematicColorVariant = getColorVariantByThematic(thematic);

  return (
    <NavigationButtonContainer to={to}>
      <ThematicPageLinkIcon
        colorVariant={!isExercise ? 'neutral' : thematicColorVariant}
        lessonType={type}
      />
      <div className="vertical-content-distribution-xs">
        {isExercise ? (
          <span
            className={cn(
              getForegroundColorByThematic(thematic),
              'text-xs font-semibold',
            )}
          >
            {t(`lessonType.${type}`)}
          </span>
        ) : null}
        <span className="text-color-title text-sm">{children}</span>
        <div className="flex flex-wrap gap-2">
          {specificLabels !== undefined
            ? specificLabels.map((label) => (
                <Badge
                  surfaceId="lighter"
                  className="whitespace-nowrap"
                  key={label}
                >
                  {label}
                </Badge>
              ))
            : null}
        </div>
      </div>
    </NavigationButtonContainer>
  );
}
