import Emittery from 'emittery';
import { getContext, start } from 'tone';
import { IWindowFocusAndBlurService } from '../window-focus-and-blur';

type AppAudioContextEvents = {
  initialize: undefined;
};

export class AppAudioContext extends Emittery<AppAudioContextEvents> {
  public audioContext: AudioContext;
  private clickHandler: EventListener;

  constructor(windowFocusAndBlur: IWindowFocusAndBlurService) {
    super();

    this.clickHandler = this.initialize.bind(this);
    const focusHandler = this.resumeContextWhenSuspended.bind(this);
    const blurHandler = this.suspendContextWhenRunning.bind(this);

    window.addEventListener('click', this.clickHandler);

    windowFocusAndBlur.on('focus', focusHandler);
    windowFocusAndBlur.on('blur', blurHandler);
  }

  public async initialize() {
    window.removeEventListener('click', this.clickHandler);

    await start();

    this.audioContext = getContext().rawContext as AudioContext;
    this.emit('initialize');
  }

  private async resumeContextWhenSuspended() {
    if (this.audioContext && this.audioContext.state === 'suspended') {
      window.addEventListener('click', this.clickHandler);
    }
  }

  private suspendContextWhenRunning() {
    if (this.audioContext && this.audioContext.state === 'running') {
      this.audioContext.suspend();
    }
  }
}
