import { Note } from 'tonal';

export function getNotePositiveDistanceInSemitones(
  note1: string,
  note2: string,
): number {
  const note1Chroma = Note.chroma(note1);
  const note2Chroma = Note.chroma(note2);
  const ajustedNote2Chroma =
    note2Chroma < note1Chroma ? note2Chroma + 12 : note2Chroma;
  return ajustedNote2Chroma - note1Chroma;
}
