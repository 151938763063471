import { ReactNode, forwardRef } from 'react';
import { cn } from '@notacami/core/css';
import { MotionSurface } from '@notacami/ui';
import { ColorVariant } from '@notacami/core/design';

type QuizButtonType = 'button' | 'success' | 'error' | 'idle';

type QuizButtonProps = {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
  type: QuizButtonType;
};

function getColorVariantByType(type: QuizButtonType): ColorVariant {
  switch (type) {
    case 'button':
    case 'idle':
      return 'neutral';
    case 'success':
      return 'emerald';
    case 'error':
      return 'red';
  }
}

function getScaleByType(type: QuizButtonType): number {
  switch (type) {
    case 'button':
      return 1;
    case 'success':
      return 1.15;
    case 'error':
      return 1;
    case 'idle':
      return 1;
  }
}

export const QuizButton = forwardRef<HTMLDivElement, QuizButtonProps>(
  ({ children, className, onClick, type }, ref) => {
    return (
      <MotionSurface
        ref={ref}
        initial={{ opacity: 0, scale: 1 }}
        animate={{
          opacity: type === 'idle' ? 0.5 : 1,
          scale: getScaleByType(type),
        }}
        exit={{ opacity: 0, scale: 1 }}
        className={cn(
          'flex items-center justify-center rounded-lg px-3 py-2',
          (type === 'button' || type === 'idle') && 'regular-ring',
          type === 'button' && 'cursor-pointer',
          className,
        )}
        onClick={type === 'button' ? onClick : undefined}
        colorVariant={getColorVariantByType(type)}
      >
        {children}
      </MotionSurface>
    );
  },
);
