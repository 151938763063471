import { useStore } from 'zustand';
import { ExerciseStoreApi } from '../../../../../services/exercise/exercise.types';
import { EndConfirmationModal } from './end-confimation-modal';

type EndConfirmationStateProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
> = {
  store: ExerciseStoreApi<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
  >;
};

export const EndConfirmationState = <
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
>({
  store,
}: EndConfirmationStateProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload
>) => {
  const cancelLeaveQuiz = useStore(store, (state) => state.cancelLeaveQuiz);
  const confirmLeaveQuiz = useStore(store, (state) => state.confirmLeaveQuiz);
  const thematic = useStore(store, (state) => state.thematic);
  const mainStep = useStore(store, (state) => state.mainStep);
  const isEndConfirmation = mainStep === 'end-confirmation';

  const handleOpenChange = (value: boolean) => {
    if (isEndConfirmation && !value) {
      cancelLeaveQuiz();
    }
  };

  return (
    <EndConfirmationModal
      open={isEndConfirmation}
      onOpenChange={handleOpenChange}
      onConfirm={confirmLeaveQuiz}
      onCancel={cancelLeaveQuiz}
      thematic={thematic}
    />
  );
};
