import { Position } from '@notacami/core/fretboard';
import { ScaleTypeName } from '@notacami/core/scales';
import { getRandomElement } from '@notacami/core/utils';
import {
  FindScalePositionConfig,
  FindScalePositionQuestion,
  FindScalePositionTurnQuestion,
} from '../../../types';
import {
  Fretboard,
  ScalePositionType,
} from '../../../../../../services/fretboard';
import { getQuestionDerivatedData } from './get-question-derivated-data';
import { tryToFindScalePositionByScaleNameAndPosition } from './try-to-find-scale-position-by-scale-name-and-position';

export function computeQuestion(
  config: FindScalePositionConfig,
  previousQuestions: FindScalePositionTurnQuestion[],
): FindScalePositionQuestion {
  const lastQuestionTurn = previousQuestions.pop();

  const previousScaleName = lastQuestionTurn?.question?.scaleName;

  const availableScaleNames = config.scaleNames
    .filter(({ selected }) => selected)
    .map(({ name }) => name);

  const availableStringIndexes = config.selectableStrings
    .filter((selectableString) => selectableString.selected)
    .map((selectableString) => selectableString.index);

  const availableScalePositionTypes = config.scalePositionTypes
    .filter((selectableString) => selectableString.selected)
    .map((selectableString) => selectableString.type);

  const maybeScalePosition = tryToFindScalePositionByScaleName(
    config.fretboard,
    availableScaleNames,
    availableStringIndexes,
    availableScalePositionTypes,
    previousScaleName ? [previousScaleName] : [],
  );

  if (maybeScalePosition === null) {
    if (previousScaleName === undefined) {
      return null;
    }
    const lastChanceScalePosition = tryToFindScalePositionByScaleName(
      config.fretboard,
      [previousScaleName],
      availableStringIndexes,
      availableScalePositionTypes,
      [],
    );

    if (lastChanceScalePosition === null) {
      return null;
    } else {
      return {
        scaleName: lastChanceScalePosition.scaleName,
        scalePosition: lastChanceScalePosition.scalePosition,
        ...getQuestionDerivatedData(
          config.fretboard.noteDetails,
          lastChanceScalePosition.scalePosition,
        ),
      };
    }
  } else {
    return {
      scaleName: maybeScalePosition.scaleName,
      scalePosition: maybeScalePosition.scalePosition,
      ...getQuestionDerivatedData(
        config.fretboard.noteDetails,
        maybeScalePosition.scalePosition,
      ),
    };
  }
}

function tryToFindScalePositionByScaleName(
  fretboard: Fretboard,
  availableScaleTypeNames: ScaleTypeName[],
  availableStringIndexes: number[],
  availableScalePositionTypes: ScalePositionType[],
  scaleTypeNamesAlreadyTried: ScaleTypeName[],
) {
  if (availableScaleTypeNames.length === scaleTypeNamesAlreadyTried.length) {
    return null;
  }

  const numberOfStrings = fretboard.noteDetails.length;
  const numberOfFrets = fretboard.noteDetails[0].length;

  const scaleName = getRandomScaleName(
    availableScaleTypeNames,
    scaleTypeNamesAlreadyTried,
  );

  const maybeScalePosition = tryToFindScalePositionByScaleNameAndPosition(
    scaleName,
    fretboard,
    availableStringIndexes,
    availableScalePositionTypes,
    [
      ...fretboard.noteDetails.map((_, index) => [index, 0] satisfies Position),
      [numberOfStrings - 1, numberOfFrets - 1],
    ],
  );

  if (maybeScalePosition === null) {
    return tryToFindScalePositionByScaleName(
      fretboard,
      availableScaleTypeNames,
      availableStringIndexes,
      availableScalePositionTypes,
      [...scaleTypeNamesAlreadyTried, scaleName],
    );
  }

  return { scaleName, scalePosition: maybeScalePosition };
}

function getRandomScaleName(
  availabeScaleTypeNames: ScaleTypeName[],
  triedScaleTypeName: ScaleTypeName[],
) {
  if (availabeScaleTypeNames.length === 1) {
    return availabeScaleTypeNames[0];
  } else {
    return getRandomElement(availabeScaleTypeNames, triedScaleTypeName);
  }
}
