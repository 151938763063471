import { Control, useWatch } from 'react-hook-form';
import { Thematic } from '@notacami/core/thematic';
import { FindScalePositionConfig } from '../../types';
import { SubmitButton } from '../../../submit-button';
import { useTranslation } from '../../../../../hooks/use-translation';

type SubmitButtonProps = {
  thematic: Thematic;
  control: Control<FindScalePositionConfig>;
};

export function ConfigurationModalSubmitButton({
  control,
  thematic,
}: SubmitButtonProps) {
  const { t } = useTranslation();

  const selectableStrings = useWatch({ control, name: 'selectableStrings' });
  const scalePositionTypes = useWatch({
    control,
    name: 'scalePositionTypes',
  });
  const scaleNames = useWatch({
    control,
    name: 'scaleNames',
  });

  const hasAtLeastOneScalePositionTypesSelected = scalePositionTypes.some(
    (scalePositionType) => scalePositionType.selected,
  );

  const hasAtLeastOneSelectableStringsSelected = selectableStrings.some(
    (string) => string.selected,
  );

  const hasAtLeastOneSelectableScaleNamesSelected = scaleNames.some(
    (scaleName) => scaleName.selected,
  );

  const startGameAllowed =
    hasAtLeastOneScalePositionTypesSelected &&
    hasAtLeastOneSelectableStringsSelected &&
    hasAtLeastOneSelectableScaleNamesSelected;

  return (
    <SubmitButton thematic={thematic} disabled={!startGameAllowed}>
      {t('button.apply')}
    </SubmitButton>
  );
}
