import { FindTheNoteFromRootAndIntervalProgressPayload } from '../../../../../services/progress/find-the-note-from-root-and-interval/types';
import { IProgressService } from '../../../../../services/progress/progress.types';
import {
  FindTheNoteFromRootAndIntervalAnswer,
  FindTheNoteFromRootAndIntervalConfig,
  FindTheNoteFromRootAndIntervalQuestion,
  FindTheNoteFromRootAndIntervalQuestionMeta,
  FindTheNoteFromRootAndIntervalResultMeta,
  FindTheNoteFromRootAndIntervalTurnQuestion,
  FindTheNoteFromRootAndIntervalTurnResult,
} from '../../types';

export const manageProgressDataAction = async (
  progressService: IProgressService<
    FindTheNoteFromRootAndIntervalQuestion,
    FindTheNoteFromRootAndIntervalAnswer,
    FindTheNoteFromRootAndIntervalQuestionMeta,
    FindTheNoteFromRootAndIntervalResultMeta,
    FindTheNoteFromRootAndIntervalProgressPayload
  >,
  _config: FindTheNoteFromRootAndIntervalConfig,
  questions: FindTheNoteFromRootAndIntervalTurnQuestion[],
  results: FindTheNoteFromRootAndIntervalTurnResult[],
  sessionPracticeTime: number,
): Promise<void> => {
  const resultIndexesWithCorrectAnswer = results
    .map((_, index) => index)
    .filter((_, index) => results[index].isCorrect);

  if (resultIndexesWithCorrectAnswer.length > 0) {
    const filteredQuestions = questions.filter((_, index) =>
      resultIndexesWithCorrectAnswer.includes(index),
    );

    const filteredResults = results.filter((_, index) =>
      resultIndexesWithCorrectAnswer.includes(index),
    );

    await progressService.saveProgressFromResultsByTuningId(
      'not-related-to-tuning',
      filteredQuestions,
      filteredResults,
      sessionPracticeTime,
    );
  }
};
