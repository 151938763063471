import * as SentryReact from '@sentry/react';
import { Component, StrictMode } from 'react';
import { RouterBoot } from './router-boot';
import { ServicesBoot } from './services-boot';
import { PreferencesBoot } from './preferences-boot';
import { I18nBoot } from './i18n-boot';
import { ThemeBoot } from './theme-boot';
import { PersonaBoot } from './persona-boot';
import { DeviceBoot } from './device-boot';
import { HapticFeedbackBoot } from './haptic-boot';

class App extends Component {
  render() {
    return (
      <StrictMode>
        <SentryReact.ErrorBoundary fallback={<div>error</div>} showDialog>
          <PersonaBoot>
            <DeviceBoot>
              <ServicesBoot>
                <HapticFeedbackBoot>
                  <PreferencesBoot>
                    <I18nBoot>
                      <ThemeBoot>
                        <RouterBoot />
                      </ThemeBoot>
                    </I18nBoot>
                  </PreferencesBoot>
                </HapticFeedbackBoot>
              </ServicesBoot>
            </DeviceBoot>
          </PersonaBoot>
        </SentryReact.ErrorBoundary>
      </StrictMode>
    );
  }
}

export const SentryApp = SentryReact.withProfiler(App);
