import { isPositionEqual, Position } from '@notacami/core/fretboard';
import { AnimatePresence } from 'motion/react';
import { BuildCellsComponentProps } from '@notacami/ui-business/guitar-neck';
import {
  NeckCellInterval,
  NeckCellNote,
  NeckCellSelect,
  PlayingAreaCell,
} from '@notacami/ui-business/neck-cells';
import {
  Fretboard,
  PositionWithInterval,
} from '../../../../../services/fretboard';
import { QuizMode } from '../../../../../services/exercise/exercise.types';
import { useNoteNotation } from '../../../../../services/note-notation/use-note-notation';

type AnswerSectionPlayCellProps = BuildCellsComponentProps & {
  correctAnswer: Position;
  fretboard: Fretboard;
  givenRootPosition: Position;
  onSelect: (position: Position) => void;
  quizMode: QuizMode;
  selectablePositionsWithInterval: PositionWithInterval[];
};

export function AnswerSectionPlayCell({
  correctAnswer,
  fretboard,
  getElementByPosition,
  givenRootPosition,
  isOpenString,
  isVertical,
  onSelect,
  position,
  quizMode,
  selectablePositionsWithInterval,
}: AnswerSectionPlayCellProps) {
  const { n } = useNoteNotation();
  const fretboardNote = getElementByPosition(fretboard.noteDetails);

  const isSelectableCell = selectablePositionsWithInterval.some(
    (selectablePosition) =>
      isPositionEqual(selectablePosition.position, position),
  );

  const isRootPosition = isPositionEqual(givenRootPosition, position);
  const isCorrectPosition = isPositionEqual(correctAnswer, position);

  return (
    <div className="relative flex flex-col items-center">
      {quizMode === 'guitar' && isSelectableCell && !isRootPosition ? (
        <PlayingAreaCell
          className="relative"
          isOpenString={isOpenString}
          isVertical={isVertical}
        />
      ) : null}
      {isRootPosition ? (
        <NeckCellInterval
          isOpenString={isOpenString}
          isVertical={isVertical}
          notePitchClass={fretboardNote.pitchClass}
          interval="1P"
          position={position}
        />
      ) : null}
      {isOpenString && !isSelectableCell && !isRootPosition ? (
        <NeckCellNote
          isOpenString={true}
          isVertical={isVertical}
          noteLabel={n(fretboardNote.pitchClass)}
          position={position}
        />
      ) : null}
      <AnimatePresence mode="popLayout">
        {isSelectableCell && quizMode === 'app' ? (
          <NeckCellSelect
            isOpenString={isOpenString}
            position={position}
            value={false}
            onSelect={onSelect}
            isVertical={isVertical}
            dataTestId={isCorrectPosition ? 'correct-answer' : undefined}
          />
        ) : null}
      </AnimatePresence>
    </div>
  );
}
