import { Surface } from '@notacami/ui';
import { ArrowUpFromLine } from 'lucide-react';
import { getHueByNoteChroma } from '@notacami/core/notes';
import { PeakToDisplay } from './compute-grouped-peaks-to-display';

type PeaksLogMaximaPeakProps = {
  peaks: PeakToDisplay[];
};

export function PeaksLogMaximaPeak({ peaks }: PeaksLogMaximaPeakProps) {
  const noteChroma = peaks[0].noteChroma;
  return (
    <div className={'relative z-0 mt-2'}>
      <Surface
        surfaceId="lighter"
        className="flex h-12 flex-wrap items-center gap-x-2 rounded-md px-2 py-1 text-xs"
      >
        <ArrowUpFromLine size={22} />
        {noteChroma !== undefined ? (
          <div className="flex items-center gap-2">
            <div
              className="h-3 w-3 rounded-full"
              style={{
                backgroundColor: getHueByNoteChroma(noteChroma),
              }}
            />
            noteChroma: <strong>{noteChroma}</strong>
          </div>
        ) : null}
      </Surface>
      <Surface className="mx-2 rounded-b-md p-1" surfaceId="app">
        {peaks.reverse().map((peak, index) => (
          <div className="relative" key={peak.time}>
            {peak.level !== undefined ? (
              <Surface
                className="mb-1 rounded-sm px-2 py-2 text-sm last:mb-0"
                surfaceId="darker"
              >
                time: <strong className="tabular-nums">{peak.time}</strong>{' '}
                level:{' '}
                <strong className="tabular-nums">
                  {Math.round(peak.level * 100) / 100}
                </strong>
              </Surface>
            ) : null}
            {peaks[index + 1] && peak.level !== undefined ? (
              <Surface
                surfaceId="soft-invert"
                className="absolute -bottom-2 right-2 z-10 rounded-full px-2 text-xs tabular-nums"
              >
                {peak.time - peaks[index + 1].time} |{' '}
                {Math.round(
                  (peak.level - (peaks[index + 1].level || 0)) * 100,
                ) / 100}
              </Surface>
            ) : null}
          </div>
        ))}
      </Surface>
    </div>
  );
}
