import { motion } from 'motion/react';
import { ArrowBigUp } from 'lucide-react';
import { forwardRef } from 'react';
import { cn } from '@notacami/core/css';
import { ArrowPosition } from '@notacami/core/fretboard';

type HighlightPositionCellProps = {
  arrowPositionOnHorizontalNeck: ArrowPosition;
  isVertical: boolean;
  leftHanded: boolean;
  className?: string;
  disabled?: boolean;
};

function getRotationDegByArrowPosition(
  arrowPosition: ArrowPosition,
  leftHanded: boolean,
): number {
  switch (arrowPosition) {
    case ArrowPosition.BOTTOM_LEFT:
      return leftHanded ? 135 : 45;
    case ArrowPosition.BOTTOM:
      return leftHanded ? 180 : 0;
    case ArrowPosition.BOTTOM_RIGHT:
      return leftHanded ? -135 : -45;
    case ArrowPosition.LEFT:
      return 90;
    case ArrowPosition.RIGHT:
      return -90;
    case ArrowPosition.TOP_LEFT:
      return leftHanded ? 45 : 135;
    case ArrowPosition.TOP:
      return leftHanded ? 0 : 180;
    case ArrowPosition.TOP_RIGHT:
      return leftHanded ? -45 : -135;
    default:
      return 0;
  }
}

export const HighlightPositionCell = forwardRef<
  HTMLDivElement,
  HighlightPositionCellProps
>(
  (
    {
      arrowPositionOnHorizontalNeck,
      isVertical,
      leftHanded,
      className,
      disabled = false,
    },
    ref,
  ) => {
    const rotationDeg = getRotationDegByArrowPosition(
      arrowPositionOnHorizontalNeck,
      leftHanded,
    );

    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, scale: 3 }}
        animate={{ opacity: disabled ? 0.5 : 1, scale: 1 }}
        exit={{ opacity: 0, scale: 1 }}
        className={cn(
          'pointer-events-none absolute inset-0 z-10 flex items-center justify-center',
          className,
        )}
        style={{
          rotate: `${isVertical ? rotationDeg + 90 : rotationDeg}deg`,
          translate: '0 2rem',
          transformOrigin: `center -${isVertical ? 0.7 : 0.5}rem`,
        }}
      >
        <motion.div
          className={cn('h-14 w-14', 'dark:h-11 dark:w-11')}
          initial={{ y: 0 }}
          animate={{
            y: [0, 5],
          }}
          transition={{
            repeat: Infinity,
            repeatDelay: 0,
            repeatType: 'mirror',
            bounce: 1.5,
          }}
        >
          <ArrowBigUp
            className={cn(
              'stroke-1 dark:stroke-transparent',
              'h-14 w-14',
              'dark:h-11 dark:w-11',
              'fill-neutral-200 stroke-neutral-800 stroke-1 text-neutral-200 dark:stroke-transparent',
            )}
          />
        </motion.div>
      </motion.div>
    );
  },
);
HighlightPositionCell.displayName = 'HighlightPositionCell';
