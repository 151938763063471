import { cn } from '@notacami/core/css';
import { getBackgroundColorByThematic } from '@notacami/core/design';
import { Thematic } from '@notacami/core/thematic';

type ThematicDotProps = { thematic: Thematic };

export function ThematicDot({ thematic }: ThematicDotProps) {
  return (
    <div
      className={cn(
        getBackgroundColorByThematic(thematic),
        'rounded-full',
        'h-5 max-h-5 min-h-5 w-5 min-w-5 max-w-5',
      )}
    />
  );
}
