import { ReactNode, createContext } from 'react';
import { Theme } from './theme.types';

// Inspired by https://github.com/pacocoursey/next-themes

type ThemeContextType = {
  theme: Theme;
};

export const ThemeContext = createContext<ThemeContextType>(
  {} as ThemeContextType,
);

type ThemeProviderProps = {
  children: ReactNode;
  theme: Theme;
};

export function ThemeProvider({ children, theme }: ThemeProviderProps) {
  return (
    <ThemeContext.Provider
      value={{
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
