import { isPositionEqual } from '@notacami/core/fretboard';
import { BuildCellsComponentProps } from '@notacami/ui-business/guitar-neck';
import { NeckCellInterval } from '@notacami/ui-business/neck-cells';
import { ScalePosition } from '../../../services/fretboard';
import { PlayingNotesState } from '../lesson/main-steps/steps/step-teacher-playing/playing-notes.reducer';

type VisualizeScalePositionsNeckCellProps = BuildCellsComponentProps & {
  scalePositionsByStringIndex: ScalePosition[][];
  stringIndex: number;
  positionIndex: number;
  playingNotes: PlayingNotesState;
  scaleHasAtLeastOnePosition: boolean;
};

export function VisualizeScalePositionsNeckCell({
  position,
  isOpenString,
  isVertical,
  scalePositionsByStringIndex,
  stringIndex,
  positionIndex,
  playingNotes,
  scaleHasAtLeastOnePosition,
}: VisualizeScalePositionsNeckCellProps) {
  if (!scaleHasAtLeastOnePosition) {
    return;
  }

  const currentScalePosition =
    scalePositionsByStringIndex[stringIndex][positionIndex];

  const intervalPosition = currentScalePosition.positions.find(
    (positionWithInterval) =>
      isPositionEqual(positionWithInterval.position, position),
  );

  if (intervalPosition === undefined) {
    return null;
  }

  const isPlayed = playingNotes.some((playingNote) =>
    isPositionEqual(playingNote.position, position),
  );

  return (
    <NeckCellInterval
      isPlayed={isPlayed}
      isOpenString={isOpenString}
      isVertical={isVertical}
      interval={intervalPosition.interval ?? ''}
      position={position}
    />
  );
}
