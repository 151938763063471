import { getTuningInfo, TuningId } from '@notacami/core/tuning';
import { EventInPart } from '@notacami/core/composer';
import { FretboardNoteDetails } from '@notacami/core/fretboard';
import { GuitarNeck } from '@notacami/ui-business/guitar-neck';
import { useGuitarNeckVertical } from '../../../../../../hooks/use-guitar-neck-vertical';
import { useLeftHanded } from '../../../../../../services/fretboard';
import { PartId } from '../../../../../../services/sequence/sequence.constants';
import { usePlayingNotes } from './use-playing-notes';
import { useNoteToDisplay } from './use-notes-to-display';
import { StepTeacherPlayingNeckCell } from './step-teacher-playing-neck-cell';

type StepTeacherPlayingProps = {
  eventsInPart: EventInPart[];
  fretboardNoteDetails: FretboardNoteDetails;
  fretLength: number;
  tuningId: TuningId;
};

export function StepTeacherPlaying({
  eventsInPart,
  fretboardNoteDetails,
  fretLength,
  tuningId,
}: StepTeacherPlayingProps) {
  const isVertical = useGuitarNeckVertical();
  const leftHanded = useLeftHanded();
  const tuningInfo = getTuningInfo(tuningId);

  const playingNotes = usePlayingNotes(PartId.LESSON_TEACHER_PLAYING);

  const notesToDisplay = useNoteToDisplay(
    PartId.LESSON_TEACHER_PLAYING,
    eventsInPart,
  );

  return (
    <GuitarNeck
      leftHanded={leftHanded}
      isVertical={isVertical}
      numberOfFrets={fretLength}
      tuning={tuningInfo.notes}
      buildCellsComponent={StepTeacherPlayingNeckCell}
      additionalCellProps={{
        fretboardNoteDetails,
        notesToDisplay,
        playingNotePositions: playingNotes.map(({ position }) => position),
      }}
    />
  );
}
