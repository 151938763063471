import { FormFieldContainerLayout, Select } from '@notacami/ui';
import { useTranslation } from '../../../../hooks/use-translation';
import { Persona, PERSONAS } from '../../../../services/persona';
import { usePersonaStore } from '../../persona/use-persona-context';

export function PersonaSection() {
  const { t } = useTranslation();
  const setPersona = usePersonaStore((state) => state.setPersona);
  const persona = usePersonaStore((state) => state.persona);

  const handleChange = (value: Persona) => {
    const selectedCaptureMode = value;
    setPersona(selectedCaptureMode);
  };

  const options = PERSONAS.map((option) => ({
    id: option,
    label: option === 'none' ? t('not-defined') : option,
    value: option,
  }));

  return (
    <FormFieldContainerLayout
      id="persona-setting-field"
      label={t('pages.settings.admin.persona.label')}
      input={
        <Select
          name="persona-setting"
          options={options}
          onChange={handleChange}
          value={persona ?? Persona.NONE}
          className="w-36"
        />
      }
    />
  );
}
