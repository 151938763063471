import { ReactNode } from 'react';
import { Title } from '@notacami/ui';
import { Thematic } from '@notacami/core/thematic';
import { useTranslation } from '../../hooks/use-translation';
import { PageUrls } from '../../utils/routes.contants';
import { BackLink } from '../ui/back-link';
import { PageLayout } from './page-layout';
import { ThematicDot } from './thematic-dot';

type ThematicPageLayoutProps = { thematic: Thematic; children: ReactNode };

export function ThematicPageLayout({
  thematic,
  children,
}: ThematicPageLayoutProps) {
  const { t } = useTranslation();

  return (
    <PageLayout
      leftAction={<BackLink to={PageUrls.HOME} />}
      title={
        <Title
          level={2}
          tagName="h1"
          left={<ThematicDot thematic={thematic} />}
        >
          <span>{t(`thematic.${thematic}`)}</span>
        </Title>
      }
      content={
        <div className="vertical-content-distribution-lg">{children}</div>
      }
    />
  );
}
