import { useContext } from 'react';
import { FindTheIntervalOnTheNeckExerciseContext } from '../../store/context';
import { usePlayingState } from '../../../../../services/exercise/hooks/use-playing-state';
import { AnswerSectionPlay } from './answer-section-play';
import { AnswerSectionResult } from './answer-section-result';

export function AnswerSection() {
  const store = useContext(FindTheIntervalOnTheNeckExerciseContext);

  const { isInPlayingPlayState, isInPlayingResultState } =
    usePlayingState(store);

  return isInPlayingPlayState || isInPlayingResultState ? (
    <div className="flex min-h-full flex-col items-center justify-center py-5">
      {isInPlayingPlayState ? <AnswerSectionPlay /> : null}
      {isInPlayingResultState ? <AnswerSectionResult /> : null}
    </div>
  ) : null;
}
