import * as SentryCapacitor from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import { IAppService } from '../app';
import { IMonitoringService } from './monitoring.types';

export class MonitoringSentryService implements IMonitoringService {
  constructor(private readonly appService: IAppService) {}

  public initialize() {
    if (import.meta.env.VITE_SENTRY_DSN) {
      SentryCapacitor.init(
        {
          dsn: import.meta.env.VITE_SENTRY_DSN,
          release: `${this.appService.getName()}@${this.appService.getVersion()}`,
          // @ts-expect-error unknown type compatibility between Sentry packages
          integrations: [
            SentryReact.reactRouterV7BrowserTracingIntegration({
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            }),
            SentryReact.replayIntegration({
              maskAllText: false,
              blockAllMedia: false,
            }),
          ],
          tracesSampleRate: 1.0,
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
        },
        SentryReact.init,
      );
    }
  }
}
