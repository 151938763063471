import { getTuningInfo, TuningId, TuningInfo } from '@notacami/core/tuning';
import {
  DEFAULT_FRET_LENGTH,
  getFretboardNoteDetailsByTuningAndFretLength,
} from '@notacami/core/fretboard';
import { getFretboardIntervalsForEachPosition } from '../../../services/fretboard-interval';
import { Fretboard } from '../../../services/fretboard';

export function getFretboardAndTuningInfoByTuningId(tuningId: TuningId): {
  fretboard: Fretboard;
  tuningInfo: TuningInfo;
} {
  const tuningInfo = getTuningInfo(tuningId);
  const fretboard = getFretboardNoteDetailsByTuningAndFretLength(
    tuningInfo.notes,
    DEFAULT_FRET_LENGTH,
  );
  const fretboardIntervals = getFretboardIntervalsForEachPosition(fretboard);

  return {
    tuningInfo,
    fretboard: {
      noteDetails: fretboard,
      intervals: fretboardIntervals,
    },
  };
}
