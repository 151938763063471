import { cn } from '@notacami/core/css';
import { getRangeNumberByLength } from '@notacami/core/utils';
import { Sizes } from './guitar-neck.constants';

type NeckSizeLayoutProps = {
  fretLength: number;
  isVertical: boolean;
  numOfStrings: number;
};

export function NeckSizeLayout({
  fretLength,
  isVertical,
  numOfStrings,
}: NeckSizeLayoutProps) {
  return (
    <div
      className={cn(
        'flex',
        !isVertical && 'flex-row',
        isVertical && 'flex-col',
      )}
    >
      {getRangeNumberByLength(fretLength + 1).map((fretIndex) => (
        <div
          className={cn(
            'flex',
            !isVertical && 'flex-col',
            isVertical && 'flex-row',
            !isVertical &&
              fretIndex === 0 &&
              Sizes.OPEN_STRING_CELL_BY_STRING.h.w,
            !isVertical &&
              fretIndex !== 0 &&
              Sizes.NECK_FRET_CELL_BY_STRING.h.w,
            isVertical &&
              fretIndex === 0 &&
              Sizes.OPEN_STRING_CELL_BY_STRING.v.h,
            isVertical && fretIndex !== 0 && Sizes.NECK_FRET_CELL_BY_STRING.v.h,
          )}
          key={fretIndex}
        >
          {getRangeNumberByLength(numOfStrings).map((stringIndex) => (
            <div
              className={cn(
                !isVertical && Sizes.STRING_TRACK.h.h,
                isVertical && Sizes.STRING_TRACK.v.w,
              )}
              key={stringIndex}
            />
          ))}
        </div>
      ))}
    </div>
  );
}
