import { useContext } from 'react';
import { useStore } from 'zustand';
import { ExerciseState } from '../../../../services/exercise/exercise.types';
import {
  FindTheIntervalOnTheNeckAnswer,
  FindTheIntervalOnTheNeckConfig,
  FindTheIntervalOnTheNeckQuestion,
  FindTheIntervalOnTheNeckQuestionMeta,
  FindTheIntervalOnTheNeckResultMeta,
} from '../types';
import { FindTheIntervalOnTheNeckProgressPayload } from '../../../../services/progress/find-the-interval-on-the-neck/types';
import { FindTheIntervalOnTheNeckExerciseContext } from './context';

export const useFindTheIntervalOnTheNeckStore = <T>(
  selector: (
    state: ExerciseState<
      FindTheIntervalOnTheNeckConfig,
      FindTheIntervalOnTheNeckQuestion,
      FindTheIntervalOnTheNeckAnswer,
      FindTheIntervalOnTheNeckQuestionMeta,
      FindTheIntervalOnTheNeckResultMeta,
      FindTheIntervalOnTheNeckProgressPayload
    >,
  ) => T,
) => {
  const store = useContext(FindTheIntervalOnTheNeckExerciseContext);
  return useStore(store, selector);
};
