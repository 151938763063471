import { ReactNode } from 'react';
import { getArrowColor } from '@notacami/core/design';
import { cn } from '@notacami/core/css';
import { ConditionalWrapper } from '@notacami/core/components';
import { ArrowRight, ChevronRight } from 'lucide-react';
import { Button, SpinLoader } from '@notacami/ui';
import { WrappedLink } from './wrapped-haptic';

type NavigationButtonContainerProps = {
  children: ReactNode;
  onClick?: () => void;
  to?: string;
  withChevron?: boolean;
  withLoading?: boolean;
};

export function NavigationButtonContainer({
  children,
  onClick,
  to,
  withChevron,
  withLoading,
}: NavigationButtonContainerProps) {
  return (
    <ConditionalWrapper
      items={[
        {
          condition: to !== undefined,
          wrapper: (children, index) =>
            to !== undefined ? (
              <WrappedLink
                key={index}
                className="w-full"
                to={to}
                onClick={onClick}
                buttonVariant="outline"
              >
                {children}
              </WrappedLink>
            ) : null,
        },
        {
          condition: to === undefined && onClick !== undefined,
          wrapper: (children, index) => (
            <Button
              className="w-full"
              colorVariant="neutral"
              key={index}
              onClick={onClick}
              variant="outline"
            >
              {children}
            </Button>
          ),
        },
      ]}
    >
      <div className="flex w-full items-center justify-between gap-3 p-2 leading-tight">
        <div className="flex items-center justify-start gap-3">{children}</div>
        <div>
          {(to !== undefined || onClick !== undefined) &&
          !withLoading &&
          !withChevron ? (
            <ArrowRight className={cn(getArrowColor(), 'h-7 w-7')} />
          ) : null}
          {(to !== undefined || onClick !== undefined) &&
          !withLoading &&
          withChevron ? (
            <ChevronRight className={cn(getArrowColor(), 'h-7 w-7')} />
          ) : null}
          {(to !== undefined || onClick !== undefined) &&
          withLoading &&
          !withChevron ? (
            <SpinLoader className={cn(getArrowColor(), 'h-7 w-7')} />
          ) : null}
        </div>
      </div>
    </ConditionalWrapper>
  );
}
