import * as Slider from '@radix-ui/react-slider';
import { cn } from '@notacami/core/css';

type RangeInputProps = {
  defaultValues?: number[];
  min: number;
  max: number;
  onValueChange: (values: number[]) => void;
  widthClassName?: string;
};

export function RangeInput({
  defaultValues,
  min,
  max,
  onValueChange,
  widthClassName,
}: RangeInputProps) {
  const handleValueChange = (values: number[]) => {
    onValueChange(values);
  };

  const thumbClassnames =
    'block w-5 h-5 bg-neutral-100 rounded-full border-neutral-800 border';
  return (
    <Slider.Root
      className={cn(
        'relative flex h-5 touch-none select-none items-center',
        widthClassName || 'w-full',
      )}
      defaultValue={defaultValues || [min, max]}
      max={max}
      min={min}
      onValueChange={handleValueChange}
    >
      <Slider.Track className="relative h-1 flex-grow rounded-full bg-neutral-900">
        <Slider.Range className="bg-primary absolute h-full rounded-full" />
      </Slider.Track>
      <Slider.Thumb className={thumbClassnames} aria-label="Min" />
      <Slider.Thumb className={thumbClassnames} aria-label="Max" />
    </Slider.Root>
  );
}
