import { Button } from '@notacami/ui';
import { Settings } from 'lucide-react';

type RightActionSectionResultProps = {
  isConfigSectionExist: boolean;
  onConfigButtonClick: () => void;
};

export function RightActionSectionResult({
  isConfigSectionExist,
  onConfigButtonClick,
}: RightActionSectionResultProps) {
  return isConfigSectionExist ? (
    <Button
      colorVariant="neutral"
      onClick={onConfigButtonClick}
      size="icon"
      variant="rounded"
    >
      <Settings />
    </Button>
  ) : null;
}
