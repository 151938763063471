import { Position } from '@notacami/core/fretboard';
import { computePositionsRepetitionInfo } from './compute-positions-repetition-info';
import {
  ListeningStep,
  ListeningStepWithRepetitionInfo,
} from './teacher.types';

export function getListeningStepWithPositionsRepetitionInfo(
  listeningSteps: ListeningStep[],
  currentStepIndex: number,
  numberOfRepetition: number,
  currentRepetitionIndex: number,
): ListeningStepWithRepetitionInfo {
  const currentListeningStepPositions = listeningSteps[
    currentStepIndex
  ].notes.map(({ position }) => position);

  const result = getPositionsListSampleAndIndexInSample(
    listeningSteps,
    currentStepIndex,
    numberOfRepetition,
    currentRepetitionIndex,
  );

  if (result !== null) {
    const { numberOfPositionsRepetition, currentPositionsRepetitionIndex } =
      computePositionsRepetitionInfo(
        currentListeningStepPositions,
        result.indexInSample,
        result.positionsListSample,
      );
    return {
      ...listeningSteps[currentStepIndex],
      numberOfPositionsRepetition,
      currentPositionsRepetitionIndex,
    };
  }

  return {
    ...listeningSteps[currentStepIndex],
    numberOfPositionsRepetition: -2,
    currentPositionsRepetitionIndex: -2,
  };
}

function getPositionsListSampleAndIndexInSample(
  listeningSteps: ListeningStep[],
  currentStepIndex: number,
  numberOfRepetition: number,
  currentRepetitionIndex: number,
): {
  indexInSample: number;
  positionsListSample: Position[][];
} | null {
  const isFinite = numberOfRepetition !== -1;
  const hasLoop = numberOfRepetition !== 1;
  const isFirstRepetition = currentRepetitionIndex === 0 || !hasLoop;
  const isLastRepetition =
    isFinite && hasLoop && currentRepetitionIndex === numberOfRepetition - 1;
  const isMiddleRepetition = !isFirstRepetition && !isLastRepetition && hasLoop;
  const listeningStepsPositions = listeningSteps.map(({ notes }) =>
    notes.map(({ position }) => position),
  );

  if (isFirstRepetition && hasLoop) {
    const doubledListeningStepsPositions = [
      ...listeningStepsPositions,
      ...listeningStepsPositions,
    ];

    return {
      indexInSample: currentStepIndex,
      positionsListSample: doubledListeningStepsPositions,
    };
  }
  if (isLastRepetition) {
    const doubledListeningStepsPositions = [
      ...listeningStepsPositions,
      ...listeningStepsPositions,
    ];

    return {
      indexInSample: currentStepIndex + listeningSteps.length,
      positionsListSample: doubledListeningStepsPositions,
    };
  }
  if (isMiddleRepetition) {
    const tripledListeningStepsPositions = [
      ...listeningStepsPositions,
      ...listeningStepsPositions,
      ...listeningStepsPositions,
    ];

    return {
      indexInSample: currentStepIndex + listeningSteps.length,
      positionsListSample: tripledListeningStepsPositions,
    };
  }
  if (!hasLoop) {
    return {
      indexInSample: currentStepIndex,
      positionsListSample: listeningStepsPositions,
    };
  }
  return null;
}
