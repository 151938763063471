import { cn } from '@notacami/core/css';
import { SurfaceId } from '@notacami/core/design';
import { Surface } from './surface';

type DividerProps = {
  className?: string;
  surfaceId?: SurfaceId;
  isVertical?: boolean;
};

export function Divider({
  className,
  surfaceId = 'darker',
  isVertical = false,
}: DividerProps) {
  return (
    <Surface
      surfaceId={surfaceId}
      className={cn(isVertical ? 'w-px' : 'h-px', className)}
    />
  );
}
