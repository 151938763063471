import { DeviceId, DEVICES } from '@notacami/core/device';

function isExpectedDeviceIdFromUrl(
  maybeDeviceId: string | null,
): maybeDeviceId is DeviceId {
  return maybeDeviceId !== null
    ? DEVICES.includes(maybeDeviceId as DeviceId)
    : false;
}

export function useGetInitialDeviceIdFromUrl(): DeviceId | null {
  const pathname = document.location.pathname;
  const searchParams = new URLSearchParams(document.location.search);

  const deviceIdInUrl = searchParams.get('device-id');

  return isExpectedDeviceIdFromUrl(deviceIdInUrl) && pathname === '/'
    ? deviceIdInUrl
    : null;
}
