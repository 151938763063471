import { isPositionEqual, Position } from '@notacami/core/fretboard';
import {
  Fretboard,
  IntervalPositionAndDistance,
  PositionWithDistance,
  PositionWithInterval,
} from './fretboard.types';

function hasDefinedIntervalAndDistance(element: {
  position: Position;
  interval: string | undefined;
  distance: number | undefined;
}): element is IntervalPositionAndDistance {
  return element.interval !== undefined && element.distance !== undefined;
}

export function aggregateIntervalPositionAndDistance(
  fretboard: Fretboard,
  fretboardIntervalsFromRoot: PositionWithInterval[],
  fretboardDistancesFromRoot: PositionWithDistance[],
): IntervalPositionAndDistance[] {
  const aggregate = fretboard.noteDetails
    .flatMap((string, x) => {
      return string.map((_fret, y) => {
        const foundPositionDistance = fretboardDistancesFromRoot.find(
          ({ position: [x2, y2] }) => isPositionEqual([x2, y2], [x, y]),
        );
        const foundPositionInterval = fretboardIntervalsFromRoot.find(
          ({ position: [x2, y2] }) => isPositionEqual([x2, y2], [x, y]),
        );
        return {
          position: [x, y] as Position,
          interval: foundPositionInterval
            ? foundPositionInterval.interval
            : undefined,
          distance: foundPositionDistance
            ? foundPositionDistance.distance
            : undefined,
        };
      });
    })
    .filter(hasDefinedIntervalAndDistance);

  return aggregate;
}
