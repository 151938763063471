import { useContext, useState } from 'react';
import { SliderInputWithLabelAndValue } from '@notacami/ui';
import { ServicesContext } from '../../../services/services.context';
import {
  MIN_MAX_RATIO_MAX,
  MIN_MAX_RATIO_MIN,
  MIN_MAX_RATIO_STEP,
  POLYNOMIAL_MAX,
  POLYNOMIAL_MIN,
  POLYNOMIAL_STEP,
  WINDOW_SIZE_MAX,
  WINDOW_SIZE_MIN,
  WINDOW_SIZE_STEP,
} from '../../../services/peak-detection';

export function PeakDetectionControlsMaxima() {
  const { peakDetection } = useContext(ServicesContext);
  const [windowSize, setWindowSize] = useState(
    peakDetection.getMaximaWindowSize(),
  );
  const [minMaxRatio, setMinMaxRatio] = useState(
    peakDetection.getMaximaMinMaxRatio(),
  );
  const [polynomial, setPolynomial] = useState(
    peakDetection.getMaximaPolynomial(),
  );

  const handleWindowSizeChange = (value: number) => {
    setWindowSize(value);
    peakDetection.applyMaximaWindowSize(value);
  };

  const handleMinMaxRatioChange = (value: number) => {
    setMinMaxRatio(value);
    peakDetection.applyMaximaMinMaxRatio(value);
  };

  const handlePolynomialChange = (value: number) => {
    setPolynomial(value);
    peakDetection.applyMaximaPolynomial(value);
  };

  return (
    <div className="flex w-full flex-col">
      <strong className="text-sm">Maxima peak</strong>
      <SliderInputWithLabelAndValue
        ariaLabel="SG window size"
        label={<div className="whitespace-nowrap text-xs">SG window size</div>}
        value={windowSize}
        min={WINDOW_SIZE_MIN}
        max={WINDOW_SIZE_MAX}
        step={WINDOW_SIZE_STEP}
        onChange={handleWindowSizeChange}
        thematic="notes"
      />
      <SliderInputWithLabelAndValue
        ariaLabel="SG polynomial"
        label={<div className="whitespace-nowrap text-xs">SG polynomial</div>}
        value={polynomial}
        min={POLYNOMIAL_MIN}
        max={POLYNOMIAL_MAX}
        step={POLYNOMIAL_STEP}
        onChange={handlePolynomialChange}
        thematic="notes"
      />
      <SliderInputWithLabelAndValue
        ariaLabel="Min Max Ratio"
        label={<div className="whitespace-nowrap text-xs">Min Max Ratio</div>}
        value={minMaxRatio}
        min={MIN_MAX_RATIO_MIN}
        max={MIN_MAX_RATIO_MAX}
        step={MIN_MAX_RATIO_STEP}
        onChange={handleMinMaxRatioChange}
        thematic="notes"
      />
    </div>
  );
}
