import { UNALTERED_AND_ALTERED_NOTES } from '@notacami/core/notes';
import { shuffle } from 'lodash';
import { FindTheNoteFromRootAndIntervalAnswer } from '../../../types';

export function computeQuestionMeta(
  correctAnswer: FindTheNoteFromRootAndIntervalAnswer,
) {
  const listNotes = UNALTERED_AND_ALTERED_NOTES;
  const isListNotesContainCorrectAnswer =
    UNALTERED_AND_ALTERED_NOTES.includes(correctAnswer);
  const listNotesWithCorrectAnswer = isListNotesContainCorrectAnswer
    ? listNotes
    : [...listNotes, correctAnswer];

  return {
    listNotes: shuffle(listNotesWithCorrectAnswer),
  };
}
