import { getRandomElement } from '@notacami/core/utils';
import { isPositionEqual } from '@notacami/core/fretboard';
import { getFretboardPositionsByStringIndexesAndNumberOfFrets } from './get-fretboard-positions-by-string-indexes-and-number-of-frets';

export function getRandomPositionOnFretboard(
  stringIndexes: number[],
  numberOfFrets: number,
  excludedPositions?: [stringIndex: number, fretIndex: number][],
): [stringIndex: number, fretIndex: number] {
  const positions = getFretboardPositionsByStringIndexesAndNumberOfFrets(
    stringIndexes,
    numberOfFrets,
  );

  const filteredPositions = positions.filter((position) => {
    return excludedPositions === undefined
      ? true
      : !excludedPositions.some((excludedPosition) =>
          isPositionEqual(position, excludedPosition),
        );
  });

  const position = getRandomElement(filteredPositions);

  return position;
}
