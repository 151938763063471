import { useContext } from 'react';
import { usePreferencesStore } from '../preferences/use-preferences-context';
import { ServicesContext } from '../../../services/services.context';
import { States } from './states';
import { FindTheIntervalOnTheNeckExerciseProvider } from './store/context';
import { computeQuizTurnQuestionAction } from './store/specific-actions/compute-quiz-turn-question';
import { computeQuizTurnResultAction } from './store/specific-actions/compute-quiz-turn-result';
import { getProgressDataAction } from './store/specific-actions/get-progress-data';
import { manageProgressDataAction } from './store/specific-actions/manage-progress-data';
import { getDefaultConfig } from './get-default-config';

type FindTheIntervalOnTheNeckProps = {
  availableIntervals: string[] | null;
};

export function FindTheIntervalOnTheNeck({
  availableIntervals,
}: FindTheIntervalOnTheNeckProps) {
  const { findTheIntervalOnTheNeckProgress } = useContext(ServicesContext);
  const tuning = usePreferencesStore((state) => state.tuningInfo);
  const fretboard = usePreferencesStore((state) => state.fretboard);
  const defaultConfig = getDefaultConfig(tuning, fretboard, availableIntervals);

  return (
    <FindTheIntervalOnTheNeckExerciseProvider
      computeQuizTurnQuestion={computeQuizTurnQuestionAction}
      computeQuizTurnResult={computeQuizTurnResultAction}
      config={defaultConfig}
      getProgressData={getProgressDataAction}
      manageProgressData={manageProgressDataAction}
      progressService={findTheIntervalOnTheNeckProgress}
      thematic="intervals"
    >
      <States
        availableIntervals={availableIntervals}
        defaultConfig={defaultConfig}
      />
    </FindTheIntervalOnTheNeckExerciseProvider>
  );
}
