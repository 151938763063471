import { useSearchParams } from 'react-router';
import { INTERVALS_SEARCH_PARAM } from '../../utils/routes.contants';
import { FindTheIntervalOnTheNeck } from '../../components/business/find-the-interval-on-the-neck';

export function FindTheIntervalOnTheNeckPage() {
  const [searchParams] = useSearchParams();

  const intervals = searchParams.get(INTERVALS_SEARCH_PARAM);

  const availableIntervals = !intervals ? null : intervals.split(',');

  return <FindTheIntervalOnTheNeck availableIntervals={availableIntervals} />;
}
