import { ScaleTypeName } from './scale.types';

export const AVAILABLE_SCALE_TYPE_NAMES: ScaleTypeName[] = [
  'harmonic minor',
  'major blues',
  'major pentatonic',
  'major',
  'minor blues',
  'minor pentatonic',
  'minor',
];
