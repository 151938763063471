import { AnimatePresence } from 'motion/react';
import {
  ArrowPosition,
  isPositionEqual,
  isPositionIncludedInPositions,
  Position,
} from '@notacami/core/fretboard';
import { BuildCellsComponentProps } from '@notacami/ui-business/guitar-neck';
import {
  HighlightPositionCell,
  NeckCellInterval,
  NeckCellSelect,
  PlayingAreaCell,
} from '@notacami/ui-business/neck-cells';
import { ScalePosition } from '../../../../../services/fretboard';
import { QuizMode } from '../../../../../services/exercise/exercise.types';

type AnswerSectionPlayCellProps = BuildCellsComponentProps & {
  quizMode: QuizMode;
  scalePosition: ScalePosition;
  currentWay: Position[];
  wayStepIndex: number;
  arrowPosition: ArrowPosition;
  onSelect: (position: Position) => void;
  playingAreaPositions: Position[];
};

export function AnswerSectionPlayCell({
  isOpenString,
  position,
  isVertical,
  leftHanded,
  quizMode,
  scalePosition,
  currentWay,
  wayStepIndex,
  arrowPosition,
  onSelect,
  playingAreaPositions,
}: AnswerSectionPlayCellProps) {
  if (!isPositionIncludedInPositions(playingAreaPositions, position)) {
    return null;
  }

  const interval = scalePosition.positions.find((positionWithInterval) =>
    isPositionEqual(positionWithInterval.position, position),
  )?.interval;
  const positionIndexInCurrentWay = currentWay.findIndex(
    (positionWithInterval) => isPositionEqual(positionWithInterval, position),
  );
  const positionIndexInPlayingArea = playingAreaPositions.findIndex(
    (positionWithInterval) => isPositionEqual(positionWithInterval, position),
  );
  const isCorrect =
    positionIndexInCurrentWay < wayStepIndex &&
    positionIndexInCurrentWay !== -1;

  return (
    <div className="relative flex flex-col items-center">
      {quizMode === 'guitar' ? (
        <PlayingAreaCell
          className={!isCorrect ? 'relative' : 'absolute'}
          isOpenString={isOpenString}
          isVertical={isVertical}
        />
      ) : null}
      <AnimatePresence mode="popLayout">
        {isCorrect ? (
          <NeckCellInterval
            key="cell-interval"
            isOpenString={isOpenString}
            isVertical={isVertical}
            interval={interval ?? ''}
            position={position}
          />
        ) : null}

        {quizMode === 'app' && !isCorrect ? (
          <NeckCellSelect
            key="note-select"
            className="relative"
            isOpenString={isOpenString}
            isVertical={isVertical}
            onSelect={onSelect}
            position={position}
            dataTestId={
              positionIndexInCurrentWay !== -1
                ? `correct-position-${positionIndexInCurrentWay}`
                : `error-position-${positionIndexInPlayingArea}`
            }
            disabled={wayStepIndex === 0 && positionIndexInCurrentWay !== 0}
            value={false}
          />
        ) : null}
        {wayStepIndex === 0 && positionIndexInCurrentWay === 0 && !isCorrect ? (
          <HighlightPositionCell
            isVertical={isVertical}
            leftHanded={leftHanded}
            arrowPositionOnHorizontalNeck={arrowPosition}
          />
        ) : null}
      </AnimatePresence>
    </div>
  );
}
