import { Position } from '@notacami/core/fretboard';

type PlayingNotesAction =
  | {
      type: 'NOTE_START';
      id: string;
      position: Position;
    }
  | {
      type: 'NOTE_END';
      id: string;
    }
  | {
      type: 'RESET';
    };

export type PlayingNotesState = { id: string; position: Position }[];

export function playingNotesReducer(
  state: PlayingNotesState,
  action: PlayingNotesAction,
): PlayingNotesState {
  switch (action.type) {
    case 'NOTE_START':
      return state.some(({ id }) => id === action.id)
        ? state
        : [...state, { id: action.id, position: action.position }];
    case 'NOTE_END':
      return state.filter(({ id }) => id !== action.id);
    case 'RESET':
      return [];
    default:
      return state;
  }
}
