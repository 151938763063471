import { DEFAULT_NOTES_POSITION_FOR_SCALE } from '@notacami/core/notes';
import { DEFAULT_REFERENCE_FREQUENCY_NOTE_NUM } from '@notacami/core/frequency';

export function getNoteFromPitch(
  frequency: number,
  referenceFrequency: number,
) {
  const noteNumFromDiapason =
    12 * (Math.log(frequency / referenceFrequency) / Math.log(2));
  const num =
    Math.round(noteNumFromDiapason) + DEFAULT_REFERENCE_FREQUENCY_NOTE_NUM;
  const octave = Math.floor((num - 12) / 12);
  const name = DEFAULT_NOTES_POSITION_FOR_SCALE[num % 12];
  const centsOff = centsOffFromPitch(frequency, referenceFrequency, num);
  return { name, num, octave, centsOff };
}

function centsOffFromPitch(
  frequency: number,
  referenceFrequency: number,
  noteNum: number,
) {
  return Math.floor(
    (1200 *
      Math.log(
        frequency / frequencyFromNoteNumber(referenceFrequency, noteNum),
      )) /
      Math.log(2),
  );
}

function frequencyFromNoteNumber(referenceFrequency: number, noteNum: number) {
  return (
    referenceFrequency *
    Math.pow(2, (noteNum - DEFAULT_REFERENCE_FREQUENCY_NOTE_NUM) / 12)
  );
}
