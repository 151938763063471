import { useStore } from 'zustand';
import { ExerciseStoreApi } from '../exercise.types';

export function useProgressData<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
>(
  store: ExerciseStoreApi<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
  >,
) {
  const previousProgressData = useStore(
    store,
    (state) => state.previousProgressData,
  );
  const currentProgressData = useStore(
    store,
    (state) => state.currentProgressData,
  );

  if (previousProgressData === null || currentProgressData === null) {
    return null;
  }

  return { currentProgressData, previousProgressData };
}
