import { StoreCapacitor } from '../storage/storage.capacitor';
import { Persona } from './persona.types';

export function getPersonaCapacitorStore() {
  return new StoreCapacitor<Persona>(
    'persona',
    Persona.NONE,
    (value) => value,
    (value) => value as Persona,
  );
}
