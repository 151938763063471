import { useContext, useEffect, useState } from 'react';
import { useStore } from 'zustand';
import { PlayButton } from '@notacami/ui-business';
import { PartId } from '../../../../../../services/sequence/sequence.constants';
import { DerivedTeacherPlayingStep } from '../../../../../../services/lesson/lesson.types';
import { ServicesContext } from '../../../../../../services/services.context';
import { LessonContext } from '../../../../../../services/lesson';

type StepTeacherPlayingPlayButtonProps = {
  step: DerivedTeacherPlayingStep;
};

export function StepTeacherPlayingPlayButton({
  step,
}: StepTeacherPlayingPlayButtonProps) {
  const { soundPlayer } = useContext(ServicesContext);
  const store = useContext(LessonContext);
  const mainStep = useStore(store, (state) => state.mainStep);

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    if (!isPlaying) {
      soundPlayer.playEventsInPart(
        PartId.LESSON_TEACHER_PLAYING,
        step.eventsInPart,
        step.bpm,
      );
    } else {
      soundPlayer.stop();
    }
  };

  const handlePartStart = ({ partId }: { partId: string }) => {
    if (partId === PartId.LESSON_TEACHER_PLAYING) {
      setIsPlaying(true);
    }
  };

  const handleStop = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    if (mainStep === 'end-confirmation') {
      soundPlayer.stop();
    }
  }, [mainStep]);

  useEffect(() => {
    soundPlayer.on('part-start', handlePartStart);
    soundPlayer.on('stop', handleStop);
    soundPlayer.playEventsInPart(
      PartId.LESSON_TEACHER_PLAYING,
      step.eventsInPart,
      step.bpm,
    );

    return () => {
      soundPlayer.stop();
      soundPlayer.off('part-start', handlePartStart);
      soundPlayer.off('stop', handleStop);
    };
  }, []);

  return <PlayButton isPlaying={isPlaying} onClick={handlePlayClick} />;
}
