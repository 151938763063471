import { Settings } from 'lucide-react';
import { Thematic } from '@notacami/core/thematic';
import { Button } from '@notacami/ui';
import { RecordingButton } from '../../../recording-button/recording-button';

type RightActionSectionPlayProps = {
  isConfigSectionExist: boolean;
  onRecordingButtonClick?: () => void;
  onConfigButtonClick: () => void;
  thematic: Thematic;
};

export function RightActionSectionPlay({
  isConfigSectionExist,
  onRecordingButtonClick,
  onConfigButtonClick,
  thematic,
}: RightActionSectionPlayProps) {
  return (
    <>
      {onRecordingButtonClick ? (
        <RecordingButton onClick={onRecordingButtonClick} thematic={thematic} />
      ) : null}
      {isConfigSectionExist ? (
        <Button
          colorVariant="neutral"
          onClick={onConfigButtonClick}
          size="icon"
          variant="rounded"
        >
          <Settings />
        </Button>
      ) : null}
    </>
  );
}
