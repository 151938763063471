type Accumulator = [isAscending: boolean, previousIndex: number];

function getAreNumbersAscendingReducer(
  accumulator: Accumulator,
  currentNumber: number,
): Accumulator {
  if (!accumulator[0]) return accumulator;

  return [accumulator[1] <= currentNumber, currentNumber];
}

export function getAreNumbersAscending(numbers: number[]): boolean {
  const areNumbersAscending = numbers.reduce(getAreNumbersAscendingReducer, [
    true,
    -Infinity,
  ])[0];

  return areNumbersAscending;
}
