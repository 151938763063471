import { PageId } from './page.constants';

export const PageUrls: Record<PageId, string> = {
  // home
  HOME: '/',

  // thematics
  SCALES: '/scales',
  INTERVALS: '/intervals',
  NOTES: '/notes',

  // explanations
  VISUALIZE_SCALE_POSITIONS: '/visualize-scale-positions',
  VISUALIZE_INTERVAL_POSITIONS: '/visualize-interval-positions',

  // fretboard-exercises
  FIND_THE_NOTE_ON_THE_NECK: '/find-the-note-on-the-neck',
  FIND_THE_INTERVAL_ON_THE_NECK: '/find-the-interval-on-the-neck',
  FIND_SCALE_POSITION: '/find-scale-position',

  // quiz-exercises
  FIND_THE_NOTE_FROM_ROOT_AND_INTERVAL: '/find-the-note-from-root-and-interval',

  // listening-exercises
  // ...nothing yet

  // miscellaneous
  SETTINGS: '/settings',
  SETTINGS_GENERAL: '/settings/general',
  SETTINGS_MUSIC: '/settings/music',
  SETTINGS_INSTRUMENT: '/settings/instrument',
  SETTINGS_ADMIN: '/settings/admin',
  SETTINGS_USER_ACCOUNT: '/settings/user-account',

  PITCH_DETECTION_DEBUG: '/pitch-detection-debug',
  NOTE_PLAYED_DEBUG: '/note-played-debug',
  MIC_INFO: '/mic-info',
  SECURITY: '/security',

  LESSON: '/lesson/:id',
  LESSON_CREATION: '/lesson-creation',
};

export const SCALE_SEARCH_PARAM = 'scale';

export const STRING_SEARCH_PARAM = 'string';

export const INTERVALS_SEARCH_PARAM = 'intervals';
