import { DEFAULT_REFERENCE_FREQUENCY } from '@notacami/core/frequency';
import { StoreCapacitor } from '../storage.capacitor';

export function getPreferenceReferenceFrequencyCapacitorStore() {
  return new StoreCapacitor<number>(
    'settings.reference-frequency',
    DEFAULT_REFERENCE_FREQUENCY,
    (value) => value.toString(),
    (value) => parseInt(value),
  );
}
