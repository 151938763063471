import { LessonId, LESSONS } from '@notacami/core/lesson';
import { LessonProvider } from '../../../services/lesson';
import { IStopWatch } from '../../../services/stopwatch';
import { ILessonProgressService } from '../../../services/lesson-progress/lesson-progress.types';
import { LessonMainSteps } from './lesson-main-steps';

type LessonProps = {
  lessonId: LessonId;
  lessonProgress: ILessonProgressService;
  stopWatch: IStopWatch;
};

export function Lesson({ lessonId, lessonProgress, stopWatch }: LessonProps) {
  const lessonInfo = LESSONS[lessonId];

  return (
    <LessonProvider
      lessonInfo={lessonInfo}
      lessonProgress={lessonProgress}
      stopWatch={stopWatch}
    >
      <LessonMainSteps />
    </LessonProvider>
  );
}
