import { ReactNode, forwardRef } from 'react';
import { motion } from 'motion/react';
import { Surface } from '@notacami/ui';

type QuestionBubbleProps = {
  children: ReactNode;
  result: ReactNode;
};

export const QuestionBubble = forwardRef<HTMLDivElement, QuestionBubbleProps>(
  ({ children, result }, ref) => {
    return (
      <motion.div
        ref={ref}
        className="relative max-w-md"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -20, opacity: 0 }}
      >
        <Surface
          surfaceId="darker"
          className="relative z-10 rounded-xl px-4 py-2"
        >
          {children}
          <div className="absolute -bottom-3 right-2">{result}</div>
        </Surface>
      </motion.div>
    );
  },
);
QuestionBubble.displayName = 'QuestionBubble';
