import { DividerWithTitle } from '@notacami/ui';
import { NavigationButtonContainer } from '../../../ui/navigation-button-container';
import { useTranslation } from '../../../../hooks/use-translation';
import { PageUrls } from '../../../../utils/routes.contants';

export function ServicesSection() {
  const { t } = useTranslation();

  return (
    <div className="vertical-content-distribution-base">
      <DividerWithTitle title="Services" tagName="h2" titleLevel={3} />
      <div className="vertical-content-distribution-sm">
        <NavigationButtonContainer to={PageUrls.PITCH_DETECTION_DEBUG}>
          {t('pages.PITCH_DETECTION_DEBUG.title')}
        </NavigationButtonContainer>
        <NavigationButtonContainer to={PageUrls.NOTE_PLAYED_DEBUG}>
          {t('pages.NOTE_PLAYED_DEBUG.title')}
        </NavigationButtonContainer>
      </div>
    </div>
  );
}
