import { forwardRef, useContext, useState } from 'react';
import { AudioWaveform, Guitar, TabletSmartphone } from 'lucide-react';
import { motion } from 'motion/react';
import {
  getClassnamesByColorVariant,
  getColorVariantByThematic,
} from '@notacami/core/design';
import { cn } from '@notacami/core/css';
import { sleep } from '@notacami/core/testing';
import { Thematic } from '@notacami/core/thematic';
import { Button } from '@notacami/ui';
import { MicError } from '../../../services/pitch-detection';
import { TutorialStepContent } from '../tutorial-step-content';
import { ConsumersIds } from '../../../services/consumer';
import { useTranslation } from '../../../hooks/use-translation';
import { ServicesContext } from '../../../services/services.context';
import { useMicTutorialStore } from './mic-tutorial.store';

type ExplainPermissionStepProps = {
  thematic: Thematic;
};

export const ExplainPermissionStep = forwardRef<
  HTMLDivElement,
  ExplainPermissionStepProps
>(({ thematic }, ref) => {
  const { pitchDetectionConsumer } = useContext(ServicesContext);
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const grantPermission = useMicTutorialStore((state) => state.grantPermission);
  const denyPermission = useMicTutorialStore((state) => state.denyPermission);
  const unsupportedMic = useMicTutorialStore((state) => state.unsupportedMic);

  const handleAskForMicPermission = async () => {
    setPending(true);
    await sleep(100);
    const micError = await pitchDetectionConsumer.addConsumer(
      ConsumersIds.MIC_TUTORIAL_EXPLAIN_PERMISSION_STEP,
    );

    switch (micError?.error) {
      case null:
        grantPermission();
        break;
      case MicError.NOT_PERMITTED:
        denyPermission();
        break;
      case MicError.UNSUPPORTED:
        unsupportedMic();
        break;
    }

    pitchDetectionConsumer.removeConsumer(
      ConsumersIds.MIC_TUTORIAL_EXPLAIN_PERMISSION_STEP,
    );
  };

  const colorVariant = getColorVariantByThematic(thematic);

  return (
    <TutorialStepContent
      ref={ref}
      content={
        <>
          <div className="flex items-center justify-center">
            <Guitar
              strokeWidth={1.2}
              className="h-20 w-20 stroke-neutral-600 dark:stroke-neutral-300"
            />
            <motion.div
              animate={{
                opacity: [0.5, 1, 0.5],
                transition: {
                  repeatDelay: 0.5,
                  repeatType: 'loop',
                  repeat: Infinity,
                },
              }}
            >
              <AudioWaveform
                strokeWidth={1.5}
                className={cn(
                  'h-8 w-8',
                  getClassnamesByColorVariant(colorVariant).stroke,
                )}
              />
            </motion.div>
            <TabletSmartphone
              strokeWidth={1.5}
              className="ml-3 h-14 w-14 stroke-neutral-600 dark:stroke-neutral-300"
            />
          </div>
          <span className="max-w-xs whitespace-pre-line text-center">
            {t('tutorial.mic-tutorial.explain-permission-step.description')}
          </span>
        </>
      }
      action={
        <Button
          colorVariant={colorVariant}
          onClick={handleAskForMicPermission}
          pending={pending}
        >
          {t('button.next')}
        </Button>
      }
    />
  );
});

ExplainPermissionStep.displayName = 'ExplainPermissionStep';
