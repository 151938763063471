import { QuizMode } from '../../../../../services/exercise/exercise.types';
import {
  FindTheIntervalOnTheNeckConfig,
  FindTheIntervalOnTheNeckTurnQuestion,
} from '../../types';
import { computeCorrectAnswer } from './utils/compute-correct-answer';
import { computeNumberOfErrorsAllowed } from './utils/compute-number-or-errors-allowed';
import { computeQuestion } from './utils/compute-question';
import { computeQuestionMeta } from './utils/compute-question-meta';

export const computeQuizTurnQuestionAction = (
  config: FindTheIntervalOnTheNeckConfig,
  previousQuestions: FindTheIntervalOnTheNeckTurnQuestion[],
  quizMode: QuizMode,
): FindTheIntervalOnTheNeckTurnQuestion => {
  const question = computeQuestion(config, previousQuestions);
  const meta = computeQuestionMeta(config, question);
  const correctAnswer = computeCorrectAnswer(config, question);
  const numberOfErrorsAllowed = computeNumberOfErrorsAllowed(quizMode);

  const turnQuestion = {
    question,
    meta,
    correctAnswer,
    numberOfErrorsAllowed,
  };

  return turnQuestion;
};
