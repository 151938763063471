import { useContext, useEffect, useState } from 'react';
import { Volume, Volume2 } from 'lucide-react';
import { ServicesContext } from '../../../services/services.context';
import { Badge } from '../../ui/badge';
import { ConsumersIds } from '../../../services/consumer';

export function SilentSection() {
  const [soundDetected, setSoundDetected] = useState(false);
  const { pitchDetection, pitchDetectionConsumer } =
    useContext(ServicesContext);

  const handleSilenceDetect = () => {
    setSoundDetected(false);
  };

  const handleSoundDetect = () => {
    setSoundDetected(true);
  };

  useEffect(() => {
    pitchDetection.on('silence-detect', handleSilenceDetect);
    pitchDetection.on('sound-detect', handleSoundDetect);
    pitchDetectionConsumer.addConsumer(ConsumersIds.NOTE_PLAYED_DEBUG_SILENT);
    return () => {
      pitchDetection.off('silence-detect', handleSilenceDetect);
      pitchDetection.off('sound-detect', handleSoundDetect);
      pitchDetectionConsumer.removeConsumer(
        ConsumersIds.NOTE_PLAYED_DEBUG_SILENT,
      );
    };
  }, []);

  return (
    <div className="flex items-center gap-2">
      <div className="text-sm">Sound detected</div>
      <Badge
        colorVariant="indigo"
        className={soundDetected ? 'opacity-100' : 'opacity-30'}
      >
        {soundDetected ? <Volume2 size={22} /> : <Volume size={22} />}
      </Badge>
    </div>
  );
}
