const texturedString =
  'border-dotted border border-neutral-400 bg-neutral-300 dark:border-neutral-400 dark:bg-neutral-600 shadow-md';

const stringWidthClassnames = [
  `h-[5px] ${texturedString}`,
  `h-[4px] ${texturedString}`,
  `h-[3px] ${texturedString}`,
  `h-[2px] ${texturedString}`,
  `h-[2px] bg-neutral-300 dark:bg-neutral-500`,
  'h-px bg-neutral-300 dark:bg-neutral-500',
];
const stringHeightClassnames = [
  `w-[5px] ${texturedString}`,
  `w-[4px] ${texturedString}`,
  `w-[3px] ${texturedString}`,
  `w-[2px] ${texturedString}`,
  'w-[2px] bg-neutral-300 dark:bg-neutral-500',
  'w-px bg-neutral-300 dark:bg-neutral-500',
];

export function getStringClassnames(octave: number, vertical: boolean): string {
  const num = [0, 0, 0, 1, 2, 3, 4, 5, 5, 5, 5][octave];
  return vertical ? stringHeightClassnames[num] : stringWidthClassnames[num];
}
