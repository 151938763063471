import { DEFAULT_FRET_LENGTH } from '@notacami/core/fretboard';
import { GuitarNeck } from '@notacami/ui-business/guitar-neck';
import { useGuitarNeckVertical } from '../../../../../hooks/use-guitar-neck-vertical';
import { useLeftHanded } from '../../../../../services/fretboard';
import {
  FindScalePositionConfig,
  FindScalePositionExistingQuestion,
} from '../../types';
import { AnswerSectionResultCell } from './answer-section-result-cell';

type AnswerSectionResultProps = {
  config: FindScalePositionConfig;
  question: FindScalePositionExistingQuestion;
};

export function AnswerSectionResult({
  config,
  question,
}: AnswerSectionResultProps) {
  const isVertical = useGuitarNeckVertical();
  const leftHanded = useLeftHanded();

  if (question === null) {
    return null;
  }

  const positions = question.scalePosition.positions;

  return (
    <GuitarNeck
      buildCellsComponent={AnswerSectionResultCell}
      isVertical={isVertical}
      leftHanded={leftHanded}
      numberOfFrets={DEFAULT_FRET_LENGTH}
      tuning={config.tuningInfo.notes}
      additionalCellProps={{
        positions,
      }}
    />
  );
}
