import { Position } from '@notacami/core/fretboard';
import { ScaleTypeName } from '@notacami/core/scales';
import { getRandomElement, getRangeNumberByLength } from '@notacami/core/utils';
import {
  Fretboard,
  getRandomPositionOnFretboard,
  ScalePosition,
  ScalePositionType,
} from '../../../../../../services/fretboard';
import { getScalePositionsByScaleTypeNameAndPosition } from './get-scale-positions-by-scale-type-name-and-position';

export function tryToFindScalePositionByScaleNameAndPosition(
  scaleTypeName: ScaleTypeName,
  fretboard: Fretboard,
  availableStringIndexes: number[],
  availableScalePositionTypes: ScalePositionType[],
  positionsAlreadyTried: Position[],
) {
  const numberOfFrets = fretboard.noteDetails[0].length;

  const numberOfPossiblePositions =
    availableStringIndexes.length * numberOfFrets;

  if (numberOfPossiblePositions === positionsAlreadyTried.length) {
    return null;
  }

  const randomPosition = getRandomPositionOnFretboard(
    availableStringIndexes,
    numberOfFrets,
    positionsAlreadyTried,
  );

  const scalePositions = getScalePositionsByScaleTypeNameAndPosition(
    fretboard,
    scaleTypeName,
    randomPosition,
  );

  const hasAtLeastOneScalePositionWithInterestingType = scalePositions.some(
    (scalePosition) => availableScalePositionTypes.includes(scalePosition.type),
  );

  if (hasAtLeastOneScalePositionWithInterestingType) {
    return getRandomScalePositionByAvailableTypes(
      scalePositions,
      availableScalePositionTypes,
    );
  }

  return tryToFindScalePositionByScaleNameAndPosition(
    scaleTypeName,
    fretboard,
    availableStringIndexes,
    availableScalePositionTypes,
    [...positionsAlreadyTried, randomPosition],
  );
}

function getRandomScalePositionByAvailableTypes(
  scalePositions: ScalePosition[],
  availableScalePositionTypes: ScalePositionType[],
): ScalePosition {
  const filteredScalePositions = scalePositions.filter(({ type }) =>
    availableScalePositionTypes.includes(type),
  );

  const indexes = getRangeNumberByLength(filteredScalePositions.length);
  const randomIndex = getRandomElement(indexes);
  return filteredScalePositions[randomIndex];
}
