import { CornerDownLeft } from 'lucide-react';
import { getIconSizeByButtonSize } from '@notacami/ui';
import { useTranslation } from '../../hooks/use-translation';
import { WrappedLink } from './wrapped-haptic';

type BackLinkProps = {
  to: string;
};
export function BackLink({ to }: BackLinkProps) {
  const { t } = useTranslation();
  return (
    <WrappedLink
      to={to}
      title={t('button.back')}
      buttonVariant="link"
      buttonSize="icon"
    >
      <CornerDownLeft size={getIconSizeByButtonSize('icon')} />
    </WrappedLink>
  );
}
