import { getClassnamesByColorVariant } from '@notacami/core/design';
import { cva } from 'class-variance-authority';

const redButtonClassnames = getClassnamesByColorVariant('red');

export const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-xl transition-colors focus-visible:outline-hidden disabled:pointer-events-none disabled:opacity-50 duration-300',
  {
    variants: {
      size: {
        default: 'px-4 py-2 gap-1',
        sm: 'px-2 py-1 text-sm gap-1',
        lg: 'px-6 py-3 text-base font-semibold gap-2',
        icon: 'w-12 h-12',
        'icon-sm': 'w-9 h-9',
        'icon-xs': 'w-6 h-6',
      },
      variant: {
        default: '',
        destructive: [
          redButtonClassnames.background,
          redButtonClassnames.backgroundHover,
          redButtonClassnames.text,
        ],
        outline: 'ring-2',
        ghost: '',
        link: 'p-0 underline underline-offset-4 hover:no-underline ',
        rounded: 'rounded-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);
