import { PeakToDisplay } from './compute-grouped-peaks-to-display';
import { PeaksLogMaximaPeak } from './peaks-log-maxima-peak';
import { PeaksLogMinimaPeak } from './peaks-log-minima-peak';

type PeaksLogItemProps = {
  peaks: PeakToDisplay[];
};

export function PeaksLogItem({ peaks }: PeaksLogItemProps) {
  const isMaxima = peaks.every((peak) => peak.isMaxima);
  return isMaxima ? (
    <PeaksLogMaximaPeak peaks={peaks} />
  ) : (
    <PeaksLogMinimaPeak numberOfPeaks={peaks.length} />
  );
}
