type TimeInMinutesSecondsMs = {
  minutes: number;
  seconds: number;
  milliseconds: number;
};

export function convertMsToMMSSmm(timeInMs: number): TimeInMinutesSecondsMs {
  const minutes = Math.floor((timeInMs / 60000) % 60);
  const seconds = Math.floor((timeInMs / 1000) % 60);
  const milliseconds = Math.floor((timeInMs / 10) % 100);
  return {
    minutes,
    seconds,
    milliseconds,
  };
}
