import { Scale } from 'tonal';
import { Position } from '@notacami/core/fretboard';
import { ScaleTypeName } from '@notacami/core/scales';
import { getScalePositionsAcrossEntireNeckHeight } from '../../../../../../services/fretboard-scale';
import {
  Fretboard,
  MAX_FRET_DIFF_BY_SEGMENT,
  MAX_FRET_DIFF_BY_STRING,
} from '../../../../../../services/fretboard';

export function getScalePositionsByScaleTypeNameAndPosition(
  fretboard: Fretboard,
  scaleTypeName: ScaleTypeName,
  position: Position,
) {
  const scaleInfo = Scale.get(scaleTypeName);
  const scaleIntervals = scaleInfo.intervals;

  const scalePositions = getScalePositionsAcrossEntireNeckHeight(
    fretboard,
    scaleIntervals,
    position,
    MAX_FRET_DIFF_BY_STRING,
    MAX_FRET_DIFF_BY_SEGMENT,
  );

  return scalePositions;
}
