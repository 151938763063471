import { IStore } from './storage.type';

export class StoreInMemory<T> implements IStore<T> {
  private value: T;

  constructor(defaultValue: T) {
    this.value = defaultValue;
  }

  public async set(value: T): Promise<void> {
    this.value = value;
  }

  public async get(): Promise<T> {
    return this.value;
  }

  delete(): Promise<void> {
    return Promise.resolve();
  }
}
