import { Note } from 'tonal';
import { UNALTERED_NOTES, UNALTERED_NOTES_LATIN } from '../notes';
import { NoteNotation } from './note-notation.types';
import { replaceSharpAndFlatSymbols } from './replace-sharp-flat-symbols';

export function translateNoteName(noteNotation: NoteNotation) {
  return (note: string) => {
    const noteInfo = Note.get(note);
    const step = noteInfo.step ?? 0;
    const accidentals = noteInfo.acc;
    const noteName =
      noteNotation === 'latin'
        ? UNALTERED_NOTES_LATIN[step]
        : UNALTERED_NOTES[step];
    return replaceSharpAndFlatSymbols(`${noteName}${accidentals}`);
  };
}
