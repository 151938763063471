import { Controller, useForm } from 'react-hook-form';
import { SwitchField } from '@notacami/ui';
import { FindTheNoteFromRootAndIntervalConfig } from '../../types';
import { useFindTheNoteFromRootAndIntervalStore } from '../../store/hook';
import { useTranslation } from '../../../../../hooks/use-translation';
import { useSafeAreaInsets } from '../../../../../hooks/use-safe-area-insets';
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from '../../../../ui/drawer';
import { RestoreDefaultConfigButton } from '../../../exercise/states/playing/restore-default-config-button';
import { MultiSelectOption } from '../../../../ui/multi-select';
import { SelectableInterval } from '../../../find-the-interval-on-the-neck/types';
import { MultiSelectField } from '../../../../ui/form/multi-select-field';
import { ConfigurationModalSubmitButton } from './configuration-modal-submit-button';

type ConfigurationModalProps = {
  availableIntervals: string[] | null;
  defaultConfig: FindTheNoteFromRootAndIntervalConfig;
};

export function ConfigurationModal({
  availableIntervals,
  defaultConfig,
}: ConfigurationModalProps) {
  const { t } = useTranslation();
  const insets = useSafeAreaInsets();

  const config = useFindTheNoteFromRootAndIntervalStore(
    (state) => state.config,
  );
  const mainStep = useFindTheNoteFromRootAndIntervalStore(
    (state) => state.mainStep,
  );
  const thematic = useFindTheNoteFromRootAndIntervalStore(
    (state) => state.thematic,
  );
  const updateConfig = useFindTheNoteFromRootAndIntervalStore(
    (state) => state.updateConfig,
  );
  const closeConfig = useFindTheNoteFromRootAndIntervalStore(
    (state) => state.closeConfig,
  );
  const skipQuestion = useFindTheNoteFromRootAndIntervalStore(
    (state) => state.skipQuestion,
  );
  const resumeQuiz = useFindTheNoteFromRootAndIntervalStore(
    (state) => state.resumeQuiz,
  );

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<FindTheNoteFromRootAndIntervalConfig>({
    defaultValues: config,
  });

  const onSubmit = (data: FindTheNoteFromRootAndIntervalConfig) => {
    updateConfig(data);

    if (isDirty) {
      skipQuestion();
    }
    closeConfig();

    reset(data);
  };

  const handleRestoreDefaultConfigClick = () => {
    reset(defaultConfig, { keepDefaultValues: true });
  };

  const handleOpenChange = (value: boolean) => {
    if (!value) {
      reset();
      resumeQuiz();
      closeConfig();
    }
  };

  return (
    <Drawer open={mainStep === 'config'} onOpenChange={handleOpenChange}>
      <DrawerContent insets={insets}>
        <form onSubmit={handleSubmit(onSubmit)} className="relative z-20">
          <DrawerHeader>
            <div className="vertical-content-distribution-base">
              <Controller
                name="withAlteredNotes"
                control={control}
                render={({ field }) => (
                  <SwitchField
                    onChange={field.onChange}
                    checked={field.value}
                    label={t('fields.with-altered-notes.label')}
                    name={field.name}
                  />
                )}
              />
              {availableIntervals === null ? (
                <Controller
                  name="intervalList"
                  control={control}
                  render={({ field }) => {
                    const handleChange = (
                      value: MultiSelectOption<string>[],
                    ) => {
                      const transformedValue: SelectableInterval[] = value.map(
                        ({ checked, value }) => ({
                          selected: checked,
                          name: value,
                        }),
                      );
                      field.onChange(transformedValue);
                    };
                    return (
                      <MultiSelectField
                        label={t('fields.intervals.label')}
                        onChange={handleChange}
                        value={field.value.map(({ name, selected }) => ({
                          checked: selected,
                          value: name,
                          label: name,
                        }))}
                      />
                    );
                  }}
                />
              ) : null}
            </div>
          </DrawerHeader>
          <DrawerFooter>
            <RestoreDefaultConfigButton
              control={control}
              defaultConfig={defaultConfig}
              onClick={handleRestoreDefaultConfigClick}
            />
            <ConfigurationModalSubmitButton
              control={control}
              thematic={thematic}
            />
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
}
