import {
  Button,
  DividerWithTitle,
  FormFieldContainerLayout,
} from '@notacami/ui';
import { useContext } from 'react';
import { useTranslation } from '../../../../hooks/use-translation';
import { useToast } from '../../../ui/use-toast';
import { ServicesContext } from '../../../../services/services.context';

export function TriggerAnalyticsSection() {
  const { analytics } = useContext(ServicesContext);
  const { t } = useTranslation();
  const { toast } = useToast();

  const handleTriggerEventClick = () => {
    analytics.track('Test');
    toast({
      description: t('toast.operation-done'),
    });
  };

  return (
    <div className="vertical-content-distribution-base">
      <DividerWithTitle title="Analytics" tagName="h2" titleLevel={3} />
      <div className="vertical-content-distribution-sm">
        <FormFieldContainerLayout
          id="trigger-analytics-field"
          label={t('pages.settings.admin.trigger-analytics.title')}
          input={
            <Button colorVariant="neutral" onClick={handleTriggerEventClick}>
              {t('button.trigger')}
            </Button>
          }
        />
      </div>
    </div>
  );
}
