import { FindTheIntervalOnTheNeckProgressPayload } from '../../../../../services/progress/find-the-interval-on-the-neck/types';
import { IProgressService } from '../../../../../services/progress/progress.types';
import {
  FindTheIntervalOnTheNeckAnswer,
  FindTheIntervalOnTheNeckConfig,
  FindTheIntervalOnTheNeckQuestion,
  FindTheIntervalOnTheNeckQuestionMeta,
  FindTheIntervalOnTheNeckResultMeta,
  FindTheIntervalOnTheNeckTurnQuestion,
  FindTheIntervalOnTheNeckTurnResult,
} from '../../types';

export const manageProgressDataAction = async (
  progressService: IProgressService<
    FindTheIntervalOnTheNeckQuestion,
    FindTheIntervalOnTheNeckAnswer,
    FindTheIntervalOnTheNeckQuestionMeta,
    FindTheIntervalOnTheNeckResultMeta,
    FindTheIntervalOnTheNeckProgressPayload
  >,
  config: FindTheIntervalOnTheNeckConfig,
  questions: FindTheIntervalOnTheNeckTurnQuestion[],
  results: FindTheIntervalOnTheNeckTurnResult[],
  sessionPracticeTime: number,
): Promise<void> => {
  const resultIndexesWithCorrectAnswer = results
    .map((_, index) => index)
    .filter((_, index) => results[index].isCorrect);

  if (resultIndexesWithCorrectAnswer.length > 0) {
    const filteredQuestions = questions.filter((_, index) =>
      resultIndexesWithCorrectAnswer.includes(index),
    );

    const filteredResults = results.filter((_, index) =>
      resultIndexesWithCorrectAnswer.includes(index),
    );

    await progressService.saveProgressFromResultsByTuningId(
      config.tuningInfo.id,
      filteredQuestions,
      filteredResults,
      sessionPracticeTime,
    );
  }
};
