export function getIndicatorVariant(
  centsOff: number | undefined,
  forFlat: boolean,
) {
  if (centsOff === undefined) return 'idle' as const;
  if (forFlat) {
    if (centsOff < -17) {
      return 'red' as const;
    }
    if (centsOff > 17) {
      return 'idle' as const;
    }
    if (centsOff <= 17 && centsOff >= -17) {
      return 'green' as const;
    }
  }
  if (!forFlat) {
    if (centsOff < -17) {
      return 'idle' as const;
    }
    if (centsOff > 17) {
      return 'red' as const;
    }
    if (centsOff <= 17 && centsOff >= -17) {
      return 'green' as const;
    }
  }
  return 'idle' as const;
}
