import { Dialog, DialogContent, DialogHeader } from '../ui/dialog';
import { useIsNative } from '../../hooks/use-is-native';
import { useSafeAreaInsets } from '../../hooks/use-safe-area-insets';
import { MicDeniedPermissionContent } from './mic-denied-permission-content';

type MicDeniedPermissionDialogProps = {
  onOpenChange?: (value: boolean) => void;
  open: boolean;
};

export function MicDeniedPermissionDialog({
  onOpenChange,
  open,
}: MicDeniedPermissionDialogProps) {
  const isNative = useIsNative();
  const insets = useSafeAreaInsets();

  return open ? (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent insets={insets}>
        <DialogHeader>
          <MicDeniedPermissionContent isNative={isNative} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  ) : null;
}
