import { cn } from '@notacami/core/css';
import { CENTS } from './constants';

export function TunerCentsOffMarkers() {
  return (
    <>
      <div className="relative z-10 flex">
        {CENTS.map(({ text }, index) => (
          <div
            key={index}
            className="flex w-[10px] flex-col items-center justify-end"
          >
            {text ? (
              <div className="text-xs text-neutral-200">{text}</div>
            ) : null}
          </div>
        ))}
      </div>
      <div className="relative z-10 flex">
        {CENTS.map(({ size, x, text }, index) => (
          <div
            key={index}
            className="flex w-[10px] flex-col items-center justify-center"
          >
            <div
              className={cn(
                'rounded-full',
                text !== '' && 'bg-neutral-200',
                size === 'big' && 'h-2 w-1 bg-neutral-400',
                size === 'small' && 'h-1 w-px bg-neutral-500',
                x === 0 && 'h-4 bg-neutral-200',
              )}
            ></div>
          </div>
        ))}
      </div>
    </>
  );
}
