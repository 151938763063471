type LogoProps = {
  size: number;
  className?: string;
};

export function Logo({ size, className }: LogoProps) {
  return (
    <div className={className}>
      <svg
        width={size.toString()}
        height={size.toString()}
        viewBox="0 0 540 540"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1174_1588"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="540"
          height="540"
        >
          <rect width="540" height="540" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1174_1588)">
          <path d="M0 0H540V540H0V0Z" fill="url(#paint0_linear_1174_1588)" />
          <path
            d="M323.848 568.924C342.353 507.558 315.944 459.172 391.061 416.873C415.847 402.916 448.419 392.247 455.461 379.974C462.503 367.701 469.419 344.599 432.147 319.332C379.408 281.423 392.063 233.549 447.386 198.261C442.927 189.887 431.195 168.102 425.544 161.488L-8.95583 430.488L-8.95544 568.924L323.848 568.924Z"
            fill="black"
          />
          <path
            d="M540 -21.8066V138.606L0 489.193V-21.8066H540Z"
            fill="url(#paint1_linear_1174_1588)"
          />
          <path
            d="M-49.2618 249.099C26.1191 242.495 77.3696 262.546 140.881 178.7C190.847 112.736 260.3 93.4377 316.236 103.599C372.588 113.835 415.363 153.939 449.237 197.601L-11.7621 496.599L-49.2618 249.099Z"
            fill="white"
            fillOpacity="0.22"
          />
          <g filter="url(#filter0_d_1174_1588)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M312.338 122.637C305.012 121.083 296.807 119.621 288.575 125.505C284.247 128.599 282.527 133.842 281.869 138.12H281.566V140.853C281.394 143.334 281.546 145.053 281.546 145.053C281.552 145.055 281.559 145.057 281.566 145.058L281.566 271.791C255.918 256.69 219.267 257.972 188.189 277.747C146.717 304.137 130.875 353.468 152.804 387.93C174.734 422.393 226.131 428.937 267.603 402.547C296.659 384.058 313.134 354.309 312.854 326.301H313.331L313.331 152.613C321.26 154.952 329.667 158.136 339.781 163.342C360.552 174.033 371.087 182.231 383.578 196.069L408.123 179.946C400.425 170.449 384.533 155.294 359.995 141.444C339.17 129.689 319.664 124.191 312.338 122.637ZM252.936 379.497C278.482 363.241 288.875 332.32 274.845 310.272C260.815 288.223 228.403 284.541 202.857 300.797C177.311 317.053 166.917 347.974 180.948 370.022C194.978 392.07 227.39 395.752 252.936 379.497Z"
              fill="url(#paint2_linear_1174_1588)"
            />
          </g>
          <path
            d="M-12.4375 247.175C6.21823 250.089 50.5853 251.573 78.8076 234.191C107.03 216.81 124.872 197.449 130.266 189.942C144.324 172.454 175.35 136.725 205.331 119.461C250.423 93.4941 361.031 69.6468 448.562 198.686"
            stroke="url(#paint3_linear_1174_1588)"
            strokeWidth="7"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1174_1588"
            x="-47.0645"
            y="-68.7441"
            width="645.188"
            height="677.489"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="95" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.43 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1174_1588"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1174_1588"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1174_1588"
            x1="329"
            y1="286.5"
            x2="460.643"
            y2="491.197"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#624BF0" />
            <stop offset="1" stopColor="#987EFF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1174_1588"
            x1="106.5"
            y1="-69.9736"
            x2="316.564"
            y2="271.574"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5B3AE0" />
            <stop offset="1" stopColor="#250F4A" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1174_1588"
            x1="155.685"
            y1="389.482"
            x2="450.203"
            y2="202.519"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E1D7FF" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1174_1588"
            x1="-12.4375"
            y1="176.675"
            x2="446.971"
            y2="176.675"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#987EFF" />
            <stop offset="1" stopColor="#664FF1" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
