import { LessonInfo } from '@notacami/core/lesson';
import { LESSON_BASE } from './demo-lesson-base';

export const DEMO_LESSON_EN_FR_TEACHER_SPEAKING_MDX: LessonInfo = {
  ...LESSON_BASE,
  id: 'demo-lesson-en-fr-teacher-speaking-mdx',
  steps: [
    {
      id: '0',
      type: 'teacher-speaking',
      text: 'Text Fr 0\n\n**Salut** <LogoText /> super logo _italic_',
    },
  ],
};
