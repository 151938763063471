import { getScaleList, ScaleTypeName } from '@notacami/core/scales';
import { ScalePositionType } from '../../../services/fretboard';

export function getConfigDataByMaybeScaleTypeName(
  scaleTypeName: ScaleTypeName | null,
) {
  return {
    scalePositionTypes: (
      ['diagonal', 'compact'] satisfies ScalePositionType[]
    ).map((scalePositionType) => ({
      selected: true,
      type: scalePositionType,
    })),
    scaleNames: getScaleList().map((name) => ({
      selected: scaleTypeName === null ? true : scaleTypeName === name,
      name,
    })),
  };
}
