import { FindScalePositionProgressPayload } from '../../../../../services/progress/find-scale-position/types';
import { IProgressService } from '../../../../../services/progress/progress.types';
import {
  FindScalePositionAnswer,
  FindScalePositionConfig,
  FindScalePositionQuestion,
  FindScalePositionQuestionMeta,
  FindScalePositionResultMeta,
  FindScalePositionTurnQuestion,
  FindScalePositionTurnResult,
} from '../../types';

export const manageProgressDataAction = async (
  progressService: IProgressService<
    FindScalePositionQuestion,
    FindScalePositionAnswer,
    FindScalePositionQuestionMeta,
    FindScalePositionResultMeta,
    FindScalePositionProgressPayload
  >,
  config: FindScalePositionConfig,
  questions: FindScalePositionTurnQuestion[],
  results: FindScalePositionTurnResult[],
  sessionPracticeTime: number,
): Promise<void> => {
  const resultIndexesWithCorrectAnswer = results
    .map((_, index) => index)
    .filter((_, index) => results[index].isCorrect);

  if (resultIndexesWithCorrectAnswer.length > 0) {
    const filteredQuestions = questions.filter((_, index) =>
      resultIndexesWithCorrectAnswer.includes(index),
    );

    const filteredResults = results.filter((_, index) =>
      resultIndexesWithCorrectAnswer.includes(index),
    );

    await progressService.saveProgressFromResultsByTuningId(
      config.tuningInfo.id,
      filteredQuestions,
      filteredResults,
      sessionPracticeTime,
    );
  }
};
