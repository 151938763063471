import { createContext, useRef } from 'react';
import { LessonStoreApi, LessonProviderProps } from './lesson.types';
import { createLessonStore } from './lesson.store';

export const LessonContext = createContext<LessonStoreApi>(
  {} as LessonStoreApi,
);

export function LessonProvider({ children, ...props }: LessonProviderProps) {
  const storeRef = useRef(createLessonStore(props));
  storeRef.current.getState().startup();

  return (
    <LessonContext.Provider value={storeRef.current}>
      {children}
    </LessonContext.Provider>
  );
}
