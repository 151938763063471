import { cn } from '@notacami/core/css';
import { getStringClassnames } from '@notacami/core/fretboard';
import { Sizes } from './guitar-neck.constants';

type NeckStringProps = {
  isVertical: boolean;
  octave: number;
};

export function NeckString({ isVertical, octave }: NeckStringProps) {
  return (
    <div
      className={cn(
        !isVertical && Sizes.STRING_TRACK.h.h,
        isVertical && Sizes.STRING_TRACK.v.w,
        !isVertical && 'w-full flex-col',
        isVertical && 'h-full flex-row',
        'relative flex items-center justify-center',
      )}
    >
      <div
        className={cn(
          !isVertical && 'w-full',
          isVertical && 'h-full',
          getStringClassnames(octave, isVertical),
        )}
      />
    </div>
  );
}
