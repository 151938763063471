import { TuningInfo } from '@notacami/core/tuning';
import { PLAYABLE_INTERVALS_LIST } from '@notacami/core/intervals';
import { Fretboard } from '../../../services/fretboard';
import { getSelectableStringsFromTuning } from '../exercise/states/configuration/configuration.utils';
import { FindTheIntervalOnTheNeckConfig } from './types';

export function getDefaultConfig(
  tuningInfo: TuningInfo,
  fretboard: Fretboard,
  availableIntervals: string[] | null,
): FindTheIntervalOnTheNeckConfig {
  return {
    intervalList: PLAYABLE_INTERVALS_LIST.map((name) => ({
      selected:
        availableIntervals === null ? true : availableIntervals.includes(name),
      name,
    })),
    selectableStrings: getSelectableStringsFromTuning(tuningInfo.notes),
    tuningInfo,
    fretboard,
  };
}
