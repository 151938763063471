import { Button } from '@notacami/ui';
import { Play, Square } from 'lucide-react';

type PlayButtonProps = {
  onClick: () => void;
  isPlaying: boolean;
};

export function PlayButton({ onClick, isPlaying }: PlayButtonProps) {
  return (
    <Button
      colorVariant="neutral"
      onClick={onClick}
      size="icon"
      variant="rounded"
    >
      {isPlaying ? <Square /> : <Play />}
    </Button>
  );
}
