import { NoteNotation } from '@notacami/core/note-notation';
import { StoreCapacitor } from '../storage.capacitor';

export function getPreferenceNoteNotationCapacitorStore() {
  return new StoreCapacitor<NoteNotation>(
    'settings.note-notation',
    'english',
    (value) => value,
    (value) => value as NoteNotation,
  );
}
