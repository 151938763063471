import { useStore } from 'zustand';
import { useContext } from 'react';
import { LessonContext } from '../../../../../services/lesson';
import { LessonEndConfirmationModal } from './lesson-end-confimation-modal';

export const LessonEndConfirmationState = () => {
  const store = useContext(LessonContext);
  const cancelLeaveLesson = useStore(store, (state) => state.cancelLeaveLesson);
  const confirmLeaveLesson = useStore(
    store,
    (state) => state.confirmLeaveLesson,
  );
  const mainThematic = useStore(
    store,
    (state) => state.derivedLessonInfo?.mainThematic,
  );
  const mainStep = useStore(store, (state) => state.mainStep);
  const isEndConfirmation = mainStep === 'end-confirmation';

  const handleOpenChange = (value: boolean) => {
    if (isEndConfirmation && !value) {
      cancelLeaveLesson();
    }
  };

  return mainThematic !== undefined ? (
    <LessonEndConfirmationModal
      open={isEndConfirmation}
      onOpenChange={handleOpenChange}
      onConfirm={confirmLeaveLesson}
      onCancel={cancelLeaveLesson}
      thematic={mainThematic}
    />
  ) : null;
};
