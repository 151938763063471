import { BookText } from 'lucide-react';
import { useLocation } from 'react-router';
import { Trans } from 'react-i18next';
import { Thematic } from '@notacami/core/thematic';
import { LessonType } from '@notacami/core/lesson';
import { getColorVariantByThematic } from '@notacami/core/design';
import { Button } from '@notacami/ui';
import { PageUrls } from '../../../../../utils/routes.contants';
import { PageLayout } from '../../../page-layout';
import { useTranslation } from '../../../../../hooks/use-translation';
import { GradientDivider } from '../../../../ui/gradient-divider';
import { ExerciseConfigurationTitle } from '../../../exercise/states/configuration/exercise-configuration-title';
import { ExpandableCard } from '../../../../ui/expandable-card/expandable-card';
import { createSimpleExpandableCardTitle } from '../../../../ui/expandable-card/title';
import { BackLink } from '../../../../ui/back-link';

type LessonIntroductionProps = {
  description: string;
  disableStartButton: boolean;
  estimatedDuration: number;
  isPending: boolean;
  lessonType: LessonType;
  mainThematic: Thematic;
  name: string;
  onStartClick: () => void;
};

export function LessonIntroduction({
  description,
  disableStartButton,
  estimatedDuration,
  isPending,
  lessonType,
  mainThematic,
  name,
  onStartClick,
}: LessonIntroductionProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <PageLayout
      leftAction={
        pathname !== PageUrls.LESSON_CREATION ? (
          <BackLink to={PageUrls.HOME} />
        ) : null
      }
      content={
        <div className="vertical-content-distribution-lg min-h-full items-stretch justify-center">
          <ExerciseConfigurationTitle
            thematic={mainThematic}
            lessonType={lessonType}
            specificLabels={[t(`thematic.${mainThematic}`)]}
          >
            {name}
          </ExerciseConfigurationTitle>
          <div className="text-center text-sm">
            <Trans
              i18nKey="estimated-duration"
              values={{ estimatedDuration }}
              components={[<strong key={0} />]}
            />
          </div>
          <GradientDivider />
          <ExpandableCard
            key={description}
            title={createSimpleExpandableCardTitle(
              <BookText />,
              t('description'),
            )}
            titleText={t('description')}
          >
            {description}
          </ExpandableCard>
        </div>
      }
      bottomAction={
        <Button
          className="w-full"
          colorVariant={getColorVariantByThematic(mainThematic)}
          disabled={disableStartButton}
          onClick={onStartClick}
          pending={isPending}
          size="lg"
        >
          {t('button.start')}
        </Button>
      }
    />
  );
}
