import { Thematic } from '@notacami/core/thematic';
import { getRoutesByThematic } from '../../utils/routes.utils';
import { useTranslation } from '../../hooks/use-translation';
import { NavigationButtonContainer } from '../ui/navigation-button-container';
import { ThematicDot } from './thematic-dot';

type ThematicItemProps = { thematic: Thematic };

export function ThematicItem({ thematic }: ThematicItemProps) {
  const { t } = useTranslation();

  return (
    <li>
      <NavigationButtonContainer to={getRoutesByThematic(thematic)}>
        <div className="text-color-title flex items-center gap-3">
          <ThematicDot thematic={thematic} />
          <span>{t(`thematic.${thematic}`)}</span>
        </div>
      </NavigationButtonContainer>
    </li>
  );
}
