import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Title } from '../title';

type H2Props = DetailedHTMLProps<
  HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

export function H2({ children }: H2Props) {
  return (
    <Title tagName="h2" level={2} className="mb-4 mt-8">
      {children}
    </Title>
  );
}
