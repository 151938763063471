import { useLocation, useOutlet } from 'react-router';
import { AnimatePresence, motion } from 'motion/react';
import { FC, useRef } from 'react';
import { usePrevious } from 'react-use';
import { Surface } from '@notacami/ui';
import { RootLayout } from './root-layout';
import { Dialogs } from './dialogs/dialogs';

const AnimatedOutlet: FC = () => {
  const o = useOutlet();
  const outlet = useRef(o);

  return <>{outlet.current}</>;
};

export function Root() {
  const { pathname, search } = useLocation();
  const previousTimestampRef = useRef(0);
  const timestamp = new Date().getTime();
  const previousPathname = usePrevious(pathname + search);

  let computedPathname, computedTimestamp;

  if (previousPathname === pathname) {
    computedPathname = pathname + search;
    computedTimestamp = previousTimestampRef.current;
  } else {
    computedPathname = pathname + search;
    computedTimestamp = timestamp;
    previousTimestampRef.current = timestamp;
  }

  return (
    <RootLayout>
      <Surface
        surfaceId="app"
        className="min-w-dvw absolute h-dvh min-h-dvh w-dvw"
      />
      <AnimatePresence>
        <motion.div
          key={computedPathname + computedTimestamp}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 0 } }}
        >
          <AnimatedOutlet />
        </motion.div>
      </AnimatePresence>
      <Dialogs />
    </RootLayout>
  );
}
