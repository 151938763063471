import {
  FretboardNoteDetails,
  isPositionEqual,
  Position,
} from '@notacami/core/fretboard';
import { NoteStartEvent } from '@notacami/core/composer';
import { BuildCellsComponentProps } from '@notacami/ui-business/guitar-neck';
import {
  NeckCellInterval,
  NeckCellNote,
} from '@notacami/ui-business/neck-cells';
import { useNoteNotation } from '../../../../../../services/note-notation/use-note-notation';

type StepTeacherPlayingNeckCellProps = BuildCellsComponentProps & {
  fretboardNoteDetails: FretboardNoteDetails;
  notesToDisplay: NoteStartEvent[];
  playingNotePositions: Position[];
};

export function StepTeacherPlayingNeckCell({
  fretboardNoteDetails,
  isOpenString,
  isVertical,
  notesToDisplay,
  playingNotePositions,
  position,
  getElementByPosition,
}: StepTeacherPlayingNeckCellProps) {
  const { n } = useNoteNotation();

  const noteToDisplay = notesToDisplay.find((noteToDisplay) =>
    isPositionEqual(noteToDisplay.position, position),
  );

  const isPlayed = playingNotePositions.some((playingNotePosition) =>
    isPositionEqual(playingNotePosition, position),
  );

  const fretboardNote = getElementByPosition(fretboardNoteDetails);

  if (noteToDisplay === undefined && position[1] === 0 && !isPlayed) {
    return (
      <NeckCellNote
        isOpenString={isOpenString}
        isVertical={isVertical}
        position={position}
        noteLabel={n(fretboardNote.pitchClass)}
      />
    );
  }

  return (
    <>
      {noteToDisplay !== undefined &&
      noteToDisplay.noteDisplayMode === 'pitch-class' ? (
        <NeckCellNote
          isPlayed={isPlayed}
          isOpenString={isOpenString}
          isVertical={isVertical}
          noteLabel={n(noteToDisplay.pitchClassToDisplay)}
          position={position}
        />
      ) : null}
      {noteToDisplay !== undefined &&
      noteToDisplay.noteDisplayMode === 'interval' &&
      noteToDisplay.intervalToDisplay !== undefined ? (
        <NeckCellInterval
          isPlayed={isPlayed}
          isOpenString={isOpenString}
          isVertical={isVertical}
          interval={noteToDisplay.intervalToDisplay}
          position={position}
        />
      ) : null}
      {noteToDisplay === undefined && isPlayed ? (
        <NeckCellNote
          isPlayed
          isOpenString={isOpenString}
          isVertical={isVertical}
          position={position}
          noteLabel={n(fretboardNote.pitchClass)}
        />
      ) : null}
    </>
  );
}
