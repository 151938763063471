import { ReactNode } from 'react';
import { Switch } from './switch';

type SwitchFieldProps = {
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  label: ReactNode;
  name: string;
};

export function SwitchField({
  checked,
  disabled = false,
  onChange,
  label,
  name,
}: SwitchFieldProps) {
  return (
    <div className="flex h-full items-center justify-between gap-2">
      <label className="text-left" htmlFor={name}>
        {label}
      </label>
      <Switch
        id={name}
        name={name}
        checked={checked}
        onCheckedChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}
