import Emittery from 'emittery';
import { NoteEvent } from '@notacami/core/composer';
import { Musician } from '../musician/musician';
import { FretboardNoteDetails } from '../fretboard';
import { computeNotesToPlay, EventInPart } from '../composer';
import { ISoundPlayer, SoundPlayerEvents } from './sound-player.types';

export class SoundPlayer
  extends Emittery<SoundPlayerEvents>
  implements ISoundPlayer
{
  constructor(private readonly musician: Musician) {
    super();

    this.bindMusicianEvents();
  }

  private bindMusicianEvents() {
    this.musician.on('note-start', (data) => {
      this.emit('note-start', data);
    });
    this.musician.on('note-end', (data) => {
      this.emit('note-end', data);
    });
    this.musician.on('part-start', (data) => {
      this.emit('part-start', data);
    });
    this.musician.on('part-end', (data) => {
      this.emit('stop');
      this.emit('part-end', data);
    });
    this.musician.on('group-note-start', (data) => {
      this.emit('group-note-start', data);
    });
    this.musician.on('group-note-end', (data) => {
      this.emit('group-note-end', data);
    });
    this.musician.on('playhead-update', (data) => {
      this.emit('playhead-update', data);
    });
  }

  public async playSequence(
    partId: string,
    noteEvents: NoteEvent[],
    fretboardNoteDetails: FretboardNoteDetails,
  ) {
    const notesToPlay = computeNotesToPlay(noteEvents, fretboardNoteDetails);
    this.musician.playNotes(partId, notesToPlay, 60);
  }

  public async playEventsInPart(
    partId: string,
    eventsInPart: EventInPart[],
    bpm: number,
  ) {
    this.musician.playNotes(partId, eventsInPart, bpm);
  }

  public stop() {
    this.emit('stop');
    this.musician.stop();
  }
}
