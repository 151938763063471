import { Position } from '@notacami/core/fretboard';
import { BoundariesOnString } from './fretboard.types';

function getFretBoundariesOnStringsByPosition(
  boundariesOnStringsAccumulator: BoundariesOnString[],
  currentPosition: Position,
): BoundariesOnString[] {
  const currentStringIndex = currentPosition[0];
  const currentFretIndex = currentPosition[1];
  const boundariesFoundForString = boundariesOnStringsAccumulator.findIndex(
    ({ stringIndex }) => stringIndex === currentStringIndex,
  );

  if (boundariesFoundForString === -1) {
    return [
      ...boundariesOnStringsAccumulator,
      {
        stringIndex: currentStringIndex,
        min: currentFretIndex,
        max: currentFretIndex,
      },
    ];
  } else {
    return boundariesOnStringsAccumulator.map((boundariesOnString) => {
      if (boundariesOnString.stringIndex === currentStringIndex) {
        return {
          stringIndex: currentStringIndex,
          min: Math.min(currentFretIndex, boundariesOnString.min),
          max: Math.max(currentFretIndex, boundariesOnString.max),
        };
      } else {
        return boundariesOnString;
      }
    });
  }
}

export function getFretBoundariesOnStringsByPositions(
  positions: Position[],
): BoundariesOnString[] {
  const fretBoundariesOnStrings = positions.reduce(
    getFretBoundariesOnStringsByPosition,
    [],
  );

  return fretBoundariesOnStrings;
}
