import { useSearchParams } from 'react-router';
import { STRING_SEARCH_PARAM } from '../../utils/routes.contants';
import { FindTheNoteOnTheNeck } from '../../components/business/find-the-note-on-the-neck';

export function FindTheNoteOnTheNeckPage() {
  const [searchParams] = useSearchParams();

  const notePitchClass = searchParams.get(STRING_SEARCH_PARAM);

  return (
    <FindTheNoteOnTheNeck
      key="FindTheNoteOnTheNeck"
      notePitchClass={notePitchClass}
    />
  );
}
