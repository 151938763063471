import { getClassnamesByColorVariant } from '@notacami/core/design';
import { getIntervalNumber } from '@notacami/core/intervals';

export function getNoteColorClassnamesByInterval(interval: string) {
  const degree = getIntervalNumber(interval);

  switch (degree) {
    case 1:
    case 8:
      return getClassnamesByColorVariant('indigo');
    case 5:
    case 12:
      return getClassnamesByColorVariant('yellow');
    case 3:
    case 10:
    case 7:
    case 14:
      return getClassnamesByColorVariant('emerald');
    case 2:
    case 4:
    case 6:
    case 9:
    case 11:
    case 13:
      return getClassnamesByColorVariant('neutral');
    default:
      return getClassnamesByColorVariant('neutral-invert');
  }
}
