import { getSoundPlayerService } from '@notacami/core/sound-player';
import { WindowFocusAndBlurService } from '@notacami/core/window-focus-and-blur';
import { AnalyticsMixpanelService } from '../services/analytics';
import { AppCapacitorService } from '../services/app';
import { DeviceCapacitorService } from '../services/device';
import { getFindScalePositionProgressCapacitorStore } from '../services/storage/progress/find-scale-position';
import { getFindScalePositionProgressInstance } from '../services/progress/find-scale-position';
import { getFindTheIntervalOnTheNeckProgressCapacitorStore } from '../services/storage/progress/find-the-interval-on-the-neck';
import { getFindTheIntervalOnTheNeckProgressInstance } from '../services/progress/find-the-interval-on-the-neck';
import { getFindTheNoteFromRootAndIntervalProgressCapacitorStore } from '../services/storage/progress/find-the-note-from-root-and-interval';
import { getFindTheNoteFromRootAndIntervalProgressInstance } from '../services/progress/find-the-note-from-root-and-interval';
import { getFindTheNoteOnTheNeckProgressCapacitorStore } from '../services/storage/progress/find-the-note-on-the-neck';
import { getFindTheNoteOnTheNeckProgressServiceInstance } from '../services/progress/find-the-note-on-the-neck';
import { getI18nService } from '../services/i18n';
import { getNotePlayedConsumer } from '../services/consumer/get-note-played-consumer';
import { getPeakDetectionConsumer } from '../services/consumer/get-peak-detection-consumer';
import { getPersonaCapacitorStore } from '../services/persona';
import { getPitchDetectionConsumer } from '../services/consumer/get-picth-detection-consumer';
import { getPreferenceAdminCapacitorStore } from '../services/storage/preferences/admin';
import { getPreferenceGainCapacitorStore } from '../services/storage/preferences/gain';
import { getPreferenceLanguageCapacitorStore } from '../services/storage/preferences/language';
import { getPreferenceLeftHandedCapacitorStore } from '../services/storage/preferences/left-handed';
import { getPreferenceMicCapacitorStore } from '../services/storage/preferences/mic-permission';
import { getPreferenceNoteNotationCapacitorStore } from '../services/storage/preferences/note-notation';
import { getPreferenceReferenceFrequencyCapacitorStore } from '../services/storage/preferences/reference-frequency';
import { getPreferenceThemeCapacitorStore } from '../services/storage/preferences/theme';
import { getPreferenceTuningCapacitorStore } from '../services/storage/preferences/tuning';
import { getTunerConsumer } from '../services/consumer/get-tuner-consumer';
import { HapticFeedbackCapacitorService } from '../services/haptic-feedback';
import { MicPermissionService } from '../services/mic-permission';
import { MonitoringSentryService } from '../services/monitoring';
import { NotePlayedService } from '../services/note-played';
import { PeakDetectionService } from '../services/peak-detection';
import { PitchDetectionService } from '../services/pitch-detection';
import { Services } from '../services/services.types';
import { StopWatchService } from '../services/stopwatch';
import { Teacher } from '../services/teacher';
import { TunerService } from '../services/tuner';
import {
  LessonProgressRepository,
  LessonProgressService,
} from '../services/lesson-progress';

export async function instanciateServices({
  analytics: intialAnalytics,
  app: intialApp,
  captureModeStore: initialCaptureModeStore,
  device: initialDevice,
  findScalePositionProgress: initialFindScalePositionProgress,
  findScalePositionProgressStore: initialFindScalePositionProgressStore,
  findTheIntervalOnTheNeckProgress: initialFindTheIntervalOnTheNeckProgress,
  findTheIntervalOnTheNeckProgressStore:
    initialFindTheIntervalOnTheNeckProgressStore,
  findTheNoteFromRootAndIntervalProgress:
    initialFindTheNoteFromRootAndIntervalProgress,
  findTheNoteFromRootAndIntervalProgressStore:
    initialFindTheNoteFromRootAndIntervalProgressStore,
  findTheNoteOnTheNeckProgress: initialFindTheNoteOnTheNeckProgress,
  findTheNoteOnTheNeckProgressStore: initialFindTheNoteOnTheNeckProgressStore,
  hapticFeedback: initialHaptic,
  i18n: initialI18n,
  lessonProgress: initialLessonProgress,
  micPermission: initialMicPermission,
  monitoring: initialMonitoring,
  notePlayed: initialNotePlayed,
  notePlayedConsumer: initialNotePlayedConsumer,
  peakDetection: initialPeakDetection,
  peakDetectionConsumer: initialPeakDetectionConsumer,
  pitchDetection: initialPitchDetection,
  pitchDetectionConsumer: initialPitchDetectionConsumer,
  preferenceAdminStore: initialPreferencesAdminStore,
  preferenceGainStore: initialPreferenceGainStore,
  preferenceLanguageStore: initialPreferencesLanguageStore,
  preferenceLeftHandedStore: initialPreferenceLeftHandedStore,
  preferenceMicStore: initialPreferenceMicStore,
  preferenceNoteNotationStore: initialPreferenceNoteNotationStore,
  preferenceReferenceFrequencyStore: initialPreferenceReferenceFrequencyStore,
  preferenceThemeStore: initialPreferenceThemeStore,
  preferenceTuningStore: initialPreferenceTuningStore,
  soundPlayer: initialSoundPlayer,
  stopWatch: initialStopWatch,
  teacher: initialTeacher,
  tuner: initialTuner,
  tunerConsumer: initialTunerConsumer,
  windowFocusAndBlur: initialWindowFocusAndBlur,
}: Partial<Services>): Promise<Services> {
  const device = initialDevice ?? new DeviceCapacitorService();
  await device.initialize();

  const app = intialApp ?? new AppCapacitorService(device);
  await app.initialize();

  const analytics = intialAnalytics ?? new AnalyticsMixpanelService(device);

  const captureModeStore =
    initialCaptureModeStore ?? getPersonaCapacitorStore();

  const hapticFeedback =
    initialHaptic ?? new HapticFeedbackCapacitorService(device);

  const preferenceAdminStore =
    initialPreferencesAdminStore ?? getPreferenceAdminCapacitorStore();

  const preferenceLeftHandedStore =
    initialPreferenceLeftHandedStore ?? getPreferenceLeftHandedCapacitorStore();

  const preferenceNoteNotationStore =
    initialPreferenceNoteNotationStore ??
    getPreferenceNoteNotationCapacitorStore();

  const preferenceReferenceFrequencyStore =
    initialPreferenceReferenceFrequencyStore ??
    getPreferenceReferenceFrequencyCapacitorStore();

  const preferenceThemeStore =
    initialPreferenceThemeStore ?? getPreferenceThemeCapacitorStore();

  const preferenceTuningStore =
    initialPreferenceTuningStore ?? getPreferenceTuningCapacitorStore();

  const preferenceMicStore =
    initialPreferenceMicStore ?? getPreferenceMicCapacitorStore();

  const preferenceLanguageStore =
    initialPreferencesLanguageStore ?? getPreferenceLanguageCapacitorStore();

  const preferenceGainStore =
    initialPreferenceGainStore ?? getPreferenceGainCapacitorStore();

  const findTheNoteOnTheNeckProgressStore =
    initialFindTheNoteOnTheNeckProgressStore ??
    getFindTheNoteOnTheNeckProgressCapacitorStore();

  const findTheNoteOnTheNeckProgress =
    initialFindTheNoteOnTheNeckProgress ??
    getFindTheNoteOnTheNeckProgressServiceInstance(
      findTheNoteOnTheNeckProgressStore,
    );

  const findScalePositionProgressStore =
    initialFindScalePositionProgressStore ??
    getFindScalePositionProgressCapacitorStore();

  const findScalePositionProgress =
    initialFindScalePositionProgress ??
    getFindScalePositionProgressInstance(findScalePositionProgressStore);

  const findTheNoteFromRootAndIntervalProgressStore =
    initialFindTheNoteFromRootAndIntervalProgressStore ??
    getFindTheNoteFromRootAndIntervalProgressCapacitorStore();

  const findTheNoteFromRootAndIntervalProgress =
    initialFindTheNoteFromRootAndIntervalProgress ??
    getFindTheNoteFromRootAndIntervalProgressInstance(
      findTheNoteFromRootAndIntervalProgressStore,
    );

  const findTheIntervalOnTheNeckProgressStore =
    initialFindTheIntervalOnTheNeckProgressStore ??
    getFindTheIntervalOnTheNeckProgressCapacitorStore();

  const findTheIntervalOnTheNeckProgress =
    initialFindTheIntervalOnTheNeckProgress ??
    getFindTheIntervalOnTheNeckProgressInstance(
      findTheIntervalOnTheNeckProgressStore,
    );

  const windowFocusAndBlur =
    initialWindowFocusAndBlur ?? new WindowFocusAndBlurService();

  const pitchDetection =
    initialPitchDetection ??
    new PitchDetectionService(
      preferenceGainStore,
      preferenceMicStore,
      windowFocusAndBlur,
    );

  const pitchDetectionConsumer =
    initialPitchDetectionConsumer ?? getPitchDetectionConsumer(pitchDetection);

  const peakDetection =
    initialPeakDetection ?? new PeakDetectionService(pitchDetection);

  const peakDetectionConsumer =
    initialPeakDetectionConsumer ?? getPeakDetectionConsumer(peakDetection);

  const micPermission =
    initialMicPermission ??
    new MicPermissionService(preferenceMicStore, device);

  const monitoring = initialMonitoring ?? new MonitoringSentryService(app);

  const notePlayed =
    initialNotePlayed ?? new NotePlayedService(pitchDetection, peakDetection);

  const notePlayedConsumer =
    initialNotePlayedConsumer ?? getNotePlayedConsumer(notePlayed);

  const i18n = initialI18n ?? getI18nService();

  const soundPlayer =
    initialSoundPlayer ?? getSoundPlayerService(windowFocusAndBlur);

  const teacher = initialTeacher ?? new Teacher(notePlayed);

  const tuner = initialTuner ?? new TunerService(pitchDetection);

  const tunerConsumer = initialTunerConsumer ?? getTunerConsumer(tuner);

  const stopWatch = initialStopWatch ?? new StopWatchService();

  const lessonProgress =
    initialLessonProgress ??
    new LessonProgressService(new LessonProgressRepository());

  return {
    analytics,
    app,
    captureModeStore,
    device,
    findScalePositionProgress,
    findScalePositionProgressStore,
    findTheIntervalOnTheNeckProgress,
    findTheIntervalOnTheNeckProgressStore,
    findTheNoteFromRootAndIntervalProgress,
    findTheNoteFromRootAndIntervalProgressStore,
    findTheNoteOnTheNeckProgress,
    findTheNoteOnTheNeckProgressStore,
    hapticFeedback,
    i18n,
    lessonProgress,
    micPermission,
    monitoring,
    notePlayed,
    notePlayedConsumer,
    peakDetection,
    peakDetectionConsumer,
    pitchDetection,
    pitchDetectionConsumer,
    preferenceAdminStore,
    preferenceGainStore,
    preferenceLanguageStore,
    preferenceLeftHandedStore,
    preferenceMicStore,
    preferenceNoteNotationStore,
    preferenceReferenceFrequencyStore,
    preferenceThemeStore,
    preferenceTuningStore,
    soundPlayer,
    stopWatch,
    teacher,
    tuner,
    tunerConsumer,
    windowFocusAndBlur,
  };
}
