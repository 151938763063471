import { useCallback, useContext, useEffect, useRef } from 'react';
import { ThemeContext } from '@notacami/ui';
import { getCanvas } from '../pitch-detection-debug/frequencies-graph/utils/get-canvas';
import { eraseAll } from '../pitch-detection-debug/frequencies-graph/utils/erase-all';
import { drawSinewave } from '../pitch-detection-debug/frequencies-graph/utils/draw-sinewave';
import { UpdatedData } from '../../../services/pitch-detection';
import { ServicesContext } from '../../../services/services.context';

export function SinewavePreview() {
  const { pitchDetection } = useContext(ServicesContext);
  const { theme } = useContext(ThemeContext);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const clearCanvas = useCallback(() => {
    const canvas = getCanvas(canvasRef.current);

    if (canvas) {
      eraseAll(canvas);
    }
  }, [canvasRef]);

  const handleSinewaveUpdate = useCallback(
    ({ buffer, bufferLength }: UpdatedData) => {
      const canvas = getCanvas(canvasRef.current);

      if (canvas) {
        drawSinewave(buffer, canvas, bufferLength, theme);
      }
    },
    [canvasRef, theme],
  );

  const handleMicClose = useCallback(() => {
    clearCanvas();
  }, [clearCanvas]);

  useEffect(() => {
    pitchDetection.on('sinewave-update', handleSinewaveUpdate);
    pitchDetection.on('mic-close', handleMicClose);

    return () => {
      pitchDetection.off('sinewave-update', handleSinewaveUpdate);
      pitchDetection.off('mic-close', handleMicClose);
    };
  }, [handleSinewaveUpdate, handleMicClose]);

  return (
    <canvas
      className="absolute bottom-0 left-0 right-0 top-0"
      ref={canvasRef}
    />
  );
}
