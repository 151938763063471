import { X } from 'lucide-react';
import { useStore } from 'zustand';
import { useContext } from 'react';
import { Button } from '@notacami/ui';
import { LessonContext } from '../../../../../services/lesson';

export function LeftActionSection() {
  const store = useContext(LessonContext);
  const askLeaveLesson = useStore(store, (state) => state.askLeaveLesson);

  return (
    <Button
      colorVariant="neutral"
      onClick={askLeaveLesson}
      size="icon"
      variant="rounded"
    >
      <X />
    </Button>
  );
}
