import { Title } from '@notacami/ui';
import { useStore } from 'zustand';
import { useContext } from 'react';
import { Bug } from 'lucide-react';
import { PageLayout } from '../page-layout';
import { LessonContext } from '../../../services/lesson';
import { useLessonPostMessageEvent } from './use-lesson-post-message-event';

export function LessonPostMessage() {
  const store = useContext(LessonContext);
  const derivedLessonInfo = useStore(store, (state) => state.derivedLessonInfo);

  useLessonPostMessageEvent();

  return (
    <>
      {!derivedLessonInfo ? (
        <PageLayout
          content="Awaiting lesson info"
          title={
            <Title tagName="h1" level={2} left={<Bug />}>
              Lesson playground
            </Title>
          }
        />
      ) : null}
    </>
  );
}
