import { DetailedHTMLProps, HTMLAttributes } from 'react';

type PProps = DetailedHTMLProps<
  HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>;

export function P(props: PProps) {
  return <p className="mb-4 last:mb-0" {...props} />;
}
