import { useContext, useEffect } from 'react';
import { cn } from '@notacami/core/css';
import { DEFAULT_FRET_LENGTH } from '@notacami/core/fretboard';
import { GuitarNeck } from '@notacami/ui-business/guitar-neck';
import { useGuitarNeckVertical } from '../../../hooks/use-guitar-neck-vertical';
import { ScalePosition, useLeftHanded } from '../../../services/fretboard';
import { PartId } from '../../../services/sequence/sequence.constants';
import { usePreferencesStore } from '../preferences/use-preferences-context';
import { StringSelectorControlledOne } from '../string-selector-controlled-one';
import { usePlayingNotes } from '../lesson/main-steps/steps/step-teacher-playing/use-playing-notes';
import { ServicesContext } from '../../../services/services.context';
import { VisualizeScalePositionsNeckCell } from './visualize-scale-positions-neck-cell';

type VisualizeScalePositionsNeckProps = {
  scalePositionsByStringIndex: ScalePosition[][];
  stringIndex: number;
  positionIndex: number;
  onStringButtonClick: (stringIndex: number) => void;
  scaleHasAtLeastOnePosition: boolean;
};

export function VisualizeScalePositionsNeck({
  scalePositionsByStringIndex,
  stringIndex,
  positionIndex,
  onStringButtonClick,
  scaleHasAtLeastOnePosition,
}: VisualizeScalePositionsNeckProps) {
  const { soundPlayer } = useContext(ServicesContext);

  const isVertical = useGuitarNeckVertical();
  const leftHanded = useLeftHanded();

  const tuning = usePreferencesStore((state) => state.tuningInfo);
  const playingNotes = usePlayingNotes(PartId.VISUALIZE_SCALE_POSITIONS);

  useEffect(() => {
    return () => {
      soundPlayer.stop();
    };
  }, [stringIndex, positionIndex]);

  useEffect(() => {
    return () => {
      soundPlayer.stop();
    };
  }, []);

  return (
    <div
      className={cn('flex flex-col justify-center gap-4', isVertical && 'mt-5')}
    >
      <GuitarNeck
        showStringNumbers={false}
        buildCustomNeckHeadFactory={({ isVertical, leftHanded }) => (
          <StringSelectorControlledOne
            isVertical={isVertical}
            stringIndex={stringIndex}
            tuning={tuning.notes}
            leftHanded={leftHanded}
            onChange={onStringButtonClick}
            disabledStrings={scalePositionsByStringIndex.map(
              (positions) => positions.length === 0,
            )}
          />
        )}
        showFretSymbols={false}
        leftHanded={leftHanded}
        isVertical={isVertical}
        numberOfFrets={DEFAULT_FRET_LENGTH}
        tuning={tuning.notes}
        buildCellsComponent={VisualizeScalePositionsNeckCell}
        additionalCellProps={{
          playingNotes,
          positionIndex,
          scaleHasAtLeastOnePosition,
          scalePositionsByStringIndex,
          stringIndex,
        }}
      />
    </div>
  );
}
