import { DetailedHTMLProps, HTMLAttributes } from 'react';

type UlProps = DetailedHTMLProps<
  HTMLAttributes<HTMLUListElement>,
  HTMLUListElement
>;

export function Ul(props: UlProps) {
  return <ul className="mb-4 list-inside last:mb-0" {...props} />;
}
