import { isPositionEqual, Position } from '@notacami/core/fretboard';
import { BuildCellsComponentProps } from '@notacami/ui-business/guitar-neck';
import { NeckCellInterval } from '@notacami/ui-business/neck-cells';
import { FretboardIntervalByPosition } from '../../../services/fretboard/fretboard.types';

type VisualizeIntervalPositionsNeckCellProps = BuildCellsComponentProps & {
  intervals: FretboardIntervalByPosition[];
  reachablePositions: Position[];
};

export function VisualizeIntervalPositionsNeckCell({
  intervals,
  isOpenString,
  isVertical,
  position,
  reachablePositions,
}: VisualizeIntervalPositionsNeckCellProps) {
  const isCurrentPositionInReachablePositions = reachablePositions.some(
    (reachablePosition) => isPositionEqual(reachablePosition, position),
  );

  if (!isCurrentPositionInReachablePositions) {
    return null;
  }

  const foundIntervalForPosition = intervals.find((interval) =>
    isPositionEqual(interval.position, position),
  );

  if (foundIntervalForPosition === undefined) {
    return null;
  }

  return (
    <NeckCellInterval
      isOpenString={isOpenString}
      isVertical={isVertical}
      interval={foundIntervalForPosition.simplifiedInterval}
      position={position}
    />
  );
}
