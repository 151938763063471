import { cn } from '@notacami/core/css';
import { Ref, SyntheticEvent } from 'react';

export type SelectOption<T> = {
  id: string;
  label: string;
  optgroupId?: string;
  value: T;
};

type SelectOptgroup = {
  id: string;
  label: string;
};

export type SelectProps<T> = {
  className?: string;
  optgroups?: SelectOptgroup[];
  name: string;
  onBlur?: () => void;
  onChange: (value: T) => void;
  options: SelectOption<T>[];
  value?: string;
  size?: 'default' | 'xs';
  placeholder?: string;
  isErrored?: boolean;
  selectRef?: Ref<HTMLSelectElement>;
};

export function Select<T extends string>({
  className,
  optgroups,
  name,
  onBlur,
  onChange,
  options,
  value,
  size = 'default',
  placeholder,
  isErrored = false,
  selectRef,
}: SelectProps<T>) {
  const handleSelect = (event: SyntheticEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value as T;
    onChange(value);
  };

  return (
    <select
      ref={selectRef}
      onChange={handleSelect}
      name={name}
      id={name}
      aria-invalid={isErrored}
      className={cn(
        size === 'default' && 'h-10 px-3 py-2',
        size === 'xs' && 'h-6 px-2 py-0 text-xs',
        'regular-border focus:outline-hidden block appearance-none rounded-lg bg-transparent',
        isErrored && 'regular-border-error',
        className,
      )}
      value={value}
      onBlur={onBlur}
      defaultValue={
        placeholder !== undefined && value === undefined
          ? '-placeholder-'
          : undefined
      }
    >
      {placeholder !== undefined ? (
        <option value="-placeholder-" disabled>
          {placeholder}
        </option>
      ) : null}
      {optgroups === undefined ? (
        <>
          {options.map(({ value, label, id }) => (
            <option key={id} value={value}>
              {label}
            </option>
          ))}
        </>
      ) : (
        <>
          {optgroups.map((optgroup) => {
            return (
              <optgroup label={optgroup.label} key={optgroup.id}>
                {options
                  .filter(({ optgroupId }) => optgroup.id === optgroupId)
                  .map(({ value, label, id }) => (
                    <option key={id} value={value}>
                      {label}
                    </option>
                  ))}
              </optgroup>
            );
          })}
        </>
      )}
    </select>
  );
}
