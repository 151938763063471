import { useContext } from 'react';
import { BookText } from 'lucide-react';
import { useStore } from 'zustand';
import { MDX_COMPONENTS } from '@notacami/ui';
import { EndConfirmationState } from '../exercise/states/end-confimation/end-confirmation-state';
import { EndState } from '../exercise/states/end';
import { PlayingState } from '../exercise/states/playing';
import { ConfigurationState } from '../exercise/states/configuration';
import { TutorialState } from '../exercise/states/tutorial';
import { ExpandableCard } from '../../ui/expandable-card/expandable-card';
import { createSimpleExpandableCardTitle } from '../../ui/expandable-card/title';
import { useLongText } from '../../../services/i18n';
import { useTranslation } from '../../../hooks/use-translation';
import { useNoteNotation } from '../../../services/note-notation/use-note-notation';
import { ExerciseConfigurationTitle } from '../exercise/states/configuration/exercise-configuration-title';
import { FindTheNoteOnTheNeckExerciseContext } from './store/context';
import { Results } from './states/end/results';
import { QuestionSection } from './states/playing/question-section';
import { AnswerSection } from './states/playing/answer-section';
import { ConfigurationModal } from './states/playing/configuration-modal';
import { FindTheNoteOnTheNeckConfig } from './types';

type StatesProps = {
  defaultConfig: FindTheNoteOnTheNeckConfig;
  notePitchClass: string | null;
};

export function States({ defaultConfig, notePitchClass }: StatesProps) {
  const store = useContext(FindTheNoteOnTheNeckExerciseContext);
  const thematic = useStore(store, (state) => state.thematic);
  const longText = useLongText('find-the-note-on-the-neck-instructions');
  const { t } = useTranslation();
  const { n } = useNoteNotation();

  return (
    <>
      <ConfigurationState
        store={store}
        title={
          <ExerciseConfigurationTitle
            thematic={thematic}
            lessonType="fretboard-exercise"
            specificLabels={
              notePitchClass !== null
                ? [
                    t('string-and-pitch-class', {
                      pitchClass: n(notePitchClass),
                    }),
                  ]
                : [t('all-strings')]
            }
          >
            {t('pages.FIND_THE_NOTE_ON_THE_NECK.title')}
          </ExerciseConfigurationTitle>
        }
        parentPageId="NOTES"
        instructions={
          <ExpandableCard
            title={createSimpleExpandableCardTitle(
              <BookText />,
              longText.frontmatter.title,
            )}
            titleText={longText.frontmatter.title}
            onlyShowTitleWhenClosed
          >
            <longText.component components={MDX_COMPONENTS} />
          </ExpandableCard>
        }
      />
      <EndState store={store}>
        <Results notePitchClass={notePitchClass} />
      </EndState>
      <TutorialState store={store} />
      <PlayingState
        answerSection={<AnswerSection />}
        questionSection={<QuestionSection />}
        configSection={
          <ConfigurationModal
            defaultConfig={defaultConfig}
            notePitchClass={notePitchClass}
          />
        }
        store={store}
      />
      <EndConfirmationState store={store} />
    </>
  );
}
