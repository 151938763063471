import { useTranslation as useRealTranslation } from 'react-i18next';
import { TranslationKey } from '../services/i18n';

export type TFunctionType = (
  key: TranslationKey,
  obj?: Record<string, unknown> | undefined,
) => string;

export function useTranslation() {
  const { t: realT } = useRealTranslation();
  const t: TFunctionType = (key, obj?) => {
    return realT(key, obj);
  };
  return { t };
}
