import { X } from 'lucide-react';
import { useStore } from 'zustand';
import { Button } from '@notacami/ui';
import { ExerciseStoreApi } from '../../../../../services/exercise/exercise.types';

type LeftActionSectionProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
> = {
  store: ExerciseStoreApi<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
  >;
};

export function LeftActionSection<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
>({
  store,
}: LeftActionSectionProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload
>) {
  const askLeaveQuiz = useStore(store, (state) => state.askLeaveQuiz);

  return (
    <Button
      colorVariant="neutral"
      onClick={askLeaveQuiz}
      size="icon"
      variant="rounded"
    >
      <X />
    </Button>
  );
}
