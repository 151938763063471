import { cn } from '@notacami/core/css';
import { NeckHeadStringSelectorItem } from './neck-head-string-selector-item';
import { SelectableStringInput } from './string-selector';

type NeckHeadStringSelectorProps = {
  leftHanded: boolean;
  onChange: (stringIndex: number) => void;
  strings: SelectableStringInput[];
  isVertical: boolean;
};

export function NeckHeadStringSelector({
  leftHanded,
  onChange,
  strings,
  isVertical,
}: NeckHeadStringSelectorProps) {
  return (
    <ul
      className={cn(
        'relative flex justify-center',
        isVertical && 'gap-1',
        !isVertical && 'flex-col-reverse',
        leftHanded && !isVertical && 'flex-col-reverse',
        leftHanded && isVertical && 'flex-row-reverse',
      )}
    >
      {strings.map((string) => (
        <NeckHeadStringSelectorItem
          isVertical={isVertical}
          leftHanded={leftHanded}
          id={string.id}
          key={string.id}
          label={string.label}
          onChange={onChange}
          selected={string.selected}
          stringNumber={string.stringNumber}
          stringIndex={string.index}
          disabled={string.disabled}
        />
      ))}
    </ul>
  );
}
