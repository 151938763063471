import { App } from '@capacitor/app';
import { IDeviceService } from '../device';
import { IAppService } from './app.types';

export class AppCapacitorService implements IAppService {
  private initialized = false;
  private name: string;
  private version: string;
  private build: string;
  private id: string;

  constructor(private readonly device: IDeviceService) {}

  async initialize() {
    if (this.device.isNative()) {
      const { build, id, name, version } = await App.getInfo();
      this.build = build;
      this.id = id;
      this.name = name;
      this.version = version;
    } else {
      this.build = 'no-build';
      this.id = 'no-id';
      this.name = APP_NAME;
      this.version = APP_VERSION;
    }
    this.initialized = true;
  }

  public getBuild() {
    this.checkIfInitialized();
    return this.build;
  }

  public getId() {
    this.checkIfInitialized();
    return this.id;
  }

  public getName() {
    this.checkIfInitialized();
    return this.name;
  }

  public getVersion() {
    this.checkIfInitialized();
    return this.version;
  }

  private checkIfInitialized() {
    if (!this.initialized) {
      throw new Error('AppService not initialized');
    }
  }
}
