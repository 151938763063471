import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { FindTheNoteFromRootAndIntervalExerciseContext } from '../../store/context';
import { QuestionSectionGeneric } from '../../../exercise/states/playing/question-section-generic';
import { useNoteNotation } from '../../../../../services/note-notation/use-note-notation';
import { useIntervalTranslation } from '../../../../../services/i18n';

export function QuestionSection() {
  const { n } = useNoteNotation();
  const { i } = useIntervalTranslation();
  const store = useContext(FindTheNoteFromRootAndIntervalExerciseContext);
  const question = useQuestion(store);

  if (!question) {
    throw new Error('question not found');
  }

  const givenInterval = question.question.givenInterval;
  const givenNote = question.question.givenNote;

  const key = givenInterval + givenNote;

  return (
    <QuestionSectionGeneric key={key} store={store}>
      <Trans
        i18nKey="pages./find-the-note-from-root-and-interval.question"
        values={{
          givenNote: n(question.question.givenNote),
          givenInterval: i(question.question.givenInterval),
        }}
        components={[<strong key={0} />]}
      />
    </QuestionSectionGeneric>
  );
}
