import { PartyPopper } from 'lucide-react';
import { forwardRef } from 'react';
import { Thematic } from '@notacami/core/thematic';
import { getColorVariantByThematic } from '@notacami/core/design';
import { Button } from '@notacami/ui';
import { TutorialStepContent } from '../tutorial-step-content';

type GetReadyStepProps = {
  onTutorialComplete: () => void;
  thematic: Thematic;
  description: string;
  buttonLabel: string;
};

export const GetReadyStep = forwardRef<HTMLDivElement, GetReadyStepProps>(
  ({ onTutorialComplete, thematic, description, buttonLabel }, ref) => {
    const colorVariant = getColorVariantByThematic(thematic);

    return (
      <TutorialStepContent
        ref={ref}
        content={
          <div className="vertical-content-distribution-base items-center">
            <PartyPopper
              strokeWidth={1.6}
              className="h-12 w-12 stroke-neutral-600 dark:stroke-neutral-300"
            />
            <p className="max-w-xs text-center">{description}</p>
          </div>
        }
        action={
          <Button colorVariant={colorVariant} onClick={onTutorialComplete}>
            {buttonLabel}
          </Button>
        }
      />
    );
  },
);

GetReadyStep.displayName = 'GetReadyStep';
