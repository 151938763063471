import { useContext } from 'react';
import { FormFieldContainerLayout, InputNumber } from '@notacami/ui';
import {
  MAX_REFERENCE_FREQUENCY,
  MIN_REFERENCE_FREQUENCY,
} from '@notacami/core/frequency';
import { usePreferencesStore } from '../../preferences/use-preferences-context';
import { useTranslation } from '../../../../hooks/use-translation';
import { ServicesContext } from '../../../../services/services.context';

export function ReferenceFrequencySection() {
  const { peakDetection, preferenceReferenceFrequencyStore, tuner } =
    useContext(ServicesContext);
  const { t } = useTranslation();
  const changeReferenceFrequency = usePreferencesStore(
    (state) => state.setFrenquencyReference,
  );
  const referenceFrequency = usePreferencesStore(
    (state) => state.referenceFrequency,
  );

  const handleChange = (newValue: number) => {
    changeReferenceFrequency(newValue);
    preferenceReferenceFrequencyStore.set(newValue);
    peakDetection.setReferenceFrequency(newValue);
    tuner.setReferenceFrequency(newValue);
  };

  const handleMinusButtonClick = () => {
    handleChange(Math.max(referenceFrequency - 1, MIN_REFERENCE_FREQUENCY));
  };

  const handlePlusButtonClick = () => {
    handleChange(Math.min(referenceFrequency + 1, MAX_REFERENCE_FREQUENCY));
  };

  return (
    <FormFieldContainerLayout
      id="reference-frequency-setting-field"
      label={t('pages.settings.reference-frequency.title')}
      input={
        <InputNumber
          className="w-12"
          id="reference-frequency-setting"
          max={MAX_REFERENCE_FREQUENCY}
          min={MIN_REFERENCE_FREQUENCY}
          onMinusClick={handleMinusButtonClick}
          onPlusClick={handlePlusButtonClick}
          onValueChange={handleChange}
          value={referenceFrequency}
        />
      }
    />
  );
}
