import { VuMeterMarkers } from './vu-meter-markers';

export function VuMeter() {
  return (
    <div className="w-60 rounded-xl border-2 border-neutral-300 bg-neutral-200 p-px">
      <div className="relative flex items-center justify-between gap-2 rounded-xl border-2 border-neutral-300 bg-neutral-900 px-3 py-1">
        <div className="text-xs text-neutral-400">
          VU <span className="text-xs text-neutral-500">(dB)</span>
        </div>
        <VuMeterMarkers />
      </div>
    </div>
  );
}
