import { getRandomIntegerWithBoundaries } from './get-random-integer-with-boundaries';

export function getRandomElement<T>(
  elements: T[],
  excludedElements: T[] = [],
): T {
  const filteredElements = elements.filter(
    (element) => !excludedElements.includes(element),
  );

  const numberOfElements = filteredElements.length;

  if (numberOfElements === 0) {
    throw new Error('Cannot get random element of empty array');
  }

  if (numberOfElements === 1) {
    return filteredElements[0];
  }

  const randomIndex = getRandomIntegerWithBoundaries(0, numberOfElements - 1);

  return filteredElements[randomIndex];
}
