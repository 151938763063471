import { Book } from 'lucide-react';
import { MDX_COMPONENTS } from '@notacami/ui';
import { ExpandableCard } from '../ui/expandable-card/expandable-card';
import { useLongText } from '../../services/i18n';
import { createOnPictureExpandableCardTitle } from '../ui/expandable-card/title-on-picture';
import { NavigationButtonContainer } from '../ui/navigation-button-container';
import { ThematicList } from './thematic-list';

export function HomePageContent() {
  const getStartedLongText = useLongText('get-started');
  return (
    <div className="vertical-content-distribution-base">
      <ExpandableCard
        picture="/pictures/get-started.png"
        isDark
        title={createOnPictureExpandableCardTitle(
          <Book className="dark-icon-shadow" />,
          getStartedLongText.frontmatter.title,
        )}
        titleText={getStartedLongText.frontmatter.title}
      >
        <getStartedLongText.component components={MDX_COMPONENTS} />
      </ExpandableCard>
      <ThematicList />
      <div className="vertical-content-distribution-sm">
        <NavigationButtonContainer to={'/lesson/demo-lesson-en-fr'}>
          Demo Lesson EN FR
        </NavigationButtonContainer>
      </div>
    </div>
  );
}
