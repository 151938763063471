import { Services } from '../services/services.types';
import { Persona } from '../services/persona';
import { IStore } from '../services/storage/storage.type';
import { LanguagePreference } from '../services/i18n';
import { FindTheNoteFromRootAndIntervalProgressStoredPayload } from '../services/progress/find-the-note-from-root-and-interval/types';
import { StoredProgressEntries } from '../services/progress/progress.types';
import { FindScalePositionProgressStoredPayload } from '../services/progress/find-scale-position/types';
import { FindTheNoteOnTheNeckProgressStoredPayload } from '../services/progress/find-the-note-on-the-neck/types';
import { StoreInMemory } from '../services/storage/storage.in-memory';
import { FindTheIntervalOnTheNeckProgressStoredPayload } from '../services/progress/find-the-interval-on-the-neck/types';
import { ThemePreference } from '../services/theme-preference';

export function instanciatePersonaServices(
  persona: Persona,
): Partial<Services> {
  const findTheNoteOnTheNeckProgressStore: IStore<
    StoredProgressEntries<FindTheNoteOnTheNeckProgressStoredPayload>
  > = new StoreInMemory([
    [
      'guitar-standard',
      240,
      [
        [[0, 0], 8],
        [[1, 3], 9],
        [[2, 2], 7],
        [[3, 8], 5],
        [[4, 5], 2],
        [[5, 1], 1],
      ],
    ],
  ]);

  const findScalePositionProgressStore: IStore<
    StoredProgressEntries<FindScalePositionProgressStoredPayload>
  > = new StoreInMemory([]);

  const findTheNoteFromRootAndIntervalProgressStore: IStore<
    StoredProgressEntries<FindTheNoteFromRootAndIntervalProgressStoredPayload>
  > = new StoreInMemory([]);

  const findTheIntervalOnTheNeckProgressStore: IStore<
    StoredProgressEntries<FindTheIntervalOnTheNeckProgressStoredPayload>
  > = new StoreInMemory([]);

  const preferenceThemeStore: IStore<ThemePreference> = new StoreInMemory(
    'light',
  );

  switch (persona) {
    case Persona.NONE:
      return {};
    case Persona.BERTRAND: {
      const preferenceLanguageStore: IStore<LanguagePreference> =
        new StoreInMemory('fr');

      return {
        findTheNoteOnTheNeckProgressStore,
        findScalePositionProgressStore,
        findTheNoteFromRootAndIntervalProgressStore,
        findTheIntervalOnTheNeckProgressStore,
        preferenceLanguageStore,
        preferenceThemeStore,
      };
    }
    case Persona.SAMI: {
      const preferenceLanguageStore: IStore<LanguagePreference> =
        new StoreInMemory('en');

      return {
        findTheNoteOnTheNeckProgressStore,
        findScalePositionProgressStore,
        findTheNoteFromRootAndIntervalProgressStore,
        findTheIntervalOnTheNeckProgressStore,
        preferenceLanguageStore,
        preferenceThemeStore,
      };
    }
  }
}
