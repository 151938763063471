import Emittery from 'emittery';
import Queue from 'yocto-queue';
import { PeakInfo } from '../peak-detection';

export type NotePlayedMessageEventMaxima = {
  type: 'maxima';
  level: number;
  noteChroma: number;
  time: number;
};

type NotePlayedMessageEvent =
  | NotePlayedMessageEventMaxima
  | { type: 'minima' }
  | { type: 'silence' };

export type DequeuePayload = {
  previousMessage: NotePlayedMessageEvent | undefined;
  currentMessage: NotePlayedMessageEvent;
};

type NotePlayedQueueEvents = {
  dequeue: DequeuePayload;
};

export class NotePlayedQueue extends Emittery<NotePlayedQueueEvents> {
  private queue: Queue<NotePlayedMessageEvent> | undefined;
  private previousMessage: NotePlayedMessageEvent | undefined;
  private isDequeing = false;

  public resume() {
    this.queue = new Queue<NotePlayedMessageEvent>();
  }

  public stop() {
    if (this.queue) {
      this.queue.clear();
      this.queue = undefined;
      this.previousMessage = undefined;
    }
  }

  public queuePeaks(peaks: PeakInfo[]) {
    const messages = this.convertPeaksInfoToMessages(peaks);
    messages.forEach((message) => {
      if (this.queue) {
        this.queue.enqueue(message);
      }
    });
    this.startDequeue();
  }

  public queueSilence() {
    if (this.queue) {
      this.queue.enqueue({ type: 'silence' });
      this.startDequeue();
    }
  }

  private startDequeue() {
    if (!this.isDequeing) {
      this.isDequeing = true;
      this.dequeue();
    }
  }

  private dequeue() {
    if (this.queue) {
      if (this.queue.size > 0) {
        const currentMessage = this.queue.dequeue();

        if (currentMessage) {
          this.emit('dequeue', {
            previousMessage: this.previousMessage,
            currentMessage,
          });
          this.previousMessage = currentMessage;

          this.dequeue();
        }
      } else {
        this.isDequeing = false;
      }
    }
  }

  private convertPeaksInfoToMessages(
    peaks: PeakInfo[],
  ): NotePlayedMessageEvent[] {
    return peaks.map((peak): NotePlayedMessageEvent => {
      switch (peak.type) {
        case 'maxima':
          return {
            level: peak.level,
            noteChroma: peak.noteChroma,
            time: peak.time,
            type: 'maxima',
          };
        case 'minima':
          return {
            type: 'minima',
          };
      }
    });
  }
}
