import { SyntheticEvent } from 'react';
import { cn } from '@notacami/core/css';
import { Minus, Plus } from 'lucide-react';
import { Button } from './button';
import { getIconSizeByButtonSize } from './button.utils';

type InputNumberProps = {
  className?: string;
  id: string;
  isInterative?: boolean;
  max: number;
  min: number;
  onBlur?: () => void;
  onMinusClick: () => void;
  onPlusClick: () => void;
  onValueChange: (value: number) => void;
  size?: 'sm' | 'default';
  step?: number;
  value: number;
};

export function InputNumber({
  className,
  id,
  isInterative = false,
  max,
  min,
  onBlur,
  onMinusClick,
  onPlusClick,
  onValueChange,
  size = 'default',
  step = 1,
  value,
}: InputNumberProps) {
  const handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    onValueChange(parseInt(event.currentTarget.value));
  };

  const iconSize = size === 'default' ? 'icon-sm' : 'icon-xs';

  return (
    <div className="flex gap-2">
      <Button
        colorVariant="neutral"
        disabled={value <= min}
        onClick={onMinusClick}
        size={iconSize}
        variant="rounded"
      >
        <Minus size={getIconSizeByButtonSize(iconSize)} />
      </Button>
      <input
        id={id}
        name={id}
        type="number"
        value={value}
        onChange={handleChange}
        inputMode="numeric"
        min={min}
        max={max}
        onBlur={onBlur}
        pattern="\d*"
        step={step}
        className={cn(
          'input regular-border rounded-lg p-1 text-center tabular-nums',
          size === 'default' ? 'h-9 text-base' : 'h-6 text-sm',
          !isInterative && 'pointer-events-none',
          className,
        )}
      />
      <Button
        colorVariant="neutral"
        disabled={value >= max}
        onClick={onPlusClick}
        size={iconSize}
        variant="rounded"
      >
        <Plus size={getIconSizeByButtonSize(iconSize)} />
      </Button>
    </div>
  );
}
