import { Book } from 'lucide-react';
import { Fragment } from 'react';
import { MDX_COMPONENTS } from '@notacami/ui';
import { ScaleTypeName } from '@notacami/core/scales';
import { PageUrls, SCALE_SEARCH_PARAM } from '../../utils/routes.contants';
import { ExpandableCard } from '../../components/ui/expandable-card/expandable-card';
import { useLongText } from '../../services/i18n';
import { useShowTabBar } from '../../components/ui/tab-bar.hooks';
import { useTranslation } from '../../hooks/use-translation';
import { createSimpleExpandableCardTitle } from '../../components/ui/expandable-card/title';
import { ThematicPageLayout } from '../../components/business/thematic-page-layout';
import { ThematicPageLinkItem } from '../../components/business/thematic-page-link-item';
import { ThematicPageContentTitle } from '../../components/business/thematic-page-content-title';

export function ScalesPage() {
  const { t } = useTranslation();
  useShowTabBar();

  const scalesLongText = useLongText('scales');
  const scaleTypeNameList: ScaleTypeName[] = ['major', 'harmonic minor'];

  return (
    <ThematicPageLayout thematic="scales">
      <ExpandableCard
        isDark
        title={createSimpleExpandableCardTitle(
          <Book />,
          scalesLongText.frontmatter.title,
        )}
        titleText={scalesLongText.frontmatter.title}
      >
        <scalesLongText.component components={MDX_COMPONENTS} />
      </ExpandableCard>
      {scaleTypeNameList.map((scaleName) => (
        <Fragment key={scaleName}>
          <ThematicPageContentTitle>
            {t('scale-and-name', { scaleName })}
          </ThematicPageContentTitle>
          <div className="vertical-content-distribution-sm">
            <ThematicPageLinkItem
              to={`${
                PageUrls.VISUALIZE_SCALE_POSITIONS
              }?${SCALE_SEARCH_PARAM}=${encodeURIComponent(scaleName)}`}
              thematic="scales"
              type="visualization"
              isExercise={false}
              specificLabels={[t(`scale.name.${scaleName}`)]}
            >
              {t('pages.VISUALIZE_SCALE_POSITIONS.title')}
            </ThematicPageLinkItem>
            <ThematicPageLinkItem
              to={`${
                PageUrls.FIND_SCALE_POSITION
              }?${SCALE_SEARCH_PARAM}=${encodeURIComponent(scaleName)}`}
              thematic="scales"
              type="fretboard-exercise"
              isExercise={true}
              specificLabels={[t(`scale.name.${scaleName}`)]}
            >
              {t('pages.FIND_SCALE_POSITION.title')}
            </ThematicPageLinkItem>
          </div>
        </Fragment>
      ))}

      <ThematicPageContentTitle>{t('all-scales')}</ThematicPageContentTitle>
      <div className="vertical-content-distribution-sm">
        <ThematicPageLinkItem
          to={PageUrls.FIND_SCALE_POSITION}
          thematic="scales"
          type="fretboard-exercise"
          isExercise={true}
          specificLabels={[t('all-scales')]}
        >
          {t('pages.FIND_SCALE_POSITION.title')}
        </ThematicPageLinkItem>
      </div>
    </ThematicPageLayout>
  );
}
