import { IPeakDetectionService } from '../peak-detection';
import { ConsumerService } from './consumer';

export function getPeakDetectionConsumer(
  peakDetectionService: IPeakDetectionService,
) {
  return new ConsumerService<void, void>(
    () => {
      return peakDetectionService.resume();
    },
    () => {
      return peakDetectionService.stop();
    },
  );
}
