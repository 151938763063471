import { useTranslation } from 'react-i18next';
import { AppLanguage } from '@notacami/core/i18n';
import { getLongText } from './get-long-text';
import { LongTextId } from './translations.types';

export function useLongText(id: LongTextId) {
  const { i18n } = useTranslation();
  const longText = getLongText(id, i18n.language as AppLanguage);
  return longText;
}
