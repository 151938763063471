import { ReactNode, useContext } from 'react';
import { HapticFeedbackProvider } from '@notacami/ui';
import { ServicesContext } from '../services/services.context';

type HapticFeedbackBootProps = {
  children: ReactNode;
};

export function HapticFeedbackBoot({ children }: HapticFeedbackBootProps) {
  const { hapticFeedback } = useContext(ServicesContext);

  return (
    <HapticFeedbackProvider
      value={{ impact: hapticFeedback.impact.bind(hapticFeedback) }}
    >
      {children}
    </HapticFeedbackProvider>
  );
}
