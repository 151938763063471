import { LessonInfo } from '@notacami/core/lesson';
import { LESSON_BASE, SEQUENCE_SAMPLE } from './demo-lesson-base';

export const DEMO_LESSON_EN_FR_TEACHER_PLAYING: LessonInfo = {
  ...LESSON_BASE,
  id: 'demo-lesson-en-fr-teacher-playing',
  steps: [
    {
      id: '0',
      type: 'teacher-playing',
      sequence: SEQUENCE_SAMPLE,
      bpm: 60,
    },
  ],
};
