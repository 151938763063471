import { useContext } from 'react';
import { useProgressData } from '../../../../../services/exercise/hooks/use-progress-data';
import { FindTheIntervalOnTheNeckExerciseContext } from '../../store/context';
import { FindTheIntervalOnTheNeckProgress } from '../../../find-the-interval-on-the-neck-progress';

type ResultsProps = {
  availableIntervals: string[] | null;
};

export function Results({ availableIntervals }: ResultsProps) {
  const store = useContext(FindTheIntervalOnTheNeckExerciseContext);
  const progressData = useProgressData(store);

  return progressData !== null ? (
    <FindTheIntervalOnTheNeckProgress
      intervals={availableIntervals}
      previousProgressData={progressData.previousProgressData}
      currentProgressData={progressData.currentProgressData}
    />
  ) : null;
}
