import { useContext, useEffect, useState } from 'react';
import { Play, Square } from 'lucide-react';
import { Button, Surface } from '@notacami/ui';
import { ConsumersIds } from '../../../services/consumer';
import { GainInput } from '../gain-input';
import { useMicErrorDialogStore } from '../mic-error-dialog.store';
import { ServicesContext } from '../../../services/services.context';
import { GraphSection } from './graph-section';
import { SilentSection } from './silent-section';
import { VuMeterSection } from './vu-meter-section';
import { NoteChangedSection } from './note-changed-section';
import { PeakDetectionControls } from './peak-detection-controls';

export function NotePlayedDebugContent() {
  const { pitchDetectionConsumer } = useContext(ServicesContext);
  const [isRecording, setIsRecording] = useState(true);

  const handleRecordingClck = () => {
    setIsRecording((state) => !state);
  };

  const openMicErrorDialog = useMicErrorDialogStore(
    (state) => state.openDialog,
  );

  useEffect(() => {
    async function resumePitchDetection() {
      const miError = await pitchDetectionConsumer.addConsumer(
        ConsumersIds.NOTE_PLAYED_DEBUG,
      );
      const errorMessage = miError?.error;
      if (errorMessage) {
        openMicErrorDialog(errorMessage);
      }
    }
    resumePitchDetection();

    return () => {
      pitchDetectionConsumer.removeConsumer(ConsumersIds.NOTE_PLAYED_DEBUG);
    };
  }, [openMicErrorDialog]);

  return (
    <div className="columns-1 lg:columns-2">
      <div className="flex justify-between gap-4">
        <GainInput thematic="notes" />
        <Button
          colorVariant="neutral"
          onClick={handleRecordingClck}
          size="icon"
          variant="rounded"
        >
          {isRecording ? <Square /> : <Play />}
        </Button>
      </div>
      <SilentSection />
      <Surface surfaceId="light" className="flex flex-col gap-2 p-2">
        <NoteChangedSection isRecording={isRecording} />
      </Surface>
      <GraphSection isRecording={isRecording} />
      <PeakDetectionControls />
      <VuMeterSection />
    </div>
  );
}
