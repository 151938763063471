import { Note } from 'tonal';
import { Sequence } from '@notacami/core/composer';
import { getChordInfoByTonicTypeAndBass } from '@notacami/core/chords';
import { getScaleInfoByTonicAndScaleTypeName } from '@notacami/core/scales';
import { FretboardNoteDetails } from '../fretboard';
import { getIntervalBetweenTwoNotes } from '../intervals/get-interval-between-to-notes';
import { getNotePitchClassToDisplay } from '../notes/get-note-pitch-class-to-display';
import {
  EventInPart,
  GroupNoteEndEvent,
  GroupNoteStartEvent,
  NoteEndEvent,
  NoteStartEvent,
} from './composer.types';
import { getGroupEventWithNoteEventOverlap } from './get-group-event-with-note-event-overlap';

function getInfoToDisplay(
  noteNameToPlay: string,
  notePitchClassToPlay: string,
  tonicPitchClass: string,
  forcedIntervals: string[],
  forcedNotes: string[],
) {
  const { interval, isInForcedIntervals } = getIntervalBetweenTwoNotes(
    tonicPitchClass,
    notePitchClassToPlay,
    forcedIntervals,
  );

  return {
    isInScaleOrChord: isInForcedIntervals,
    intervalToDisplay: interval,
    pitchClassToDisplay: getNotePitchClassToDisplay(
      noteNameToPlay,
      forcedNotes,
    ),
  };
}

export function createPartEvents(
  sequence: Sequence,
  fretboardNoteDetails: FretboardNoteDetails,
): EventInPart[] {
  const noteEvents = sequence.filter(
    (sequenceEvent) => sequenceEvent.type === 'note',
  );

  const groupNoteEvents = sequence.filter(
    (sequenceEvent) => sequenceEvent.type === 'group-note',
  );

  const groupNoteStartEvents: GroupNoteStartEvent[] = groupNoteEvents.map(
    (groupNoteEvent, index) => {
      switch (groupNoteEvent.groupType) {
        case 'chord': {
          const chordInfo = getChordInfoByTonicTypeAndBass(
            groupNoteEvent.value.type,
            groupNoteEvent.value.tonic,
            groupNoteEvent.value.bass,
          );
          const rootPitchClass = Note.pitchClass(chordInfo.tonic as string);
          return {
            duration: groupNoteEvent.duration,
            groupType: groupNoteEvent.groupType,
            id: `group-note-${index}`,
            noteDisplayMode: groupNoteEvent.noteDisplayMode,
            time: groupNoteEvent.time,
            type: 'group-note-start',
            value: groupNoteEvent.value,
            valueToDisplay: chordInfo.symbol,
            rootPitchClass,
            chordIntervals: chordInfo.intervals,
            chordNotes: chordInfo.notes,
          };
        }
        case 'scale': {
          const scaleInfo = getScaleInfoByTonicAndScaleTypeName(
            groupNoteEvent.value.tonic,
            groupNoteEvent.value.type,
          );
          const tonicPitchClass = Note.pitchClass(groupNoteEvent.value.tonic);
          return {
            duration: groupNoteEvent.duration,
            groupType: groupNoteEvent.groupType,
            id: `group-note-${index}`,
            noteDisplayMode: groupNoteEvent.noteDisplayMode,
            time: groupNoteEvent.time,
            type: 'group-note-start',
            value: groupNoteEvent.value,
            valueToDisplay: {
              type: scaleInfo.type,
              tonicPitchClass,
            },
            tonicPitchClass,
            scaleIntervals: scaleInfo.intervals,
            scaleNotes: scaleInfo.notes,
          };
        }
      }
    },
  );
  const groupNoteEndEvents: GroupNoteEndEvent[] = groupNoteEvents.map(
    ({ duration, time }, index) => ({
      type: 'group-note-end',
      id: `group-note-${index}`,
      time: time + duration,
    }),
  );

  const noteStartEventsForPart: NoteStartEvent[] = noteEvents
    .map((noteEvent, index): NoteStartEvent | null => {
      const position = noteEvent.position;
      const time = noteEvent.time;
      const duration = noteEvent.duration;

      const foundEvent = getGroupEventWithNoteEventOverlap(
        groupNoteStartEvents,
        noteEvent,
      );

      const noteNameToPlay =
        fretboardNoteDetails[position[0]][position[1]].name;

      const notePichClassToPlay =
        fretboardNoteDetails[position[0]][position[1]].pitchClass;

      switch (foundEvent?.groupType) {
        case 'scale': {
          const { intervalToDisplay, isInScaleOrChord, pitchClassToDisplay } =
            getInfoToDisplay(
              noteNameToPlay,
              notePichClassToPlay,
              foundEvent.tonicPitchClass,
              foundEvent.scaleIntervals,
              foundEvent.scaleNotes,
            );

          return {
            duration,
            id: `note-${index}`,
            intervalToDisplay,
            isInScaleOrChord,
            noteDisplayMode: foundEvent.noteDisplayMode,
            noteNameToPlay,
            pitchClassToDisplay,
            position,
            groupId: foundEvent.id,
            time,
            type: 'note-start',
          };
        }
        case 'chord': {
          const { intervalToDisplay, isInScaleOrChord, pitchClassToDisplay } =
            getInfoToDisplay(
              noteNameToPlay,
              notePichClassToPlay,
              foundEvent.rootPitchClass,
              foundEvent.chordIntervals,
              foundEvent.chordNotes,
            );

          return {
            duration,
            groupId: foundEvent.id,
            id: `note-${index}`,
            intervalToDisplay,
            isInScaleOrChord,
            noteDisplayMode: foundEvent.noteDisplayMode,
            noteNameToPlay,
            pitchClassToDisplay,
            position,
            time,
            type: 'note-start',
          };
        }
        case undefined:
          return {
            duration,
            groupId: null,
            id: `note-${index}`,
            isInScaleOrChord: false,
            noteDisplayMode: 'pitch-class',
            noteNameToPlay,
            pitchClassToDisplay: notePichClassToPlay,
            position,
            time,
            type: 'note-start',
          };
        default:
          return null;
      }
    })
    .filter(
      (
        maybeNoteStartEvent: NoteStartEvent | null,
      ): maybeNoteStartEvent is NoteStartEvent => maybeNoteStartEvent !== null,
    );

  const noteEndEventsForPart: NoteEndEvent[] = noteStartEventsForPart.map(
    ({ id, duration, time }) => ({
      id,
      time: duration + time,
      type: 'note-end',
    }),
  );

  const endEventTime = noteStartEventsForPart.reduce(
    (acc: number, curr: NoteStartEvent) => {
      if (curr.duration + curr.time > acc) {
        return curr.duration + curr.time;
      } else {
        return acc;
      }
    },
    0,
  );

  return [
    ...groupNoteStartEvents,
    ...groupNoteEndEvents,
    ...noteStartEventsForPart,
    ...noteEndEventsForPart,
    { type: 'part-end', time: endEventTime },
  ];
}
