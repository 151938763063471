import { Device } from '@capacitor/device';
import { Animation, StatusBar } from '@capacitor/status-bar';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { IDeviceService } from './device.types';

export class DeviceCapacitorService implements IDeviceService {
  private initialized = false;
  private platform: string;
  private identifier: string;

  async initialize() {
    const { platform } = await Device.getInfo();
    this.platform = platform;
    const id = await Device.getId();
    this.identifier = id.identifier;

    if (platform === 'android') {
      StatusBar.setOverlaysWebView({ overlay: true });
      StatusBar.show({ animation: Animation.None });
      // fix this problem https://github.com/AlwaysLoveme/capacitor-plugin-safe-area/issues/25
      await SafeArea.setImmersiveNavigationBar();
    }

    this.initialized = true;
  }

  public isNative() {
    this.checkIfInitialized();
    return this.platform !== 'web';
  }

  public getPlatform() {
    this.checkIfInitialized();
    return this.platform;
  }

  public getIdentifier() {
    this.checkIfInitialized();
    return this.identifier;
  }

  private checkIfInitialized() {
    if (!this.initialized) {
      throw new Error('DeviceService not initialized');
    }
  }
}
