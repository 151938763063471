import { Position } from '@notacami/core/fretboard';

export function getDistanceBetweenPositions(
  position1: Position,
  position2: Position,
) {
  const distance = Math.sqrt(
    Math.pow(Math.abs(position1[0] - position2[0]), 2) +
      Math.pow(Math.abs(position1[1] - position2[1]), 2),
  );

  return Math.round(distance * 100) / 100;
}
