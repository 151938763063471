import { useSearchParams } from 'react-router';
import { Title } from '@notacami/ui';
import { INTERVALS_SEARCH_PARAM, PageUrls } from '../../utils/routes.contants';
import { useTranslation } from '../../hooks/use-translation';
import { Badge } from '../../components/ui/badge';
import { PageLayout } from '../../components/business/page-layout';
import { useTextIntervalsWithSeparation } from '../../services/intervals';
import { useHideTabBar } from '../../components/ui/tab-bar.hooks';
import { VisualizeIntervalPositionsNeck } from '../../components/business/visualize-intervals-positions/visualize-interval-positions-neck';
import { BackLink } from '../../components/ui/back-link';

export function VisualizeIntervalPositionsPage() {
  useHideTabBar();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { translate } = useTextIntervalsWithSeparation();

  const intervals = searchParams.get(INTERVALS_SEARCH_PARAM);

  const availableIntervals = !intervals ? null : intervals.split(',');

  return (
    <PageLayout
      title={
        <div className="flex flex-col items-start">
          <Title level={2} tagName="h1">
            {t('pages.visualize_interval_positions.title')}
          </Title>
          <Badge surfaceId="lighter">
            {availableIntervals !== null
              ? translate(availableIntervals)
              : t('all-intervals')}
          </Badge>
        </div>
      }
      leftAction={<BackLink to={PageUrls.INTERVALS} />}
      content={
        <div className="vertical-content-distribution-lg min-h-full items-stretch justify-center">
          <VisualizeIntervalPositionsNeck
            availableIntervals={availableIntervals}
          />
        </div>
      }
    />
  );
}
