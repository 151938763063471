import { motion } from 'motion/react';
import { useCallback, useEffect, useRef } from 'react';
import { NoteName } from '@notacami/core/notes';
import { useNoteNotation } from '../../../services/note-notation/use-note-notation';

type NoteItemProps = {
  onAskDisappear: (id: number) => void;
  id: number;
  noteName: NoteName;
};

export function NoteItem({ id, noteName, onAskDisappear }: NoteItemProps) {
  const { n } = useNoteNotation();
  const timeoutRef = useRef<number>();

  const disappear = useCallback(() => {
    onAskDisappear(id);
  }, [onAskDisappear, id]);

  useEffect(() => {
    timeoutRef.current = window.setTimeout(disappear, 1000);
    () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, [disappear]);

  return (
    <motion.div
      initial={{ x: 0, y: 5, opacity: 0 }}
      animate={{ x: -8, y: 0, opacity: 1 }}
      exit={{ x: -16, y: -10, opacity: 0 }}
      className="text-color-regular-inverted absolute flex items-center rounded-xl bg-neutral-500 px-3 py-1 text-base font-semibold dark:bg-neutral-200"
    >
      {n(noteName)}
    </motion.div>
  );
}
