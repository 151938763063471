import { Position } from './fretboard.types';
import { isPositionEqual } from './is-positions-equal';

export function isPositionIncludedInPositions(
  positions: Position[],
  maybeIncludedPosition: Position,
) {
  return positions.some((position) =>
    isPositionEqual(position, maybeIncludedPosition),
  );
}
