import { useContext } from 'react';
import { FormFieldContainerLayout, Switch } from '@notacami/ui';
import { usePreferencesStore } from '../../preferences/use-preferences-context';
import { useTranslation } from '../../../../hooks/use-translation';
import { ServicesContext } from '../../../../services/services.context';

export function LeftHandedSection() {
  const { preferenceLeftHandedStore } = useContext(ServicesContext);
  const { t } = useTranslation();
  const changeLeftHanded = usePreferencesStore((state) => state.setLeftHanded);
  const leftHanded = usePreferencesStore((state) => state.leftHanded);

  const handleChange = (checked: boolean) => {
    changeLeftHanded(checked);
    preferenceLeftHandedStore.set(checked);
  };

  return (
    <FormFieldContainerLayout
      id="left-handed-setting-field"
      label={t('pages.settings.left-handed.title')}
      input={
        <Switch
          id="left-handed-setting"
          name="left-handed-setting"
          checked={leftHanded}
          onCheckedChange={handleChange}
        />
      }
    />
  );
}
