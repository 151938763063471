const RECT_WIDTH = 2;

export function drawBoundariesLines(
  ctx: CanvasRenderingContext2D,
  boundaries: { min: number; max: number },
  CANVAS_WIDTH: number,
  CANVAS_HEIGHT: number,
  X_AXIS_RATIO: number,
) {
  ctx.fillStyle = 'rgba(255, 255, 0, 0.5)';
  const realMinX =
    CANVAS_WIDTH - boundaries.min / X_AXIS_RATIO - RECT_WIDTH / 2;
  ctx.fillRect(realMinX, CANVAS_HEIGHT, RECT_WIDTH, -CANVAS_HEIGHT);

  const realMaxX =
    CANVAS_WIDTH - boundaries.max / X_AXIS_RATIO - RECT_WIDTH / 2;
  ctx.fillRect(Math.round(realMaxX), CANVAS_HEIGHT, RECT_WIDTH, -CANVAS_HEIGHT);
}
