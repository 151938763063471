import { isPositionEqual } from '@notacami/core/fretboard';
import { Segment, TonicAndOctavePosition } from '../fretboard';

export function getSegmentByTonicAndOctavePosition(
  segments: Segment[],
  tonicAndOctavePosition: TonicAndOctavePosition,
): Segment {
  const foundSegment = segments.find(
    (positionWithIntervals) =>
      positionWithIntervals.some(
        ({ position, interval }) =>
          interval === '8P' &&
          isPositionEqual(position, tonicAndOctavePosition.octavePosition),
      ) &&
      positionWithIntervals.some(
        ({ position, interval }) =>
          interval === '1P' &&
          isPositionEqual(position, tonicAndOctavePosition.tonicPosition),
      ),
  );

  if (foundSegment === undefined) {
    throw new Error('Segment not found');
  }
  return foundSegment;
}
