import { MicOff } from 'lucide-react';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';
import { Button } from '@notacami/ui';
import { useTranslation } from '../../hooks/use-translation';

type MicDeniedPermissionContentProps = {
  isNative: boolean;
};

export function MicDeniedPermissionContent({
  isNative,
}: MicDeniedPermissionContentProps) {
  const { t } = useTranslation();

  const handleOpenSettingsClick = () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  return (
    <div className="vertical-content-distribution-base items-center">
      <MicOff
        strokeWidth={1.6}
        className="h-12 w-12 stroke-neutral-600 dark:stroke-neutral-300"
      />
      <p className="max-w-xs text-center">
        {t('tutorial.mic-tutorial.permission-denied-step.description')}
      </p>

      {isNative ? (
        <>
          <p className="max-w-xs text-center">
            {t(
              'tutorial.mic-tutorial.permission-denied-step.description-native',
            )}
          </p>
          <Button colorVariant="neutral" onClick={handleOpenSettingsClick}>
            {t('button.settings')}
          </Button>
        </>
      ) : null}
    </div>
  );
}
