import { Degree } from './intervals.types';

export const DEGREES_SEMITONS: (Degree | 0)[] = [
  1, 0, 2, 0, 3, 4, 0, 5, 0, 6, 0, 7, 8, 0, 9, 0, 10, 11, 0, 12, 0, 13, 0, 14,
];

export const BASIC_INTERVAL_LIST = [
  '1P',
  '2m',
  '2M',
  '3m',
  '3M',
  '4P',
  '5d',
  '5P',
  '6m',
  '6M',
  '7m',
  '7M',
  '8P',
];

export const PLAYABLE_INTERVALS_LIST = [
  '2m',
  '2M',
  '2A',
  '3d',
  '3m',
  '3M',
  '3A',
  '4d',
  '4P',
  '4A',
  '5d',
  '5P',
  '5A',
  '6d',
  '6m',
  '6M',
  '6A',
  '7d',
  '7m',
  '7M',
  '7A',
  '8d',
  '8P',
  '8A',
  '9d',
  '9m',
  '9M',
  '9A',
  '11d',
  '11P',
  '11A',
  '13d',
  '13m',
  '13M',
  '13A',
];

export const SELECTABLE_INTERVALS_LIST = [
  '1P',
  '1A',
  '2m',
  '2M',
  '2A',
  '3d',
  '3m',
  '3M',
  '3A',
  '4d',
  '4P',
  '4A',
  '5d',
  '5P',
  '5A',
  '6d',
  '6m',
  '6M',
  '6A',
  '7d',
  '7m',
  '7M',
  '7A',
  '8d',
  '8P',
  '8A',
  '9d',
  '9m',
  '9M',
  '9A',
  '10m',
  '10M',
  '10A',
  '11d',
  '11P',
  '11A',
  '12d',
  '12P',
  '12A',
  '13d',
  '13m',
  '13M',
  '13A',
  '14d',
  '14m',
  '14M',
  '14A',
];
