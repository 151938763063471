import { Loader2 } from 'lucide-react';

type SpinLoaderProps = {
  className?: string;
  size?: number;
};

export function SpinLoader({ className, size }: SpinLoaderProps) {
  return (
    <span className="animate-spin">
      <Loader2 className={className} size={size} />
    </span>
  );
}
