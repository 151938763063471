import { Unlock, Scale } from 'lucide-react';
import { Browser } from '@capacitor/browser';
import { useTranslation as useTranslationI18Next } from 'react-i18next';
import type { AppLanguage } from '@notacami/core/i18n';
import { NavigationButtonContainer } from '../../ui/navigation-button-container';
import { useTranslation } from '../../../hooks/use-translation';

type LegalsLessonType = 'privacy' | 'terms';

function getLegalsUrl(type: LegalsLessonType, language: AppLanguage) {
  const urls: Record<LegalsLessonType, Record<AppLanguage, string>> = {
    privacy: {
      en: 'https://notacami.vercel.app/en/privacy-policy',
      fr: 'https://notacami.vercel.app/fr/privacy-policy',
    },
    terms: {
      en: 'https://notacami.vercel.app/en/terms-and-conditions',
      fr: 'https://notacami.vercel.app/fr/terms-and-conditions',
    },
  };
  return urls[type][language];
}

export function LegalsSection() {
  const { t } = useTranslation();
  const { i18n } = useTranslationI18Next();
  const language = i18n.language as AppLanguage;

  const handleTermsAndConditionsClick = async () => {
    await Browser.open({
      url: getLegalsUrl('terms', language),
    });
  };

  const handlePrivacyPolicyClick = async () => {
    await Browser.open({
      url: getLegalsUrl('privacy', language),
    });
  };

  return (
    <div className="vertical-content-distribution-sm">
      <NavigationButtonContainer
        withChevron
        onClick={handleTermsAndConditionsClick}
      >
        <>
          <Scale size={22} />
          {t('button.terms-and-conditions')}
        </>
      </NavigationButtonContainer>
      <NavigationButtonContainer withChevron onClick={handlePrivacyPolicyClick}>
        <>
          <Unlock size={22} />
          {t('button.privacy-policy')}
        </>
      </NavigationButtonContainer>
    </div>
  );
}
