import { useContext } from 'react';
import { FindScalePostionExerciseContext } from '../../store/context';
import { usePlayingState } from '../../../../../services/exercise/hooks/use-playing-state';
import { useFindScalePositionStore } from '../../store/hook';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { AnswerSectionResult } from './answer-section-result';
import { AnswerSectionPlay } from './answer-section-play';

export function AnswerSection() {
  const store = useContext(FindScalePostionExerciseContext);
  const question = useQuestion(store);
  const { isInPlayingPlayState, isInPlayingResultState } =
    usePlayingState(store);

  const config = useFindScalePositionStore((state) => state.config);
  const currentTurn = useFindScalePositionStore((state) => state.currentTurn);
  const quizMode = useFindScalePositionStore((state) => state.quizMode);
  const showAnswerTip = useFindScalePositionStore(
    (state) => state.showAnswerTip,
  );
  const hideAnswerTip = useFindScalePositionStore(
    (state) => state.hideAnswerTip,
  );
  const submitAnswer = useFindScalePositionStore((state) => state.submitAnswer);
  const addError = useFindScalePositionStore((state) => state.addError);

  return isInPlayingPlayState || isInPlayingResultState ? (
    <div className="flex min-h-full flex-col items-center justify-center py-5">
      {isInPlayingPlayState &&
      question.question !== null &&
      question.correctAnswer !== null ? (
        <AnswerSectionPlay
          addError={addError}
          config={config}
          correctAnswer={question.correctAnswer}
          hideAnswerTip={hideAnswerTip}
          key={currentTurn}
          question={question.question}
          quizMode={quizMode}
          showAnswerTip={showAnswerTip}
          submitAnswer={submitAnswer}
        />
      ) : null}

      {isInPlayingResultState && question.question !== null ? (
        <AnswerSectionResult question={question.question} config={config} />
      ) : null}
    </div>
  ) : null;
}
