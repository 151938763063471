import { motion } from 'motion/react';
import { cn } from '@notacami/core/css';
import { StringNumber } from '@notacami/ui-business';
import { useNoteNotation } from '../../services/note-notation/use-note-notation';

type NeckHeadStringSelectorItemProps = {
  disabled: boolean;
  id: string;
  isVertical: boolean;
  label: string;
  leftHanded: boolean;
  onChange: (stringIndex: number) => void;
  selected: boolean;
  stringIndex: number;
  stringNumber: number;
};

function getXPosition(selected: boolean, leftHanded: boolean) {
  if (selected && leftHanded) {
    return 30;
  }
  if (selected && !leftHanded) {
    return -30;
  }
  return 0;
}

export function NeckHeadStringSelectorItem({
  disabled,
  id,
  isVertical,
  label,
  leftHanded,
  onChange,
  selected,
  stringIndex,
  stringNumber,
}: NeckHeadStringSelectorItemProps) {
  const { n } = useNoteNotation();

  const handleChange = () => {
    onChange(stringIndex);
  };

  return (
    <li className={cn('relative flex items-center justify-center')}>
      <input
        hidden
        type="checkbox"
        id={id}
        checked={selected}
        onChange={handleChange}
        disabled={disabled}
      />

      <motion.div
        animate={
          isVertical
            ? { y: selected ? -30 : 0 }
            : { x: getXPosition(selected, leftHanded) }
        }
        className={cn(
          'absolute',
          isVertical ? 'left-1/2 -ml-2' : 'top-1/2 -mt-2',
        )}
      >
        <StringNumber stringNumber={stringNumber} selected={selected} />
      </motion.div>
      <div className="py-1">
        <label
          className={cn(
            'relative flex items-center justify-center rounded-md px-2 py-1 text-sm font-semibold',
            isVertical ? 'h-6 w-9' : 'h-10 w-6',
            'cursor-pointer transition-all',
            selected && 'bg-stone-300 text-neutral-500',
            !selected &&
              'bg-stone-500 text-neutral-200 dark:bg-stone-900 dark:text-neutral-400',
            disabled &&
              'cursor-auto bg-neutral-800 text-neutral-500 dark:bg-neutral-600 dark:text-neutral-500',
          )}
          htmlFor={id}
        >
          {n(label)}
        </label>
      </div>
    </li>
  );
}
