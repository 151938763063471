import { AppLanguage } from '@notacami/core/i18n';
import { MdxComponentsProps, MdxMetadata } from '@notacami/ui';
import { LongTextId } from './translations.types';
import getStartedFR, {
  frontmatter as getStartedFRFormatter,
} from './long-text/get-started.fr.mdxn';
import getStartedEN, {
  frontmatter as getStartedENFormatter,
} from './long-text/get-started.en.mdxn';
import notesFR, {
  frontmatter as notesFRFormatter,
} from './long-text/notes.fr.mdxn';
import notesEN, {
  frontmatter as notesENFormatter,
} from './long-text/notes.en.mdxn';
import findTheNoteOnTheNeckInstructionsFR, {
  frontmatter as findTheNoteOnTheNeckInstructionsFRFormatter,
} from './long-text/find-the-note-on-the-neck-instructions.fr.mdxn';
import findTheNoteOnTheNeckInstructionsEN, {
  frontmatter as findTheNoteOnTheNeckInstructionsENFormatter,
} from './long-text/find-the-note-on-the-neck-instructions.en.mdxn';
import intervalsFR, {
  frontmatter as intervalsFRFormatter,
} from './long-text/intervals.fr.mdxn';
import intervalsEN, {
  frontmatter as intervalsENFormatter,
} from './long-text/intervals.en.mdxn';
import scalesFR, {
  frontmatter as scalesFRFormatter,
} from './long-text/scales.fr.mdxn';
import scalesEN, {
  frontmatter as scalesENFormatter,
} from './long-text/scales.en.mdxn';

export const LongTexts: Record<
  AppLanguage,
  Record<
    LongTextId,
    {
      frontmatter: MdxMetadata;
      component: (props: MdxComponentsProps) => JSX.Element;
    }
  >
> = {
  en: {
    'get-started': {
      frontmatter: getStartedENFormatter,
      component: getStartedEN,
    },
    notes: {
      frontmatter: notesENFormatter,
      component: notesEN,
    },
    'find-the-note-on-the-neck-instructions': {
      frontmatter: findTheNoteOnTheNeckInstructionsENFormatter,
      component: findTheNoteOnTheNeckInstructionsEN,
    },
    intervals: {
      frontmatter: intervalsENFormatter,
      component: intervalsEN,
    },
    scales: {
      frontmatter: scalesENFormatter,
      component: scalesEN,
    },
  },
  fr: {
    'get-started': {
      frontmatter: getStartedFRFormatter,
      component: getStartedFR,
    },
    notes: {
      frontmatter: notesFRFormatter,
      component: notesFR,
    },
    'find-the-note-on-the-neck-instructions': {
      frontmatter: findTheNoteOnTheNeckInstructionsFRFormatter,
      component: findTheNoteOnTheNeckInstructionsFR,
    },
    intervals: {
      frontmatter: intervalsFRFormatter,
      component: intervalsFR,
    },
    scales: {
      frontmatter: scalesFRFormatter,
      component: scalesFR,
    },
  },
};
