import { useCallback, useContext, useEffect, useReducer } from 'react';
import { cn } from '@notacami/core/css';
import { getHueByNoteChroma } from '@notacami/core/notes';
import { ServicesContext } from '../../../services/services.context';
import { ConsumersIds } from '../../../services/consumer';
import { reducer } from './note-changed-section.reducer';

type NoteChangedSectionProps = {
  isRecording: boolean;
};

export function NoteChangedSection({ isRecording }: NoteChangedSectionProps) {
  const { notePlayed, notePlayedConsumer } = useContext(ServicesContext);
  const [state, dispatch] = useReducer(reducer, []);

  const handleNoteStart = useCallback(
    ({ noteChroma, timestamp }: { noteChroma: number; timestamp: number }) => {
      dispatch({ type: 'NOTE_START', noteChroma, timestamp });
    },
    [],
  );

  const handleNoteEnd = useCallback(
    ({
      durationInMs,
      timestamp,
    }: {
      durationInMs: number;
      timestamp: number;
    }) => {
      dispatch({ type: 'NOTE_END', durationInMs, timestamp });
    },
    [],
  );

  useEffect(() => {
    if (isRecording) {
      notePlayedConsumer.addConsumer(
        ConsumersIds.NOTE_PLAYED_DEBUG_NOTE_CHANGED,
      );
      notePlayed.on('note-start', handleNoteStart);
      notePlayed.on('note-end', handleNoteEnd);
    }
    return () => {
      notePlayedConsumer.removeConsumer(
        ConsumersIds.NOTE_PLAYED_DEBUG_NOTE_CHANGED,
      );
      notePlayed.off('note-start', handleNoteStart);
      notePlayed.off('note-end', handleNoteEnd);
    };
  }, [isRecording, handleNoteStart]);

  useEffect(() => {
    if (isRecording) {
      dispatch({ type: 'RESET' });
    }
  }, [isRecording]);

  return (
    <div className="flex h-6 gap-2">
      {state.map((noteToDisplay) => (
        <div
          key={noteToDisplay.timestamp}
          style={{
            borderColor: getHueByNoteChroma(noteToDisplay.noteChroma),
          }}
          className={cn(
            'flex w-8 items-center justify-center rounded-lg border-4 text-xs font-semibold',
            noteToDisplay.ended && 'opacity-50',
          )}
        >
          {noteToDisplay.noteChroma}
        </div>
      ))}
    </div>
  );
}
