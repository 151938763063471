import { ReactNode } from 'react';
import { cn } from '@notacami/core/css';
import { getRangeNumberByLength } from '@notacami/core/utils';
import { NeckFret } from './neck-fret';
import { NeckHead } from './neck-head';
import { BuildCustomNeckHeadFactory } from './guitar-neck.types';

type NeckFretsProps = {
  buildCustomNeckHeadFactory?: BuildCustomNeckHeadFactory;
  buildFretsFactory?: (fretIndex: number) => ReactNode;
  fretLength: number;
  isTransparentBorder: boolean;
  isVertical: boolean;
  leftHanded: boolean;
};

export function NeckFrets({
  buildCustomNeckHeadFactory,
  buildFretsFactory,
  fretLength,
  isTransparentBorder,
  isVertical,
  leftHanded,
}: NeckFretsProps) {
  return (
    <div
      className={cn(
        'absolute top-0 flex h-full w-full',
        !isVertical && !leftHanded && 'flex-row',
        !isVertical && leftHanded && 'flex-row-reverse',
        isVertical && 'flex-col',
      )}
    >
      <NeckHead
        buildCustomNeckHeadFactory={buildCustomNeckHeadFactory}
        buildFretsFactory={buildFretsFactory}
        isVertical={isVertical}
        leftHanded={leftHanded}
      />
      {getRangeNumberByLength(fretLength).map((fretIndex) => (
        <NeckFret
          key={fretIndex}
          isLast={fretIndex === fretLength - 1}
          isVertical={isVertical}
          leftHanded={leftHanded}
          buildFretsFactory={buildFretsFactory}
          fretIndex={fretIndex + 1}
          isTransparentBorder={isTransparentBorder}
        />
      ))}
    </div>
  );
}
