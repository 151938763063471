import { ReactNode, useContext, useEffect, useState } from 'react';
import { PreferencesProvider } from '../components/business/preferences/preferences.context';
import { ServicesContext } from '../services/services.context';
import { PreferencesProps } from '../components/business/preferences/preferences.store';
import { getFretboardAndTuningInfoByTuningId } from '../components/business/preferences/compute-fretboard-and-tuning-info-by-tuning-id';

type PreferencesBootProps = {
  children: ReactNode;
};

export function PreferencesBoot({ children }: PreferencesBootProps) {
  const [initialPreferences, setInitialPreferences] =
    useState<PreferencesProps | null>(null);

  const services = useContext(ServicesContext);

  const getInitialPreferences = async (): Promise<PreferencesProps> => {
    const [
      admin,
      noteNotation,
      tuningId,
      referenceFrequency,
      leftHanded,
      theme,
      language,
    ] = await Promise.all([
      services.preferenceAdminStore.get(),
      services.preferenceNoteNotationStore.get(),
      services.preferenceTuningStore.get(),
      services.preferenceReferenceFrequencyStore.get(),
      services.preferenceLeftHandedStore.get(),
      services.preferenceThemeStore.get(),
      services.preferenceLanguageStore.get(),
    ]);

    const { fretboard, tuningInfo } =
      getFretboardAndTuningInfoByTuningId(tuningId);

    return {
      admin,
      fretboard,
      leftHanded,
      noteNotation,
      referenceFrequency,
      tuningInfo,
      themePreference: theme,
      languagePreference: language,
    };
  };

  useEffect(() => {
    async function getInitialPreferencesAtMount() {
      const initialPreferences = await getInitialPreferences();
      setInitialPreferences(initialPreferences);
    }
    getInitialPreferencesAtMount();
  }, [services]);

  return initialPreferences !== null ? (
    <PreferencesProvider
      admin={initialPreferences.admin}
      fretboard={initialPreferences.fretboard}
      leftHanded={initialPreferences.leftHanded}
      noteNotation={initialPreferences.noteNotation}
      referenceFrequency={initialPreferences.referenceFrequency}
      tuningInfo={initialPreferences.tuningInfo}
      themePreference={initialPreferences.themePreference}
      languagePreference={initialPreferences.languagePreference}
    >
      {children}
    </PreferencesProvider>
  ) : null;
}
