import { FindTheIntervalOnTheNeckProgressStoredPayload } from '../../progress/find-the-interval-on-the-neck/types';
import { StoredProgressEntries } from '../../progress/progress.types';
import { StoreCapacitor } from '../storage.capacitor';

export function getFindTheIntervalOnTheNeckProgressCapacitorStore() {
  return new StoreCapacitor<
    StoredProgressEntries<FindTheIntervalOnTheNeckProgressStoredPayload>
  >(
    'quiz.find-the-interval-on-the-neck.progress',
    [],
    (value) => JSON.stringify(value),
    (value) => JSON.parse(value),
  );
}
