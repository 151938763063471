import {
  FindTheIntervalOnTheNeckTurnQuestion,
  FindTheIntervalOnTheNeckTurnResult,
} from '../../../components/business/find-the-interval-on-the-neck/types';
import { AbstractProgressEntry } from '../abstract-progress-entry';
import { StoredProgressEntryOutcome } from '../progress.types';
import {
  FindTheIntervalOnTheNeckProgressPayload,
  FindTheIntervalOnTheNeckProgressStoredPayload,
} from './types';

export function fromStoredEntry(
  entry: StoredProgressEntryOutcome<FindTheIntervalOnTheNeckProgressStoredPayload>,
) {
  return new ProgressEntry({ interval: entry[0] }, entry[1]);
}

export function fromResult(
  question: FindTheIntervalOnTheNeckTurnQuestion,
  _result: FindTheIntervalOnTheNeckTurnResult,
) {
  return new ProgressEntry(
    {
      interval: question.question.givenInterval,
    },
    1,
  );
}

class ProgressEntry extends AbstractProgressEntry<
  FindTheIntervalOnTheNeckProgressPayload,
  FindTheIntervalOnTheNeckProgressStoredPayload
> {
  public toOutcome() {
    return {
      id: this.payload.interval,
      payload: this.payload,
      numberOfCorrectAnswer: this.numberOfCorrectAnswer,
    };
  }

  public toStoreOutcome() {
    return [this.payload.interval, this.numberOfCorrectAnswer] satisfies [
      FindTheIntervalOnTheNeckProgressStoredPayload,
      number,
    ];
  }
}
