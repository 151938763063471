import { getRandomNote } from '@notacami/core/notes';
import { getRandomElement } from '@notacami/core/utils';
import {
  FindTheNoteFromRootAndIntervalConfig,
  FindTheNoteFromRootAndIntervalQuestion,
  FindTheNoteFromRootAndIntervalTurnQuestion,
} from '../../../types';

export function computeQuestion(
  config: FindTheNoteFromRootAndIntervalConfig,
  previousQuestions: FindTheNoteFromRootAndIntervalTurnQuestion[],
): FindTheNoteFromRootAndIntervalQuestion {
  const lastQuestionTurn = previousQuestions.pop();

  const availableIntervalNames = config.intervalList
    .filter(({ selected }) => selected)
    .map(({ name }) => name);

  const lastGivenNote = lastQuestionTurn?.question.givenNote;
  const lastGivenInterval =
    availableIntervalNames.length > 1
      ? lastQuestionTurn?.question.givenInterval
      : undefined;

  const givenNote = getRandomNote(config.withAlteredNotes, lastGivenNote);
  const givenInterval = getRandomElement(
    availableIntervalNames,
    lastGivenInterval !== undefined ? [lastGivenInterval] : undefined,
  );

  return {
    givenInterval,
    givenNote,
  };
}
