type Action =
  | {
      type: 'NOTE_START';
      timestamp: number;
      noteChroma: number;
    }
  | {
      type: 'NOTE_END';
      timestamp: number;
      durationInMs: number;
    }
  | {
      type: 'RESET';
    };

type NoteToDisplay = {
  timestamp: number;
  noteChroma: number;
  durationInMs?: number;
  ended: boolean;
};

type State = NoteToDisplay[];

const MAX_HISTORY_ELEMENTS = 7;

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'NOTE_START': {
      return state.findIndex(
        (noteToDisplay) => noteToDisplay.timestamp === action.timestamp,
      ) === -1
        ? [getNoteStart(action.noteChroma, action.timestamp), ...state].slice(
            0,
            MAX_HISTORY_ELEMENTS,
          )
        : state;
    }
    case 'NOTE_END': {
      return state.map((noteToDisplay) =>
        noteToDisplay.timestamp === action.timestamp
          ? {
              ...noteToDisplay,
              ended: true,
              durationInMs: action.durationInMs,
            }
          : noteToDisplay,
      );
    }
    case 'RESET': {
      return [];
    }
    default: {
      return state;
    }
  }
}

function getNoteStart(noteChroma: number, timestamp: number): NoteToDisplay {
  return {
    noteChroma,
    timestamp,
    ended: false,
  };
}
