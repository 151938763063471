import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Title } from '../title';

type H1Props = DetailedHTMLProps<
  HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

export function H1({ children }: H1Props) {
  return (
    <Title tagName="h1" level={1} className="mb-4 mt-8">
      {children}
    </Title>
  );
}
