import { Thematic } from '../thematic';
import { ColorVariant } from './design.types';

export function getColorVariantByThematic(thematic: Thematic): ColorVariant {
  switch (thematic) {
    case 'notes':
      return 'indigo';
    case 'scales':
      return 'emerald';
    case 'intervals':
      return 'yellow';
  }
}
