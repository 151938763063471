import { Note } from 'tonal';
import { SELECTABLE_INTERVALS_LIST } from '@notacami/core/intervals';
import { getRandomElement } from '@notacami/core/utils';
import {
  FindTheIntervalOnTheNeckConfig,
  FindTheIntervalOnTheNeckQuestion,
  FindTheIntervalOnTheNeckTurnQuestion,
} from '../../../types';
import { getRandomPositionOnFretboard } from '../../../../../../services/fretboard';
import {
  getFretboardPositiveIntervalsByPosition,
  getChromaAndMidiPositionsByRootNoteNameAndIntervalPositions,
  getFretboardNearestAvailableIntervalsByPosition,
} from '../../../../../../services/fretboard-interval';

export function computeQuestion(
  config: FindTheIntervalOnTheNeckConfig,
  previousQuestions: FindTheIntervalOnTheNeckTurnQuestion[],
): FindTheIntervalOnTheNeckQuestion {
  const lastQuestionTurn = previousQuestions.pop();

  const availableIntervalNames = config.intervalList
    .filter(({ selected }) => selected)
    .map(({ name }) => name);

  const lastGivenRootPosition = lastQuestionTurn?.question.givenRootPosition;
  const lastGivenInterval =
    availableIntervalNames.length > 1
      ? lastQuestionTurn?.question.givenInterval
      : undefined;

  const numberOfStrings = config.fretboard.noteDetails.length;
  const numberOfFrets = config.fretboard.noteDetails[0].length;

  const selectableStringIndexes = config.selectableStrings
    .filter((selectableString) => selectableString.selected)
    .map((selectableString) => selectableString.index);

  const givenRootPosition = getRandomPositionOnFretboard(
    selectableStringIndexes,
    numberOfFrets,
    [
      [numberOfStrings - 1, numberOfFrets - 1], // exclude last note on fretboard
      ...(lastGivenRootPosition !== undefined ? [lastGivenRootPosition] : []),
    ],
  );

  const [rootStringIndex, rootFretIndex] = givenRootPosition;

  const givenRootNoteName =
    config.fretboard.noteDetails[rootStringIndex][rootFretIndex].name;

  const fretboardIntervalsFromRoot = getFretboardPositiveIntervalsByPosition(
    config.fretboard,
    givenRootPosition,
  );

  const nearestIntervalsPosition =
    getFretboardNearestAvailableIntervalsByPosition(
      config.fretboard,
      givenRootPosition,
      fretboardIntervalsFromRoot,
    ).filter(
      (nearestIntervalPosition) => nearestIntervalPosition.interval !== '1P',
    );

  const filteredNearestIntervalsPosition = nearestIntervalsPosition.filter(
    ({ interval }) =>
      availableIntervalNames.includes(interval) &&
      interval !== lastGivenInterval,
  );

  if (filteredNearestIntervalsPosition.length === 0) {
    return computeQuestion(config, previousQuestions);
  }

  const randomIntervalPosition = getRandomElement(
    filteredNearestIntervalsPosition,
  );

  const selectablePositionsWithInterval = nearestIntervalsPosition.filter(
    ({ interval }) => SELECTABLE_INTERVALS_LIST.includes(interval),
  );

  const { position: expectedIntervalPosition, interval: givenInterval } =
    randomIntervalPosition;

  const expectedNoteName = Note.transpose(givenRootNoteName, givenInterval);

  const expectedNoteChroma = Note.chroma(expectedNoteName);
  const noteChromaAndMidiPositions =
    getChromaAndMidiPositionsByRootNoteNameAndIntervalPositions(
      givenRootNoteName,
      [
        { position: givenRootPosition, interval: '1P' },
        ...nearestIntervalsPosition,
      ],
    );

  return {
    givenInterval,
    expectedNoteName,
    expectedIntervalPosition,
    expectedNoteChroma,
    givenRootNoteName,
    givenRootPosition,
    selectablePositionsWithInterval,
    noteChromaAndMidiPositions,
  };
}
