import { useStore } from 'zustand';
import { AnimatePresence } from 'motion/react';
import { ExerciseStoreApi } from '../../../../services/exercise/exercise.types';
import { MicTutorial } from '../../mic-tutorial';
import { MicTutorialStepper } from '../../mic-tutorial/mic-tutorial-stepper';
import { useTranslation } from '../../../../hooks/use-translation';
import { QuizLayout } from '../../quiz-layout';
import { LeftActionSection } from './playing/left-action-section';

type TutorialStateProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
> = {
  store: ExerciseStoreApi<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
  >;
};

export function TutorialState<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
>({
  store,
}: TutorialStateProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload
>) {
  const { t } = useTranslation();
  const mainStep = useStore(store, (state) => state.mainStep);
  const tutorialId = useStore(store, (state) => state.tutorialId);
  const thematic = useStore(store, (state) => state.thematic);
  const confirmLeaveQuiz = useStore(store, (state) => state.confirmLeaveQuiz);
  const leaveTutorial = useStore(store, (state) => state.leaveTutorial);

  const isInTutorialStep =
    (mainStep === 'tutorial' || mainStep === 'end-confirmation') &&
    tutorialId !== undefined;

  return (
    <AnimatePresence>
      {isInTutorialStep && tutorialId === 'mic-tutorial' ? (
        <QuizLayout
          resizeWhenDrawerOpened
          progress={<MicTutorialStepper thematic={thematic} />}
          answer={
            <MicTutorial
              shouldExplainForPermission
              thematic={thematic}
              onTutorialAbort={confirmLeaveQuiz}
              onTutorialComplete={leaveTutorial}
              getReadyStepDescription={t(
                'tutorial.mic-tutorial.get-ready-step.find-the-note-on-the-neck-playing-description',
              )}
              getReadyStepButtonLabel={t('button.lets-go')}
            />
          }
          leftAction={<LeftActionSection store={store} />}
        />
      ) : null}
    </AnimatePresence>
  );
}
