import { cn } from '@notacami/core/css';

type NeckStringHighlightProps = {
  className?: string;
};

export function NeckStringHighlight({ className }: NeckStringHighlightProps) {
  return (
    <div className={cn('relative h-4 w-4', className)}>
      <div className="absolute h-full w-full animate-ping rounded-full bg-neutral-800 dark:bg-neutral-100"></div>
      <div className="relative h-full w-full rounded-full bg-neutral-800 dark:bg-neutral-100"></div>
    </div>
  );
}
