import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { FindTheNoteOnTheNeckExerciseContext } from '../../store/context';
import { usePreferencesStore } from '../../../preferences/use-preferences-context';
import { QuestionSectionGeneric } from '../../../exercise/states/playing/question-section-generic';
import { useNoteNotation } from '../../../../../services/note-notation/use-note-notation';

export function QuestionSection() {
  const tuning = usePreferencesStore((state) => state.tuningInfo);
  const { n } = useNoteNotation();

  const numberOfStrings = tuning.notes.length;

  const store = useContext(FindTheNoteOnTheNeckExerciseContext);
  const question = useQuestion(store);

  if (!question) {
    throw new Error('question not found');
  }

  const givenNote = question.question.givenNote;
  const givenStringIndex = question.question.givenStringIndex;

  const key = givenNote + givenStringIndex;

  return (
    <QuestionSectionGeneric key={key} store={store}>
      <Trans
        i18nKey="pages./find-the-note-on-the-neck.question"
        values={{
          note: n(givenNote),
          stringIndex: numberOfStrings - givenStringIndex,
        }}
        components={[<strong key={0} />]}
      />
    </QuestionSectionGeneric>
  );
}
