import { Control, useWatch } from 'react-hook-form';
import { Thematic } from '@notacami/core/thematic';
import { SubmitButton } from '../../../submit-button';
import { useTranslation } from '../../../../../hooks/use-translation';
import { FindTheNoteFromRootAndIntervalConfig } from '../../types';

type SubmitButtonProps = {
  thematic: Thematic;
  control: Control<FindTheNoteFromRootAndIntervalConfig>;
};

export function ConfigurationModalSubmitButton({
  control,
  thematic,
}: SubmitButtonProps) {
  const { t } = useTranslation();

  const intervalList = useWatch({ control, name: 'intervalList' });

  const hasAtLeastOneIntervalSelected = intervalList.some(
    (string) => string.selected,
  );

  const startGameNotAllowed = !hasAtLeastOneIntervalSelected;

  return (
    <SubmitButton thematic={thematic} disabled={startGameNotAllowed}>
      {t('button.apply')}
    </SubmitButton>
  );
}
