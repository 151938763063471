import { forwardRef, useContext, useEffect } from 'react';
import { Thematic } from '@notacami/core/thematic';
import { getColorVariantByThematic } from '@notacami/core/design';
import { Button } from '@notacami/ui';
import { TutorialStepContent } from '../tutorial-step-content';
import { TuningStepContent } from '../tuning-step-content';
import { ConsumersIds } from '../../../services/consumer';
import { useTranslation } from '../../../hooks/use-translation';
import { ServicesContext } from '../../../services/services.context';
import { useMicTutorialStore } from './mic-tutorial.store';

type TuningStepProps = {
  thematic: Thematic;
};

export const TuningStep = forwardRef<HTMLDivElement, TuningStepProps>(
  ({ thematic }, ref) => {
    const { pitchDetectionConsumer } = useContext(ServicesContext);
    const { t } = useTranslation();
    const colorVariant = getColorVariantByThematic(thematic);
    const goToGetReady = useMicTutorialStore((state) => state.goToGetReady);

    useEffect(() => {
      pitchDetectionConsumer.addConsumer(ConsumersIds.MIC_TUTORIAL_TUNING_STEP);
      return () => {
        pitchDetectionConsumer.removeConsumer(
          ConsumersIds.MIC_TUTORIAL_TUNING_STEP,
        );
      };
    }, []);

    return (
      <TutorialStepContent
        ref={ref}
        content={<TuningStepContent />}
        action={
          <Button onClick={goToGetReady} colorVariant={colorVariant}>
            {t('button.done')}
          </Button>
        }
      />
    );
  },
);

TuningStep.displayName = 'TuningStep';
