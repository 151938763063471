import { isPositionEqual, Position } from '@notacami/core/fretboard';
import { Segment } from '../fretboard';

export function getSegmentEndedByOctavePosition(
  segments: Segment[],
  position: Position,
): Segment | null {
  const foundSegment = segments.find((positionWithIntervals) =>
    positionWithIntervals.some(
      (positionWithInterval) =>
        positionWithInterval.interval === '8P' &&
        isPositionEqual(positionWithInterval.position, position),
    ),
  );
  return foundSegment === undefined ? null : foundSegment;
}
