import { ArrowRight } from 'lucide-react';
import { motion } from 'motion/react';
import { useStore } from 'zustand';
import { getColorVariantByThematic } from '@notacami/core/design';
import { ButtonTimer } from '../../../../ui/button-timer';
import {
  DURATION_BEFORE_NEXT_TURN_CORRECT,
  DURATION_BEFORE_NEXT_TURN_NOT_CORRECT,
} from '../../../../../services/exercise/exercise.constants';
import { ExerciseStoreApi } from '../../../../../services/exercise/exercise.types';
import { useIsPaused } from '../../../../../services/exercise/hooks/use-is-paused';
import { useResult } from '../../../../../services/exercise/hooks/use-result';

type BottomRightActionSectionResultProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
> = {
  store: ExerciseStoreApi<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
  >;
};

export function BottomRightActionSectionResult<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
>({
  store,
}: BottomRightActionSectionResultProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload
>) {
  const thematic = useStore(store, (state) => state.thematic);
  const goToNextStep = useStore(store, (state) => state.goToNextStep);
  const result = useResult(store);

  const isPaused = useIsPaused(store);
  const colorVariant = getColorVariantByThematic(thematic);

  const handleNextRound = () => {
    goToNextStep();
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 5 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 5 }}
    >
      <ButtonTimer
        buttonProps={{
          onClick: handleNextRound,
          children: <ArrowRight />,
          colorVariant,
        }}
        forInMs={
          result?.isCorrect
            ? DURATION_BEFORE_NEXT_TURN_CORRECT
            : DURATION_BEFORE_NEXT_TURN_NOT_CORRECT
        }
        paused={isPaused}
      />
    </motion.div>
  );
}
