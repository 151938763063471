import { cn } from '@notacami/core/css';
import { Checkbox } from './checkbox';

export type MultiSelectOption<T> = {
  value: T & string;
  label: string;
  checked: boolean;
};

type MultiSelectProps<T> = {
  className?: string;
  options: MultiSelectOption<T>[];
  onChange: (options: MultiSelectOption<T>[]) => void;
};

function getOptionIdByValue<T>(value: T & string) {
  return `multi-select-option-${value}`;
}

export function MultiSelect<T>({
  className,
  options,
  onChange,
}: MultiSelectProps<T>) {
  const handleChange = (value: T & string) => (checked: boolean) => {
    const updatedOptions = options.map((option) =>
      option.value === value
        ? {
            ...option,
            checked,
          }
        : option,
    );

    onChange(updatedOptions);
  };

  return (
    <div className={cn('flex flex-wrap justify-center gap-2', className)}>
      {options.map((option) => {
        const id = getOptionIdByValue(option.value);
        return (
          <div
            key={id}
            className={cn(
              'flex items-center justify-center rounded-full px-3 py-1 text-sm',
              option.checked
                ? 'text-lighter bg-neutral-500'
                : 'bg-neutral-300 dark:bg-neutral-700',
            )}
          >
            <Checkbox
              id={id}
              name={id}
              checked={option.checked}
              onCheckedChange={handleChange(option.value)}
            />
            <label htmlFor={id} className="pl-2">
              {option.label}
            </label>
          </div>
        );
      })}
    </div>
  );
}
