import { Sizes } from '../guitar-neck';

export function getNeckCellBaseClassnames(
  isVertical: boolean,
  isOpenString: boolean,
) {
  return [
    'relative flex justify-center items-center',
    !isVertical && !isOpenString && Sizes.NECK_FRET_NOTE_BY_STRING.h.w,
    isVertical && !isOpenString && Sizes.NECK_FRET_NOTE_BY_STRING.v.h,
    !isVertical && isOpenString && Sizes.OPEN_STRING_NOTE_BY_STRING.h.w,
    isVertical && isOpenString && Sizes.OPEN_STRING_NOTE_BY_STRING.v.h,
    !isVertical && Sizes.NOTE_TRACK.h.h,
    isVertical && Sizes.NOTE_TRACK.v.w,
    !isOpenString && `rounded-full`,
    isOpenString && `rounded-md`,
    'text-md font-semibold',
  ];
}
