import { useContext } from 'react';
import { useStore } from 'zustand';
import { LessonContext } from '../../../../../../services/lesson';
import { DerivedTeacherSpeakingStep } from '../../../../../../services/lesson/lesson.types';
import { StepTeacherSpeaking } from './step-teacher-speaking';

type StepTeacherSpeakingWrapperProps = {
  step: DerivedTeacherSpeakingStep;
};

export function StepTeacherSpeakingWrapper({
  step,
}: StepTeacherSpeakingWrapperProps) {
  const store = useContext(LessonContext);

  const derivedLessonInfo = useStore(store, (state) => state.derivedLessonInfo);

  return derivedLessonInfo !== null ? (
    <div className="flex h-full flex-col justify-center p-4">
      <StepTeacherSpeaking text={step.text} key={step.id} />
    </div>
  ) : null;
}
