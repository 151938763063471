import { Part, getTransport } from 'tone';
import Emittery from 'emittery';
import { Instrument } from '../instrument/instrument';
import { AppAudioContext } from '../app-audio-context';
import {
  EventInPart,
  GroupNoteEndEvent,
  GroupNoteStartEvent,
  NoteEndEvent,
  NoteStartEvent,
} from '../composer';
import { IWindowFocusAndBlurService } from '../window-focus-and-blur';
import { MusicianEvents } from './musician.types';

export class Musician extends Emittery<MusicianEvents> {
  private currentPart: Part<EventInPart> | null = null;
  private currentPartId: string | null = null;
  private isPlaying = false;
  private intervalId: number;
  private onTransportStartHandler: () => void;
  private onTransportPauseHandler: () => void;
  private onTransportStopHandler: () => void;

  constructor(
    private readonly instrument: Instrument,
    private readonly appAudioContext: AppAudioContext,
    private readonly windowFocusAndBlur: IWindowFocusAndBlurService,
  ) {
    super();

    this.onTransportStartHandler = this.onTransportStart.bind(this);
    this.onTransportPauseHandler = this.onTransportPause.bind(this);
    this.onTransportStopHandler = this.onTransportStop.bind(this);

    const blurHandler = this.stop.bind(this);
    this.windowFocusAndBlur.on('blur', blurHandler);

    getTransport().on('start', this.onTransportStartHandler);
    getTransport().on('pause', this.onTransportPauseHandler);
    getTransport().on('stop', this.onTransportStopHandler);
  }

  public async playNotes(partId: string, events: EventInPart[], bpm: number) {
    this.stop();
    await this.appAudioContext.initialize();

    if (this.instrument.isReady) {
      getTransport().bpm.value = bpm;
      this.currentPart = this.createPart(events);
      this.currentPartId = partId;

      this.onPartStart();
      this.currentPart.start(0);
      getTransport().start();
    }
  }

  public stop() {
    if (this.currentPart !== null) {
      getTransport().stop();
      this.currentPart.stop();
      this.disposeSequence();
    }
  }

  private onNotePlay(noteStartEvent: NoteStartEvent) {
    if (this.currentPartId) {
      this.emit('note-start', {
        partId: this.currentPartId,
        noteStartEvent,
      });
    }
  }

  private onNoteEnd(noteEndEvent: NoteEndEvent) {
    if (this.currentPartId) {
      this.emit('note-end', {
        partId: this.currentPartId,
        noteEndEvent,
      });
    }
  }

  private onPartStart() {
    if (this.currentPartId) {
      this.emit('part-start', {
        partId: this.currentPartId,
      });
    }
  }

  private onPartEnd() {
    if (this.currentPartId) {
      this.emit('part-end', { partId: this.currentPartId });
      this.stop();
    }
  }

  private onGroupNoteStart(groupNoteStartEvent: GroupNoteStartEvent) {
    if (this.currentPartId) {
      this.emit('group-note-start', {
        partId: this.currentPartId,
        groupNoteStartEvent,
      });
    }
  }

  private onGroupNoteEnd(groupNoteEndEvent: GroupNoteEndEvent) {
    if (this.currentPartId) {
      this.emit('group-note-end', {
        partId: this.currentPartId,
        groupNoteEndEvent,
      });
    }
  }

  private disposeSequence() {
    if (this.currentPart) {
      this.currentPart.dispose();
      this.currentPart = null;
      this.currentPartId = null;
    }
  }

  private createPart(events: EventInPart[]) {
    const part = new Part((time, event) => {
      switch (event.type) {
        case 'note-start':
          this.instrument.triggerAttackRelease(
            event.noteNameToPlay,
            event.duration,
            time,
            0.1,
          );
          this.onNotePlay(event);
          break;

        case 'note-end':
          this.onNoteEnd(event);
          break;
        case 'part-end':
          this.onPartEnd();
          break;
        case 'group-note-start':
          this.onGroupNoteStart(event);
          break;
        case 'group-note-end':
          this.onGroupNoteEnd(event);
          break;
      }
    }, events);
    part.humanize = true;
    part.loop = false;

    return part;
  }

  private onTransportStart() {
    this.startEmitPlayheadUpdate();
  }

  private onTransportPause() {
    this.stopEmitPlayheadUpdate();
  }

  private onTransportStop() {
    this.stopEmitPlayheadUpdate();
  }

  private startEmitPlayheadUpdate() {
    if (!this.isPlaying) {
      this.isPlaying = true;

      this.emitPlayheadUpdate(0);

      this.intervalId = window.setInterval(() => {
        if (this.currentPartId !== null) {
          this.emitPlayheadUpdate(getTransport().seconds);
        }
      }, 10);
    }
  }

  private stopEmitPlayheadUpdate() {
    if (this.isPlaying) {
      this.isPlaying = false;
      window.clearInterval(this.intervalId);
    }
  }

  private emitPlayheadUpdate(seconds: number) {
    if (this.currentPartId !== null) {
      this.emit('playhead-update', {
        partId: this.currentPartId,
        seconds: seconds,
      });
    }
  }
}
