import { Note } from 'tonal';
import { SelectableStringInput } from '../../../string-selector';
import { SelectableString } from '../../../find-the-note-on-the-neck/types';

export function getSelectableStringsFromTuning(
  tuning: string[],
  originalSelectableStrings?: SelectableString[] | undefined,
  disabledStrings?: boolean[],
): SelectableStringInput[] {
  const numberOfStrings = tuning.length;
  const selectableStrings = tuning.map((note, index) => {
    const pitchClass = Note.pitchClass(note);
    const octave = Note.octave(note) || 0;
    return {
      id: `${index}-${pitchClass}`,
      stringNumber: numberOfStrings - index,
      index,
      label: pitchClass,
      octave,
      selected: originalSelectableStrings
        ? originalSelectableStrings[index].selected
        : true,
      disabled: disabledStrings ? disabledStrings[index] : false,
    };
  });

  return selectableStrings;
}
