import { Check } from 'lucide-react';
import { forwardRef } from 'react';
import { motion } from 'motion/react';
import { cn } from '@notacami/core/css';
import { Position } from '@notacami/core/fretboard';
import { getNeckCellBaseClassnames } from './get-neck-cell-base-classnames';

type NeckCellSelectProps = {
  isOpenString: boolean;
  isVertical: boolean;
  onSelect: (position: Position) => void;
  position: Position;
  value: boolean;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
};

export const NeckCellSelect = forwardRef<
  HTMLButtonElement,
  NeckCellSelectProps
>(
  (
    {
      isOpenString,
      isVertical,
      onSelect,
      position,
      value,
      className,
      disabled = false,
      dataTestId,
    },
    ref,
  ) => {
    const handleClick = () => {
      onSelect(position);
    };

    return (
      <motion.button
        data-testid={dataTestId}
        disabled={disabled}
        initial={{ opacity: 0 }}
        animate={{ opacity: disabled ? 0.5 : 1 }}
        exit={{ opacity: 0 }}
        ref={ref}
        onClick={handleClick}
        className={cn(
          getNeckCellBaseClassnames(isVertical, isOpenString),
          'cursor-pointer',
          'border-2',
          value
            ? 'border-indigo-500 bg-indigo-400 text-white dark:border-indigo-600 dark:bg-indigo-500 dark:text-white'
            : 'border-neutral-500/80 bg-neutral-300 text-neutral-500 dark:border-neutral-500 dark:bg-neutral-600 dark:text-neutral-300',
          className,
        )}
      >
        <Check className="h-6 w-6 drop-shadow" />
      </motion.button>
    );
  },
);
NeckCellSelect.displayName = 'NeckCellSelect';
