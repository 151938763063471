import { motion } from 'motion/react';
import { ForwardedRef, forwardRef } from 'react';
import { Position } from '@notacami/core/fretboard';
import { cn } from '@notacami/core/css';
import { ConditionalWrapper } from '@notacami/core/components';
import { getNeckCellBaseClassnames } from './get-neck-cell-base-classnames';
import { getNoteColorClassnamesByInterval } from './get-note-color-classnames-by-interval';

type NeckCellIntervalProps = {
  interval: string;
  isOpenString: boolean;
  isVertical: boolean;
  position: Position;
  notePitchClass?: string;
  onClick?: (note: Position) => void;
  isPlayed?: boolean;
  isHighlighted?: boolean;
};

export const NeckCellInterval = forwardRef<
  HTMLButtonElement | HTMLDivElement,
  NeckCellIntervalProps
>(
  (
    {
      interval,
      isOpenString,
      isVertical,
      position,
      onClick,
      notePitchClass,
      isPlayed = false,
      isHighlighted = false,
    },
    ref,
  ) => {
    const colors = getNoteColorClassnamesByInterval(interval);

    const handleClick = () => {
      onClick && onClick(position);
    };

    const baseClassNames = getNeckCellBaseClassnames(isVertical, isOpenString);

    const classNames = cn(
      ...baseClassNames,
      'shadow-md',
      colors.text,
      colors.border,
      'border-4',
      !isOpenString &&
        !isPlayed &&
        !isHighlighted &&
        colors.backgroundTransparent,
      (isOpenString || isPlayed || isHighlighted) && colors.background,
    );

    return (
      <ConditionalWrapper
        items={[
          {
            condition: onClick !== undefined,
            wrapper: (children, index) => (
              <motion.button
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                ref={ref as ForwardedRef<HTMLButtonElement>}
                className={classNames}
                onClick={handleClick}
              >
                {children}
              </motion.button>
            ),
          },
          {
            condition: onClick === undefined,
            wrapper: (children, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                ref={ref as ForwardedRef<HTMLDivElement>}
                className={classNames}
              >
                {children}
              </motion.div>
            ),
          },
        ]}
      >
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: isPlayed || isHighlighted ? 1 : 0 }}
            className={cn(baseClassNames, 'absolute')}
          >
            <div
              className={cn(
                'absolute h-full w-full rounded-full',
                isPlayed && 'scale-125',
                isHighlighted && 'animate-ping',
                'border-4 border-neutral-100 shadow-md dark:border-neutral-100',
              )}
            />
          </motion.div>
          <div
            className={cn('relative', isOpenString ? 'text-xs' : 'text-base')}
          >
            {notePitchClass ? notePitchClass : interval}
          </div>
        </>
      </ConditionalWrapper>
    );
  },
);
NeckCellInterval.displayName = 'NeckCellInterval';
