import { FretboardNoteDetails, Position } from '@notacami/core/fretboard';
import { getRangeNumber } from '@notacami/core/utils';
import { getCombinationFromTwoArray } from '../../utils/get-combination-from-two-arrays';

export function getFretboardReachablePositionsByRootPosition(
  fretboardNoteDetails: FretboardNoteDetails,
  rootPosition: Position,
  maxFretDiff: number,
): Position[] {
  const numberOfStrings = fretboardNoteDetails.length;
  const numberOfFrets = fretboardNoteDetails[0].length;

  const minFretIndex = Math.max(0, rootPosition[1] - (maxFretDiff - 1));
  const maxFretIndex = Math.min(
    numberOfFrets - 1,
    rootPosition[1] + (maxFretDiff - 1),
  );

  const stringIndexes = getRangeNumber(0, numberOfStrings - 1);
  const fretIndexes = getRangeNumber(minFretIndex, maxFretIndex);
  const positions = getCombinationFromTwoArray(stringIndexes, fretIndexes);
  return positions;
}
