import { isPositionEqual, Position } from '@notacami/core/fretboard';
import { NeckCellNote, NeckCellStatus } from '@notacami/ui-business/neck-cells';
import { BuildCellsComponentProps } from '@notacami/ui-business/guitar-neck';
import { Fretboard } from '../../../../../services/fretboard';
import { useNoteNotation } from '../../../../../services/note-notation/use-note-notation';

type AnswerSectionResultCellProps = BuildCellsComponentProps & {
  fretboard: Fretboard;
  correctAnswerPosition: Position;
};

export function AnswerSectionResultCell({
  isOpenString,
  getElementByPosition,
  isVertical,
  position,
  fretboard,
  correctAnswerPosition,
}: AnswerSectionResultCellProps) {
  const { n } = useNoteNotation();
  const fretboardNote = getElementByPosition(fretboard.noteDetails);

  const isCorrectPosition = isPositionEqual(position, correctAnswerPosition);

  return (
    <>
      {isOpenString ? (
        <NeckCellNote
          isOpenString={isOpenString}
          isVertical={isVertical}
          noteLabel={n(fretboardNote.pitchClass)}
          position={position}
        />
      ) : null}
      {isCorrectPosition && !isOpenString ? (
        <NeckCellStatus
          isOpenString={isOpenString}
          isVertical={isVertical}
          label={fretboardNote.pitchClass}
          status="success"
        />
      ) : null}
    </>
  );
}
