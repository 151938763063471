import * as Tone from 'tone';
import Emittery from 'emittery';
import { sleep } from '@notacami/core/testing';
import { IWindowFocusAndBlurService } from '@notacami/core/window-focus-and-blur';
import { IStore } from '../storage/storage.type';
import { autoCorrelate } from './auto-correlate';
import {
  IPitchDetectionService,
  MicError,
  PitchDetectionServiceEvents,
} from './pitch-detection.types';

const FTT_SIZE = 1024;

export class PitchDetectionService
  extends Emittery<PitchDetectionServiceEvents>
  implements IPitchDetectionService
{
  private analyser: AnalyserNode | undefined;
  private audioContext: AudioContext;
  private detectionInProgress = false;
  private frequencyDetected: number;
  private gainNode: Tone.Gain | undefined;
  private initiliazingDetection = false;
  private mediaStream: MediaStream;
  private mediaStreamAudioSourceNode: MediaStreamAudioSourceNode;
  private meter: Tone.Meter | undefined;
  private previousFrequencyDetected: number;
  private previouslyTooQuiet: boolean;
  private requestAnimationFrameId: number;
  private smoothingCount: number;
  private smoothingCountThreshold: number;
  private smoothingThreshold: number;
  private isRunningBeforeBlur: boolean;

  constructor(
    private readonly preferenceGainStore: IStore<number>,
    private readonly preferenceMicStore: IStore<PermissionState>,
    private readonly windowFocusAndBlur: IWindowFocusAndBlurService,
  ) {
    super();

    const focusHandler = this.onFocus.bind(this);
    const blurHandler = this.onBlur.bind(this);

    this.windowFocusAndBlur.on('focus', focusHandler);
    this.windowFocusAndBlur.on('blur', blurHandler);
  }

  private onFocus() {
    if (this.isRunningBeforeBlur) {
      this.resume();
    }
  }

  private onBlur() {
    this.isRunningBeforeBlur = this.isRunning;
    this.stop();
  }

  private isSupported(): boolean {
    return Tone.UserMedia.supported;
  }

  private async initialize() {
    await sleep(100);

    if (!this.isSupported()) {
      return this.abortInitialization(MicError.UNSUPPORTED);
    }

    this.audioContext = Tone.getContext().rawContext as AudioContext;

    try {
      const supportedConstraints =
        navigator.mediaDevices.getSupportedConstraints();

      const constraints = {
        audio: {
          ...(supportedConstraints.echoCancellation
            ? { echoCancellation: true }
            : {}),
          ...(supportedConstraints.autoGainControl
            ? { autoGainControl: false }
            : {}),
          ...(supportedConstraints.noiseSuppression
            ? { noiseSuppression: false }
            : {}),
        },
        video: false,
      };

      this.mediaStream = await navigator.mediaDevices.getUserMedia(constraints);

      this.mediaStreamAudioSourceNode =
        this.audioContext.createMediaStreamSource(this.mediaStream);

      this.preferenceMicStore.set('granted');
    } catch (err) {
      this.preferenceMicStore.set('denied');
      return this.abortInitialization(MicError.NOT_PERMITTED);
    }

    await Tone.start();

    this.analyser = this.audioContext.createAnalyser();
    this.analyser.minDecibels = -100;
    this.analyser.maxDecibels = -10;
    this.analyser.smoothingTimeConstant = 0.85;
    this.analyser.fftSize = FTT_SIZE;

    this.meter = new Tone.Meter(0);

    const persistedGain = await this.preferenceGainStore.get();
    this.gainNode = new Tone.Gain(persistedGain);

    Tone.connectSeries(
      this.mediaStreamAudioSourceNode,
      this.gainNode,
      this.analyser,
      this.meter,
    );

    this.detectionInProgress = true;
    this.initiliazingDetection = false;
    this.visualize();

    this.emit('mic-open');
    return { error: null };
  }

  private abortInitialization(error: MicError) {
    this.disposeNodes();
    this.initiliazingDetection = false;
    this.emit('mic-error', error);
    return { error: error };
  }

  private disposeNodes() {
    if (this.mediaStream && this.mediaStreamAudioSourceNode) {
      // code from ToneJs code base
      this.mediaStream.getAudioTracks().forEach((track) => {
        track.stop();
      });
      this.mediaStreamAudioSourceNode.disconnect();
    }
    if (this.analyser) {
      this.analyser.disconnect();
      this.analyser = undefined;
    }
    if (this.gainNode) {
      this.gainNode.dispose();
      this.gainNode = undefined;
    }
    if (this.meter) {
      this.meter.dispose();
      this.meter = undefined;
    }
  }

  public resume() {
    if (this.isRunning) {
      return Promise.resolve({ error: null });
    }
    this.initiliazingDetection = true;
    return this.initialize();
  }

  public applyGain(value: number) {
    if (!this.isRunning || this.gainNode === undefined) return;
    this.gainNode.gain.setValueAtTime(value, this.audioContext.currentTime);
  }

  public get isRunning() {
    return this.detectionInProgress || this.initiliazingDetection;
  }

  public stop() {
    if (!this.isRunning) return;
    window.cancelAnimationFrame(this.requestAnimationFrameId);
    this.disposeNodes();
    this.emit('mic-close');
    this.detectionInProgress = false;
  }

  private visualize() {
    if (this.meter === undefined || this.analyser === undefined) {
      return;
    }

    const now = new Date().getTime();

    const level = this.meter.getValue() as number;
    this.emit('meter-update', level);

    this.previousFrequencyDetected = 0;
    this.smoothingCount = 0;
    this.smoothingThreshold = 5;
    this.smoothingCountThreshold = 5;

    const bufferLengthAlt = this.analyser.fftSize;
    const bufferFrequencies = new Float32Array(bufferLengthAlt);
    this.analyser.getFloatFrequencyData(bufferFrequencies);

    const bufferLength = this.analyser.fftSize;
    const bufferSineWave = new Float32Array(bufferLength);
    this.analyser.getFloatTimeDomainData(bufferSineWave);

    this.emit('frequencies-update', {
      buffer: bufferFrequencies,
      bufferLength: bufferLengthAlt,
      sampleRate: this.audioContext.sampleRate,
    });

    this.emit('sinewave-update', {
      buffer: bufferSineWave,
      bufferLength: bufferLength,
      sampleRate: this.audioContext.sampleRate,
    });

    const autoCorrelateValue = autoCorrelate(
      bufferSineWave,
      this.audioContext.sampleRate,
    );

    this.frequencyDetected = Math.round(autoCorrelateValue);

    const smoothingValue = 'none';

    if (autoCorrelateValue === -1) {
      if (!this.previouslyTooQuiet) {
        this.previouslyTooQuiet = true;
        this.emit('silence-detect', { time: now });
      }
      this.requestAnimationFrameId = window.requestAnimationFrame(() => {
        this.visualize();
      });
      return;
    } else {
      if (this.previouslyTooQuiet) {
        this.emit('sound-detect');
        this.previouslyTooQuiet = false;
      }
    }

    if (smoothingValue === 'none') {
      this.smoothingThreshold = 99999;
      this.smoothingCountThreshold = 0;
    } else if (smoothingValue === 'basic') {
      this.smoothingThreshold = 10;
      this.smoothingCountThreshold = 5;
    } else if (smoothingValue === 'very') {
      this.smoothingThreshold = 5;
      this.smoothingCountThreshold = 10;
    }

    // Check if this value has been within the given range for n iterations
    if (this.frequencyIsSimilarEnough()) {
      if (this.smoothingCount < this.smoothingCountThreshold) {
        this.smoothingCount++;
        this.requestAnimationFrameId = window.requestAnimationFrame(() => {
          this.visualize();
        });
        return;
      } else {
        this.previousFrequencyDetected = this.frequencyDetected;
        this.smoothingCount = 0;
      }
    } else {
      this.previousFrequencyDetected = this.frequencyDetected;
      this.smoothingCount = 0;
      this.loop();
      return;
    }

    this.emit('frequency-detect', this.frequencyDetected);
    this.emit('frequency-and-level-detect', {
      frequency: this.frequencyDetected,
      level,
      time: now,
    });
    this.loop();
  }

  private loop() {
    this.requestAnimationFrameId = window.requestAnimationFrame(() => {
      this.visualize();
    });
  }

  private frequencyIsSimilarEnough() {
    return (
      Math.abs(this.frequencyDetected - this.previousFrequencyDetected) <
      this.smoothingThreshold
    );
  }
}
