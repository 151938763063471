import { useContext } from 'react';
import { ScaleTypeName } from '@notacami/core/scales';
import { useProgressData } from '../../../../../services/exercise/hooks/use-progress-data';
import { FindScalePositionProgress } from '../../../find-scale-position-progress';
import { FindScalePostionExerciseContext } from '../../store/context';

type ResultsProps = {
  scaleTypeName: ScaleTypeName | null;
};

export function Results({ scaleTypeName }: ResultsProps) {
  const store = useContext(FindScalePostionExerciseContext);
  const progressData = useProgressData(store);

  return progressData !== null ? (
    <FindScalePositionProgress
      previousProgressData={progressData.previousProgressData}
      currentProgressData={progressData.currentProgressData}
      scaleName={scaleTypeName}
    />
  ) : null;
}
