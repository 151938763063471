import { Sampler } from 'tone';
import { Frequency, Time } from 'tone/build/esm/core/type/Units';
import { AppAudioContext } from '../app-audio-context';
import GUITAR_ACOUSTIC_CONFIG from './instrument-guitar-acoustic.constant';

export class Instrument {
  private sampler: Sampler;
  private onInitializeHandler: () => void;

  constructor(private readonly appAudioContext: AppAudioContext) {
    this.onInitializeHandler = this.initialize.bind(this);

    this.appAudioContext.on('initialize', this.onInitializeHandler);
  }

  public get isReady() {
    return this.sampler !== undefined;
  }

  public initialize() {
    this.appAudioContext.off('initialize', this.onInitializeHandler);
    this.initializeSampler();
  }

  private async initializeSampler() {
    const samples = await this.loadSamples();
    this.sampler = new Sampler(samples).toDestination();
  }

  public triggerAttackRelease(
    notes: Frequency | Frequency[],
    duration: Time | Time[],
    time?: Time | undefined,
    velocity?: number | undefined,
  ) {
    if (this.sampler) {
      this.sampler.triggerAttackRelease(notes, duration, time, velocity);
    }
  }

  private async loadSamples() {
    const baseUrl = GUITAR_ACOUSTIC_CONFIG.baseUrl;
    const promises = Object.entries(GUITAR_ACOUSTIC_CONFIG.urls).map(
      ([key, url]) => {
        return this.getAudioBufferFromUrl(key, baseUrl + url);
      },
    );
    const results = await Promise.all(promises);
    const samples = Object.fromEntries(results);
    return samples;
  }

  private async getAudioBufferFromUrl(
    key: string,
    url: string,
  ): Promise<[string, AudioBuffer]> {
    return new Promise((resolve) => {
      const request = new XMLHttpRequest();
      request.onload = async (_e) => {
        const arrayBuffer = request.response;

        const audioBuffer =
          await this.appAudioContext.audioContext.decodeAudioData(arrayBuffer);
        resolve([key, audioBuffer]);
      };
      request.open('GET', url, true);
      request.responseType = 'arraybuffer';
      request.send();
    });
  }
}
