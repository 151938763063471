import { Controller, useForm } from 'react-hook-form';
import {
  FindTheIntervalOnTheNeckConfig,
  SelectableInterval,
} from '../../types';
import { useFindTheIntervalOnTheNeckStore } from '../../store/hook';
import { MultipleStringSelectorField } from '../../../../ui/form/multiple-string-selector-field';
import { useLeftHanded } from '../../../../../services/fretboard';
import { useTranslation } from '../../../../../hooks/use-translation';
import { useSafeAreaInsets } from '../../../../../hooks/use-safe-area-insets';
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from '../../../../ui/drawer';
import { RestoreDefaultConfigButton } from '../../../exercise/states/playing/restore-default-config-button';
import { MultiSelectOption } from '../../../../ui/multi-select';
import { MultiSelectField } from '../../../../ui/form/multi-select-field';
import { ConfigurationModalSubmitButton } from './configuration-modal-submit-button';

type ConfigurationModalProps = {
  availableIntervals: string[] | null;
  defaultConfig: FindTheIntervalOnTheNeckConfig;
};

export function ConfigurationModal({
  availableIntervals,
  defaultConfig,
}: ConfigurationModalProps) {
  const { t } = useTranslation();
  const leftHanded = useLeftHanded();
  const insets = useSafeAreaInsets();

  const config = useFindTheIntervalOnTheNeckStore((state) => state.config);
  const mainStep = useFindTheIntervalOnTheNeckStore((state) => state.mainStep);
  const thematic = useFindTheIntervalOnTheNeckStore((state) => state.thematic);
  const updateConfig = useFindTheIntervalOnTheNeckStore(
    (state) => state.updateConfig,
  );
  const closeConfig = useFindTheIntervalOnTheNeckStore(
    (state) => state.closeConfig,
  );
  const skipQuestion = useFindTheIntervalOnTheNeckStore(
    (state) => state.skipQuestion,
  );
  const resumeQuiz = useFindTheIntervalOnTheNeckStore(
    (state) => state.resumeQuiz,
  );

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<FindTheIntervalOnTheNeckConfig>({
    defaultValues: config,
  });

  const onSubmit = (data: FindTheIntervalOnTheNeckConfig) => {
    updateConfig(data);

    if (isDirty) {
      skipQuestion();
    }
    closeConfig();

    reset(data);
  };

  const handleOpenChange = (value: boolean) => {
    if (!value) {
      reset();
      resumeQuiz();
      closeConfig();
    }
  };

  const handleRestoreDefaultConfigClick = () => {
    reset(defaultConfig, { keepDefaultValues: true });
  };

  return (
    <Drawer open={mainStep === 'config'} onOpenChange={handleOpenChange}>
      <DrawerContent insets={insets}>
        <form onSubmit={handleSubmit(onSubmit)} className="relative z-20">
          <DrawerHeader>
            <div className="vertical-content-distribution-base">
              <Controller
                name="selectableStrings"
                control={control}
                render={({ field }) => (
                  <MultipleStringSelectorField
                    leftHanded={leftHanded}
                    onChange={field.onChange}
                    strings={field.value}
                  />
                )}
              />
              {availableIntervals === null ? (
                <Controller
                  name="intervalList"
                  control={control}
                  render={({ field }) => {
                    const handleChange = (
                      value: MultiSelectOption<string>[],
                    ) => {
                      const transformedValue: SelectableInterval[] = value.map(
                        ({ checked, value }) => ({
                          selected: checked,
                          name: value,
                        }),
                      );
                      field.onChange(transformedValue);
                    };
                    return (
                      <MultiSelectField
                        label={t('fields.intervals.label')}
                        onChange={handleChange}
                        value={field.value.map(({ name, selected }) => ({
                          checked: selected,
                          value: name,
                          label: name,
                        }))}
                      />
                    );
                  }}
                />
              ) : null}
            </div>
          </DrawerHeader>
          <DrawerFooter>
            <RestoreDefaultConfigButton
              control={control}
              defaultConfig={defaultConfig}
              onClick={handleRestoreDefaultConfigClick}
            />
            <ConfigurationModalSubmitButton
              control={control}
              thematic={thematic}
            />
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
}
