import { Interval, Note, Range } from 'tonal';
import { FretboardNoteDetails } from '@notacami/core/fretboard';
import { getNoteDetails } from '@notacami/core/notes';

export function getFretboardNoteDetailsByTuningAndFretLength(
  tuningNotes: string[],
  fretLength: number,
): FretboardNoteDetails {
  return tuningNotes.map((note) => {
    const endNote = Note.transpose(note, Interval.fromSemitones(fretLength));
    const notes = Range.chromatic([note, endNote], { sharps: true });
    return notes.map(getNoteDetails);
  });
}
