import { AnimatePresence } from 'motion/react';
import { NoteName } from '@notacami/core/notes';
import { useNoteNotation } from '../../../../../services/note-notation/use-note-notation';
import { QuizButton } from '../../../../ui/quiz-button';

type AnswerProps = {
  correctAnswer?: NoteName;
  listNotes: NoteName[];
  onNoteSelect?: (note: NoteName) => void;
  selectedAnswer?: NoteName;
};

function getQuizButtonType(
  noteName: string,
  selectedAnswer?: NoteName,
  correctAnswer?: NoteName,
) {
  if (selectedAnswer === undefined) {
    return 'button';
  }
  if (selectedAnswer !== correctAnswer && noteName === selectedAnswer) {
    return 'error';
  }
  if (noteName === correctAnswer) {
    return 'success';
  }
  return 'idle';
}

export function Answer({
  correctAnswer,
  listNotes,
  onNoteSelect,
  selectedAnswer,
}: AnswerProps) {
  const { n } = useNoteNotation();

  return (
    <div className="flex max-w-[640px] flex-wrap justify-center gap-4 px-4">
      <AnimatePresence mode="popLayout">
        {listNotes.map((noteName, index) => (
          <QuizButton
            key={`${noteName}-${index}`}
            type={getQuizButtonType(noteName, selectedAnswer, correctAnswer)}
            className="w-14"
            onClick={
              onNoteSelect
                ? () => {
                    onNoteSelect(noteName);
                  }
                : undefined
            }
          >
            {n(noteName)}
          </QuizButton>
        ))}
      </AnimatePresence>
    </div>
  );
}
