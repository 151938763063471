import { TunerCentsOffBgGradients } from './tuner-cents-off-background';
import { TunerCentsOffMarkers } from './tuner-cents-off-markers';
import { TunerCentsOffThumb } from './tuner-cents-off-thumb';

export function TunerCentsOff() {
  return (
    <div className="relative z-0 -mt-2 rounded-xl border-2 border-neutral-300 bg-neutral-900 px-4 py-2">
      <TunerCentsOffBgGradients />
      <TunerCentsOffThumb />
      <TunerCentsOffMarkers />
    </div>
  );
}
