import { ReactNode } from 'react';
import { Title } from '@notacami/ui';

type ThematicPageContentTitleProps = {
  children: ReactNode;
};

export function ThematicPageContentTitle({
  children,
}: ThematicPageContentTitleProps) {
  return (
    <div className="flex justify-between gap-4 px-4 capitalize">
      <Title tagName="h2" level={3}>
        {children}
      </Title>
      <div className="regular-border-color h-auto border-r-2" />
    </div>
  );
}
