import { cn } from '@notacami/core/css';

type StringNumberProps = {
  className?: string;
  selected: boolean;
  stringNumber: number;
};

export function StringNumber({
  selected,
  stringNumber,
  className,
}: StringNumberProps) {
  return (
    <div
      className={cn(
        className,
        'flex h-4 w-4 items-start justify-center rounded-full text-xs font-semibold transition-all',
        selected &&
          'bg-stone-500 text-neutral-100 dark:bg-stone-700 dark:text-neutral-300',
        !selected &&
          'bg-stone-300 text-neutral-100 dark:bg-stone-800 dark:text-neutral-500',
      )}
    >
      {stringNumber}
    </div>
  );
}
