import { FC, ReactNode } from 'react';
import { cn } from '@notacami/core/css';
import { Title } from '@notacami/ui';
import { ExpandableCardTitleProps } from './expandable-card';

export function createOnPictureExpandableCardTitle(
  icon: ReactNode,
  text: string,
): FC<ExpandableCardTitleProps> {
  return function OnPictureExpandableCardTitle({
    isDarkPicture,
    isOnPicture,
  }: ExpandableCardTitleProps) {
    return (
      <div className="flex items-center gap-2">
        {icon}
        <Title
          tagName="h2"
          className={cn(
            isDarkPicture && isOnPicture && 'text-lighter',
            !isDarkPicture && isOnPicture && 'text-darker',
          )}
          level={isOnPicture ? 1 : 2}
        >
          {text}
        </Title>
      </div>
    );
  };
}
