import { Animation, StatusBar, Style } from '@capacitor/status-bar';
import { Device } from '@capacitor/device';
import { Theme } from '@notacami/ui';
import { ThemePreference } from './theme-preference.types';

export const THEME_MEDIA_QUERY = '(prefers-color-scheme: dark)';

export function getSystemTheme(): Theme {
  const mediaQueryList = window.matchMedia(THEME_MEDIA_QUERY);
  const isDark = mediaQueryList.matches;
  const systemTheme = isDark ? 'dark' : 'light';
  return systemTheme;
}

export async function applyTheme(theme: Theme) {
  mutateDocumentElementClassname(theme);
  changeStatusBarThemeExceptForWeb(theme);
}

function mutateDocumentElementClassname(theme: Theme) {
  const element = document.documentElement;
  element.classList.add(theme);
  element.classList.remove(theme === 'dark' ? 'light' : 'dark');
  element.style.colorScheme = theme;
}

async function changeStatusBarThemeExceptForWeb(theme: Theme) {
  const { platform } = await Device.getInfo();

  if (platform !== 'web') {
    StatusBar.show({ animation: Animation.None });
    StatusBar.setStyle({
      style: theme === 'dark' ? Style.Dark : Style.Light,
    });
  }
}

export function getThemeToApplyByThemePreference(
  themePreference: ThemePreference,
): Theme {
  const themeToApply =
    themePreference === 'system' ? getSystemTheme() : themePreference;
  return themeToApply;
}
