import { useStore } from 'zustand';
import { usePlayingState } from '../../../../../services/exercise/hooks/use-playing-state';
import { ExerciseStoreApi } from '../../../../../services/exercise/exercise.types';
import { RightActionSectionPlay } from './right-action-section-play';
import { RightActionSectionResult } from './right-action-section-result';

type RightActionSectionProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
> = {
  isConfigSectionExist: boolean;
  onRecordingButtonClick?: () => void;
  store: ExerciseStoreApi<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
  >;
};

export function RightActionSection<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
>({
  isConfigSectionExist,
  onRecordingButtonClick,
  store,
}: RightActionSectionProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload
>) {
  const openConfig = useStore(store, (state) => state.openConfig);
  const thematic = useStore(store, (state) => state.thematic);

  const { isInPlayingPlayState, isInPlayingResultState } =
    usePlayingState(store);

  return (
    <>
      {isInPlayingPlayState ? (
        <RightActionSectionPlay
          isConfigSectionExist={isConfigSectionExist}
          onRecordingButtonClick={onRecordingButtonClick}
          onConfigButtonClick={openConfig}
          thematic={thematic}
        />
      ) : null}
      {isInPlayingResultState ? (
        <RightActionSectionResult
          isConfigSectionExist={isConfigSectionExist}
          onConfigButtonClick={openConfig}
        />
      ) : null}
    </>
  );
}
