import { Slot } from '@radix-ui/react-slot';
import { VariantProps } from 'class-variance-authority';
import {
  getClassnamesByColorVariant,
  ColorVariant,
  getClassnamesBySurfaceId,
  SurfaceId,
} from '@notacami/core/design';
import {
  ButtonHTMLAttributes,
  forwardRef,
  MouseEventHandler,
  useContext,
} from 'react';
import { cn } from '@notacami/core/css';
import { buttonVariants } from './button.variants';
import { SpinLoader } from './spin-loader';
import { getIconSizeByButtonSize } from './button.utils';
import { HapticFeedbackContext } from './haptic-feedback.context';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    colorVariant?: ColorVariant;
    pending?: boolean;
    onSurface?: SurfaceId;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild = false,
      children,
      className,
      colorVariant,
      onClick,
      pending = false,
      size,
      onSurface,
      type = 'button',
      variant = 'default',
      ...props
    },
    ref,
  ) => {
    const { impact } = useContext(HapticFeedbackContext);
    const Comp = asChild ? Slot : 'button';
    const surfaceClassNames =
      onSurface !== undefined ? getClassnamesBySurfaceId(onSurface) : undefined;
    const colorVariantClassNames =
      colorVariant !== undefined
        ? getClassnamesByColorVariant(colorVariant)
        : undefined;
    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      if (onClick) {
        onClick(event);
      }
      impact();
    };

    return (
      <Comp
        className={cn(
          'relative',
          buttonVariants({ variant, size, className }),
          variant === 'outline' &&
            colorVariantClassNames !== undefined && [
              colorVariantClassNames.ring,
              colorVariantClassNames.ringHover,
            ],
          variant === 'outline' &&
            surfaceClassNames !== undefined && [surfaceClassNames.ring],
          (variant === 'default' || variant === 'rounded') &&
            colorVariantClassNames !== undefined && [
              colorVariantClassNames.text,
              colorVariantClassNames.background,
              colorVariantClassNames.backgroundHover,
            ],
          (variant === 'default' || variant === 'rounded') &&
            surfaceClassNames !== undefined && [
              surfaceClassNames.text,
              surfaceClassNames.background,
            ],
          (variant === 'ghost' || variant === 'link') &&
            colorVariantClassNames !== undefined && [
              colorVariantClassNames.textColored,
              colorVariantClassNames.textColoredHover,
            ],
          (variant === 'ghost' || variant === 'link') &&
            surfaceClassNames !== undefined && [surfaceClassNames.text],
        )}
        onClick={handleClick}
        ref={ref}
        type={type}
        {...props}
      >
        {pending ? (
          <SpinLoader size={getIconSizeByButtonSize(size)} />
        ) : (
          children
        )}
      </Comp>
    );
  },
);
Button.displayName = 'Button';
