import { AnimatePresence, motion } from 'motion/react';
import { ReactNode } from 'react';
import { useStore } from 'zustand';
import { Button, Title } from '@notacami/ui';
import { getColorVariantByThematic } from '@notacami/core/design';
import { PageLayout } from '../../page-layout';
import { ExerciseStoreApi } from '../../../../services/exercise/exercise.types';
import { useTranslation } from '../../../../hooks/use-translation';

type EndStateProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
> = {
  children: ReactNode;
  store: ExerciseStoreApi<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
  >;
};

export const EndState = <
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
>({
  store,
  children,
}: EndStateProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload
>) => {
  const { t } = useTranslation();

  const isInEndState = useStore(store, (state) => state.mainStep === 'end');
  const thematic = useStore(store, (state) => state.thematic);
  const returnToConfigurationStepFromEndStep = useStore(
    store,
    (state) => state.returnToConfigurationStepFromEndStep,
  );

  const handleResetGame = () => {
    returnToConfigurationStepFromEndStep();
  };

  return (
    <AnimatePresence>
      {isInEndState ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          data-testid="end-section"
        >
          <PageLayout
            title={
              <Title level={2} tagName="h1">
                {t('title.progress')}
              </Title>
            }
            content={children}
            bottomAction={
              <Button
                className="w-full"
                onClick={handleResetGame}
                colorVariant={getColorVariantByThematic(thematic)}
                size="lg"
              >
                {t('button.back')}
              </Button>
            }
          />
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
