import { MicError } from '../../services/pitch-detection';
import { MicUnsupportedDialog } from './mic-unsupported-dialog';
import { MicDeniedPermissionDialog } from './mic-denied-permission-dialog';

type MicErrorDialogProps = {
  micError: MicError | null;
  onClose?: () => void;
};

export function MicErrorDialog({ micError, onClose }: MicErrorDialogProps) {
  return (
    <>
      <MicDeniedPermissionDialog
        open={micError === MicError.NOT_PERMITTED}
        onOpenChange={onClose}
      />
      <MicUnsupportedDialog
        open={micError === MicError.UNSUPPORTED}
        onOpenChange={onClose}
      />
    </>
  );
}
