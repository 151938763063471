import { useStore } from 'zustand';
import { useState } from 'react';
import { ExerciseStoreApi } from '../exercise.types';
import { useWindowFocusAndBlur } from '../../../hooks/use-window-focus-and-blur';

export function useIsPaused<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
>(
  store: ExerciseStoreApi<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
  >,
) {
  const mainStep = useStore(store, (state) => state.mainStep);
  const [windowIsBlur, setWindowIsBlur] = useState(false);

  const handleFocus = () => {
    setWindowIsBlur(false);
  };
  const handleBlur = () => {
    setWindowIsBlur(true);
  };

  const isPaused =
    mainStep === 'end-confirmation' ||
    mainStep === 'pause' ||
    mainStep === 'config' ||
    windowIsBlur;

  useWindowFocusAndBlur(handleFocus, handleBlur);

  return isPaused;
}
