import { Position } from '@notacami/core/fretboard';
import { Boundaries } from './fretboard.types';

function reduceFretBoundariesByPositions(
  boundariesAccumulator: Boundaries,
  currentPosition: Position,
): Boundaries {
  const currentFretIndex = currentPosition[1];

  return {
    min: Math.min(currentFretIndex, boundariesAccumulator.min),
    max: Math.max(currentFretIndex, boundariesAccumulator.max),
  };
}

export function getFretBoundariesByPositions(
  positions: Position[],
): Boundaries {
  const fretBoundaries = positions.reduce(reduceFretBoundariesByPositions, {
    min: +Infinity,
    max: -Infinity,
  });

  return fretBoundaries;
}
