export function getPercentageBetweenBoundaries(
  min: number,
  max: number,
  value: number,
) {
  const range = max - min;
  const correctedStartValue = value - min;
  const percentage = (correctedStartValue * 100) / range;
  return Math.round(percentage);
}
