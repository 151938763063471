import { createRoot } from 'react-dom/client';
import { SentryApp } from './boot/app.tsx';

import './style.css';

export async function boot() {
  const container = document.getElementById('root');

  if (container !== null) {
    const root = createRoot(container);
    root.render(<SentryApp />);
  }
}
