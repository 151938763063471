import { useStore } from 'zustand';
import { AnimatePresence } from 'motion/react';
import { ExerciseStoreApi } from '../../../../../services/exercise/exercise.types';
import { useResult } from '../../../../../services/exercise/hooks/use-result';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { usePlayingState } from '../../../../../services/exercise/hooks/use-playing-state';
import { TurnErrors } from './turn-errors';

type QuestionSectionGenericErrorsProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
> = {
  store: ExerciseStoreApi<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
  >;
};

export function QuestionSectionGenericErrors<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
>({
  store,
}: QuestionSectionGenericErrorsProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload
>) {
  const result = useResult(store);
  const { numberOfErrorsAllowed } = useQuestion(store);
  const numberOfTurnErrors = useStore(
    store,
    (state) => state.numberOfTurnErrors,
  );
  const { isInPlayingResultState, isInPlayingPlayState } =
    usePlayingState(store);

  return (
    <div className="absolute bottom-0 right-0">
      <AnimatePresence>
        {isInPlayingPlayState ||
        (isInPlayingResultState && !result?.isCorrect) ? (
          <TurnErrors
            numberOfErrorsAllowed={numberOfErrorsAllowed}
            numberOfErrorsOnCurrentTurn={numberOfTurnErrors}
          />
        ) : null}
      </AnimatePresence>
    </div>
  );
}
