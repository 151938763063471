import { useContext, useEffect, useState } from 'react';
import {
  Microphone,
  MicrophonePermissionState,
} from '@mozartec/capacitor-microphone';
import { ServicesContext } from '../../services/services.context';

export function MicInfoContent() {
  const { preferenceMicStore } = useContext(ServicesContext);
  const [state, setState] = useState<{
    nativePermissionStatusState: MicrophonePermissionState | null | 'error';
    webPermissionStatusState: PermissionState | null;
    webPermissionStatusStatePersisted: PermissionState | null;
    devices: MediaDeviceInfo[] | null;
    supportedConstraints: MediaTrackSupportedConstraints | null;
  }>({
    nativePermissionStatusState: null,
    webPermissionStatusState: null,
    webPermissionStatusStatePersisted: null,
    devices: null,
    supportedConstraints: null,
  });

  useEffect(() => {
    async function launch() {
      const webPermissionStatus = await navigator.permissions.query({
        name: 'microphone' as PermissionName,
      });
      const webPermissionStatusStatePersisted = await preferenceMicStore.get();
      const devices = await navigator.mediaDevices.enumerateDevices();
      const supportedConstraints =
        navigator.mediaDevices.getSupportedConstraints();

      try {
        const nativePermissionStatus = await Microphone.checkPermissions();

        setState({
          webPermissionStatusState: webPermissionStatus.state,
          webPermissionStatusStatePersisted,
          nativePermissionStatusState: nativePermissionStatus.microphone,
          devices,
          supportedConstraints,
        });
      } catch (e) {
        setState({
          webPermissionStatusState: webPermissionStatus.state,
          webPermissionStatusStatePersisted,
          nativePermissionStatusState: 'error',
          devices,
          supportedConstraints,
        });
      }
    }
    launch();
  }, []);

  return (
    <div className="vertical-content-distribution-base">
      <div>
        <strong>MicPermission (native):</strong>
        <br />
        {state.nativePermissionStatusState}
      </div>
      <div>
        <strong>MicPermission (web):</strong>
        <br />
        {state.webPermissionStatusState}
      </div>
      <div>
        <strong>MicPermission (web) persisted:</strong>
        <br />
        {state.webPermissionStatusStatePersisted}
      </div>
      <div>
        <strong>Devices:</strong>
        <br />
        <ul className="list-inside list-disc">
          {state.devices !== null
            ? state.devices
                .filter((device) => device.kind === 'audioinput')
                .map((device, index) => (
                  <li key={index}>{JSON.stringify(device, null, 2)}</li>
                ))
            : null}
        </ul>
      </div>
      <div>
        <strong>Supported constraints:</strong>
        <br />{' '}
        <ul className="list-inside list-disc">
          {state.supportedConstraints !== null
            ? Object.entries(state.supportedConstraints).map(
                ([constraint, value]) => (
                  <li key={constraint}>
                    {constraint}: {JSON.stringify(value, null, 2)}
                  </li>
                ),
              )
            : null}
        </ul>
      </div>
    </div>
  );
}
