import { DebouncedCapacitorStore } from '../storage-debounced.capacitor';

export function getPreferenceGainCapacitorStore() {
  return new DebouncedCapacitorStore(
    'settings.gain',
    1,
    (value) => value.toString(),
    (value) => parseInt(value),
    1000,
  );
}
