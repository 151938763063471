import { Position } from '@notacami/core/fretboard';
import { Fretboard, isValidFretDiff, PositionWithInterval } from '../fretboard';
import { getFretboardPositiveIntervalsByPosition } from './get-fretboard-positive-intervals-by-position';

export function getFretboardPositiveReachableIntervalsByPosition(
  fretboard: Fretboard,
  position: Position,
  maxFretDiffBySegment: number,
  forceIntervals?: string[],
): PositionWithInterval[] {
  const intervals = getFretboardPositiveIntervalsByPosition(
    fretboard,
    position,
    forceIntervals,
  ).filter(({ position: [_stringIndex, fretIndex] }) =>
    isValidFretDiff(fretIndex - position[1], maxFretDiffBySegment),
  );

  return intervals;
}
