import { isPositionEqual, Position } from '@notacami/core/fretboard';
import {
  NeckCellInterval,
  NeckCellStatus,
} from '@notacami/ui-business/neck-cells';
import { BuildCellsComponentProps } from '@notacami/ui-business/guitar-neck';
import { Fretboard } from '../../../../../services/fretboard';

type AnswerSectionResultCellProps = BuildCellsComponentProps & {
  fretboard: Fretboard;
  correctAnswer: Position;
  givenRootPosition: Position;
};

export function AnswerSectionResultCell({
  position,
  isOpenString,
  getElementByPosition,
  isVertical,
  fretboard,
  correctAnswer,
  givenRootPosition,
}: AnswerSectionResultCellProps) {
  const fretboardNote = getElementByPosition(fretboard.noteDetails);

  if (isPositionEqual(correctAnswer, position)) {
    return (
      <NeckCellStatus
        isOpenString={isOpenString}
        isVertical={isVertical}
        label={fretboardNote.pitchClass}
        status="success"
      />
    );
  }

  if (isPositionEqual(givenRootPosition, position)) {
    return (
      <NeckCellInterval
        isOpenString={isOpenString}
        isVertical={isVertical}
        notePitchClass={fretboardNote.pitchClass}
        interval="1P"
        position={position}
      />
    );
  }

  return null;
}
