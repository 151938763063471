import { LessonId, LessonInfo } from './lesson.types';
import { DEMO_LESSON_EN_FR } from './lessons/demo-lesson-en-fr';
import { DEMO_LESSON_EN_FR_TEACHER_LISTENING } from './lessons/demo-lesson-en-fr-teacher-listening';
import { DEMO_LESSON_EN_FR_TEACHER_PLAYING } from './lessons/demo-lesson-en-fr-teacher-playing';
import { DEMO_LESSON_EN_FR_TEACHER_SPEAKING_MDX } from './lessons/demo-lesson-en-fr-teacher-speaking-mdx';
import { DEMO_LESSON_EN_FR_TEACHER_SPEAKING_TEXT } from './lessons/demo-lesson-en-fr-teacher-speaking-text';

export const LESSONS: Record<LessonId, LessonInfo> = {
  'demo-lesson-en-fr-teacher-listening': DEMO_LESSON_EN_FR_TEACHER_LISTENING,
  'demo-lesson-en-fr-teacher-playing': DEMO_LESSON_EN_FR_TEACHER_PLAYING,
  'demo-lesson-en-fr-teacher-speaking-mdx':
    DEMO_LESSON_EN_FR_TEACHER_SPEAKING_MDX,
  'demo-lesson-en-fr-teacher-speaking-text':
    DEMO_LESSON_EN_FR_TEACHER_SPEAKING_TEXT,
  'demo-lesson-en-fr': DEMO_LESSON_EN_FR,
};

export const LESSON_IDS: LessonId[] = [
  'demo-lesson-en-fr-teacher-listening',
  'demo-lesson-en-fr-teacher-playing',
  'demo-lesson-en-fr-teacher-speaking-mdx',
  'demo-lesson-en-fr-teacher-speaking-text',
  'demo-lesson-en-fr',
];
