import {
  FretboardNoteDetails,
  getArrowPositionTowardsMiddleOfPositionsByPosition,
} from '@notacami/core/fretboard';
import {
  getChromaAndMidiByPositions,
  getFretBoundariesOnStringsByPositions,
  getPositionsByFretBoundariesOnStrings,
  ScalePosition,
} from '../../../../../../services/fretboard';

export function getQuestionDerivatedData(
  fretboard: FretboardNoteDetails,
  scalePosition: ScalePosition,
) {
  const onlyPositionsLength = scalePosition.positions.map(
    ({ position }) => position,
  );
  const positionsLength = onlyPositionsLength.length;
  const tonicPosition = onlyPositionsLength[0];
  const endPosition = onlyPositionsLength[positionsLength - 1];

  const fretBoundariesOnStrings = getFretBoundariesOnStringsByPositions(
    scalePosition.positions.map(({ position }) => position),
  );
  const playingAreaPositions = getPositionsByFretBoundariesOnStrings(
    fretBoundariesOnStrings,
  );

  return {
    tonicPitchClass: fretboard[tonicPosition[0]][tonicPosition[1]].pitchClass,
    playingAreaPositions,
    positionsWithChromaAndMidi: getChromaAndMidiByPositions(
      fretboard,
      playingAreaPositions,
    ),
    arrowPositions: {
      way: getArrowPositionTowardsMiddleOfPositionsByPosition(
        scalePosition.positions.map(({ position }) => position),
        tonicPosition,
      ),
      wayBack: getArrowPositionTowardsMiddleOfPositionsByPosition(
        scalePosition.positions.map(({ position }) => position),
        endPosition,
      ),
    },
  };
}
