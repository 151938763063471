import { NoteName } from 'tonal';
import {
  UNALTERED_AND_ALTERED_NOTES,
  UNALTERED_NOTES,
} from '@notacami/core/notes';
import { getRandomElement } from '../utils/get-random-element';

export function getRandomNote(
  withAlteredNotes: boolean,
  excludedNote?: NoteName,
): NoteName {
  const notes = withAlteredNotes
    ? UNALTERED_AND_ALTERED_NOTES
    : UNALTERED_NOTES;

  const filteredNotes = notes.filter((note) => excludedNote !== note);

  return getRandomElement(filteredNotes);
}
