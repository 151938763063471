import { ITunerService } from '../tuner';
import { ConsumerService } from './consumer';

export function getTunerConsumer(tunerService: ITunerService) {
  return new ConsumerService<void, void>(
    () => {
      return tunerService.resume();
    },
    () => {
      return tunerService.stop();
    },
  );
}
