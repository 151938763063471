import {
  getClassnamesByColorVariant,
  ColorVariant,
} from '@notacami/core/design';
import { Ear, Eye, Guitar, Shapes } from 'lucide-react';
import { cn } from '@notacami/core/css';
import { LessonType } from '@notacami/core/lesson';

type ThematicPageLinkIconProps = {
  colorVariant: ColorVariant;
  lessonType: LessonType;
};

type ThematicIconProps = {
  lessonType: LessonType;
};

function ThematicIcon({ lessonType }: ThematicIconProps) {
  switch (lessonType) {
    case 'quiz-exercise':
      return <Shapes size={22} />;
    case 'fretboard-exercise':
      return <Guitar size={22} />;
    case 'listening-exercise':
      return <Ear size={22} />;
    case 'visualization':
      return <Eye size={22} />;
    default:
      return null;
  }
}

export function ThematicPageLinkIcon({
  colorVariant,
  lessonType,
}: ThematicPageLinkIconProps) {
  const classnames = getClassnamesByColorVariant(colorVariant);
  return (
    <div
      className={cn(
        classnames.background,
        classnames.text,
        'flex h-9 min-h-[2.25rem] w-9 min-w-[2.25rem] items-center justify-center rounded-full',
      )}
    >
      {<ThematicIcon lessonType={lessonType} />}
    </div>
  );
}
