import { Note } from 'tonal';
import { getNoteInfoByFrequency, NoteFromPitch } from '@notacami/core/notes';
import { getDataInBufferInBoundaries } from '../../components/business/note-played-debug/get-data-in-buffer-in-boundaries';
import { FrequencyLevelAndTime } from '../pitch-detection';

export function getNoteChromaInTimeInterval(
  buffer: FrequencyLevelAndTime[],
  startX: number,
  endX: number,
  referenceFrequency: number,
) {
  const dataInBoundaries = getDataInBufferInBoundaries(
    buffer.map(({ frequency, time }) => ({
      x: time,
      y: frequency,
    })),
    startX,
    endX,
  );
  const notesChroma = dataInBoundaries.map(({ y }) =>
    getNoteInfoByFrequency(y, referenceFrequency),
  );

  const numOfElementsByNoteName = notesChroma.reduce<Record<string, number>>(
    groupNoteNameToCount,
    {},
  );

  const keys = Object.keys(numOfElementsByNoteName);
  const values = Object.values(numOfElementsByNoteName);

  const maxElement = Math.max(...values);

  const index = values.indexOf(maxElement);

  const currentNoteNameMorePresent = keys[index];

  const noteChromaMorePresent = Note.chroma(currentNoteNameMorePresent);

  return noteChromaMorePresent;
}

function groupNoteNameToCount(
  accumulator: Record<string, number>,
  current: NoteFromPitch,
): Record<string, number> {
  const currentNoteName = current.name;
  if (!accumulator[currentNoteName]) {
    accumulator[currentNoteName] = 1;
  } else {
    accumulator[currentNoteName] += 1;
  }
  return accumulator;
}
