import { motion } from 'motion/react';
import { ChevronDown } from 'lucide-react';
import { Surface } from '@notacami/ui';

type ChevronProps = {
  isOpen: boolean;
};

export function Chevron({ isOpen }: ChevronProps) {
  return (
    <Surface
      surfaceId="app"
      className="flex h-8 w-8 items-center justify-center rounded-full"
    >
      <motion.div
        animate={{
          rotate: isOpen ? 180 : 0,
        }}
      >
        <ChevronDown size={24} />
      </motion.div>
    </Surface>
  );
}
