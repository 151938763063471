import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Theme, ThemeProvider } from '@notacami/ui';
import { usePreferencesStore } from '../components/business/preferences/use-preferences-context';
import {
  applyTheme,
  getSystemTheme,
  getThemeToApplyByThemePreference,
  THEME_MEDIA_QUERY,
} from '../services/theme-preference';

type ThemeBootProps = {
  children: ReactNode;
};

export function ThemeBoot({ children }: ThemeBootProps) {
  const themePreference = usePreferencesStore((state) => state.themePreference);

  const [currentTheme, setCurrentTheme] = useState<Theme>(
    getThemeToApplyByThemePreference(themePreference),
  );

  useEffect(() => {
    function applyThemeWhenThemePreferenceChanged() {
      const themeToApply = getThemeToApplyByThemePreference(themePreference);

      applyTheme(themeToApply);
      setCurrentTheme(themeToApply);
    }

    applyThemeWhenThemePreferenceChanged();
  }, [themePreference]);

  const handleSystemThemeChange = useCallback(() => {
    if (themePreference !== 'system') return;

    const themeToApply = getSystemTheme();

    applyTheme(themeToApply);
    setCurrentTheme(themeToApply);
  }, [themePreference]);

  useEffect(() => {
    const media = window.matchMedia(THEME_MEDIA_QUERY);

    // Intentionally use deprecated listener methods to support iOS & old browsers
    media.addListener(handleSystemThemeChange);

    return () => media.removeListener(handleSystemThemeChange);
  }, [handleSystemThemeChange]);

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
}
