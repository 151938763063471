import { forwardRef } from 'react';
import { Button } from '@notacami/ui';
import { MicDeniedPermissionContent } from '../mic-denied-permission-content';
import { TutorialStepContent } from '../tutorial-step-content';
import { useIsNative } from '../../../hooks/use-is-native';
import { useTranslation } from '../../../hooks/use-translation';

type PermissionDeniedStepProps = {
  abortTutorial: () => void;
};

export const PermissionDeniedStep = forwardRef<
  HTMLDivElement,
  PermissionDeniedStepProps
>(({ abortTutorial }, ref) => {
  const { t } = useTranslation();
  const isNative = useIsNative();

  return (
    <TutorialStepContent
      ref={ref}
      content={<MicDeniedPermissionContent isNative={isNative} />}
      action={
        <Button colorVariant="neutral" onClick={abortTutorial}>
          {t('button.i-get-it')}
        </Button>
      }
    />
  );
});

PermissionDeniedStep.displayName = 'PermissionDeniedStep';
