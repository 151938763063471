import { Interval } from 'tonal';

export function getIntervalListSemitones(
  intervals: string[],
): { simpliedSemitones: number; semitones: number; interval: string }[] {
  return intervals.map((interval) => {
    const semitones = Interval.semitones(interval);
    if (isNaN(semitones)) {
      throw new Error(
        `Number of semitones not found for interval: ${interval}`,
      );
    }
    return {
      simpliedSemitones: semitones < 0 ? (12 + semitones) % 12 : semitones % 12,
      semitones,
      interval,
    };
  });
}
