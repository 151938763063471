import { PanInfo, motion } from 'motion/react';
import { useContext, useRef, useState } from 'react';
import { Logo, LogoText } from '@notacami/ui';
import { usePreferencesStore } from '../preferences/use-preferences-context';
import { ServicesContext } from '../../../services/services.context';

const NUMBER_OF_ROUND_TRIPS = 20;

export function VersionSection() {
  const { app, preferenceAdminStore } = useContext(ServicesContext);
  const [leftDragComplete, setLeftDragComplete] = useState(false);
  const numberbOfRoundTrips = useRef(0);

  const admin = usePreferencesStore((state) => state.admin);
  const unlockAdmin = usePreferencesStore((state) => state.unlockAdmin);

  const handleDrag = (_: unknown, info: PanInfo) => {
    if (numberbOfRoundTrips.current >= NUMBER_OF_ROUND_TRIPS && !admin) {
      unlockAdmin();
      preferenceAdminStore.set(true);
      return;
    }
    if (info.offset.x < -100 && !leftDragComplete) {
      setLeftDragComplete(true);
    }
    if (info.offset.x > 100 && leftDragComplete) {
      setLeftDragComplete(false);
      numberbOfRoundTrips.current++;
    }
  };

  return (
    <div className="flex justify-center">
      <div className="relative flex flex-col items-center gap-4 tabular-nums">
        <Logo size={80} className="overflow-hidden rounded-xl" />
        <div className="flex flex-col items-center gap-2">
          <LogoText type="monochrome" />
          <span className="text-xs">
            {'version '}
            <span className="text-color-title font-semibold">
              {app.getVersion()}
            </span>
          </span>
        </div>
        <motion.div
          drag="x"
          dragConstraints={{ left: -100, right: 100 }}
          className="absolute -inset-2"
          onDrag={handleDrag}
        />
      </div>
    </div>
  );
}
