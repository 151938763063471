import { Device } from '@capacitor/device';
import i18next from 'i18next';
import type { AppLanguage } from '@notacami/core/i18n';
import { LanguagePreference } from './languages.types';
import { II18nService } from './i18n.types';
import { initializeI18nextInstance } from './get-i18next-instance';

export class I18nService implements II18nService {
  private isInitialized = false;

  private async retrievePreferedLanguage(
    languagePreference: LanguagePreference,
  ): Promise<AppLanguage> {
    if (languagePreference === 'system') {
      const deviceLanguage = await Device.getLanguageCode();
      return deviceLanguage.value as AppLanguage;
    } else {
      return languagePreference;
    }
  }

  public async updateLanguage(languagePreference: LanguagePreference) {
    const language = await this.retrievePreferedLanguage(languagePreference);

    if (!this.isInitialized) {
      await initializeI18nextInstance(language);
      this.isInitialized = true;
    } else {
      await i18next.changeLanguage(language);
    }
  }
}
