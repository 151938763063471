import { groupBy, map, pipe, toPairs } from 'lodash/fp';
import { Interval } from 'tonal';

export function getGroupedIntervalsByNumberOfSemitones(
  intervals: string[],
): [semitones: number, intervalList: string[]][] {
  const group = pipe([
    groupBy(Interval.semitones),
    toPairs,
    map(
      (
        semitonesWithIntervalList: [semitones: string, intervalList: string[]],
      ) => [
        parseInt(semitonesWithIntervalList[0]),
        semitonesWithIntervalList[1],
      ],
    ),
  ]);
  return group(intervals);
}
