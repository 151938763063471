import { Scale } from 'tonal';
import { ScaleTypeName } from './scale.types';

export function getScaleInfoByTonicAndScaleTypeName(
  tonicNoteName: string,
  scaleTypeName: ScaleTypeName,
) {
  const scaleInfo = Scale.get(`${tonicNoteName} ${scaleTypeName}`);
  return scaleInfo;
}
