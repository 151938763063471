import { useContext } from 'react';
import { FormFieldContainerLayout, Select } from '@notacami/ui';
import { useTranslation } from '../../../../hooks/use-translation';
import { usePreferencesStore } from '../../preferences/use-preferences-context';
import { ServicesContext } from '../../../../services/services.context';
import {
  THEME_PREFERENCES,
  ThemePreference,
} from '../../../../services/theme-preference';

export function ThemeSection() {
  const { t } = useTranslation();
  const { preferenceThemeStore } = useContext(ServicesContext);
  const themePreference = usePreferencesStore((state) => state.themePreference);
  const setTheme = usePreferencesStore((state) => state.setThemePreference);

  const handleSelectChange = async (value: ThemePreference) => {
    await preferenceThemeStore.set(value);
    setTheme(value);
  };

  const options = THEME_PREFERENCES.map((themePreference) => ({
    id: themePreference,
    value: themePreference,
    label: t(`pages.settings.general.appearence.option.${themePreference}`),
  }));

  return (
    <FormFieldContainerLayout
      id="theme-setting-field"
      label={t('pages.settings.general.appearence.title')}
      input={
        <Select
          options={options}
          name="theme-setting"
          value={themePreference}
          onChange={handleSelectChange}
          className="w-48"
        />
      }
    />
  );
}
