import { isPositionEqual, Position } from '@notacami/core/fretboard';
import { IntervalPositionAndDistance } from '../fretboard';

export function getFilteredIntervalsPositionForSegmentComputation(
  originalIntervalsPosition: IntervalPositionAndDistance[],
  scaleIntervals: string[],
  startPosition: Position,
  endPosition: Position,
) {
  const numberOfInterval = scaleIntervals.length;
  const startInterval = scaleIntervals[0];
  const endInterval = scaleIntervals[numberOfInterval - 1];

  const intervalPositionsSortedByDistance = [...originalIntervalsPosition].sort(
    (intervalPosition1, intervalPosition2) =>
      intervalPosition1.distance - intervalPosition2.distance,
  );

  const scaleIntervalsPosition = intervalPositionsSortedByDistance
    .filter(({ interval }) => scaleIntervals.includes(interval))
    .filter(({ position, interval }) =>
      interval === startInterval
        ? isPositionEqual(position, startPosition)
        : true,
    )
    .filter(({ position, interval }) =>
      interval === endInterval ? isPositionEqual(position, endPosition) : true,
    );

  const MAX_INTERVAL_POSITION_COUNT = 2;

  const scaleIntervalsPositionWithMaxTwoPositionsByInterval =
    scaleIntervalsPosition.reduce<IntervalPositionAndDistance[]>(
      (intervalsPositionAccumulator, currentIntervalPosition) => {
        const currentInterval = currentIntervalPosition.interval;
        const numberOfCurrentIntervalInAccumulator =
          intervalsPositionAccumulator.filter(
            ({ interval }) => interval === currentInterval,
          ).length;
        if (
          numberOfCurrentIntervalInAccumulator < MAX_INTERVAL_POSITION_COUNT
        ) {
          return [...intervalsPositionAccumulator, currentIntervalPosition];
        }
        return intervalsPositionAccumulator;
      },
      [],
    );

  return scaleIntervalsPositionWithMaxTwoPositionsByInterval;
}
