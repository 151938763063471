import { useContext, useEffect, useState } from 'react';
import { cn } from '@notacami/core/css';
import { ServicesContext } from '../../../services/services.context';
import { MARKERS } from './constants';

export function VuMeterMarkers() {
  const { pitchDetection } = useContext(ServicesContext);
  const [currentLevel, setCurrentLevel] = useState<number>(-100);

  const handleMeterUpdate = (level: number) => {
    setCurrentLevel(Math.round(level));
  };

  const handleMicClose = () => {
    setCurrentLevel(-100);
  };

  useEffect(() => {
    pitchDetection.on('meter-update', handleMeterUpdate);
    pitchDetection.on('mic-close', handleMicClose);

    return () => {
      pitchDetection.off('meter-update', handleMeterUpdate);
      pitchDetection.off('mic-close', handleMicClose);
    };
  }, []);

  return (
    <div className="vertical-content-distribution-sm items-center justify-center">
      <div className="relative z-10 flex">
        {MARKERS.map(({ text, level }) => (
          <div
            key={level}
            className="flex w-3 flex-col items-center justify-end"
          >
            {text ? (
              <div className="text-xs text-neutral-200">{text}</div>
            ) : null}
          </div>
        ))}
      </div>
      <div className="relative z-10 flex">
        {MARKERS.map(({ level, status }) => (
          <div
            key={level}
            className="flex w-3 flex-col items-center justify-center"
          >
            <div
              className={cn(
                'h-2 w-2 rounded-full transition-all',
                currentLevel >= level && status === 'good' && 'bg-green-400',
                currentLevel >= level && status === 'high' && 'bg-yellow-500',
                currentLevel >= level && status === 'peak' && 'bg-red-500',
                currentLevel < level && 'bg-neutral-500',
              )}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}
