import { LessonStepType } from '@notacami/core/lesson';
import {
  ILessonProgressService,
  LessonProgressEntry,
} from './lesson-progress.types';
import { LessonProgressSource } from './lesson-progress.source';

export class LessonProgressService implements ILessonProgressService {
  constructor(private readonly store: LessonProgressSource) {}

  public async upsertProgressInfoByLessonId(
    lessonId: string,
    sessionPracticeTime: number,
    progressEntries: LessonProgressEntry[],
    stepsInfo: { id: string; type: LessonStepType }[],
  ) {
    const {
      entries: progressEntriesInStore,
      practiceTime: practiceTimeInStore,
    } = await this.store.getLessonProgressByLessonId(lessonId);

    const upsertedEntries = stepsInfo
      .map(
        ({ id: stepId, type: stepType }): LessonProgressEntry | undefined => {
          const foundEntryInStore = progressEntriesInStore.find(
            (entry) => entry.id === stepId,
          );
          const foundEntryInParams = progressEntries.find(
            (entry) => entry.id === stepId,
          );
          if (stepType === 'teacher-listening') {
            if (foundEntryInStore && foundEntryInParams) {
              return {
                type: 'teacher-listening',
                id: stepId,
                numberOfRepetitions:
                  foundEntryInParams.numberOfRepetitions +
                  foundEntryInStore.numberOfRepetitions,
              };
            }
            if (foundEntryInStore && !foundEntryInParams) {
              return {
                type: 'teacher-listening',
                id: stepId,
                numberOfRepetitions: foundEntryInStore.numberOfRepetitions,
              };
            }
            if (!foundEntryInStore && foundEntryInParams) {
              return {
                type: 'teacher-listening',
                id: stepId,
                numberOfRepetitions: foundEntryInParams.numberOfRepetitions,
              };
            }
            return {
              type: 'teacher-listening',
              id: stepId,
              numberOfRepetitions: 0,
            };
          }
        },
      )
      .filter((value) => value !== undefined);

    await this.store.saveLessonProgressByLessonId(
      lessonId,
      practiceTimeInStore + sessionPracticeTime,
      upsertedEntries,
    );
  }

  public async deleteLessonProgressByLessonId(lessonId: string) {
    await this.store.deleteLessonProgressByLessonId(lessonId);
  }

  public async getLessonProgressByLessonId(lessonId: string) {
    const entries = await this.store.getLessonProgressByLessonId(lessonId);
    return entries;
  }
}
