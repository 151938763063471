import { useCallback, useContext, useEffect, useState } from 'react';
import { ServicesContext } from '../../../services/services.context';
import { ConsumersIds } from '../../../services/consumer';
import { PeaksUpdateInfo } from '../../../services/peak-detection';
import {
  computeGroupedPeaksToDisplay,
  GroupedPeaksToDisplay,
} from './compute-grouped-peaks-to-display';
import { PeaksLogItem } from './peaks-log-item';

type PeaksLogProps = {
  isRecording: boolean;
};

const MAX_ELEMENTS = 10;

export function PeaksLog({ isRecording }: PeaksLogProps) {
  const [groupedPeaksToDisplay, setGroupedPeaksToDisplay] =
    useState<GroupedPeaksToDisplay>([]);
  const { peakDetectionConsumer, peakDetection } = useContext(ServicesContext);

  const handlePeaksUpdate = useCallback(
    ({
      minimaPeaksIntoBoundaries,
      maximaPeaksIntoBoundaries,
      maximaPeaksOutsideBoundaries,
      minimaPeaksOutsideBoundaries,
    }: PeaksUpdateInfo) => {
      if (isRecording) {
        const computedGroupedPeaksToDisplay = computeGroupedPeaksToDisplay({
          minimaPeaksIntoBoundaries,
          maximaPeaksIntoBoundaries,
          maximaPeaksOutsideBoundaries,
          minimaPeaksOutsideBoundaries,
        });
        setGroupedPeaksToDisplay(computedGroupedPeaksToDisplay);
      }
    },
    [isRecording],
  );

  useEffect(() => {
    peakDetection.on('peaks-update', handlePeaksUpdate);
    peakDetectionConsumer.addConsumer(ConsumersIds.NOTE_PLAYED_DEBUG_PEAKS_LOG);

    return () => {
      peakDetection.off('peaks-update', handlePeaksUpdate);
      peakDetectionConsumer.removeConsumer(
        ConsumersIds.NOTE_PLAYED_DEBUG_PEAKS_LOG,
      );
    };
  }, [handlePeaksUpdate]);

  const definitivePeaksToDisplay = groupedPeaksToDisplay.slice(0, MAX_ELEMENTS);

  return (
    <div className="relative h-80 w-full overflow-y-scroll py-4">
      {definitivePeaksToDisplay.map((peaksToDisplay, index) => (
        <PeaksLogItem key={`peak-${index}`} peaks={peaksToDisplay} />
      ))}
    </div>
  );
}
