import { useContext } from 'react';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { PersonaState } from './persona.store';
import { PersonaContext } from './persona.context';

export function usePersonaStore<T>(
  selector: (state: PersonaState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const store = useContext(PersonaContext);

  return useStoreWithEqualityFn(store, selector, equalityFn);
}
