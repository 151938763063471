import { Note } from 'tonal';
import { TuningInfo } from '@notacami/core/tuning';
import { Fretboard } from '../../../services/fretboard';
import { getSelectableStringsFromTuning } from '../exercise/states/configuration/configuration.utils';
import { FindTheNoteOnTheNeckConfig } from './types';

const INITIAL_CONFIGURATION = {
  showNeckHeadNotes: true,
  withAlteredNotes: false,
};

export function getDefaultConfig(
  tuningInfo: TuningInfo,
  fretboard: Fretboard,
  notePitchClass: string | null,
): FindTheNoteOnTheNeckConfig {
  return {
    withAlteredNotes: INITIAL_CONFIGURATION.withAlteredNotes,
    showNeckHeadNotes: INITIAL_CONFIGURATION.showNeckHeadNotes,
    selectableStrings: getSelectableStringsFromTuning(
      tuningInfo.notes,
      notePitchClass !== null
        ? tuningInfo.notes.map((note, index) => ({
            index,
            selected: Note.pitchClass(note) === notePitchClass,
          }))
        : undefined,
    ),
    tuningInfo,
    fretboard,
  };
}
