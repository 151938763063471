import {
  FretboardNoteDetails,
  isPositionEqual,
  Position,
} from '@notacami/core/fretboard';
import { NoteStartEvent } from '@notacami/core/composer';
import { BuildCellsComponentProps } from '@notacami/ui-business/guitar-neck';
import {
  NeckCellInterval,
  NeckCellNote,
  NeckCellRepetition,
} from '@notacami/ui-business/neck-cells';
import { ListeningStepWithRepetitionInfo } from '../../../../../../services/teacher';
import { useNoteNotation } from '../../../../../../services/note-notation/use-note-notation';

type StepTeacherListeningNeckCellProps = BuildCellsComponentProps & {
  fretboardNoteDetails: FretboardNoteDetails;
  listeningStep: ListeningStepWithRepetitionInfo;
  notesToDisplay: NoteStartEvent[];
  notesToPlayPositions: Position[];
};

export function StepTeacherListeningNeckCell({
  fretboardNoteDetails,
  getElementByPosition,
  isOpenString,
  isVertical,
  listeningStep,
  notesToDisplay,
  notesToPlayPositions,
  position,
}: StepTeacherListeningNeckCellProps) {
  const { n } = useNoteNotation();
  const noteToDisplay = notesToDisplay.find((noteToDisplay) =>
    isPositionEqual(noteToDisplay.position, position),
  );

  const isNoteToHighlight = notesToPlayPositions.some((notesToPlayPosition) =>
    isPositionEqual(notesToPlayPosition, position),
  );

  const fretboardNote = getElementByPosition(fretboardNoteDetails);

  const listeningStepNotesPositions = listeningStep.notes.map(
    (note) => note.position,
  );
  const positionIsInListeningStep = listeningStepNotesPositions.some(
    (listeningStepNotesPosition) =>
      isPositionEqual(listeningStepNotesPosition, position),
  );
  const shouldShowRepetition =
    listeningStep.numberOfPositionsRepetition > 1 && positionIsInListeningStep;

  if (noteToDisplay === undefined && position[1] === 0 && !isNoteToHighlight) {
    return (
      <NeckCellNote
        isOpenString={isOpenString}
        isVertical={isVertical}
        position={position}
        noteLabel={n(fretboardNote.pitchClass)}
      />
    );
  }

  return (
    <>
      {noteToDisplay !== undefined &&
      noteToDisplay.noteDisplayMode === 'pitch-class' &&
      !shouldShowRepetition ? (
        <NeckCellNote
          isHighlighted={isNoteToHighlight}
          isOpenString={isOpenString}
          isVertical={isVertical}
          noteLabel={n(noteToDisplay.pitchClassToDisplay)}
          position={position}
        />
      ) : null}
      {noteToDisplay !== undefined &&
      noteToDisplay.noteDisplayMode === 'interval' &&
      noteToDisplay.intervalToDisplay !== undefined &&
      !shouldShowRepetition ? (
        <NeckCellInterval
          isHighlighted={isNoteToHighlight}
          isOpenString={isOpenString}
          isVertical={isVertical}
          interval={noteToDisplay.intervalToDisplay}
          position={position}
        />
      ) : null}
      {noteToDisplay === undefined &&
      isNoteToHighlight &&
      !shouldShowRepetition ? (
        <NeckCellNote
          isHighlighted
          isOpenString={isOpenString}
          isVertical={isVertical}
          position={position}
          noteLabel={n(fretboardNote.pitchClass)}
        />
      ) : null}
      {shouldShowRepetition ? (
        <NeckCellRepetition
          currentPositionsRepetitionIndex={
            listeningStep.currentPositionsRepetitionIndex
          }
          isOpenString={isOpenString}
          isVertical={isVertical}
          numberOfPositionsRepetition={
            listeningStep.numberOfPositionsRepetition
          }
          position={position}
          isHighlighted
        />
      ) : null}
    </>
  );
}
