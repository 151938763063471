import { ThemePreference } from '../../theme-preference';
import { StoreCapacitor } from '../storage.capacitor';

export function getPreferenceThemeCapacitorStore() {
  return new StoreCapacitor<ThemePreference>(
    'settings.theme',
    'system',
    (value) => value,
    (value) => value as ThemePreference,
  );
}
