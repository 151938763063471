import { useContext, useEffect } from 'react';
import { DEFAULT_FRET_LENGTH, Position } from '@notacami/core/fretboard';
import { GuitarNeck } from '@notacami/ui-business/guitar-neck';
import { useGuitarNeckVertical } from '../../../../../hooks/use-guitar-neck-vertical';
import { usePreferencesStore } from '../../../preferences/use-preferences-context';
import { useLeftHanded } from '../../../../../services/fretboard';
import { PartId } from '../../../../../services/sequence/sequence.constants';
import { FindTheNoteOnTheNeckConfig } from '../../types';
import { QuizMode } from '../../../../../services/exercise/exercise.types';
import { ServicesContext } from '../../../../../services/services.context';
import { AnswerSectionResultCell } from './answer-section-result-cell';

export type AnswerSectionResultProps = {
  config: FindTheNoteOnTheNeckConfig;
  quizMode: QuizMode;
  correctAnswerPosition: Position;
};

export function AnswerSectionResult({
  config,
  quizMode,
  correctAnswerPosition,
}: AnswerSectionResultProps) {
  const { soundPlayer } = useContext(ServicesContext);
  const isVertical = useGuitarNeckVertical();
  const leftHanded = useLeftHanded();

  const fretboard = usePreferencesStore((state) => state.fretboard);

  useEffect(() => {
    function playCorrectAnswerNote() {
      if (quizMode === 'app') {
        soundPlayer.playSequence(
          PartId.FIND_THE_NOTE_ON_THE_NECK,
          [
            {
              type: 'note',
              position: correctAnswerPosition,
              time: 0,
              duration: 0.25,
            },
          ],
          fretboard.noteDetails,
        );
      }
    }
    playCorrectAnswerNote();
  }, [config, quizMode]);

  return (
    <GuitarNeck
      buildCellsComponent={AnswerSectionResultCell}
      isVertical={isVertical}
      leftHanded={leftHanded}
      numberOfFrets={DEFAULT_FRET_LENGTH}
      tuning={config.tuningInfo.notes}
      additionalCellProps={{
        fretboard,
        correctAnswerPosition,
      }}
    />
  );
}
