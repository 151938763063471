import { Note } from 'tonal';
import { getRandomNote } from '@notacami/core/notes';
import { getRandomStringIndex } from '../../../../../../services/fretboard';
import {
  FindTheNoteOnTheNeckConfig,
  FindTheNoteOnTheNeckQuestion,
  FindTheNoteOnTheNeckTurnQuestion,
} from '../../../types';

export function computeQuestion(
  config: FindTheNoteOnTheNeckConfig,
  previousTurnQuestions: FindTheNoteOnTheNeckTurnQuestion[],
): FindTheNoteOnTheNeckQuestion {
  const lastPreviousQuestion = previousTurnQuestions.pop()?.question;

  const lastGivenNote = lastPreviousQuestion?.givenNote;
  const lastGivenStringIndex = lastPreviousQuestion?.givenStringIndex;

  const givenNote = getRandomNote(config.withAlteredNotes, lastGivenNote);
  const givenStringIndex = getRandomStringIndex(
    config.selectableStrings,
    lastGivenStringIndex,
  );
  const givenNoteChroma = Note.chroma(givenNote);

  return {
    givenNote,
    givenStringIndex,
    givenNoteChroma,
  };
}
