import { Title } from '@notacami/ui';
import { useTranslation } from '../../hooks/use-translation';
import { PageUrls } from '../../utils/routes.contants';
import { useHideTabBar } from '../../components/ui/tab-bar.hooks';
import { PageLayout } from '../../components/business/page-layout';
import { Settings } from '../../components/business/settings/settings';
import { BackLink } from '../../components/ui/back-link';

export function SettingsPage() {
  useHideTabBar();
  const { t } = useTranslation();

  return (
    <PageLayout
      title={
        <Title tagName="h1" level={2}>
          {t('pages.settings.title')}
        </Title>
      }
      leftAction={<BackLink to={PageUrls.HOME} />}
      content={<Settings />}
    />
  );
}
