import { ReactNode, forwardRef } from 'react';
import { MotionSurface } from '@notacami/ui';
import { ConditionalWrapper } from '@notacami/core/components';
import { useSafeAreaInsets } from '../../hooks/use-safe-area-insets';

type LessonLayoutProps = {
  bottomCenterAction?: ReactNode;
  bottomLeftAction?: ReactNode;
  bottomRightAction?: ReactNode;
  center: ReactNode;
  resizeWhenDrawerOpened?: boolean;
  topCenter?: ReactNode;
  topLeftAction?: ReactNode;
  topRightAction?: ReactNode;
};

export const LessonLayout = forwardRef<HTMLDivElement, LessonLayoutProps>(
  (
    {
      bottomCenterAction,
      bottomLeftAction,
      bottomRightAction,
      center,
      resizeWhenDrawerOpened = false,
      topCenter,
      topLeftAction,
      topRightAction,
    },
    ref,
  ) => {
    const { bottom, left, right, top } = useSafeAreaInsets();
    return (
      <ConditionalWrapper
        items={[
          {
            condition: !resizeWhenDrawerOpened,
            wrapper: (children, index) => (
              <div
                data-testid="lesson-layout"
                key={index}
                className="fixed z-50 p-2"
                style={{ bottom, left, right, top }}
              >
                {children}
              </div>
            ),
          },
          {
            condition: resizeWhenDrawerOpened,
            wrapper: (children, index) => (
              <div
                data-testid="lesson-layout"
                key={index}
                className="fixed z-50 p-2"
                vaul-drawer-wrapper=""
                style={{ bottom, left, right, top }}
              >
                {children}
              </div>
            ),
          },
        ]}
      >
        <MotionSurface
          surfaceId="light"
          initial={{ y: 5, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          exit={{
            y: 5,
            opacity: 0,
          }}
          ref={ref}
          className="relative h-full overflow-hidden rounded-3xl"
        >
          <div className="absolute inset-0 overflow-y-scroll">
            <div className="flex min-h-full items-center justify-center py-14">
              {center}
            </div>
          </div>
          <div className="absolute inset-x-3 top-3 flex justify-between gap-4">
            <div className="flex w-24 justify-start">{topLeftAction}</div>
            <div className="flex grow items-center justify-center">
              {topCenter}
            </div>
            <div className="flex w-24 justify-end gap-2">{topRightAction}</div>
          </div>

          <div className="absolute inset-x-3 bottom-3 flex items-end justify-between gap-4">
            <div className="flex w-36 justify-start">{bottomLeftAction}</div>
            <div className="flex grow items-center justify-center">
              {bottomCenterAction}
            </div>
            <div className="flex w-36 justify-end gap-2">
              {bottomRightAction}
            </div>
          </div>
        </MotionSurface>
      </ConditionalWrapper>
    );
  },
);
