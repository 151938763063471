import { useContext } from 'react';
import { LessonProvider } from '../../../services/lesson';
import { ServicesContext } from '../../../services/services.context';
import { LessonMainSteps } from './lesson-main-steps';
import { LessonPostMessage } from './lesson-post-message';

export function LessonCreation() {
  const { lessonProgress, stopWatch } = useContext(ServicesContext);

  return (
    <LessonProvider lessonProgress={lessonProgress} stopWatch={stopWatch}>
      <LessonPostMessage />
      <LessonMainSteps />
    </LessonProvider>
  );
}
