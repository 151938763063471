import { useEffect, useState } from 'react';
import { CapacitorHttp } from '@capacitor/core';

export function PingSection() {
  const [data, setData] = useState('...loading');

  useEffect(() => {
    async function getData() {
      const response = await CapacitorHttp.get({
        url: `${import.meta.env.VITE_API_ENDPOINT}/ping`,
      });
      const text = await response.data;
      setData(text);
    }
    getData();
  }, []);

  return (
    <div className="flex justify-between">
      <strong>Ping:</strong> {data}
    </div>
  );
}
