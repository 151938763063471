import { forwardRef } from 'react';
import { MessageCircleOff } from 'lucide-react';
import { Thematic } from '@notacami/core/thematic';
import { getColorVariantByThematic } from '@notacami/core/design';
import { Button } from '@notacami/ui';
import { TutorialStepContent } from '../tutorial-step-content';
import { useTranslation } from '../../../hooks/use-translation';

type QuietPlaceStepProps = {
  onClick: () => void;
  thematic: Thematic;
};

export const QuietPlaceStep = forwardRef<HTMLDivElement, QuietPlaceStepProps>(
  ({ onClick, thematic }, ref) => {
    const { t } = useTranslation();
    const colorVariant = getColorVariantByThematic(thematic);
    return (
      <TutorialStepContent
        ref={ref}
        content={
          <div className="vertical-content-distribution-base items-center">
            <MessageCircleOff
              strokeWidth={1.6}
              className="h-12 w-12 stroke-neutral-600 dark:stroke-neutral-300"
            />
            <p className="max-w-xs text-center">
              {t('tutorial.mic-tutorial.quiet-place-step.description')}
            </p>
          </div>
        }
        action={
          <Button onClick={onClick} colorVariant={colorVariant}>
            {t('button.lets-go')}
          </Button>
        }
      />
    );
  },
);

QuietPlaceStep.displayName = 'QuietPlaceStep';
