import { DEFAULT_FRET_LENGTH } from '../../fretboard/fretboard.constants';
import { Sequence } from '../../composer';
import { LessonInfo } from '../lesson.types';

export const LESSON_BASE: Omit<LessonInfo, 'id' | 'steps'> = {
  tuningId: 'guitar-standard',
  fretLength: DEFAULT_FRET_LENGTH,
  name: 'La 1ère position de la gamme majeure',
  description:
    'Apprendre la première position de la gamme majeure te donne des outils solides pour progresser en guitare. C’est un peu comme si tu apprenais les bases d’un vocabulaire musical, ce qui va t’aider pour tout le reste ! Et puis, ça permet aussi de mieux te repérer sur le manche, ce qui devient vite essentiel.',
  mainThematic: 'scales',
  estimatedDuration: 5,
  lessonType: 'fretboard-exercise',
};

export const SEQUENCE_SAMPLE: Sequence = [
  {
    type: 'group-note',
    groupType: 'scale',
    value: { tonic: 'A', type: 'major' },
    noteDisplayMode: 'interval',
    time: 0,
    duration: 4,
  },
  {
    type: 'note',
    position: [0, 5],
    time: 0,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [0, 7],
    time: 0.25,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [1, 4],
    time: 0.5,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [1, 5],
    time: 0.75,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [1, 7],
    time: 1,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [2, 4],
    time: 1.25,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [2, 6],
    time: 1.5,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [2, 7],
    time: 1.75,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [2, 7],
    time: 2,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [2, 6],
    time: 2.25,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [2, 4],
    time: 2.5,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [1, 7],
    time: 2.75,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [1, 5],
    time: 3,
    duration: 0.25,
  },

  {
    type: 'note',
    position: [1, 4],
    time: 3.25,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [0, 7],
    time: 3.5,
    duration: 0.25,
  },
  {
    type: 'note',
    position: [0, 5],
    time: 3.75,
    duration: 0.25,
  },
];
