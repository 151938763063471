type Data<T> = T & { time: number };

export class TimedBuffer<T> {
  public buffer: Data<T>[] = [];

  constructor(private readonly bufferSizeInMs: number) {}

  public addToBuffer(data: Data<T>) {
    this.clearOutdatedData();
    this.buffer = [...this.buffer, data];
  }

  public clear() {
    this.buffer = [];
  }

  private clearOutdatedData() {
    const now = new Date().getTime();
    this.buffer = this.buffer.filter(
      (data) => data.time >= now - this.bufferSizeInMs,
    );
  }
}
