import { Fragment, useContext, useEffect, useState } from 'react';
import { Surface } from '@notacami/ui';
import { cn } from '@notacami/core/css';
import { ServicesContext } from '../../../services/services.context';
import { ConsumersIds } from '../../../services/consumer';
import { MAX_LEVEL, MIN_LEVEL } from './vu-meter-debug.constants';

export function VuMeterDebug() {
  const { pitchDetection, pitchDetectionConsumer } =
    useContext(ServicesContext);
  const [currentLevel, setCurrentLevel] = useState<number>(MIN_LEVEL);

  const handleMeterUpdate = (level: number) => {
    const roundedLevel = Math.round(level);
    const boundedLevel = Math.min(Math.max(roundedLevel, MIN_LEVEL), MAX_LEVEL);
    setCurrentLevel(boundedLevel);
  };

  const handleMicClose = () => {
    setCurrentLevel(MIN_LEVEL);
  };

  useEffect(() => {
    pitchDetection.on('meter-update', handleMeterUpdate);
    pitchDetection.on('mic-close', handleMicClose);
    pitchDetectionConsumer.addConsumer(ConsumersIds.VU_METER_DEBUG);

    return () => {
      pitchDetection.off('meter-update', handleMeterUpdate);
      pitchDetection.off('mic-close', handleMicClose);
      pitchDetectionConsumer.removeConsumer(ConsumersIds.VU_METER_DEBUG);
    };
  }, []);

  return (
    <Surface
      surfaceId="light"
      className="regular-border relative mr-11 h-80 w-10 rounded-2xl"
    >
      <div className="absolute inset-x-1 bottom-4 h-[calc(78%-32px)] rounded-xl">
        <Surface
          surfaceId="soft-invert"
          className="absolute -inset-x-2 h-1 rounded-full"
          style={{ bottom: `${currentLevel + 100}%` }}
        />
        {[-100, -75, -50, -25, 0, 10, 20, 30].map((value) => (
          <Fragment key={value}>
            <Surface
              surfaceId="invert"
              className={cn(
                'absolute inset-x-2 flex h-px items-center justify-start rounded-full opacity-30',
                value === 0 && 'inset-x-1 h-1 opacity-50',
              )}
              style={{
                bottom: `${value + 100}%`,
              }}
            />
            <div
              className="absolute flex h-10 w-10 items-center justify-start text-xs font-semibold"
              style={{
                bottom: `calc(${value + 100}% - 20px)`,
                left: 'calc(100% + 14px)',
              }}
            >
              {value}
            </div>
          </Fragment>
        ))}
      </div>
    </Surface>
  );
}
