import { orderBy, partition } from 'lodash/fp';
import { Surface } from '@notacami/ui';
import { ProgressData } from '../../services/progress/progress.types';
import { useTranslation } from '../../hooks/use-translation';
import { FindTheNoteOnTheNeckProgressPayload } from '../../services/progress/find-the-note-on-the-neck/types';
import { useNoteNotation } from '../../services/note-notation/use-note-notation';
import { ProgressBar } from './progress-bar';
import { ProgressPraticeTime } from './progress-practice-time';
import { usePreferencesStore } from './preferences/use-preferences-context';

type FindTheNoteOnTheNeckProgressProps = {
  currentProgressData: ProgressData<FindTheNoteOnTheNeckProgressPayload>;
  notePitchClass: string | null;
  previousProgressData: ProgressData<FindTheNoteOnTheNeckProgressPayload>;
};

export function FindTheNoteOnTheNeckProgress({
  currentProgressData,
  notePitchClass,
  previousProgressData,
}: FindTheNoteOnTheNeckProgressProps) {
  const { t } = useTranslation();
  const { n } = useNoteNotation();
  const tuningInfo = usePreferencesStore((state) => state.tuningInfo);

  const stringList =
    notePitchClass === null
      ? tuningInfo.stringList
      : tuningInfo.stringList.filter(
          (stringDescription) =>
            stringDescription.pitchClass === notePitchClass,
        );

  const stringListProgressData = stringList.map(
    ({ pitchClass, stringIndexes }) => {
      const previousFoundEntries = previousProgressData.entries.filter(
        (entry) => stringIndexes.includes(entry.payload[0]),
      );
      const currentFoundEntries = currentProgressData.entries.filter((entry) =>
        stringIndexes.includes(entry.payload[0]),
      );

      const previousNumberOfCorrectAnswer =
        previousFoundEntries.length > 0
          ? previousFoundEntries.reduce(
              (acc, cur) => acc + cur.numberOfCorrectAnswer,
              0,
            )
          : 0;

      const currentNumberOfCorrectAnswer =
        currentFoundEntries.length > 0
          ? currentFoundEntries.reduce(
              (acc, cur) => acc + cur.numberOfCorrectAnswer,
              0,
            )
          : 0;

      return {
        pitchClass,
        stringIndexes,
        previousNumberOfCorrectAnswer,
        currentNumberOfCorrectAnswer,
      };
    },
  );

  const orderedStringListProgressData = orderBy(
    'currentNumberOfCorrectAnswer',
    'desc',
    stringListProgressData,
  );
  const orderedStringListProgressDataPartition = partition(
    ({
      previousNumberOfCorrectAnswer,
      currentNumberOfCorrectAnswer,
    }: {
      previousNumberOfCorrectAnswer: number;
      currentNumberOfCorrectAnswer: number;
    }) => {
      return previousNumberOfCorrectAnswer !== currentNumberOfCorrectAnswer;
    },
    orderedStringListProgressData,
  );

  return (
    <div className="vertical-content-distribution-lg relative flex min-h-full w-full items-center justify-center">
      <Surface
        surfaceId="light"
        className="flex w-full items-center justify-center rounded-xl p-4"
      >
        <ProgressPraticeTime
          fromInMs={previousProgressData.practiceTime}
          toInMs={currentProgressData.practiceTime}
          className="w-full"
        />
      </Surface>
      <div className="vertical-content-distribution-base w-full">
        {orderedStringListProgressDataPartition[0].map(
          (
            {
              pitchClass,
              previousNumberOfCorrectAnswer,
              currentNumberOfCorrectAnswer,
            },
            index,
          ) => {
            return (
              <ProgressBar
                key={pitchClass}
                staggerIndex={index}
                title={t('string-and-pitch-class', {
                  pitchClass: n(pitchClass),
                })}
                label={t('tab-header.number-of-repetition')}
                min={0}
                max={10}
                from={previousNumberOfCorrectAnswer}
                to={currentNumberOfCorrectAnswer}
              />
            );
          },
        )}
      </div>
      <div className="vertical-content-distribution-base w-full">
        {orderedStringListProgressDataPartition[1].map(
          (
            {
              pitchClass,
              previousNumberOfCorrectAnswer,
              currentNumberOfCorrectAnswer,
            },
            index,
          ) => {
            return (
              <ProgressBar
                key={pitchClass}
                staggerIndex={index}
                title={t('string-and-pitch-class', {
                  pitchClass: n(pitchClass),
                })}
                label={t('tab-header.number-of-repetition')}
                min={0}
                max={10}
                from={previousNumberOfCorrectAnswer}
                to={currentNumberOfCorrectAnswer}
                size="sm"
              />
            );
          },
        )}
      </div>
    </div>
  );
}
