import { ReactNode } from 'react';
import { AnimatePresence } from 'motion/react';
import { useStore } from 'zustand';
import { ExerciseStoreApi } from '../../../../../services/exercise/exercise.types';
import { QuestionBubble } from './question-bubble';
import { QuestionSectionGenericResult } from './question-section-generic-result';
import { QuestionSectionGenericErrors } from './question-section-generic-errors';

type QuestionSectionGenericProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
> = {
  children: ReactNode;
  store: ExerciseStoreApi<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
  >;
};

export function QuestionSectionGeneric<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
>({
  store,
  children,
}: QuestionSectionGenericProps<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload
>) {
  const currentTurn = useStore(store, (state) => state.currentTurn);
  return (
    <div className="flex justify-center">
      <AnimatePresence mode="popLayout">
        <QuestionBubble
          key={currentTurn}
          result={
            <>
              <QuestionSectionGenericResult store={store} />
              <QuestionSectionGenericErrors store={store} />
            </>
          }
        >
          {children}
        </QuestionBubble>
      </AnimatePresence>
    </div>
  );
}
