import { Surface } from '@notacami/ui';
import { ArrowDownToLine } from 'lucide-react';

type PeaksLogMinimaPeakProps = {
  numberOfPeaks: number;
};

export function PeaksLogMinimaPeak({ numberOfPeaks }: PeaksLogMinimaPeakProps) {
  return (
    <div className="absolute right-0 z-10 -mt-3 h-8">
      <Surface
        surfaceId="soft-invert"
        className="flex h-8 items-center justify-center gap-x-1 rounded-full py-1 pl-3 pr-2 text-xs"
      >
        <strong className="tabular-nums">{numberOfPeaks}</strong>
        <ArrowDownToLine size={20} />
      </Surface>
    </div>
  );
}
