import { FC, ReactNode, RefObject, useRef } from 'react';
import { motion, useInView } from 'motion/react';
import { useWindowSize } from 'react-use';
import { cn } from '@notacami/core/css';
import { MotionSurface } from '@notacami/ui';
import { ConditionalWrapper } from '@notacami/core/components';
import { useSafeAreaInsets } from '../../../hooks/use-safe-area-insets';
import { useTranslation } from '../../../hooks/use-translation';
import { State } from './expandable-card.reducer';
import { SecondaryTitle } from './secondary-title';
import { ExpandableCardTitleProps } from './expandable-card';
import { Chevron } from './chevron';

type ContentProps = {
  children: ReactNode;
  contentRef: RefObject<HTMLDivElement>;
  isDarkPicture?: boolean;
  isExpandable: boolean;
  isRenderInPortal: boolean;
  longTextRef: RefObject<HTMLDivElement>;
  onAnimationComplete: (variant: string) => void;
  onAnimationStart: (variant: string) => void;
  onTitleClick: () => void;
  onPictureLoad: () => void;
  picture?: string;
  pictureLoaded: boolean;
  pictureRef: RefObject<HTMLImageElement>;
  scrollingContentRef: RefObject<HTMLDivElement>;
  secondaryTitleContainerRef: RefObject<HTMLDivElement>;
  state: State;
  title: FC<ExpandableCardTitleProps>;
};

export function Content({
  children,
  contentRef,
  isDarkPicture = false,
  isExpandable,
  isRenderInPortal,
  longTextRef,
  onAnimationComplete,
  onAnimationStart,
  onPictureLoad,
  onTitleClick,
  picture,
  pictureLoaded,
  pictureRef,
  scrollingContentRef,
  secondaryTitleContainerRef,
  state,
  title: Title,
}: ContentProps) {
  const { height } = useWindowSize();
  const { t } = useTranslation();

  const { top, bottom } = useSafeAreaInsets();

  const titleContainerRef = useRef(null);
  const isInView = useInView(titleContainerRef);

  const overlayVariants = {
    normal: {
      opacity: 0,
    },
    expand: {
      opacity: 1,
    },
  };

  const PADDING = 20;

  const offsetTop = top + PADDING;
  const offsetBottom = bottom + PADDING;
  const viewportHeight = height - offsetTop - offsetBottom;
  const targetHeight = Math.min(viewportHeight, state.height);
  const addedTopOffset = (viewportHeight - targetHeight) / 2;

  const contentVariants = {
    normal: !isRenderInPortal
      ? { y: 0, height: state.pushHeight, width: state.pushWidth, x: 0 }
      : {
          y: state.y,
          height: state.pushHeight,
          width: state.pushWidth,
          x: state.x,
          transition: {
            type: 'spring',
            bounce: 0.15,
          },
        },
    expand: {
      y: offsetTop + addedTopOffset,
      height: targetHeight,
      width: state.pushWidth,
      x: state.x,
    },
  };

  return (
    <div
      className={cn(
        'inset-0',
        state.open || state.isTransitioning ? 'fixed z-50' : 'absolute',
      )}
    >
      <motion.div
        variants={!state.isInitialized ? undefined : overlayVariants}
        initial="normal"
        animate={state.open ? 'expand' : 'normal'}
        className={cn(
          state.open || state.isTransitioning ? 'block' : 'hidden',
          'surface-overlay fixed inset-0',
        )}
      />
      <MotionSurface
        surfaceId="light"
        ref={contentRef}
        className={cn(
          'flex origin-top-left flex-col overflow-hidden rounded-3xl',
          state.open || state.isTransitioning ? 'fixed' : 'absolute',
          state.open || state.isTransitioning ? 'z-10' : 'cursor-pointer',
        )}
        variants={!state.isInitialized ? undefined : contentVariants}
        initial="normal"
        animate={state.open ? 'expand' : 'normal'}
        transition={
          !isRenderInPortal ? { duration: 0 } : { type: 'spring', bounce: 0.4 }
        }
        onAnimationComplete={onAnimationComplete}
        onAnimationStart={onAnimationStart}
      >
        <div
          ref={scrollingContentRef}
          className={cn(
            'relative',
            picture && 'pt-[160px]',
            state.open || state.isTransitioning
              ? 'overflow-y-auto overflow-x-hidden'
              : 'overflow-hidden',
          )}
        >
          {picture && state.isOpenCompleted ? (
            <>
              <div className="fixed inset-0 h-80 max-h-full w-full overflow-hidden">
                <img
                  draggable="false"
                  className={cn(
                    pictureLoaded ? 'opacity-100' : 'opacity-0',
                    'block h-full w-full object-cover transition-opacity duration-500',
                  )}
                  src={picture}
                />
              </div>
              <div className="fixed inset-0 h-80 max-h-full w-full overflow-hidden rounded-3xl backdrop-blur-3xl"></div>
            </>
          ) : null}
          {picture ? (
            <div
              className={cn(
                'absolute inset-0 -top-20 h-80 overflow-hidden rounded-3xl',
                isDarkPicture ? 'bg-neutral-700' : 'bg-neutral-300',
              )}
            >
              <img
                draggable="false"
                className={cn(
                  pictureLoaded ? 'opacity-100' : 'opacity-0',
                  'block h-full w-full object-cover transition-opacity duration-500',
                )}
                src={picture}
                ref={pictureRef}
                onLoad={onPictureLoad}
              />
            </div>
          ) : null}
          <div className="absolute inset-x-0 top-0 h-[208px]">
            <motion.div
              ref={titleContainerRef}
              className={cn(
                'left-0 right-0',
                'absolute bottom-0',
                isDarkPicture ? 'text-lighter' : 'text-darker',
              )}
              animate={{
                y: state.open ? -44 : 0,
              }}
            >
              <ConditionalWrapper
                items={[
                  {
                    condition: state.open,
                    wrapper: (children, index) => (
                      <button
                        key={index}
                        className="flex w-full items-center justify-between p-4"
                        onClick={onTitleClick}
                        title={t('button.close')}
                        type="button"
                      >
                        {children}
                      </button>
                    ),
                  },
                  {
                    condition: !state.open,
                    wrapper: (children, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between p-4"
                      >
                        {children}
                      </div>
                    ),
                  },
                ]}
              >
                <>
                  <Title isDarkPicture={isDarkPicture} isOnPicture />

                  {isExpandable ? <Chevron isOpen={state.open} /> : null}
                </>
              </ConditionalWrapper>
            </motion.div>
          </div>
          <div
            className={cn(
              'pointer-events-none inset-x-0 h-6',
              !state.isOpenCompleted && 'hidden',
              state.isOpenCompleted && picture && 'sticky',
              state.isOpenCompleted && !picture && 'fixed',
              picture ? '-top-40 -mb-6' : 'top-0',
              'rounded-t-3xl bg-gradient-to-b',
              'from-stone-100 to-stone-100/0',
              'dark:from-neutral-900 dark:to-neutral-900/0',
            )}
          />
          <MotionSurface
            surfaceId="light"
            animate={
              picture
                ? {
                    y: !state.open ? 60 : 0,
                    scaleX: !state.open ? 0.98 : 1,
                  }
                : undefined
            }
            initial={
              picture
                ? {
                    y: 60,
                    scaleX: 0.98,
                  }
                : undefined
            }
            className="relative rounded-t-3xl"
          >
            {!picture ? (
              <SecondaryTitle
                onClickTitle={onTitleClick}
                open={state.open}
                title={
                  <Title isDarkPicture={isDarkPicture} isOnPicture={false} />
                }
                isVisible={false}
                isExpandable={isExpandable}
              />
            ) : null}
            <div
              ref={longTextRef}
              className={cn(
                'px-4',
                picture ? 'py-8' : 'py-4',
                !picture && '-mt-4',
              )}
            >
              {children}
            </div>
          </MotionSurface>
          <motion.div
            className={cn(
              'left-0 right-0 top-0',
              !picture && !state.isTransitioning && !state.open
                ? 'absolute'
                : 'fixed',
            )}
            initial={picture ? { y: -100, opacity: 0 } : { y: 0, opacity: 1 }}
            animate={
              (!isInView && picture) || !picture
                ? { y: 0, opacity: 1 }
                : {
                    y: -100,
                    opacity: 0,
                  }
            }
            transition={{
              type: 'spring',
              bounce: 0,
            }}
          >
            <SecondaryTitle
              ref={secondaryTitleContainerRef}
              onClickTitle={onTitleClick}
              open={state.open}
              title={
                <Title isDarkPicture={isDarkPicture} isOnPicture={false} />
              }
              isExpandable={isExpandable}
            />
          </motion.div>
          <motion.div
            animate={{
              opacity: !state.isOpenCompleted && picture ? 0 : 1,
            }}
            initial={false}
            className={cn(
              state.isOpenCompleted ? 'fixed' : 'absolute',
              'pointer-events-none inset-x-0 bottom-0 h-6',
              'bg-gradient-to-t',
              'from-stone-100 to-stone-200/0',
              'dark:from-neutral-900 dark:to-neutral-900/0',
            )}
          />
        </div>
      </MotionSurface>
      <div
        className={cn(
          'absolute inset-0 z-10',
          state.isTransitioning && !state.open ? 'block' : 'hidden',
        )}
      />
    </div>
  );
}
