import { motion } from 'motion/react';
import { cn } from '@notacami/core/css';
import { getStringClassnames } from '@notacami/core/fretboard';
import { StringNumber } from '@notacami/ui-business';
import { useNoteNotation } from '../../services/note-notation/use-note-notation';

type StringSelectorProps = {
  id: string;
  label: string;
  octave: number;
  onChange: (stringIndex: number) => void;
  selected: boolean;
  stringNumber: number;
  stringIndex: number;
  disabled: boolean;
};

export function StringSelectorItem({
  id,
  label,
  octave,
  onChange,
  selected,
  stringNumber,
  stringIndex,
  disabled,
}: StringSelectorProps) {
  const { n } = useNoteNotation();

  const handleChange = () => {
    onChange(stringIndex);
  };

  return (
    <li className={cn('relative', disabled && 'opacity-30')}>
      <input
        hidden
        type="checkbox"
        id={id}
        checked={selected}
        onChange={handleChange}
        disabled={disabled}
      />
      <div className="absolute flex h-full w-full items-start justify-center">
        <div className={cn('h-full', getStringClassnames(octave, true))} />
      </div>
      <motion.div
        animate={{ y: selected ? -22 : -5 }}
        className="absolute left-1/2 -ml-2"
      >
        <StringNumber stringNumber={stringNumber} selected={selected} />
      </motion.div>
      <div className="py-1">
        <label
          className={cn(
            'relative flex h-7 w-10 items-center justify-center rounded-md px-2 py-1 text-sm font-semibold',
            'cursor-pointer border-2 transition-all',
            selected &&
              'border-stone-600 bg-stone-600 text-neutral-50 dark:border-stone-600 dark:bg-stone-600',
            !selected &&
              'border-stone-300 bg-stone-300 text-neutral-500 dark:border-stone-800 dark:bg-stone-800 dark:text-neutral-500',
          )}
          htmlFor={id}
        >
          {n(label)}
        </label>
      </div>
    </li>
  );
}
