import { useContext, useEffect, useState } from 'react';
import { NoteEvent } from '@notacami/core/composer';
import { PlayButton } from '@notacami/ui-business';
import { PartId } from '../../../services/sequence/sequence.constants';
import { PositionWithInterval } from '../../../services/fretboard';
import { usePreferencesStore } from '../preferences/use-preferences-context';
import { ServicesContext } from '../../../services/services.context';

type VisualizeScalePositionsPlayNotesProps = {
  positionsWithInterval: PositionWithInterval[];
};

export function VisualizeScalePositionsPlayNotes({
  positionsWithInterval,
}: VisualizeScalePositionsPlayNotesProps) {
  const { soundPlayer } = useContext(ServicesContext);

  const [isPlaying, setIsPlaying] = useState(false);

  const fretboard = usePreferencesStore((state) => state.fretboard);

  const handlePlayClick = () => {
    if (!isPlaying) {
      const noteEvents: NoteEvent[] = [
        ...positionsWithInterval,
        ...positionsWithInterval
          .slice(0, positionsWithInterval.length - 1)
          .reverse(),
      ].map(({ position }, index) => ({
        type: 'note',
        position: position,
        duration: 0.25,
        time: 0.25 * index,
        isCheckpoint: false,
      }));
      soundPlayer.playSequence(
        PartId.VISUALIZE_SCALE_POSITIONS,
        noteEvents,
        fretboard.noteDetails,
      );
    } else {
      soundPlayer.stop();
    }
  };

  const handlePartStart = ({ partId }: { partId: string }) => {
    if (partId === PartId.VISUALIZE_SCALE_POSITIONS) {
      setIsPlaying(true);
    }
  };

  const handleStop = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    soundPlayer.on('part-start', handlePartStart);
    soundPlayer.on('stop', handleStop);

    return () => {
      soundPlayer.off('part-start', handlePartStart);
      soundPlayer.off('stop', handleStop);
    };
  }, []);

  return <PlayButton isPlaying={isPlaying} onClick={handlePlayClick} />;
}
