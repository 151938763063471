import { Position } from '@notacami/core/fretboard';
import { Fretboard, Segment } from '../fretboard';
import { getFretboardPositiveReachableIntervalsByPosition } from '../fretboard-interval';
import { computeSegment } from './compute-segment';

function isValidSegment(segment: Segment | null): segment is Segment {
  return segment !== null;
}

export function getScalePositionSegmentsByPosition(
  fretboard: Fretboard,
  scaleIntervals: string[],
  position: Position,
  maxFretDiffByString: number,
  maxFretDiffBySegment: number,
): Segment[] {
  const intervalsPosition = getFretboardPositiveReachableIntervalsByPosition(
    fretboard,
    position,
    maxFretDiffBySegment,
    scaleIntervals,
  );

  const scaleIntervalsPosition = intervalsPosition.filter(({ interval }) =>
    [...scaleIntervals, '8P'].includes(interval),
  );

  const reachableOctave = scaleIntervalsPosition.filter(
    ({ interval }) => interval === '8P',
  );

  const scalePositions = reachableOctave
    .map(({ position: octavePosition }) =>
      computeSegment(
        fretboard,
        [...scaleIntervals, '8P'],
        position,
        octavePosition,
        maxFretDiffByString,
        maxFretDiffBySegment,
      ),
    )
    .filter(isValidSegment);

  return scalePositions;
}
