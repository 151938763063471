import { TunerCentsOff } from './tuner-cents-off';
import { TunerHead } from './tuner-head';

export function ChromaticTuner() {
  return (
    <div className="z-0 flex h-24 w-60 flex-col items-center justify-center rounded-xl border-2 border-neutral-300 bg-neutral-200">
      <TunerHead />
      <TunerCentsOff />
    </div>
  );
}
