import { Position } from '@notacami/core/fretboard';
import { Boundaries } from './fretboard.types';

function reduceStringBoundariesByPositions(
  boundariesAccumulator: Boundaries,
  currentPosition: Position,
): Boundaries {
  const currentFretIndex = currentPosition[0];

  return {
    min: Math.min(currentFretIndex, boundariesAccumulator.min),
    max: Math.max(currentFretIndex, boundariesAccumulator.max),
  };
}

export function getStringBoundariesByPositions(
  positions: Position[],
): Boundaries {
  const fretBoundaries = positions.reduce(reduceStringBoundariesByPositions, {
    min: +Infinity,
    max: -Infinity,
  });

  return fretBoundaries;
}
