import { Haptics } from '@capacitor/haptics';
import { IDeviceService } from '../device';
import { IHapticFeedbackService } from './haptic-feedback.types';

export class HapticFeedbackCapacitorService implements IHapticFeedbackService {
  constructor(private readonly device: IDeviceService) {}

  public impact() {
    if (this.device.isNative()) {
      Haptics.impact();
    }
  }
}
