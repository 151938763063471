import { TuningInfo } from '@notacami/core/tuning';
import { ScaleTypeName } from '@notacami/core/scales';
import { Fretboard } from '../../../services/fretboard';
import { getSelectableStringsFromTuning } from '../exercise/states/configuration/configuration.utils';
import { getConfigDataByMaybeScaleTypeName } from './get-config-data-by-maybe-scale-name';
import { FindScalePositionConfig } from './types';

export function getDefaultConfig(
  tuningInfo: TuningInfo,
  fretboard: Fretboard,
  scaleTypeName: ScaleTypeName | null,
): FindScalePositionConfig {
  return {
    selectableStrings: getSelectableStringsFromTuning(tuningInfo.notes),
    tuningInfo,
    fretboard,
    ...getConfigDataByMaybeScaleTypeName(scaleTypeName),
  };
}
