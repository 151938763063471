import { X } from 'lucide-react';
import { motion } from 'motion/react';
import { cn } from '@notacami/core/css';
import { MotionSurface } from '@notacami/ui';
import { getRangeNumberByLength } from '@notacami/core/utils';

type TurnErrorsProps = {
  numberOfErrorsOnCurrentTurn: number;
  numberOfErrorsAllowed: number;
};

export function TurnErrors({
  numberOfErrorsOnCurrentTurn,
  numberOfErrorsAllowed,
}: TurnErrorsProps) {
  const isFull = numberOfErrorsOnCurrentTurn >= numberOfErrorsAllowed;
  return (
    <MotionSurface
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: numberOfErrorsOnCurrentTurn === 0 ? 0 : 1 }}
      colorVariant={isFull ? 'red' : undefined}
      surfaceId={isFull ? undefined : 'invert'}
      className="relative rounded-full px-2 py-1"
    >
      <div className="flex">
        {getRangeNumberByLength(numberOfErrorsAllowed).map((index) => (
          <div key={index} className="relative">
            <X
              className="h-4 w-4 text-neutral-500/70 dark:text-neutral-400/70"
              strokeWidth={3}
            />
            {numberOfErrorsOnCurrentTurn > index ? (
              <motion.div
                className="absolute inset-0"
                initial={{ opacity: 0, scale: 4 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ bounce: 0.25 }}
              >
                <X
                  className={cn(
                    'h-4 w-4',
                    isFull ? 'text-white' : 'text-red-400 dark:text-red-600',
                  )}
                  strokeWidth={3}
                />
              </motion.div>
            ) : null}
          </div>
        ))}
      </div>
    </MotionSurface>
  );
}
