import { getGroupedIntervalsByNumberOfSemitones } from '@notacami/core/intervals';

export function getMergedIntervalScoringEntries(
  entries: [interval: string, numberOfCorrectAnswer: number][],
): {
  semitones: number;
  intervals: string[];
  sumOfCorrectAnswer: number;
}[] {
  const intervalList = entries.map((entry) => entry[0]);

  const groupedIntervaList =
    getGroupedIntervalsByNumberOfSemitones(intervalList);

  const mergedIntervalScoringEntriesList = groupedIntervaList.map(
    ([semitones, intervalList]) => ({
      semitones,
      intervals: intervalList,
      sumOfCorrectAnswer: entries.reduce(
        (acc, [interval, numberOfCorrectAnswer]) => {
          const isInIntervalList = intervalList.includes(interval);
          return acc + (isInIntervalList ? numberOfCorrectAnswer : 0);
        },
        0,
      ),
    }),
  );

  return mergedIntervalScoringEntriesList;
}
