import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { cn } from '@notacami/core/css';
import { MotionSurface } from './motion-surface';
import { SurfaceProps } from './surface';

// Inspired by https://github.com/motiondivision/motion/discussions/1884

export const SurfaceAutoResizable = forwardRef<HTMLDivElement, SurfaceProps>(
  ({ children, className, ...rest }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number | 'auto'>('auto');
    const [surfacePaddingHeight, setSurfacePaddingHeight] = useState<number>(0);

    const handleResize = (entry: ResizeObserverEntry) => {
      const observedHeight = entry.borderBoxSize?.[0].blockSize;
      setHeight(observedHeight ?? 'auto');
    };

    useEffect(() => {
      if (containerRef.current) {
        const observer = new ResizeObserver((entries) => {
          if (entries[0]) {
            handleResize(entries[0]);
          }
        });

        observer.observe(containerRef.current);

        return () => {
          observer.disconnect();
        };
      }
    }, []);

    const surfaceRef = useCallback((node: HTMLDivElement | null) => {
      if (node !== null && window) {
        const paddingTopSize = window
          .getComputedStyle(node, null)
          .getPropertyValue('padding-top');
        const paddingBottomSize = window
          .getComputedStyle(node, null)
          .getPropertyValue('padding-bottom');
        const newHeight =
          parseFloat(paddingTopSize) + parseFloat(paddingBottomSize);
        setSurfacePaddingHeight(newHeight ?? 0);
      }
    }, []);

    const computedHeight =
      height === 'auto' ? height : surfacePaddingHeight + height;

    return (
      <MotionSurface
        ref={(node) => {
          surfaceRef(node);
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        style={{ height: computedHeight }}
        animate={{ height: computedHeight }}
        transition={{ duration: 0.1 }}
        className={cn('overflow-hidden', className)}
        {...rest}
      >
        <div ref={containerRef}>{children}</div>
      </MotionSurface>
    );
  },
);

SurfaceAutoResizable.displayName = 'SurfaceAutoResizable';
