import { Control, Controller } from 'react-hook-form';
import { ReactNode } from 'react';
import { Surface } from '@notacami/ui';
import { QuizModeSelectorField } from '../../../../ui/form/quiz-mode-selector-field';
import { DurationField } from '../../../../ui/form/duration-field';
import { FormValues } from '../configuration';
import { GradientDivider } from '../../../../ui/gradient-divider';

type ConfigurationFormProps = {
  control: Control<FormValues>;
  title: ReactNode;
  instructions?: ReactNode;
  isShowingQuizModeSelector: boolean;
};

export function ConfigurationForm({
  control,
  title,
  instructions,
  isShowingQuizModeSelector,
}: ConfigurationFormProps) {
  return (
    <div className="vertical-content-distribution-lg min-h-full items-stretch justify-center">
      {title}
      <GradientDivider />
      <div className="vertical-content-distribution-base">
        {instructions}
        <Surface
          surfaceId="light"
          className="vertical-content-distribution-base rounded-2xl p-4"
        >
          {isShowingQuizModeSelector ? (
            <Controller
              name="quizMode"
              control={control}
              render={(data) => {
                return (
                  <QuizModeSelectorField
                    onChange={data.field.onChange}
                    value={data.field.value}
                    defaultValue={data.formState.defaultValues?.quizMode}
                  />
                );
              }}
            />
          ) : null}
          <Controller
            name="duration"
            control={control}
            render={({ field }) => (
              <DurationField
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
              />
            )}
          />
        </Surface>
      </div>
    </div>
  );
}
