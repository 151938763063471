import { ComponentPropsWithoutRef, ReactNode, forwardRef } from 'react';
import {
  getClassnamesByColorVariant,
  ColorVariant,
  SurfaceId,
  getClassnamesBySurfaceId,
} from '@notacami/core/design';
import { cn } from '@notacami/core/css';

export type SurfaceProps = {
  surfaceId?: SurfaceId;
  colorVariant?: ColorVariant;
  children?: ReactNode;
  className?: string;
  // Omit onAnimationStart to avoid type conflict when using onAnimationStart prop on MotionSurface
} & Omit<
  ComponentPropsWithoutRef<'div'>,
  'onAnimationStart' | 'onDrag' | 'onDragEnd' | 'onDragStart'
>;

export const Surface = forwardRef<HTMLDivElement, SurfaceProps>(
  ({ surfaceId, colorVariant, children, className, ...rest }, ref) => {
    const surfaceClassNames =
      surfaceId !== undefined ? getClassnamesBySurfaceId(surfaceId) : undefined;
    const colorVariantClassNames =
      colorVariant !== undefined
        ? getClassnamesByColorVariant(colorVariant)
        : undefined;

    return (
      <div
        {...rest}
        ref={ref}
        className={cn(
          'transition-colors duration-500',
          surfaceClassNames !== undefined && [
            surfaceClassNames.background,
            surfaceClassNames.text,
          ],
          colorVariantClassNames !== undefined && [
            colorVariantClassNames.background,
            colorVariantClassNames.text,
          ],
          className,
        )}
      >
        {children}
      </div>
    );
  },
);
Surface.displayName = 'Surface';
