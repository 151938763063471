import { useContext, useEffect, useState } from 'react';
import { FormFieldContainerLayout, Select } from '@notacami/ui';
import {
  LANGUAGE_PREFERENCE_OPTIONS,
  LanguagePreference,
} from '../../../../services/i18n';
import { useTranslation } from '../../../../hooks/use-translation';
import { ServicesContext } from '../../../../services/services.context';

export function LanguageSection() {
  const { i18n, preferenceLanguageStore: preferencesLanguageStore } =
    useContext(ServicesContext);
  const { t } = useTranslation();
  const [langPreference, setLangPreference] = useState<LanguagePreference>();

  const handleSelectChange = async (value: LanguagePreference) => {
    setLangPreference(value);
    await preferencesLanguageStore.set(value);
    i18n.updateLanguage(value);
  };

  useEffect(() => {
    async function getPersistedLanguagePreference() {
      const persistedLanguagePreference = await preferencesLanguageStore.get();
      setLangPreference(persistedLanguagePreference);
    }
    getPersistedLanguagePreference();
  }, []);

  const options = LANGUAGE_PREFERENCE_OPTIONS.map((languagePreference) => ({
    id: languagePreference,
    label: t(`pages.settings.general.language.option.${languagePreference}`),
    value: languagePreference,
  }));

  return (
    <>
      {langPreference !== undefined ? (
        <FormFieldContainerLayout
          id="language-setting-field"
          label={t('pages.settings.general.language.title')}
          input={
            <Select
              options={options}
              name="language-setting"
              onChange={handleSelectChange}
              value={langPreference}
              className="w-48"
            />
          }
        />
      ) : null}
    </>
  );
}
