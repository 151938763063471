import { Boundaries } from '@notacami/core/fretboard';
import { getAreNumbersAscending } from '../../utils/get-are-numbers-acsending';
import {
  MAX_FRET_DIFF_BY_SEGMENT,
  MAX_FRET_DIFF_BY_STRING,
  PositionWithInterval,
  ScalePositionType,
} from '../fretboard';

function getFretBoundariesOnScalePosition(
  boundariesOnSegmentAccumulator: Boundaries,
  currentPosition: PositionWithInterval,
): Boundaries {
  const currentFretIndex = currentPosition.position[1];

  return {
    min: Math.min(currentFretIndex, boundariesOnSegmentAccumulator.min),
    max: Math.max(currentFretIndex, boundariesOnSegmentAccumulator.max),
  };
}

export function getScalePositionType(
  intervalPositions: PositionWithInterval[],
): ScalePositionType {
  const boundaries = intervalPositions.reduce(
    getFretBoundariesOnScalePosition,
    { min: +Infinity, max: -Infinity },
  );

  const fretDiffOnScalePosition = boundaries.max - boundaries.min;

  const isCompact = fretDiffOnScalePosition < MAX_FRET_DIFF_BY_STRING;

  if (isCompact) {
    return 'compact';
  }

  const tonicAndOctavePositions = intervalPositions
    .filter(({ interval }) => ['1P', '8P'].includes(interval))
    .map(({ position }) => position[1]);

  const isFretIndexesAreFollowingForTonicAndOctave = getAreNumbersAscending(
    tonicAndOctavePositions,
  );

  const isLarge = fretDiffOnScalePosition >= MAX_FRET_DIFF_BY_SEGMENT;

  if (isFretIndexesAreFollowingForTonicAndOctave && isLarge) {
    return 'diagonal';
  }

  return 'other';
}
