import { useSearchParams } from 'react-router';
import { getIsScaleTypeName } from '@notacami/core/scales';
import { SCALE_SEARCH_PARAM } from '../../utils/routes.contants';
import { FindScalePosition } from '../../components/business/find-scale-position';

export function FindScalePositionPage() {
  const [searchParams] = useSearchParams();

  const scaleTypeNameInSearchParam = searchParams.get(SCALE_SEARCH_PARAM);

  const scaleTypeName =
    scaleTypeNameInSearchParam !== null &&
    getIsScaleTypeName(scaleTypeNameInSearchParam)
      ? scaleTypeNameInSearchParam
      : null;

  return <FindScalePosition scaleTypeName={scaleTypeName} />;
}
