import { createContext, useRef } from 'react';
import { createExerciseStore } from '../../../../services/exercise/exercise.store';
import {
  ExerciseProviderProps,
  ExerciseStoreApi,
} from '../../../../services/exercise/exercise.types';
import {
  FindTheNoteFromRootAndIntervalAnswer,
  FindTheNoteFromRootAndIntervalConfig,
  FindTheNoteFromRootAndIntervalQuestion,
  FindTheNoteFromRootAndIntervalQuestionMeta,
  FindTheNoteFromRootAndIntervalResultMeta,
} from '../types';
import { FindTheNoteFromRootAndIntervalProgressPayload } from '../../../../services/progress/find-the-note-from-root-and-interval/types';

export const FindTheNoteFromRootAndIntervalExerciseContext = createContext<
  ExerciseStoreApi<
    FindTheNoteFromRootAndIntervalConfig,
    FindTheNoteFromRootAndIntervalQuestion,
    FindTheNoteFromRootAndIntervalAnswer,
    FindTheNoteFromRootAndIntervalQuestionMeta,
    FindTheNoteFromRootAndIntervalResultMeta,
    FindTheNoteFromRootAndIntervalProgressPayload
  >
>(
  {} as ExerciseStoreApi<
    FindTheNoteFromRootAndIntervalConfig,
    FindTheNoteFromRootAndIntervalQuestion,
    FindTheNoteFromRootAndIntervalAnswer,
    FindTheNoteFromRootAndIntervalQuestionMeta,
    FindTheNoteFromRootAndIntervalResultMeta,
    FindTheNoteFromRootAndIntervalProgressPayload
  >,
);

export function FindTheNoteFromRootAndIntervalExerciseProvider({
  children,
  ...props
}: ExerciseProviderProps<
  FindTheNoteFromRootAndIntervalConfig,
  FindTheNoteFromRootAndIntervalQuestion,
  FindTheNoteFromRootAndIntervalAnswer,
  FindTheNoteFromRootAndIntervalQuestionMeta,
  FindTheNoteFromRootAndIntervalResultMeta,
  FindTheNoteFromRootAndIntervalProgressPayload
>) {
  const storeRef = useRef(createExerciseStore(props));

  return (
    <FindTheNoteFromRootAndIntervalExerciseContext.Provider
      value={storeRef.current}
    >
      {children}
    </FindTheNoteFromRootAndIntervalExerciseContext.Provider>
  );
}
