import { useCallback, useContext, useEffect, useReducer } from 'react';
import {
  EventInPart,
  GroupNoteEndEvent,
  GroupNoteStartEvent,
} from '@notacami/core/composer';
import { PartId } from '../../../../../../services/sequence/sequence.constants';
import { ServicesContext } from '../../../../../../services/services.context';
import { playingGroupNoteReducer } from './playing-group-note.reducer';

export function usePlayingGroupNote(
  partIdToUse: PartId,
  eventsInPart: EventInPart[],
) {
  const { soundPlayer } = useContext(ServicesContext);

  const firstGroupNoteStartEvent = eventsInPart
    .filter((event) => event.type === 'group-note-start')
    .find((event) => event.time === 0);

  const [state, dispatch] = useReducer(playingGroupNoteReducer, {
    firstPlayingGroup: firstGroupNoteStartEvent
      ? firstGroupNoteStartEvent
      : null,
    playingGroupNote: [],
  });

  const handleGroupNoteStart = useCallback(
    ({
      partId,
      groupNoteStartEvent,
    }: {
      partId: string;
      groupNoteStartEvent: GroupNoteStartEvent;
    }) => {
      if (partId === partIdToUse) {
        dispatch({
          type: 'GROUP_NOTE_START',
          groupNote: groupNoteStartEvent,
        });
      }
    },
    [partIdToUse],
  );

  const handleGroupNoteEnd = useCallback(
    ({
      partId,
      groupNoteEndEvent,
    }: {
      partId: string;
      groupNoteEndEvent: GroupNoteEndEvent;
    }) => {
      if (partId === partIdToUse) {
        dispatch({
          type: 'GROUP_NOTE_END',
          id: groupNoteEndEvent.id,
        });
      }
    },
    [partIdToUse],
  );

  const handleStop = useCallback(() => {
    dispatch({
      type: 'RESET',
    });
  }, []);

  useEffect(() => {
    soundPlayer.on('group-note-start', handleGroupNoteStart);
    soundPlayer.on('group-note-end', handleGroupNoteEnd);
    soundPlayer.on('stop', handleStop);

    return () => {
      soundPlayer.off('group-note-start', handleGroupNoteStart);
      soundPlayer.off('group-note-end', handleGroupNoteEnd);
      soundPlayer.off('stop', handleStop);
    };
  }, [handleGroupNoteStart, handleGroupNoteEnd, handleStop]);

  return state.playingGroupNote.length > 0 ? state.playingGroupNote[0] : null;
}
