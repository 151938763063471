import { AnimatePresence } from 'motion/react';
import { Position } from '@notacami/core/fretboard';
import { BuildCellsComponentProps } from '@notacami/ui-business/guitar-neck';
import {
  NeckCellNote,
  NeckCellSelect,
  PlayingAreaCell,
} from '@notacami/ui-business/neck-cells';
import { Fretboard } from '../../../../../services/fretboard';
import { QuizMode } from '../../../../../services/exercise/exercise.types';
import { useNoteNotation } from '../../../../../services/note-notation/use-note-notation';

type AnswerSectionPlayCellProps = BuildCellsComponentProps & {
  fretboard: Fretboard;
  showNeckHeadNotes: boolean;
  givenStringIndex: number;
  quizMode: QuizMode;
  onSelect: (position: Position) => void;
};

export function AnswerSectionPlayCell({
  position,
  isOpenString,
  getElementByPosition,
  isVertical,
  fretboard,
  showNeckHeadNotes,
  givenStringIndex,
  quizMode,
  onSelect,
}: AnswerSectionPlayCellProps) {
  const { n } = useNoteNotation();
  const fretboardNote = getElementByPosition(fretboard.noteDetails);

  return (
    <div className="relative flex flex-col items-center">
      {givenStringIndex === position[0] &&
      quizMode === 'guitar' &&
      !isOpenString ? (
        <PlayingAreaCell
          className="relative"
          isOpenString={isOpenString}
          isVertical={isVertical}
        />
      ) : null}
      {isOpenString && showNeckHeadNotes ? (
        <NeckCellNote
          isOpenString
          isVertical={isVertical}
          noteLabel={n(fretboardNote.pitchClass)}
          position={position}
        />
      ) : null}
      <AnimatePresence mode="popLayout">
        {givenStringIndex === position[0] &&
        quizMode === 'app' &&
        !isOpenString ? (
          <NeckCellSelect
            key="neck-cell-select"
            isOpenString={isOpenString}
            position={position}
            value={false}
            onSelect={onSelect}
            isVertical={isVertical}
          />
        ) : null}
      </AnimatePresence>
    </div>
  );
}
