import { ReactNode } from 'react';
import { Note } from 'tonal';
import { cn } from '@notacami/core/css';
import { FretboardSymbols } from './fretboard-symbols';
import { NeckBackground } from './neck-background';
import { NeckCells } from './neck-cells';
import { NeckStringNumbers } from './neck-string-numbers';
import { NeckFrets } from './neck-frets';
import {
  BuildCellsComponent,
  BuildCustomNeckHeadFactory,
} from './guitar-neck.types';
import { NeckStrings } from './neck-strings';

export type GuitarNeckProps<AdditionalCellProps> = {
  buildCellsComponent?: BuildCellsComponent<AdditionalCellProps>;
  buildCustomNeckHeadFactory?: BuildCustomNeckHeadFactory;
  buildFretsFactory?: (fretIndex: number) => ReactNode;
  highlightedStrings?: boolean[];
  isVertical?: boolean;
  leftHanded: boolean;
  numberOfFrets: number;
  showFretSymbols?: boolean;
  showStringNumbers?: boolean;
  tuning: string[];
  additionalCellProps?: AdditionalCellProps;
};

export function GuitarNeck<AdditionalCellProps>({
  buildCellsComponent,
  buildCustomNeckHeadFactory,
  buildFretsFactory,
  highlightedStrings,
  isVertical = false,
  leftHanded,
  numberOfFrets,
  showFretSymbols = true,
  showStringNumbers = true,
  tuning,
  additionalCellProps = {} as AdditionalCellProps,
}: GuitarNeckProps<AdditionalCellProps>) {
  const octaves = tuning.map((note) => Note.octave(note) ?? -1);
  const numberOfStrings = tuning.length;

  return (
    <div
      className={cn(
        'flex items-center justify-center',
        !isVertical && !leftHanded && 'flex-row',
        !isVertical && leftHanded && 'flex-row-reverse',
        isVertical && 'flex-col',
      )}
    >
      {showStringNumbers ? (
        <NeckStringNumbers
          highlightedStrings={highlightedStrings}
          isVertical={isVertical}
          leftHanded={leftHanded}
          numberOfStrings={numberOfStrings}
        />
      ) : null}
      <div
        className={cn(
          'relative select-none',
          !isVertical && 'w-fit',
          isVertical && 'h-fit',
          isVertical && 'flex flex-row',
          !isVertical && 'flex flex-col',
        )}
      >
        {showFretSymbols ? (
          <FretboardSymbols
            fretboardLength={numberOfFrets}
            isVertical={isVertical}
            leftHanded={leftHanded}
          />
        ) : null}
        <div
          className={cn(
            'relative rounded-xl',
            'border-2 border-solid border-neutral-900 dark:border-neutral-500',
            'bg-neutral-400 dark:bg-neutral-900',
          )}
        >
          <NeckBackground
            fretboardLength={numberOfFrets}
            numOfStrings={numberOfStrings}
            isVertical={isVertical}
            leftHanded={leftHanded}
          />
          {buildFretsFactory !== undefined ||
          buildCustomNeckHeadFactory !== undefined ? (
            <NeckFrets
              buildCustomNeckHeadFactory={buildCustomNeckHeadFactory}
              buildFretsFactory={buildFretsFactory}
              fretLength={numberOfFrets}
              isTransparentBorder={true}
              isVertical={isVertical}
              leftHanded={leftHanded}
            />
          ) : null}
          <NeckStrings
            leftHanded={leftHanded}
            octaves={octaves}
            isVertical={isVertical}
          />
          <NeckCells
            buildCellsComponent={buildCellsComponent}
            isVertical={isVertical}
            leftHanded={leftHanded}
            numberOfFrets={numberOfFrets}
            numberOfStrings={numberOfStrings}
            additionalCellProps={additionalCellProps}
          />
        </div>
        {showFretSymbols ? (
          <FretboardSymbols
            fretboardLength={numberOfFrets}
            isVertical={isVertical}
            leftHanded={leftHanded}
          />
        ) : null}
      </div>
    </div>
  );
}
