import {
  FindTheNoteFromRootAndIntervalConfig,
  FindTheNoteFromRootAndIntervalTurnQuestion,
} from '../../types';
import { computeQuestion } from './utils/compute-question';
import { computeCorrectAnswer } from './utils/compute-correct-answer';
import { computeQuestionMeta } from './utils/compute-question-meta';

export const computeQuizTurnQuestionAction = (
  config: FindTheNoteFromRootAndIntervalConfig,
  previousQuestions: FindTheNoteFromRootAndIntervalTurnQuestion[],
): FindTheNoteFromRootAndIntervalTurnQuestion => {
  const question = computeQuestion(config, previousQuestions);
  const correctAnswer = computeCorrectAnswer(question);
  const meta = computeQuestionMeta(correctAnswer);

  const questionTurn = {
    question,
    correctAnswer,
    meta,
    numberOfErrorsAllowed: 1,
  };

  return questionTurn;
};
