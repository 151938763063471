import { cn } from '@notacami/core/css';
import { getRangeNumberByLength } from '@notacami/core/utils';
import { StringNumber } from '../string-number';
import { Sizes } from './guitar-neck.constants';
import { NeckStringHighlight } from './neck-string-highlight';

type NeckStringNumbersProps = {
  highlightedStrings?: boolean[];
  isVertical: boolean;
  leftHanded: boolean;
  numberOfStrings: number;
};

export function NeckStringNumbers({
  highlightedStrings,
  isVertical,
  leftHanded,
  numberOfStrings,
}: NeckStringNumbersProps) {
  const highlighted = highlightedStrings || Array(numberOfStrings).fill(false);
  return (
    <div
      className={cn(
        'flex justify-center',
        !isVertical && 'flex-col-reverse',
        !leftHanded && isVertical && 'flex-row',
        leftHanded && isVertical && 'flex-row-reverse',
        isVertical ? 'h-6' : 'w-6',
      )}
    >
      {getRangeNumberByLength(numberOfStrings).map((stringIndex) => (
        <div
          key={stringIndex}
          className={cn(
            'flex items-center justify-center',
            !isVertical && Sizes.STRING_TRACK.h.h,
            isVertical && Sizes.STRING_TRACK.v.w,
          )}
        >
          {highlighted[stringIndex] ? (
            <div className={cn('absolute')}>
              <NeckStringHighlight />
            </div>
          ) : null}
          <StringNumber
            className="relative"
            stringNumber={numberOfStrings - stringIndex}
            selected
          />
        </div>
      ))}
    </div>
  );
}
