import { cn } from '@notacami/core/css';
import { NeckString } from './neck-string';

type NeckStringsProps = {
  isVertical: boolean;
  leftHanded: boolean;
  octaves: number[];
};

export function NeckStrings({
  isVertical,
  leftHanded,
  octaves,
}: NeckStringsProps) {
  return (
    <div
      className={cn(
        !isVertical && 'flex-col-reverse',
        isVertical && !leftHanded && 'flex-row',
        isVertical && leftHanded && 'flex-row-reverse',
        'absolute top-0 flex h-full w-full',
      )}
    >
      {octaves.map((octave, index) => {
        const id = `${octave}-${index}`;
        return <NeckString key={id} octave={octave} isVertical={isVertical} />;
      })}
    </div>
  );
}
