import { isPositionEqual, Position } from '@notacami/core/fretboard';
import { Segment, TonicAndOctavePosition } from '../fretboard';
import { getOctavePositionWithIntervalInSegment } from './get-octave-position-with-interval-in-segment';
import { getTonicAndOctavePositionBySegment } from './get-tonic-and-octave-positions-by-segment';
import { getTonicPositionWithIntervalInSegment } from './get-tonic-position-with-interval-in-segment';

function getTonicAndOctavePositionBySegments(segments: Segment[]) {
  return segments.map(getTonicAndOctavePositionBySegment);
}

function isOctaveSegmentIsTonicOfAnotherSegment(
  segmentsTonicPosition: Position[],
) {
  return (segment: Segment) => {
    const octavePosition = getOctavePositionWithIntervalInSegment(segment);

    return !segmentsTonicPosition.some((startPosition) =>
      isPositionEqual(startPosition, octavePosition),
    );
  };
}

function getSegmentTonicPositions(segments: Segment[]) {
  return segments.map(getTonicPositionWithIntervalInSegment);
}

export function getLeafPositionsBySegments(
  segments: Segment[],
): TonicAndOctavePosition[] {
  const segmentsTonicPosition = getSegmentTonicPositions(segments);

  const leafSegments = segments.filter(
    isOctaveSegmentIsTonicOfAnotherSegment(segmentsTonicPosition),
  );

  const leafTonicAndOctavePositions =
    getTonicAndOctavePositionBySegments(leafSegments);

  return leafTonicAndOctavePositions;
}
