import { useContext, useEffect } from 'react';
import { useStore } from 'zustand';
import { LessonContext } from '../../../../../../services/lesson';
import { DerivedTeacherListeningStep } from '../../../../../../services/lesson';
import { ServicesContext } from '../../../../../../services/services.context';
import { StepTeacherListening } from './step-teacher-listening';

type StepTeacherListeningWrapperProps = {
  step: DerivedTeacherListeningStep;
};

export function StepTeacherListeningWrapper({
  step,
}: StepTeacherListeningWrapperProps) {
  const store = useContext(LessonContext);

  const { teacher } = useContext(ServicesContext);

  const derivedLessonInfo = useStore(store, (state) => state.derivedLessonInfo);

  const fretboardNoteDetails = useStore(
    store,
    (state) => state.fretboardNoteDetails,
  );

  const updateProgressEntry = useStore(
    store,
    (state) => state.updateProgressEntry,
  );

  const handleStepToListenUpdated = ({
    currentRepetitionIndex,
  }: {
    currentRepetitionIndex: number;
  }) => {
    updateProgressEntry({
      id: step.id,
      type: step.type,
      numberOfRepetitions: currentRepetitionIndex,
    });
  };

  useEffect(() => {
    teacher.on('step-to-listen-updated', handleStepToListenUpdated);

    return () => {
      teacher.off('step-to-listen-updated', handleStepToListenUpdated);
    };
  }, []);

  return derivedLessonInfo !== null && fretboardNoteDetails !== null ? (
    <StepTeacherListening
      eventsInPart={step.eventsInPart}
      fretboardNoteDetails={fretboardNoteDetails}
      fretLength={derivedLessonInfo.fretLength}
      key={step.id}
      listeningSteps={step.listeningSteps}
      numberOfRepetitions={step.numberOfRepetitions}
      tuningId={derivedLessonInfo.tuningId}
    />
  ) : null;
}
