import { TuningId } from '@notacami/core/tuning';
import { QuizTurnQuestion, QuizTurnResult } from '../exercise/exercise.types';
import { AbstractProgressEntry } from './abstract-progress-entry';
import { ProgressSource } from './progress.source';
import { IProgressService } from './progress.types';

export class ProgressService<
  Question,
  Answer,
  QuestionMeta,
  ResultMeta,
  Payload,
  StoredPayload,
> implements
    IProgressService<Question, Answer, QuestionMeta, ResultMeta, Payload>
{
  constructor(
    private readonly store: ProgressSource<Payload, StoredPayload>,
    private readonly fromResult: (
      question: QuizTurnQuestion<Question, Answer, QuestionMeta>,
      result: QuizTurnResult<Answer, ResultMeta>,
    ) => AbstractProgressEntry<Payload, StoredPayload>,
  ) {}

  public async saveProgressFromResultsByTuningId(
    tuningId: TuningId,
    questions: QuizTurnQuestion<Question, Answer, QuestionMeta>[],
    results: QuizTurnResult<Answer, ResultMeta>[],
    sessionPracticeTime: number,
  ) {
    const entriesInStore =
      await this.store.getRecordEntriesByTuningId(tuningId);

    const upsertedEntries = this.getUpsertedRecordEntries(
      results,
      questions,
      entriesInStore,
    );

    await this.store.saveRecordDataByTuningId(
      tuningId,
      sessionPracticeTime,
      upsertedEntries,
    );
  }

  public async deleteRecordByTuningId(tuningId: TuningId) {
    await this.store.deleteRecordByTuningId(tuningId);
  }

  private getUpsertedRecordEntries(
    results: QuizTurnResult<Answer, ResultMeta>[],
    questions: QuizTurnQuestion<Question, Answer, QuestionMeta>[],
    entriesInStore: AbstractProgressEntry<Payload, StoredPayload>[],
  ) {
    return results.reduce<AbstractProgressEntry<Payload, StoredPayload>[]>(
      (entries, result, index) => {
        const entryFromResult = this.fromResult(questions[index], result);

        const existingEntryIndex = entries.findIndex(
          (entry) => entry.toOutcome().id === entryFromResult.toOutcome().id,
        );

        if (existingEntryIndex === -1) {
          return [...entries, entryFromResult];
        } else {
          const existingEntry = entries[existingEntryIndex];
          existingEntry.addCorrectAnswer();
          return entries;
        }
      },
      entriesInStore,
    );
  }

  public async getProgressDataByTuningId(tuningId: TuningId) {
    const entries = await this.store.getProgressDataByTuningId(tuningId);
    return entries;
  }
}
