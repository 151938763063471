import { QuizMode } from '../../../../../services/exercise/exercise.types';
import {
  FindScalePositionConfig,
  FindScalePositionQuestion,
  FindScalePositionTurnQuestion,
} from '../../types';
import { computeCorrectAnswer } from './utils/compute-correct-answer';
import { computeNumberOfErrorsAllowed } from './utils/compute-number-or-errors-allowed';
import { computeQuestion } from './utils/compute-question';

export const computeQuizTurnQuestionAction = (
  config: FindScalePositionConfig,
  previousQuestions: FindScalePositionTurnQuestion[],
  quizMode: QuizMode,
): FindScalePositionTurnQuestion => {
  const question: FindScalePositionQuestion = computeQuestion(
    config,
    previousQuestions,
  );

  const correctAnswer = question?.scalePosition
    ? computeCorrectAnswer(question.scalePosition.positions)
    : null;
  const numberOfErrorsAllowed =
    question === null
      ? 0
      : computeNumberOfErrorsAllowed(question.scalePosition, quizMode);

  const turnQuestion = {
    question,
    meta: undefined,
    correctAnswer,
    numberOfErrorsAllowed,
  };

  return turnQuestion;
};
