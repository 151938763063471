import { useContext } from 'react';
import { FormFieldContainerLayout, Select } from '@notacami/ui';
import {
  getTuningSummary,
  TuningId,
  TUNINGS_LIST,
} from '@notacami/core/tuning';
import { usePreferencesStore } from '../../preferences/use-preferences-context';
import { useTranslation } from '../../../../hooks/use-translation';
import { ServicesContext } from '../../../../services/services.context';

export function TuningSection() {
  const { preferenceTuningStore } = useContext(ServicesContext);
  const { t } = useTranslation();
  const changeTuning = usePreferencesStore((state) => state.setTuning);
  const tuning = usePreferencesStore((state) => state.tuningInfo);

  const handleChange = (value: TuningId) => {
    changeTuning(value);
    preferenceTuningStore.set(value);
  };

  const optgroups = TUNINGS_LIST.map((instrument) => ({
    id: instrument.name,
    label: t(`pages.settings.tuning.group.${instrument.name}`),
  }));

  const options = TUNINGS_LIST.flatMap((instrument) => {
    return instrument.tunings.map((tuning) => ({
      id: tuning.id,
      value: tuning.id,
      label: `${tuning.name}: ${getTuningSummary(tuning.notes)}`,
      optgroupId: instrument.name,
    }));
  });

  return (
    <FormFieldContainerLayout
      id="tuning-setting-field"
      label={t('pages.settings.tuning.title')}
      input={
        <Select
          name="tuning-setting"
          options={options}
          optgroups={optgroups}
          onChange={handleChange}
          value={tuning.id}
          className="w-36"
        />
      }
    />
  );
}
