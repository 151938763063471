import { FretSymbol } from '@notacami/core/fretboard';

const FRET_SYMBOLS: FretSymbol[] = [0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 2];

function repeatArray<P>(arr: P[], n: number): P[] {
  return Array(n).fill(arr).flat();
}

export function getFretboardSymbols(fretLength: number) {
  const num = Math.floor(fretLength - 1 / FRET_SYMBOLS.length) + 1;
  return repeatArray<FretSymbol>(FRET_SYMBOLS, num).slice(0, fretLength);
}
