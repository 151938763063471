import { useParams, useNavigate } from 'react-router';
import { useContext, useEffect } from 'react';
import { getIsValidLessonId } from '@notacami/core/lesson';
import { useHideTabBar } from '../components/ui/tab-bar.hooks';
import { Lesson } from '../components/business/lesson';
import { ServicesContext } from '../services/services.context';

export function LessonPage() {
  useHideTabBar();

  const { lessonProgress, stopWatch } = useContext(ServicesContext);

  const navigate = useNavigate();

  const params = useParams();
  const lessonIdInUrl = params.id;

  const isLessonIdInUrlValid = getIsValidLessonId(lessonIdInUrl);

  useEffect(() => {
    if (!isLessonIdInUrlValid) {
      navigate('/');
    }
  }, []);

  return isLessonIdInUrlValid ? (
    <Lesson
      lessonId={lessonIdInUrl}
      lessonProgress={lessonProgress}
      stopWatch={stopWatch}
    />
  ) : null;
}
