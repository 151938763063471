import { getRandomElement } from '@notacami/core/utils';
import { SelectableString } from '../../components/business/find-the-note-on-the-neck/types';

export function getRandomStringIndex(
  selectableStrings: SelectableString[],
  excludedStringIndex?: number,
) {
  const selectableStringsIndexSelected = selectableStrings
    .filter(({ selected }) => selected)
    .map(({ index }) => index);

  if (selectableStringsIndexSelected.length === 0) {
    throw new Error('Cannot get random string index when no string selected');
  }

  if (selectableStringsIndexSelected.length === 1) {
    return selectableStringsIndexSelected[0];
  }

  const filteredSelectableStringsIndexSelected =
    selectableStringsIndexSelected.filter(
      (stringIndex) => stringIndex !== excludedStringIndex,
    );

  return getRandomElement(filteredSelectableStringsIndexSelected);
}
