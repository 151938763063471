import { RouterProvider } from 'react-router/dom';
import * as SentryReact from '@sentry/react';
import { createBrowserRouter } from 'react-router';
import { PageUrls } from '../utils/routes.contants';
import { Root } from '../components/business/root';
import { HomePage } from '../pages/home-page';
import { PitchDetectionDebugPage } from '../pages/debug/pitch-detection-debug-page';
import { SettingsPage } from '../pages/settings';
import { NotesPage } from '../pages/notes/notes-page';
import { ScalesPage } from '../pages/scales/scales-page';
import { FindTheNoteOnTheNeckPage } from '../pages/notes/find-the-note-on-the-neck-page';
import { FindTheNoteFromRootAndIntervalPage } from '../pages/intervals/find-the-note-from-root-and-interval-page';
import { FindTheIntervalOnTheNeckPage } from '../pages/intervals/find-the-interval-on-the-neck-page';
import { SettingsGeneralPage } from '../pages/settings/general-page';
import { SettingsMusicPage } from '../pages/settings/music-page';
import { SettingsAdminPage } from '../pages/settings/admin-page';
import { VisualizeScalePositionsPage } from '../pages/scales/visualize-scale-positions-page';
import { VisualizeIntervalPositionsPage } from '../pages/intervals/visualize-interval-positions-page';
import { FindScalePositionPage } from '../pages/scales/find-scale-position-page';
import { SettingsInstrumentPage } from '../pages/settings/instrument-page';
import { IntervalsPage } from '../pages/intervals/intervals-page';
import { LessonPage } from '../pages/lesson';
import { NotFoundPage } from '../pages/not-found';
import { LessonCreationPage } from '../pages/lesson-creation';
import { NotePlayedDebugPage } from '../pages/debug/note-played-debug-page';
import { MicInfoPage } from '../pages/debug/mic-info-page';
import { SettingsUserAccountPage } from '../pages/settings/user-account-page';
import { SecurityPage } from '../pages/debug/security-page';

function getRouter() {
  const sentryCreateBrowserRouter =
    SentryReact.wrapCreateBrowserRouterV7(createBrowserRouter);

  const router = sentryCreateBrowserRouter([
    {
      path: PageUrls.HOME,
      element: <Root />,
      errorElement: <NotFoundPage />,
      children: [
        {
          index: true,
          element: <HomePage />,
        },
        {
          path: PageUrls.PITCH_DETECTION_DEBUG,
          element: <PitchDetectionDebugPage />,
        },
        {
          path: PageUrls.NOTE_PLAYED_DEBUG,
          element: <NotePlayedDebugPage />,
        },
        {
          path: PageUrls.MIC_INFO,
          element: <MicInfoPage />,
        },
        {
          path: PageUrls.SECURITY,
          element: <SecurityPage />,
        },
        // Settings
        {
          path: PageUrls.SETTINGS,
          element: <SettingsPage />,
        },
        {
          path: PageUrls.SETTINGS_GENERAL,
          element: <SettingsGeneralPage />,
        },
        {
          path: PageUrls.SETTINGS_MUSIC,
          element: <SettingsMusicPage />,
        },
        {
          path: PageUrls.SETTINGS_INSTRUMENT,
          element: <SettingsInstrumentPage />,
        },
        {
          path: PageUrls.SETTINGS_ADMIN,
          element: <SettingsAdminPage />,
        },
        {
          path: PageUrls.SETTINGS_USER_ACCOUNT,
          element: <SettingsUserAccountPage />,
        },
        // Thematic
        {
          path: PageUrls.NOTES,
          element: <NotesPage />,
        },
        {
          path: PageUrls.SCALES,
          element: <ScalesPage />,
        },
        {
          path: PageUrls.INTERVALS,
          element: <IntervalsPage />,
        },
        // Exercises
        {
          path: PageUrls.FIND_THE_NOTE_ON_THE_NECK,
          element: <FindTheNoteOnTheNeckPage />,
        },
        {
          path: PageUrls.FIND_THE_NOTE_FROM_ROOT_AND_INTERVAL,
          element: <FindTheNoteFromRootAndIntervalPage />,
        },
        {
          path: PageUrls.FIND_THE_INTERVAL_ON_THE_NECK,
          element: <FindTheIntervalOnTheNeckPage />,
        },
        {
          path: PageUrls.VISUALIZE_SCALE_POSITIONS,
          element: <VisualizeScalePositionsPage />,
        },
        {
          path: PageUrls.FIND_SCALE_POSITION,
          element: <FindScalePositionPage />,
        },
        {
          path: PageUrls.VISUALIZE_INTERVAL_POSITIONS,
          element: <VisualizeIntervalPositionsPage />,
        },
        {
          path: PageUrls.LESSON,
          element: <LessonPage />,
        },
        {
          path: PageUrls.LESSON_CREATION,
          element: <LessonCreationPage />,
        },
      ],
    },
  ]);
  return router;
}

export function RouterBoot() {
  return <RouterProvider router={getRouter()} />;
}
