import { useCallback, useContext, useEffect, useReducer, useRef } from 'react';
import {
  DEFAULT_FRET_LENGTH,
  isPositionEqual,
  Position,
} from '@notacami/core/fretboard';
import { GuitarNeck } from '@notacami/ui-business/guitar-neck';
import { useGuitarNeckVertical } from '../../../../../hooks/use-guitar-neck-vertical';
import { useLeftHanded } from '../../../../../services/fretboard';
import { usePreferencesStore } from '../../../preferences/use-preferences-context';
import { useTranslation } from '../../../../../hooks/use-translation';
import { PartId } from '../../../../../services/sequence/sequence.constants';
import {
  FindScalePositionAnswer,
  FindScalePositionConfig,
  FindScalePositionExistingAnswer,
  FindScalePositionExistingQuestion,
} from '../../types';
import { QuizMode } from '../../../../../services/exercise/exercise.types';
import { ServicesContext } from '../../../../../services/services.context';
import { ConsumersIds } from '../../../../../services/consumer';
import { AnswerSectionPlayCell } from './answer-section-play-cell';
import { INITIAL_STATE, reducer } from './answer-section-play.reducer';

type AnswerSectionPlayProps = {
  addError: (currentUserAnswer: FindScalePositionAnswer) => void;
  config: FindScalePositionConfig;
  correctAnswer: FindScalePositionExistingAnswer;
  hideAnswerTip: () => void;
  question: FindScalePositionExistingQuestion;
  quizMode: QuizMode;
  showAnswerTip: (answerTipText: string) => void;
  submitAnswer: (userAnswer: FindScalePositionAnswer) => void;
};

export function AnswerSectionPlay({
  addError,
  config,
  correctAnswer,
  hideAnswerTip,
  question,
  quizMode,
  showAnswerTip,
  submitAnswer,
}: AnswerSectionPlayProps) {
  const { notePlayed, notePlayedConsumer, soundPlayer, peakDetectionConsumer } =
    useContext(ServicesContext);
  const fretboard = usePreferencesStore((state) => state.fretboard);
  const { t } = useTranslation();

  const userAnswer = useRef<FindScalePositionAnswer>({
    way: [],
    wayBack: [],
  });

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const isVertical = useGuitarNeckVertical();
  const leftHanded = useLeftHanded();

  useEffect(() => {
    if (state.wayStepIndex === 0) {
      showAnswerTip(
        t(
          state.isWayBack
            ? `exercise.find-scale-position.${quizMode}.tip-way-back`
            : `exercise.find-scale-position.${quizMode}.tip-way`,
        ),
      );
    } else {
      hideAnswerTip();
    }
  }, [showAnswerTip, hideAnswerTip, t, state, quizMode]);

  const currentWayPositions = state.isWayBack
    ? correctAnswer.wayBack
    : correctAnswer.way;

  const currentExpectedPosition = currentWayPositions[state.wayStepIndex];
  const previouslyFoundPosition =
    currentWayPositions[Math.max(0, state.wayStepIndex - 1)];

  const isWayAndWayBackCompleted =
    state.isWayBack && state.wayStepIndex === currentWayPositions.length - 1;

  const isOneWayCompleted =
    !state.isWayBack && state.wayStepIndex === currentWayPositions.length - 1;

  const handleAddError = () => {
    if (state.wayStepIndex !== 0) {
      addError(userAnswer.current);
    }
  };

  const handleSelect = (position: Position) => {
    if (isPositionEqual(currentExpectedPosition, position)) {
      playSound(position);

      addPositionToUserAnswer(position, state.isWayBack);

      if (isWayAndWayBackCompleted) {
        submitAnswer(userAnswer.current);
        return;
      }

      if (isOneWayCompleted) {
        dispatch({ type: 'GO_WAY_BACK' });
        return;
      }

      dispatch({ type: 'GO_NEXT_STEP' });
    } else {
      handleAddError();
    }
  };

  const playSound = (position: Position) => {
    soundPlayer.playSequence(
      PartId.FIND_SCALE_POSITION,
      [
        {
          type: 'note',
          position,
          time: 0,
          duration: 0.25,
        },
      ],
      fretboard.noteDetails,
    );
  };

  const handleNoteStart = useCallback(
    ({ noteChroma }: { noteChroma: number }) => {
      const currentWayNoteChroma =
        fretboard.noteDetails[currentExpectedPosition[0]][
          currentExpectedPosition[1]
        ].chroma;
      const previouslyFoundWayNoteChroma =
        fretboard.noteDetails[previouslyFoundPosition[0]][
          previouslyFoundPosition[1]
        ].chroma;

      if (currentWayNoteChroma === noteChroma) {
        addPositionToUserAnswer(currentExpectedPosition, state.isWayBack);

        if (isWayAndWayBackCompleted) {
          submitAnswer(userAnswer.current);
          return;
        }

        if (isOneWayCompleted) {
          dispatch({ type: 'GO_WAY_BACK' });
          return;
        }

        dispatch({ type: 'GO_NEXT_STEP' });
      } else {
        if (
          state.wayStepIndex !== 0 ||
          previouslyFoundWayNoteChroma !== noteChroma
        ) {
          addError(userAnswer.current);
        }
      }
    },
    [
      question.positionsWithChromaAndMidi,
      isWayAndWayBackCompleted,
      isOneWayCompleted,
      previouslyFoundPosition,
      currentExpectedPosition,
      addError,
      fretboard,
      state,
      submitAnswer,
    ],
  );

  const addPositionToUserAnswer = (position: Position, isWayBack: boolean) => {
    if (userAnswer.current !== null) {
      const userWayPositions = userAnswer.current.way;
      const userWayBackPositions = userAnswer.current.wayBack;
      userAnswer.current = {
        way: isWayBack ? userWayPositions : [...userWayPositions, position],
        wayBack: isWayBack
          ? [...userWayBackPositions, position]
          : userWayBackPositions,
      };
    }
  };

  useEffect(() => {
    peakDetectionConsumer.addConsumer(ConsumersIds.FIND_SCALE_POSITION);
    notePlayedConsumer.addConsumer(ConsumersIds.FIND_SCALE_POSITION);
    notePlayed.on('note-start', handleNoteStart);

    return () => {
      peakDetectionConsumer.removeConsumer(ConsumersIds.FIND_SCALE_POSITION);
      notePlayedConsumer.removeConsumer(ConsumersIds.FIND_SCALE_POSITION);
      notePlayed.off('note-start', handleNoteStart);
    };
  }, [handleNoteStart]);

  const scalePosition = question.scalePosition;
  const playingAreaPositions = question.playingAreaPositions;

  return (
    <GuitarNeck
      numberOfFrets={DEFAULT_FRET_LENGTH}
      tuning={config.tuningInfo.notes}
      isVertical={isVertical}
      buildCellsComponent={AnswerSectionPlayCell}
      leftHanded={leftHanded}
      additionalCellProps={{
        arrowPosition: state.isWayBack
          ? question.arrowPositions.wayBack
          : question.arrowPositions.way,
        currentWay: state.isWayBack ? correctAnswer.wayBack : correctAnswer.way,
        playingAreaPositions,
        onSelect: handleSelect,
        quizMode,
        scalePosition,
        wayStepIndex: state.wayStepIndex,
      }}
    />
  );
}
