import { StateCreator, createStore } from 'zustand';
import { LessonInfo } from '@notacami/core/lesson';
import { getTuningInfo } from '@notacami/core/tuning';
import { getFretboardNoteDetailsByTuningAndFretLength } from '@notacami/core/fretboard';
import { IStopWatch } from '../stopwatch';
import {
  ILessonProgressService,
  LessonProgressEntry,
} from '../lesson-progress/lesson-progress.types';
import {
  CreateLessonStoreProps,
  DataSlice,
  ExerciseState,
  RuntimeSlice,
} from './lesson.types';
import { getDerivedLessonInfo } from './get-derived-lesson-info';

function computeDerivedLessonInfoAndFretboardNoteDetails(
  lessonInfo: LessonInfo,
) {
  const tuningInfo = getTuningInfo(lessonInfo.tuningId);
  const fretboardNoteDetails = getFretboardNoteDetailsByTuningAndFretLength(
    tuningInfo.notes,
    lessonInfo?.fretLength,
  );
  const derivedLessonInfo = getDerivedLessonInfo(
    lessonInfo,
    fretboardNoteDetails,
  );

  return { derivedLessonInfo, fretboardNoteDetails };
}

function getDataSlice(
  lessonInfo?: LessonInfo | null,
): StateCreator<ExerciseState, [], [], DataSlice> {
  let fretboardNoteDetails = null;
  let derivedLessonInfo = null;
  if (lessonInfo) {
    const computedLessonInfo =
      computeDerivedLessonInfoAndFretboardNoteDetails(lessonInfo);
    fretboardNoteDetails = computedLessonInfo.fretboardNoteDetails;
    derivedLessonInfo = computedLessonInfo.derivedLessonInfo;
  }
  return (set) => ({
    derivedLessonInfo,
    lessonInfo: lessonInfo ?? null,
    fretboardNoteDetails,
    updateLessonInfo: (lessonInfo: LessonInfo | null) => {
      if (lessonInfo !== null) {
        const { fretboardNoteDetails, derivedLessonInfo } =
          computeDerivedLessonInfoAndFretboardNoteDetails(lessonInfo);
        set({ fretboardNoteDetails, derivedLessonInfo, lessonInfo });
      } else {
        set({
          derivedLessonInfo: null,
          fretboardNoteDetails: null,
          lessonInfo,
        });
      }
    },
  });
}

function getRuntimeSlice(
  stopWatch: IStopWatch,
  lessonProgress: ILessonProgressService,
): StateCreator<ExerciseState, [], [], RuntimeSlice> {
  return (set, get) => ({
    mainStep: 'introduction',
    previousStepId: null,
    currentStepId: null,
    nextStepId: null,
    isInMicOnboarding: false,
    progressEntries: [],
    previousProgressInfo: null,
    currentProgressInfo: null,
    startup: () => {
      get().goToIntroduction();
    },
    goToIntroduction: () => {
      const derivedLessonInfo = get().derivedLessonInfo;

      if (derivedLessonInfo === null) return;

      const teacherListeningSteps = derivedLessonInfo.steps.filter(
        (step) => step.type === 'teacher-listening',
      );

      stopWatch.reset();

      set({
        mainStep: 'introduction',
        currentStepId: null,
        previousStepId: null,
        nextStepId: null,
        progressEntries: teacherListeningSteps.map(({ id }) => ({
          id,
          numberOfRepetitions: 0,
          type: 'teacher-listening',
        })),
      });
    },
    goToSteps: () => {
      stopWatch.resume();

      const derivedLessonInfo = get().derivedLessonInfo;

      if (derivedLessonInfo === null) {
        return;
      }

      if (derivedLessonInfo.steps?.[0]?.id !== undefined) {
        set({
          mainStep: 'steps',
          currentStepId: derivedLessonInfo.steps[0].id,
          nextStepId: derivedLessonInfo.steps?.[1]?.id || null,
          previousStepId: null,
        });
      }
    },
    goToEndConfirmation: () => {
      stopWatch.stop();
      set({ mainStep: 'end-confirmation' });
    },
    goToEndSummary: async () => {
      const derivedLessonInfo = get().derivedLessonInfo;

      if (derivedLessonInfo === null) return;

      stopWatch.stop();

      const lessonId = derivedLessonInfo.id;

      const stepsInfo = derivedLessonInfo.steps
        .filter((step) => step.type === 'teacher-listening')
        .map(({ id, type }) => ({ id, type }));

      const previousProgressInfo =
        await lessonProgress.getLessonProgressByLessonId(lessonId);

      const sessionPracticeTime = Math.round(stopWatch.getTime() / 1000);

      await lessonProgress.upsertProgressInfoByLessonId(
        lessonId,
        sessionPracticeTime,
        get().progressEntries,
        stepsInfo,
      );

      const currentProgressInfo =
        await lessonProgress.getLessonProgressByLessonId(lessonId);

      set({
        mainStep: 'end-summary',
        previousProgressInfo,
        currentProgressInfo,
      });
    },
    goToMicOnboarding: () =>
      set({ mainStep: 'mic-onboarding', isInMicOnboarding: true }),
    leaveMicOnboarding: () => {
      set({ isInMicOnboarding: false });
      get().goToSteps();
    },
    updateCurrentStepId: (currentStepId: string | null) => {
      if (currentStepId === null) {
        return set({
          currentStepId: null,
          previousStepId: null,
          nextStepId: null,
        });
      }
      const derivedLessonInfo = get().derivedLessonInfo;
      if (derivedLessonInfo === null) {
        return;
      }
      const stepIndex = derivedLessonInfo.steps.findIndex(
        ({ id }) => id === currentStepId,
      );
      const previousStepId =
        stepIndex === 0 ? null : derivedLessonInfo.steps[stepIndex - 1].id;

      const nextStepId =
        stepIndex === derivedLessonInfo.steps.length - 1
          ? null
          : derivedLessonInfo.steps[stepIndex + 1].id;

      set({ currentStepId, previousStepId, nextStepId });
    },
    askLeaveLesson: () => {
      get().goToEndConfirmation();
    },
    cancelLeaveLesson: () => {
      if (get().isInMicOnboarding) {
        get().goToMicOnboarding();
      } else {
        stopWatch.resume();
        set({
          mainStep: 'steps',
        });
      }
    },
    confirmLeaveLesson: async () => {
      get().goToEndSummary();
    },
    moveForward: () => {
      const derivedLessonInfo = get().derivedLessonInfo;
      const nextStepId = get().nextStepId;
      const nextStep =
        derivedLessonInfo !== null && nextStepId !== null
          ? derivedLessonInfo.steps.find(({ id }) => id === nextStepId)
          : undefined;

      if (nextStep !== undefined) {
        get().updateCurrentStepId(nextStepId);
      } else {
        get().goToEndSummary();
      }
    },
    updateProgressEntry: (updatedEntry: LessonProgressEntry) => {
      set((state) => ({
        progressEntries: state.progressEntries.map((entry) => {
          if (entry.id === updatedEntry.id) {
            return updatedEntry;
          } else {
            return entry;
          }
        }),
      }));
    },
  });
}

export function createLessonStore(props: CreateLessonStoreProps) {
  return createStore<ExerciseState>()((...a) => ({
    ...getDataSlice(props.lessonInfo)(...a),
    ...getRuntimeSlice(props.stopWatch, props.lessonProgress)(...a),
  }));
}
