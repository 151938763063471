import { LessonInfo } from '@notacami/core/lesson';
import { FretboardNoteDetails } from '@notacami/core/fretboard';
import { DerivedLessonInfo } from './lesson.types';
import { getDerivedStep } from './get-derived-step';

export function getDerivedLessonInfo(
  lessonInfo: LessonInfo,
  fretboardNoteDetails: FretboardNoteDetails,
): DerivedLessonInfo {
  const { steps, ...rest } = lessonInfo;

  const derivedSteps = steps.map((step) =>
    getDerivedStep(step, fretboardNoteDetails),
  );

  return {
    ...rest,
    steps: derivedSteps,
  };
}
