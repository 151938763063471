import mixpanel, { Dict, RequestOptions, Callback } from 'mixpanel-browser';
import { IDeviceService } from '../device';
import { IAnalyticsService } from './analytics.types';

export class AnalyticsMixpanelService implements IAnalyticsService {
  private initialized = false;

  constructor(private readonly deviceService: IDeviceService) {}

  public initialize() {
    const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN !== undefined;

    if (mixpanelToken) {
      mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
        debug: import.meta.env.DEV,
        track_pageview: false,
        persistence: 'localStorage',
        ignore_dnt: true,
      });

      this.initialized = true;
      this.sendStartupEvent();
    }
  }

  private async sendStartupEvent() {
    if (this.deviceService.getPlatform() !== 'web') {
      this.identify(this.deviceService.getIdentifier());
      this.track('App startup');
    }
  }

  private identify(unique_id?: string | undefined) {
    if (this.initialized) {
      mixpanel.identify(unique_id);
    }
  }

  public track(
    event_name: string,
    properties?: Dict | undefined,
    optionsOrCallback?: RequestOptions | Callback | undefined,
    callback?: Callback | undefined,
  ) {
    if (this.initialized) {
      mixpanel.track(event_name, properties, optionsOrCallback, callback);
    }
  }
}
