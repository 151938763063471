import { ReactNode, forwardRef } from 'react';
import { motion } from 'motion/react';

type TutorialStepContentProps = {
  content: ReactNode;
  action: ReactNode;
};

export const TutorialStepContent = forwardRef<
  HTMLDivElement,
  TutorialStepContentProps
>(({ content, action }, ref) => {
  return (
    <motion.div
      ref={ref}
      initial={{ x: 10, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -10, opacity: 0 }}
      className="absolute inset-0 flex min-h-full flex-col items-center justify-between gap-4 p-5"
    >
      <div className="flex grow flex-col items-center justify-center gap-4">
        {content}
      </div>
      {action}
    </motion.div>
  );
});

TutorialStepContent.displayName = 'TutorialStepContent';
