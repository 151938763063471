import { LessonInfo } from '@notacami/core/lesson';
import { LESSON_BASE, SEQUENCE_SAMPLE } from './demo-lesson-base';

export const DEMO_LESSON_EN_FR: LessonInfo = {
  ...LESSON_BASE,
  id: 'demo-lesson-en-fr',
  steps: [
    {
      id: 'teacher-speaking-0',
      type: 'teacher-speaking',
      text: 'Quand tu joues la **gamme majeure** dans sa **première position**, tu travailles tes doigts sur plusieurs cases tout en développant ta dextérité.',
    },
    {
      id: 'teacher-speaking-1',
      type: 'teacher-speaking',
      text: "Une fois que tu maîtrises la **première position** de la **gamme majeure**, tu as déjà un point de départ pour l'improvisation.",
    },
    {
      id: 'teacher-speaking-2',
      type: 'teacher-speaking',
      text: 'Je vais te montrer comment jouer cette position.',
    },
    {
      id: 'teacher-playing-0',
      type: 'teacher-playing',
      sequence: SEQUENCE_SAMPLE,
      bpm: 60,
    },
    {
      id: 'teacher-speaking-3',
      type: 'teacher-speaking',
      text: 'À toi de jouer.',
    },
    {
      id: 'teacher-listening-0',
      type: 'teacher-listening',
      sequence: SEQUENCE_SAMPLE,
      numberOfRepetitions: 20,
      title: 'Gamme de La majeure',
    },
    {
      id: 'teacher-speaking-4',
      type: 'teacher-speaking',
      text: "Quand tu joues les notes d'une **gamme majeure**, tu commences à identifier les relations entre elles. Tu vas entendre comment une note s'enchaîne à une autre. Avec le temps, cela améliore ton **oreille musicale**, ce qui est super utile pour jouer à l'oreille et comprendre les morceaux sans forcément regarder des partitions.",
    },
  ],
};
