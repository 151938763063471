import { ReactNode } from 'react';
import { cn } from '@notacami/core/css';
import { Sizes } from './guitar-neck.constants';

type NeckFretProps = {
  buildFretsFactory?: (fretIndex: number) => ReactNode;
  fretIndex: number;
  isLast: boolean;
  isVertical: boolean;
  isTransparentBorder: boolean;
  leftHanded: boolean;
};

export function NeckFret({
  buildFretsFactory,
  isTransparentBorder,
  fretIndex,
  isLast,
  isVertical,
  leftHanded,
}: NeckFretProps) {
  return (
    <div
      className={cn(
        'relative',
        isTransparentBorder
          ? 'border-transparent'
          : 'border-neutral-200/80 dark:border-neutral-400/80',
        !isVertical && Sizes.NECK_FRET_CELL_BY_STRING.h.w,
        isVertical && Sizes.NECK_FRET_CELL_BY_STRING.v.h,
        !isLast && 'box-border',
        !isLast && !isVertical && !leftHanded && 'border-r-2',
        !isLast && !isVertical && leftHanded && 'border-l-2',
        !isLast && isVertical && 'border-b-2',
      )}
    >
      {buildFretsFactory ? buildFretsFactory(fretIndex) : null}
    </div>
  );
}
