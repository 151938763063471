export const UNALTERED_NOTES: string[] = ['C', 'D', 'E', 'F', 'G', 'A', 'B'];

export const UNALTERED_NOTES_LATIN: string[] = [
  'Do',
  'Re',
  'Mi',
  'Fa',
  'Sol',
  'La',
  'Si',
];

export const UNALTERED_AND_ALTERED_NOTES: string[] = [
  'Cb',
  'C',
  'C#',
  'Db',
  'D',
  'D#',
  'Eb',
  'E',
  'E#',
  'Fb',
  'F',
  'F#',
  'Gb',
  'G',
  'G#',
  'Ab',
  'A',
  'A#',
  'Bb',
  'B',
  'B#',
];

export const UNALTERED_NOTES_SEMITONES: Record<string, number> = {
  C: 0,
  D: 2,
  E: 4,
  F: 5,
  G: 7,
  A: 9,
  B: 11,
};

export const ALTERED_NOTES: string[] = [
  'Cb',
  'C#',
  'Db',
  'D#',
  'Eb',
  'E#',
  'Fb',
  'F#',
  'Gb',
  'G#',
  'Ab',
  'A#',
  'Bb',
  'B#',
];

export const DEFAULT_NOTES_POSITION_FOR_SCALE: string[] = [
  'C',
  'C#',
  'D',
  'Eb',
  'E',
  'F',
  'F#',
  'G',
  'Ab',
  'A',
  'Bb',
  'B',
];
