import { getHueByNoteChroma } from '@notacami/core/notes';
import { MaximaPeakInfo } from '../../../services/peak-detection';

const RECT_HEIGHT = 4;

export function drawMaximaPeaks(
  ctx: CanvasRenderingContext2D,
  maximaPeaks: MaximaPeakInfo[],
  now: number,
  CANVAS_WIDTH: number,
  CANVAS_HEIGHT: number,
  X_AXIS_RATIO: number,
  Y_AXIS_RATIO: number,
  MIN_LEVEL: number,
  isOusideBoundaries: boolean,
) {
  maximaPeaks.forEach(({ time: x, level: y, width, noteChroma }) => {
    ctx.fillStyle = getHueByNoteChroma(
      noteChroma,
      isOusideBoundaries ? 0.5 : 1,
    );
    const realX = CANVAS_WIDTH - (now - x) / X_AXIS_RATIO;
    const realY = CANVAS_HEIGHT - (y - MIN_LEVEL) / Y_AXIS_RATIO;

    ctx.fillRect(
      Math.round(realX),
      Math.round(realY + RECT_HEIGHT),
      Math.round(width / X_AXIS_RATIO),
      RECT_HEIGHT,
    );
  });
}
