import { MotionSurface } from '@notacami/ui';
import { MdxComponent } from '../../../../mdx-component';
import { Badge } from '../../../../../ui/badge';

type StepTeacherSpeakingProps = {
  text: string;
};

export function StepTeacherSpeaking({ text }: StepTeacherSpeakingProps) {
  return (
    <MotionSurface
      surfaceId="app"
      className="relative max-w-md rounded-xl px-4 py-2"
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
    >
      <Badge surfaceId="soft-invert" className="absolute -top-8 right-0">
        prof Julien
      </Badge>
      <MdxComponent text={text} />
    </MotionSurface>
  );
}
