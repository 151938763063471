import { Hourglass } from 'lucide-react';
import { motion } from 'motion/react';
import {
  getClassnamesByColorVariant,
  getColorVariantByThematic,
} from '@notacami/core/design';
import { cn } from '@notacami/core/css';
import { Thematic } from '@notacami/core/thematic';
import { formatMsToMMSSWithoutLeadingZero } from '@notacami/core/utils';

type ProgressProps = {
  time: number;
  isPaused?: boolean;
  thematic: Thematic;
  onClick?: () => void;
};

export function Progress({
  time,
  thematic,
  isPaused = false,
  onClick,
}: ProgressProps) {
  const formattedTime = formatMsToMMSSWithoutLeadingZero(time);
  const classnames = getClassnamesByColorVariant(
    getColorVariantByThematic(thematic),
  );

  const variants = {
    visible: { opacity: 1 },
    hidden: {
      opacity: [1, 0.2, 1],
      transition: { repeat: Infinity, repeatDelay: 0.2 },
    },
  };

  return (
    <button
      className={cn(
        'flex items-center justify-center gap-1 rounded-3xl border-2 bg-neutral-600 px-3 py-2 tabular-nums dark:bg-neutral-700',
        classnames.border,
        'text-neutral-100',
      )}
      type="button"
      onClick={onClick}
      data-testid="progress-time"
    >
      <motion.div
        animate={{ rotate: isPaused ? 0 : 180 }}
        transition={{
          repeat: isPaused ? 0 : Infinity,
          repeatDelay: 5,
          repeatType: 'mirror',
        }}
      >
        <Hourglass className="h-4" strokeWidth={3} />
      </motion.div>
      <motion.div
        className="h-4 pt-px leading-none"
        variants={variants}
        animate={time === 0 ? 'hidden' : 'visible'}
      >
        {formattedTime}
      </motion.div>
    </button>
  );
}
