import { Note } from 'tonal';
import { NoteName } from '@notacami/core/notes';
import { Interval } from '../intervals';

export function getNoteFromRootAndInterval(
  root: NoteName,
  interval: Interval,
): NoteName {
  const note = Note.transpose(root, interval);

  return note;
}
