import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { cn } from '@notacami/core/css';
import { DetailedNoteDetect } from '../../../../../services/tuner';
import { ServicesContext } from '../../../../../services/services.context';
import { ConsumersIds } from '../../../../../services/consumer';

export function TunerCentsOffThumb() {
  const { tuner, tunerConsumer } = useContext(ServicesContext);
  const resetTimeoutIdRef = useRef<number>();
  const [currentNotePlayed, setNotePlayed] = useState<DetailedNoteDetect>();

  const handleDetailedNoteDetect = useCallback(
    ({ note }: { note: DetailedNoteDetect }) => {
      setNotePlayed(note);

      if (resetTimeoutIdRef.current) {
        clearTimeout(resetTimeoutIdRef.current);
      }
      resetTimeoutIdRef.current = window.setTimeout(resetData, 500);
    },
    [],
  );

  const resetData = () => {
    setNotePlayed(undefined);
  };

  useEffect(() => {
    tuner.on('detailed-note-detect', handleDetailedNoteDetect);
    tunerConsumer.addConsumer(ConsumersIds.TUNER_CENTS_OFF);

    return () => {
      tuner.off('detailed-note-detect', handleDetailedNoteDetect);
      tunerConsumer.removeConsumer(ConsumersIds.TUNER_CENTS_OFF);
    };
  }, [handleDetailedNoteDetect]);

  const leftPercentage = currentNotePlayed?.centsOff
    ? currentNotePlayed.centsOff + 50
    : 50;

  const boundedLeftPercentage = Math.min(Math.max(leftPercentage, 0), 100);

  return (
    <div className="px-1">
      <div className="relative h-0 w-full">
        <div
          style={{ left: boundedLeftPercentage + '%' }}
          className="shadow-xs absolute top-4 z-20 -ml-1 flex h-4 w-2 items-center justify-center rounded-full border border-white bg-white shadow-white transition-all"
        >
          <div
            className={cn(
              currentNotePlayed?.centsOff ? 'bg-primary' : 'bg-neutral-500',
              'h-2 w-1 rounded-full',
            )}
          ></div>
        </div>
      </div>
    </div>
  );
}
