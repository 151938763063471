import { useStore } from 'zustand';
import { ExerciseStoreApi } from '../exercise.types';

export function useResult<
  Config,
  Question,
  Answer,
  ResultMeta,
  RoundMeta,
  ProgressPayload,
>(
  store: ExerciseStoreApi<
    Config,
    Question,
    Answer,
    ResultMeta,
    RoundMeta,
    ProgressPayload
  >,
) {
  const currentTurn = useStore(store, (state) => state.currentTurn);
  const results = useStore(store, (state) => state.results);

  const result = results[currentTurn - 1];

  if (result === undefined) {
    return null;
  }

  return result;
}
