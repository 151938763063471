import { useContext, useState } from 'react';
import { SliderInputWithLabelAndValue } from '@notacami/ui';
import { ServicesContext } from '../../../services/services.context';
import {
  BOUNDARIES_MAX_MAX,
  BOUNDARIES_MAX_MIN,
  BOUNDARIES_MIN_MAX,
  BOUNDARIES_MIN_MIN,
  BOUNDARIES_STEP,
} from '../../../services/peak-detection';

export function PeakDetectionControlsBoundaries() {
  const { peakDetection } = useContext(ServicesContext);
  const [boundariesMin, setBoundariesMin] = useState(
    peakDetection.getBoundaries().min,
  );
  const [boundariesMax, setBoundariesMax] = useState(
    peakDetection.getBoundaries().max,
  );

  const handleBoundariesMinChange = (value: number) => {
    setBoundariesMin(value);
    peakDetection.applyBoundariesMin(value);
  };

  const handleBoundariesMaxChange = (value: number) => {
    setBoundariesMax(value);
    peakDetection.applyBoundariesMax(value);
  };

  return (
    <div className="flex w-full flex-col">
      <strong className="text-sm">Boundaries</strong>

      <div className="w-full columns-1 sm:columns-2">
        <SliderInputWithLabelAndValue
          ariaLabel="Boundaries Min"
          label={<div className="whitespace-nowrap text-xs">Min</div>}
          min={BOUNDARIES_MIN_MIN}
          max={BOUNDARIES_MIN_MAX}
          step={BOUNDARIES_STEP}
          onChange={handleBoundariesMinChange}
          thematic="notes"
          value={boundariesMin}
        />

        <SliderInputWithLabelAndValue
          ariaLabel="Boundaries Max"
          label={<div className="whitespace-nowrap text-xs">Max</div>}
          min={BOUNDARIES_MAX_MIN}
          max={BOUNDARIES_MAX_MAX}
          step={BOUNDARIES_STEP}
          onChange={handleBoundariesMaxChange}
          thematic="notes"
          value={boundariesMax}
        />
      </div>
    </div>
  );
}
