import { Interval } from 'tonal';
import { FretboardNoteDetails } from '@notacami/core/fretboard';
import {
  FretboardIntervals,
  getFretboardReachablePositionsByRootPosition,
  MAX_FRET_DIFF_FOR_PRECOMPUTE_INTERVALS,
} from '../fretboard';

export function getFretboardIntervalsForEachPosition(
  fretboardNoteDetails: FretboardNoteDetails,
): FretboardIntervals {
  const reachablePositionsOnEachFretboardPosition = fretboardNoteDetails.map(
    (_, stringIndex) =>
      _.map((__, fretIndex) =>
        getFretboardReachablePositionsByRootPosition(
          fretboardNoteDetails,
          [stringIndex, fretIndex],
          MAX_FRET_DIFF_FOR_PRECOMPUTE_INTERVALS,
        ),
      ),
  );

  const reachableIntervalsOnEachFretboardPosition =
    reachablePositionsOnEachFretboardPosition.map((_, stringIndex) =>
      _.map((reachablePositions, fretIndex) =>
        reachablePositions.map((reachablePosition) => {
          const rootNoteName =
            fretboardNoteDetails[stringIndex][fretIndex].name;
          const targetNoteName =
            fretboardNoteDetails[reachablePosition[0]][reachablePosition[1]];

          const distanceInterval = Interval.distance(
            rootNoteName,
            targetNoteName,
          );
          const distanceInSemitones = Interval.semitones(distanceInterval);
          const regularInterval = Interval.fromSemitones(distanceInSemitones);

          const intervalInfo = Interval.get(regularInterval);

          return {
            position: reachablePosition,
            interval: regularInterval,
            semitones: intervalInfo.semitones,
            simplifiedInterval: Interval.fromSemitones(
              intervalInfo.dir === -1
                ? Math.abs((12 * 10 + intervalInfo.semitones) % 12)
                : Math.abs(intervalInfo.semitones % 12),
            ),
            direction: intervalInfo.dir,
          };
        }),
      ),
    );

  return reachableIntervalsOnEachFretboardPosition;
}
