import { FormFieldContainerLayout } from '@notacami/ui';
import { useTranslation } from '../../../../hooks/use-translation';
import { Badge } from '../../../ui/badge';

export function MonitoringSection() {
  const { t } = useTranslation();
  const viteSentryDsn = import.meta.env.VITE_SENTRY_DSN;

  return (
    <FormFieldContainerLayout
      id="sentry-dsn-field"
      label="Sentry DSN"
      isVertical
      input={
        <>
          {viteSentryDsn === undefined ? (
            <Badge surfaceId="darker">{t('not-defined')}</Badge>
          ) : (
            <Badge
              surfaceId="soft-invert"
              className="max-w-72 overflow-x-scroll"
            >
              {viteSentryDsn}
            </Badge>
          )}
        </>
      }
    />
  );
}
