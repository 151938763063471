import { FormFieldContainerLayout } from '@notacami/ui';
import { Badge } from '../../../ui/badge';

export function ViteSection() {
  const mode = import.meta.env.MODE;

  return (
    <FormFieldContainerLayout
      id="vite-mode-field"
      label="Vite Mode"
      input={
        <Badge colorVariant={mode === 'production' ? 'red' : 'emerald'}>
          {mode}
        </Badge>
      }
    />
  );
}
