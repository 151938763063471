import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { cn } from '@notacami/core/css';
import { DetailedNoteDetect } from '../../../../services/tuner';
import { useNoteNotation } from '../../../../services/note-notation/use-note-notation';
import { ServicesContext } from '../../../../services/services.context';
import { ConsumersIds } from '../../../../services/consumer';
import { TunerHeadCentsOffIndicator } from './tuner-head/tuner-head-cents-off-indicator';
import { getIndicatorVariant } from './tuner-head/utils';

export function TunerHead() {
  const { pitchDetection, tuner, tunerConsumer, pitchDetectionConsumer } =
    useContext(ServicesContext);
  const { n, noteNotation } = useNoteNotation();
  const resetTimeoutIdRef = useRef<number>();
  const [currentNotePlayed, setCurrentNotePlayed] =
    useState<DetailedNoteDetect>();

  const handleDetailedNoteDetect = useCallback(
    ({ note }: { note: DetailedNoteDetect }) => {
      setCurrentNotePlayed(note);

      if (resetTimeoutIdRef.current) {
        clearTimeout(resetTimeoutIdRef.current);
      }
      resetTimeoutIdRef.current = window.setTimeout(resetData, 2000);
    },
    [],
  );

  const handleMicClose = useCallback(() => {
    resetData();
  }, []);

  const resetData = () => {
    setCurrentNotePlayed(undefined);
  };

  useEffect(() => {
    tuner.on('detailed-note-detect', handleDetailedNoteDetect);
    pitchDetection.on('mic-close', handleMicClose);
    tunerConsumer.addConsumer(ConsumersIds.TUNER_CENTS_OFF);
    pitchDetectionConsumer.addConsumer(ConsumersIds.TUNER_CENTS_OFF);

    return () => {
      tuner.off('detailed-note-detect', handleDetailedNoteDetect);
      pitchDetection.off('mic-close', handleMicClose);
      tunerConsumer.removeConsumer(ConsumersIds.TUNER_CENTS_OFF);
      pitchDetectionConsumer.removeConsumer(ConsumersIds.TUNER_CENTS_OFF);
    };
  }, [handleMicClose, handleDetailedNoteDetect]);

  const flatIndicatorVariant = getIndicatorVariant(
    currentNotePlayed?.centsOff,
    true,
  );
  const sharpIndicatorVariant = getIndicatorVariant(
    currentNotePlayed?.centsOff,
    false,
  );

  return (
    <div className="z-20 flex items-end gap-2">
      <TunerHeadCentsOffIndicator symbol="♭" variant={flatIndicatorVariant} />

      <div className="relative flex h-16 w-16 items-center justify-center rounded-xl border-2 border-neutral-300 bg-neutral-100 shadow-xl shadow-neutral-900/20">
        <span
          className={cn(
            noteNotation === 'english' ? 'text-3xl' : 'text-2xl',
            'text-neutral-700',
          )}
        >
          {currentNotePlayed?.name ? n(currentNotePlayed.name) : '-'}
        </span>
        <span className="absolute bottom-0 block text-xs text-neutral-600">
          {currentNotePlayed?.octave || '-'}
        </span>
      </div>

      <TunerHeadCentsOffIndicator symbol="♯" variant={sharpIndicatorVariant} />
    </div>
  );
}
