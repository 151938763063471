import { motion } from 'motion/react';
import { forwardRef } from 'react';
import { cn } from '@notacami/core/css';
import {
  getClassnamesByColorVariant,
  getColorVariantByThematic,
} from '@notacami/core/design';
import { Thematic } from '@notacami/core/thematic';
import { useTranslation } from '../../../../../hooks/use-translation';

type QuestionScoringResultProps = {
  thematic: Thematic;
};

export const QuestionScoringResult = forwardRef<
  HTMLDivElement,
  QuestionScoringResultProps
>(({ thematic }, ref) => {
  const { t } = useTranslation();

  const colorVariant = getColorVariantByThematic(thematic);
  const classNames = getClassnamesByColorVariant(colorVariant);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={cn(
        'relative whitespace-nowrap px-2 text-xs font-semibold',
        classNames.background,
        classNames.text,
        'h-6 rounded-full',
        'flex items-center',
      )}
    >
      {t(`components.question-scoring-result.well-done`)}
    </motion.div>
  );
});
QuestionScoringResult.displayName = 'QuestionScoringResult';
