import { ScalePosition } from '../fretboard';

export function keepOnlyDiagonalAndCompactScalePositionWhenPossible(
  scalePositions: ScalePosition[],
): ScalePosition[] {
  const hasAtLeastOneCompactOrDiagonalScalePosition = scalePositions.some(
    ({ type }) => ['diagonal', 'compact'].includes(type),
  );

  if (!hasAtLeastOneCompactOrDiagonalScalePosition) {
    return scalePositions;
  }
  const filteredScalePosition = scalePositions.filter(
    ({ type }) => type !== 'other',
  );

  return filteredScalePosition;
}
