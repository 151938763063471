import { LogoText, Title } from '@notacami/ui';
import { Settings2 } from 'lucide-react';
import { PageUrls } from '../utils/routes.contants';
import { useShowTabBar } from '../components/ui/tab-bar.hooks';
import { HomePageContent } from '../components/business/home-page-content';
import { PageLayout } from '../components/business/page-layout';
import { LogoDevBadge } from '../components/business/logo-dev-badge';
import { WrappedLink } from '../components/ui/wrapped-haptic';

export function HomePage() {
  useShowTabBar();

  return (
    <PageLayout
      title={
        <div className="flex items-center gap-2">
          <Title level={1} tagName="h1">
            <LogoText className="text-3xl" />
          </Title>
          <LogoDevBadge />
        </div>
      }
      rightAction={
        <WrappedLink
          to={PageUrls.SETTINGS}
          buttonVariant="link"
          buttonSize="icon"
        >
          <Settings2 size={30} />
        </WrappedLink>
      }
      content={<HomePageContent />}
    />
  );
}
