import { Title, TitleProps } from '@notacami/ui';

type TitleWithTextAboveProps = {
  textAbove: string;
  titleProps: TitleProps;
};

export function TitleWithTextAbove({
  textAbove,
  titleProps,
}: TitleWithTextAboveProps) {
  return (
    <div className="flex flex-col justify-start gap-0">
      <span className="text-xs">{textAbove}</span>
      <Title {...titleProps} />
    </div>
  );
}
