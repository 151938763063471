import { cn } from '@notacami/core/css';

type LogoTextProps = {
  className?: string;
  type?: 'default' | 'monochrome' | 'on-primary';
};

export function LogoText({ className, type = 'default' }: LogoTextProps) {
  return (
    <span
      className={cn(
        'text-color-title font-semibold',
        type === 'on-primary' && 'text-lighter',
        className,
      )}
    >
      <span
        className={cn(
          'bg-gradient-to-r bg-clip-text text-transparent',
          type === 'default' && [
            'from-indigo-700 to-indigo-400',
            'dark:from-indigo-400 dark:to-indigo-600',
          ],
          type === 'on-primary' && 'from-indigo-100 to-indigo-200',
          type === 'monochrome' && [
            'from-neutral-600 via-neutral-500 to-neutral-500/80',
            'dark:from-neutral-200 dark:via-neutral-400 dark:to-neutral-500',
          ],
        )}
      >
        nota
      </span>
      camí
    </span>
  );
}
