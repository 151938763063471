import { Badge } from '../ui/badge';

function shortenMode(mode: string) {
  switch (mode) {
    case 'development':
      return 'dev';
    default:
      return mode;
  }
}

export function LogoDevBadge() {
  const mode = import.meta.env.MODE;

  return mode !== 'production' ? (
    <Badge colorVariant="yellow" className="animate-bounce">
      {shortenMode(mode)}
    </Badge>
  ) : null;
}
