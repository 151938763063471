import { useContext, useEffect } from 'react';
import { ServicesContext } from '../services/services.context';

export function useWindowFocusAndBlur(onFocus: () => void, onBlur: () => void) {
  const { windowFocusAndBlur } = useContext(ServicesContext);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      windowFocusAndBlur.on('focus', onFocus);
      windowFocusAndBlur.on('blur', onBlur);
    }
    return () => {
      if (typeof window !== 'undefined') {
        windowFocusAndBlur.off('focus', onFocus);
        windowFocusAndBlur.off('blur', onBlur);
      }
    };
  }, [onFocus, onBlur]);
}
