import { Preferences } from '@capacitor/preferences';
import { IStore } from './storage.type';

export class StoreCapacitor<T> implements IStore<T> {
  constructor(
    private readonly key: string,
    private readonly defaultValue: T,
    private readonly serialize: (value: T) => string,
    private readonly deserialize: (value: string) => T,
  ) {}

  public async set(value: T): Promise<void> {
    const stringifiedValue = this.serialize(value);
    await Preferences.set({
      key: this.key,
      value: stringifiedValue,
    });
  }

  public async get(): Promise<T> {
    const { value } = await Preferences.get({
      key: this.key,
    });
    if (value !== null) {
      return this.deserialize(value);
    }
    return this.defaultValue;
  }

  public async delete(): Promise<void> {
    await Preferences.remove({ key: this.key });
  }
}
