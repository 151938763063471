import { createContext, useRef } from 'react';
import { SequencerStoreApi, SequencerProviderProps } from './sequencer.types';
import { createSequencerStore } from './sequencer.store';

export const SequencerContext = createContext<SequencerStoreApi>(
  {} as SequencerStoreApi,
);

export function SequencerProvider({
  children,
  ...props
}: SequencerProviderProps) {
  const storeRef = useRef(createSequencerStore(props));

  return (
    <SequencerContext.Provider value={storeRef.current}>
      {children}
    </SequencerContext.Provider>
  );
}
