import { ReactNode, useContext, useEffect, useState } from 'react';
import { ServicesContext } from '../services/services.context';
import { usePreferencesStore } from '../components/business/preferences/use-preferences-context';

type I18nBootProps = {
  children: ReactNode;
};

export function I18nBoot({ children }: I18nBootProps) {
  const [initialized, setInitialized] = useState(false);
  const languagePreference = usePreferencesStore(
    (state) => state.languagePreference,
  );
  const services = useContext(ServicesContext);

  useEffect(() => {
    async function updateLanguage() {
      await services.i18n.updateLanguage(languagePreference);
      setInitialized(true);
    }
    updateLanguage();
  }, [services, languagePreference, setInitialized]);

  return initialized ? <>{children}</> : null;
}
