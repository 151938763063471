import {
  DEFAULT_APP_LANGUAGE,
  AppLanguage,
  SUPPORTED_APP_LANGUAGES,
} from '@notacami/core/i18n';
import { TRANSLATIONS } from './translations.constants';

export function getConfig(initialLanguage?: AppLanguage) {
  return {
    resources: TRANSLATIONS,
    supportedLngs: SUPPORTED_APP_LANGUAGES,
    fallbackLng: DEFAULT_APP_LANGUAGE,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    lng: initialLanguage,
    initImmediate: true,
    react: { useSuspense: false },
  };
}
