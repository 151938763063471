import { GroupNoteStartEvent } from '@notacami/core/composer';

type PlayingGroupNoteAction =
  | {
      type: 'INIT';
      firstPlayingGroup: GroupNoteStartEvent;
    }
  | {
      type: 'GROUP_NOTE_START';
      groupNote: GroupNoteStartEvent;
    }
  | {
      type: 'GROUP_NOTE_END';
      id: string;
    }
  | {
      type: 'RESET';
    };

export type PlayingGroupNoteState = {
  playingGroupNote: GroupNoteStartEvent[];
  firstPlayingGroup: GroupNoteStartEvent | null;
};

export function playingGroupNoteReducer(
  state: PlayingGroupNoteState,
  action: PlayingGroupNoteAction,
): PlayingGroupNoteState {
  switch (action.type) {
    case 'INIT':
      return { ...state, firstPlayingGroup: action.firstPlayingGroup };
    case 'GROUP_NOTE_START': {
      const isPlayingGroupNoteAlreadyExist = state.playingGroupNote.some(
        ({ id }) => id === action.groupNote.id,
      );
      return {
        ...state,
        playingGroupNote: isPlayingGroupNoteAlreadyExist
          ? state.playingGroupNote
          : [...state.playingGroupNote, action.groupNote],
      };
    }
    case 'GROUP_NOTE_END':
      return {
        ...state,
        playingGroupNote: state.playingGroupNote.filter(
          ({ id }) => id !== action.id,
        ),
      };
    case 'RESET':
      return {
        ...state,
        playingGroupNote:
          state.firstPlayingGroup !== null ? [state.firstPlayingGroup] : [],
      };
    default:
      return state;
  }
}
