import { LessonStep } from '@notacami/core/lesson';
import { FretboardNoteDetails } from '@notacami/core/fretboard';
import { createPartEvents } from '@notacami/core/composer';
import { createListeningSteps } from '../teacher/create-listening-steps';
import { DerivedStep } from './lesson.types';

export function getDerivedStep(
  step: LessonStep,
  fretboardNoteDetails: FretboardNoteDetails,
): DerivedStep {
  switch (step.type) {
    case 'teacher-speaking':
      return step;
    case 'teacher-listening': {
      const { sequence, title, ...rest } = step;
      const eventsInPart = createPartEvents(sequence, fretboardNoteDetails);
      const listeningSteps = createListeningSteps(eventsInPart);

      return { ...rest, eventsInPart, listeningSteps, title };
    }
    case 'teacher-playing': {
      const { sequence, ...rest } = step;
      const eventsInPart = createPartEvents(sequence, fretboardNoteDetails);
      return { ...rest, eventsInPart };
    }
  }
}
