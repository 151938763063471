import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { FindScalePostionExerciseContext } from '../../store/context';
import { QuestionSectionGeneric } from '../../../exercise/states/playing/question-section-generic';
import { useNoteNotation } from '../../../../../services/note-notation/use-note-notation';

export function QuestionSection() {
  const { n } = useNoteNotation();

  const store = useContext(FindScalePostionExerciseContext);
  const question = useQuestion(store);

  if (question?.question === null) {
    return null;
  }

  const givenScaleName = question.question.scaleName;
  const givenTonicPitchClass = question.question.tonicPitchClass;

  const key = givenScaleName + givenTonicPitchClass;

  return (
    <QuestionSectionGeneric key={key} store={store}>
      <Trans
        i18nKey="pages.FIND_SCALE_POSITION.question"
        values={{
          scaleName: givenScaleName,
          tonicPitchClass: n(givenTonicPitchClass),
        }}
        components={[<strong key={0} />]}
      />
    </QuestionSectionGeneric>
  );
}
