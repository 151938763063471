import * as Slider from '@radix-ui/react-slider';
import {
  getClassnamesByColorVariant,
  getColorVariantByThematic,
} from '@notacami/core/design';
import { cn } from '@notacami/core/css';
import { Thematic } from '@notacami/core/thematic';

export type SliderInputProps = {
  ariaLabel: string;
  max: number;
  min: number;
  onChange: (value: number) => void;
  step?: number;
  thematic: Thematic;
  value: number;
  widthClassName?: string;
};

export function SliderInput({
  ariaLabel,
  max,
  min,
  onChange,
  step = 1,
  thematic,
  value,
  widthClassName,
}: SliderInputProps) {
  const handleValueChange = (values: number[]) => {
    onChange(values[0]);
  };
  const colorVariant = getColorVariantByThematic(thematic);
  const classnames = getClassnamesByColorVariant(colorVariant);

  const thumbClassnames =
    'block w-5 h-5 bg-neutral-100 rounded-full border-neutral-300 border-2';
  return (
    <Slider.Root
      className={cn(
        'relative flex h-5 touch-none select-none items-center',
        widthClassName || 'w-full',
      )}
      value={[value]}
      max={max}
      min={min}
      step={step}
      onValueChange={handleValueChange}
    >
      <Slider.Track className="relative h-1 flex-grow rounded-full bg-neutral-700 dark:bg-neutral-500">
        <Slider.Range
          className={cn('absolute h-full rounded-full', classnames.background)}
        />
      </Slider.Track>
      <Slider.Thumb className={thumbClassnames} aria-label={ariaLabel} />
    </Slider.Root>
  );
}
