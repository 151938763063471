import { createContext, PropsWithChildren } from 'react';
import {
  PreferencesProps,
  PreferencesStore,
  createPreferencesStore,
} from './preferences.store';

type PreferenceProviderProps = PropsWithChildren<PreferencesProps>;

export const PreferencesContext = createContext<PreferencesStore>(
  {} as PreferencesStore,
);

export function PreferencesProvider({
  children,
  ...props
}: PreferenceProviderProps) {
  return (
    <PreferencesContext.Provider value={createPreferencesStore(props)}>
      {children}
    </PreferencesContext.Provider>
  );
}
