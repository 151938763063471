import { ReactNode } from 'react';
import { cn } from '@notacami/core/css';
import { Info } from 'lucide-react';

type FormFieldContainerLayoutProps = {
  error?: string;
  id: string;
  info?: string;
  input: ReactNode;
  isCentered?: boolean;
  isVertical?: boolean;
  label?: string;
  value?: ReactNode;
};

export function FormFieldContainerLayout({
  error,
  id,
  info,
  input,
  isCentered = false,
  isVertical = false,
  label,
  value,
}: FormFieldContainerLayoutProps) {
  return (
    <div className={cn('flex flex-col gap-2', isCentered && 'items-center')}>
      <div
        className={cn(
          'flex',
          isVertical && 'flex-col gap-2',
          isVertical && isCentered && 'items-center',
          !isVertical && 'items-center justify-between gap-4',
        )}
      >
        <div className="flex flex-col">
          {label ? (
            <label
              htmlFor={id}
              className={cn(
                'text-sm leading-tight',
                isCentered && value === undefined && 'text-center',
              )}
            >
              {label}
            </label>
          ) : null}
          {value}
        </div>

        <div>{input}</div>
      </div>
      {error ? (
        <div
          className={cn(
            'text-error-color text-sm',
            isCentered && 'text-center',
          )}
        >
          {error}
        </div>
      ) : null}
      {info !== undefined ? (
        <div className="flex gap-2 text-xs">
          <Info className="min-h-5 min-w-5" />
          <span dangerouslySetInnerHTML={{ __html: info }} />
        </div>
      ) : null}
    </div>
  );
}
