export const Sizes = {
  STRING_TRACK: {
    v: { w: 'w-10' },
    h: { h: 'h-12' },
  },
  NECK_FRET_CELL_BY_STRING: {
    v: { h: 'h-10' },
    h: { w: 'w-12' },
  },
  OPEN_STRING_CELL_BY_STRING: {
    v: { h: 'h-8' },
    h: { w: 'w-8' },
  },
  SYMBOL_TRACK: {
    v: { w: 'w-10' },
    h: { h: 'h-10' },
  },
  NECK_FRET_NOTE_BY_STRING: {
    v: { h: 'h-8' },
    h: { w: 'w-9' },
  },
  OPEN_STRING_NOTE_BY_STRING: {
    v: { h: 'h-5' },
    h: { w: 'w-5' },
  },
  NOTE_TRACK: {
    v: { w: 'w-8' },
    h: { h: 'h-9' },
  },
};
