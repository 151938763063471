import { useContext, useEffect } from 'react';
import { DEFAULT_FRET_LENGTH } from '@notacami/core/fretboard';
import { GuitarNeck } from '@notacami/ui-business/guitar-neck';
import { useGuitarNeckVertical } from '../../../../../hooks/use-guitar-neck-vertical';
import { FindTheIntervalOnTheNeckExerciseContext } from '../../store/context';
import { useResult } from '../../../../../services/exercise/hooks/use-result';
import { useFindTheIntervalOnTheNeckStore } from '../../store/hook';
import { usePreferencesStore } from '../../../preferences/use-preferences-context';
import { useLeftHanded } from '../../../../../services/fretboard';
import { useQuestion } from '../../../../../services/exercise/hooks/use-question';
import { PartId } from '../../../../../services/sequence/sequence.constants';
import { ServicesContext } from '../../../../../services/services.context';
import { AnswerSectionResultCell } from './answer-section-result-cell';

export function AnswerSectionResult() {
  const { soundPlayer } = useContext(ServicesContext);
  const isVertical = useGuitarNeckVertical();
  const leftHanded = useLeftHanded();

  const fretboard = usePreferencesStore((state) => state.fretboard);

  const config = useFindTheIntervalOnTheNeckStore((state) => state.config);
  const quizMode = useFindTheIntervalOnTheNeckStore((state) => state.quizMode);
  const store = useContext(FindTheIntervalOnTheNeckExerciseContext);
  const question = useQuestion(store);
  const result = useResult(store);

  const correctAnswer = question.correctAnswer;
  const givenRootPosition = question.question.givenRootPosition;

  useEffect(() => {
    function playCorrectAnswerNote() {
      if (result !== null && quizMode === 'app') {
        soundPlayer.playSequence(
          PartId.FIND_THE_NOTE_ON_THE_NECK,
          [
            {
              type: 'note',
              position: question.correctAnswer,
              time: 0,
              duration: 0.25,
            },
          ],
          fretboard.noteDetails,
        );
      }
    }
    playCorrectAnswerNote();
  }, [result, config, quizMode]);

  return result !== null ? (
    <GuitarNeck
      buildCellsComponent={AnswerSectionResultCell}
      isVertical={isVertical}
      leftHanded={leftHanded}
      numberOfFrets={DEFAULT_FRET_LENGTH}
      tuning={config.tuningInfo.notes}
      additionalCellProps={{
        correctAnswer,
        fretboard,
        givenRootPosition,
      }}
    />
  ) : null;
}
