import { ChangeEventHandler, useContext } from 'react';
import { motion } from 'motion/react';
import { Surface } from '@notacami/ui';
import { Guitar, Pointer } from 'lucide-react';
import { cn } from '@notacami/core/css';
import { QuizMode } from '../../services/exercise/exercise.types';
import { ServicesContext } from '../../services/services.context';

type QuizModeSelectorProps = {
  appLabel: string;
  defaultValue?: QuizMode;
  guitarLabel: string;
  onChange: (value: QuizMode) => void;
  value: QuizMode;
};

export function QuizModeSelector({
  appLabel,
  defaultValue,
  guitarLabel,
  onChange,
  value,
}: QuizModeSelectorProps) {
  const { hapticFeedback } = useContext(ServicesContext);

  const handleInputRadioChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    hapticFeedback.impact();
    onChange(event.target.value as QuizMode);
  };

  const buttonActiveClassName = 'text-neutral-100';
  return (
    <Surface
      surfaceId="app"
      className="regular-border relative rounded-xl text-sm"
    >
      <motion.div
        initial={{ x: defaultValue === 'app' ? '100%' : '0%' }}
        animate={{ x: value === 'app' ? '100%' : '0%' }}
        transition={{ type: 'spring', bounce: 0.25 }}
        className="absolute h-full w-28 p-1"
      >
        <div className="h-full w-full rounded-xl bg-stone-600"></div>
      </motion.div>
      <div className="relative flex w-56 gap-2 p-1">
        <div
          className={cn(
            'flex w-28 items-center justify-center transition-colors',
            value === 'guitar' && buttonActiveClassName,
          )}
        >
          <input
            type="radio"
            id="playWithGuitar"
            name="quizMode"
            value="guitar"
            checked={value === 'guitar'}
            onChange={handleInputRadioChange}
            hidden
          />
          <label
            className="flex w-full cursor-pointer flex-col items-center gap-1 p-2 text-right"
            htmlFor="playWithGuitar"
          >
            <Guitar size={18} />
            <span>{guitarLabel}</span>
          </label>
        </div>

        <div
          className={cn(
            'flex w-28 items-center justify-center transition-colors',
            value === 'app' && buttonActiveClassName,
          )}
        >
          <input
            type="radio"
            id="playWithApp"
            name="quizMode"
            value="app"
            checked={value === 'app'}
            onChange={handleInputRadioChange}
            hidden
          />
          <label
            className="flex w-full cursor-pointer flex-col items-center gap-1 p-2 text-left"
            htmlFor="playWithApp"
          >
            <Pointer size={18} />
            <span>{appLabel}</span>
          </label>
        </div>
      </div>
    </Surface>
  );
}
