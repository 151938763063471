import { useCallback, useContext, useEffect, useReducer } from 'react';
import { NoteEndEvent, NoteStartEvent } from '@notacami/core/composer';
import { PartId } from '../../../../../../services/sequence/sequence.constants';
import { ServicesContext } from '../../../../../../services/services.context';
import { playingNotesReducer } from './playing-notes.reducer';

export function usePlayingNotes(partIdToUse: PartId) {
  const { soundPlayer } = useContext(ServicesContext);

  const [playingNotes, dispatch] = useReducer(playingNotesReducer, []);

  const handleNoteStart = useCallback(
    ({
      partId,
      noteStartEvent,
    }: {
      partId: string;
      noteStartEvent: NoteStartEvent;
    }) => {
      if (partId === partIdToUse) {
        dispatch({
          type: 'NOTE_START',
          id: noteStartEvent.id,
          position: noteStartEvent.position,
        });
      }
    },
    [partIdToUse],
  );

  const handleNoteEnd = useCallback(
    ({
      partId,
      noteEndEvent,
    }: {
      partId: string;
      noteEndEvent: NoteEndEvent;
    }) => {
      if (partId === partIdToUse) {
        dispatch({
          type: 'NOTE_END',
          id: noteEndEvent.id,
        });
      }
    },
    [partIdToUse],
  );

  const handleStop = useCallback(() => {
    dispatch({
      type: 'RESET',
    });
  }, []);

  useEffect(() => {
    soundPlayer.on('note-start', handleNoteStart);
    soundPlayer.on('note-end', handleNoteEnd);
    soundPlayer.on('stop', handleStop);

    return () => {
      soundPlayer.off('note-start', handleNoteStart);
      soundPlayer.off('note-end', handleNoteEnd);
      soundPlayer.off('stop', handleStop);
    };
  }, [handleNoteStart, handleNoteEnd, handleStop]);

  return playingNotes;
}
