import { Surface } from '@notacami/ui';
import { Infinity, Repeat2 } from 'lucide-react';
import { DerivedTeacherListeningStep } from '../../../../../../services/lesson';
import { useTeacherCurrentListeningStep } from './use-teacher-current-listening-step';

type StepTeacherListeningRepetitionProps = {
  step: DerivedTeacherListeningStep;
};

export function StepTeacherListeningRepetition({
  step,
}: StepTeacherListeningRepetitionProps) {
  const currentListeningStep = useTeacherCurrentListeningStep(
    step.listeningSteps[0],
  );

  return step.numberOfRepetitions !== 0 ? (
    <Surface
      surfaceId="app"
      className="regular-ring flex h-8 items-center gap-3 rounded-full pl-3"
    >
      <Repeat2 />
      <Surface
        surfaceId="invert"
        className="flex h-8 items-center gap-3 rounded-full px-3 tabular-nums"
      >
        <strong>
          {currentListeningStep.currentRepetitionIndex + 1} /{' '}
          {step.numberOfRepetitions === -1 ? (
            <Infinity className="inline-block" />
          ) : (
            step.numberOfRepetitions
          )}
        </strong>
      </Surface>
    </Surface>
  ) : null;
}
