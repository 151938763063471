import * as Sentry from '@sentry/capacitor';
import {
  Button,
  DividerWithTitle,
  FormFieldContainerLayout,
} from '@notacami/ui';
import { useTranslation } from '../../../../hooks/use-translation';
import { useToast } from '../../../ui/use-toast';

export function TriggerErrorSection() {
  const { t } = useTranslation();
  const { toast } = useToast();

  const handleTriggerWebErrorClick = () => {
    toast({
      description: t('toast.operation-done'),
    });
    throw new Error('Test');
  };

  const handleTriggerNativeErrorClick = () => {
    toast({
      description: t('toast.operation-done'),
    });
    Sentry.nativeCrash();
  };

  return (
    <div className="vertical-content-distribution-base">
      <DividerWithTitle title="Monitoring" tagName="h2" titleLevel={3} />
      <div className="vertical-content-distribution-sm">
        <FormFieldContainerLayout
          id="trigger-web-error-field"
          label={t('pages.settings.admin.trigger-web-error.title')}
          input={
            <Button colorVariant="neutral" onClick={handleTriggerWebErrorClick}>
              {t('button.trigger')}
            </Button>
          }
        />
        <FormFieldContainerLayout
          id="trigger-native-error-field"
          label={t('pages.settings.admin.trigger-native-error.title')}
          input={
            <Button
              colorVariant="neutral"
              onClick={handleTriggerNativeErrorClick}
            >
              {t('button.trigger')}
            </Button>
          }
        />
      </div>
    </div>
  );
}
