import { useContext } from 'react';
import { useStore } from 'zustand';
import { ExerciseState } from '../../../../services/exercise/exercise.types';
import {
  FindTheNoteOnTheNeckAnswer,
  FindTheNoteOnTheNeckConfig,
  FindTheNoteOnTheNeckQuestion,
  FindTheNoteOnTheNeckQuestionMeta,
  FindTheNoteOnTheNeckResultMeta,
} from '../types';
import { FindTheNoteOnTheNeckProgressPayload } from '../../../../services/progress/find-the-note-on-the-neck/types';
import { FindTheNoteOnTheNeckExerciseContext } from './context';

export const useFindTheNoteOnTheNeckStore = <T>(
  selector: (
    state: ExerciseState<
      FindTheNoteOnTheNeckConfig,
      FindTheNoteOnTheNeckQuestion,
      FindTheNoteOnTheNeckAnswer,
      FindTheNoteOnTheNeckQuestionMeta,
      FindTheNoteOnTheNeckResultMeta,
      FindTheNoteOnTheNeckProgressPayload
    >,
  ) => T,
) => {
  const store = useContext(FindTheNoteOnTheNeckExerciseContext);
  return useStore(store, selector);
};
