import { StoreCapacitor } from '../storage.capacitor';

export function getPreferenceAdminCapacitorStore() {
  return new StoreCapacitor<boolean>(
    'settings.admin',
    false,
    (value) => JSON.stringify(value),
    (value) => JSON.parse(value),
  );
}
