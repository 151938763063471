import {
  FindTheNoteFromRootAndIntervalTurnQuestion,
  FindTheNoteFromRootAndIntervalTurnResult,
} from '../../../components/business/find-the-note-from-root-and-interval/types';
import { AbstractProgressEntry } from '../abstract-progress-entry';
import { StoredProgressEntryOutcome } from '../progress.types';
import {
  FindTheNoteFromRootAndIntervalProgressPayload,
  FindTheNoteFromRootAndIntervalProgressStoredPayload,
} from './types';

export function fromStoredEntry(
  entry: StoredProgressEntryOutcome<FindTheNoteFromRootAndIntervalProgressStoredPayload>,
) {
  return new ProgressEntry({ interval: entry[0] }, entry[1]);
}

export function fromResult(
  question: FindTheNoteFromRootAndIntervalTurnQuestion,
  _result: FindTheNoteFromRootAndIntervalTurnResult,
) {
  return new ProgressEntry(
    {
      interval: question.question.givenInterval,
    },
    1,
  );
}

class ProgressEntry extends AbstractProgressEntry<
  FindTheNoteFromRootAndIntervalProgressPayload,
  FindTheNoteFromRootAndIntervalProgressStoredPayload
> {
  public toOutcome() {
    return {
      id: this.payload.interval,
      payload: this.payload,
      numberOfCorrectAnswer: this.numberOfCorrectAnswer,
    };
  }

  public toStoreOutcome() {
    return [this.payload.interval, this.numberOfCorrectAnswer] satisfies [
      FindTheNoteFromRootAndIntervalProgressStoredPayload,
      number,
    ];
  }
}
