import { ReactNode } from 'react';
import { motion } from 'motion/react';
import { cn } from '@notacami/core/css';
import { Surface } from '@notacami/ui';
import { useSafeAreaInsets } from '../../hooks/use-safe-area-insets';
import { useTabBarStore } from '../ui/tab-bar.store';
import { usePageLayoutStore } from './page-layout.store';
import { ANIMATION_DELAY } from './page-layout.constants';

type PageLayoutProps = {
  content: ReactNode;
  title?: ReactNode;
  leftAction?: ReactNode;
  rightAction?: ReactNode;
  bottomAction?: ReactNode;
  fullWidth?: boolean;
};

export function PageLayout({
  content,
  title,
  leftAction,
  rightAction,
  bottomAction,
  fullWidth = false,
}: PageLayoutProps) {
  const { bottom, top, left, right } = useSafeAreaInsets();
  const topAndBottomBarHidden = usePageLayoutStore(
    (state) => state.topAndBottomBarHidden,
  );
  const tabBarHidden = useTabBarStore((state) => state.hidden);

  return (
    <Surface
      surfaceId="app"
      className="absolute inset-0 h-dvh overflow-x-hidden"
      style={{
        paddingLeft: `${left}px`,
        paddingRight: `${right}px`,
      }}
    >
      <div
        className={cn(
          !fullWidth && 'max-w-2xl',
          'relative mx-auto flex min-h-dvh',
        )}
      >
        <motion.div
          animate={{
            y: topAndBottomBarHidden ? -80 - top : 0,
          }}
          transition={{
            type: 'spring',
            bounce: 0.25,
            delay: topAndBottomBarHidden ? 0 : ANIMATION_DELAY,
          }}
          className="fixed left-0 right-0 top-0 z-50 backdrop-blur-sm"
        >
          <Surface
            surfaceId="app"
            className="pointer-events-none w-full"
            style={{ height: `${top}px` }}
          />
          <div
            className={cn(
              'relative',
              'bg-gradient-to-b',
              'from-stone-200 via-stone-200/80 to-stone-200/30',
              'dark:from-black dark:via-black/80 dark:to-black/30',
            )}
          >
            <div
              className={cn(
                'absolute inset-0',
                'bg-gradient-to-b',
                'from-stone-200 via-stone-200/80 to-stone-200/30',
                'dark:from-black dark:via-black/80 dark:to-black/30',
              )}
            />
            <div
              className={cn(
                'absolute inset-x-0 bottom-0 h-0.5 opacity-30',
                'bg-gradient-to-r',
                'from-neutral-400/30 via-neutral-400/80 to-neutral-400/30',
              )}
            />
            <div
              className="relative -mt-4 h-24 pb-5 pt-9"
              style={{
                paddingLeft: `${left}px`,
                paddingRight: `${right}px`,
              }}
            >
              <div className="mx-auto flex h-full max-w-2xl items-center justify-between gap-5 px-5">
                <div className="flex items-center justify-start gap-5">
                  {leftAction}
                  {title ? (
                    <motion.div
                      initial={{ opacity: 0, x: 50 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -50 }}
                      transition={{
                        type: 'spring',
                        duration: 0.65,
                      }}
                    >
                      {title}
                    </motion.div>
                  ) : null}
                </div>
                {rightAction}
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          animate={{ y: topAndBottomBarHidden ? 96 + bottom : 0 }}
          transition={{
            type: 'spring',
            bounce: 0.25,
            delay: topAndBottomBarHidden ? 0 : ANIMATION_DELAY,
          }}
          className={cn(
            'fixed bottom-0 left-0 right-0 z-50',
            !bottomAction && 'pointer-events-none',
          )}
        >
          <div
            className={cn(
              'relative',
              !tabBarHidden && !bottomAction && 'h-24',
              tabBarHidden && !bottomAction && 'h-5',
              bottomAction && 'backdrop-blur-sm',
              'bg-gradient-to-t',
              'from-stone-200 to-stone-200/0',
              'dark:from-black dark:to-black/0',
            )}
            style={{
              paddingBottom: `${bottom}px`,
              paddingLeft: `${left}px`,
              paddingRight: `${right}px`,
            }}
          >
            {bottomAction ? (
              <>
                <div
                  className={cn(
                    'absolute inset-0',
                    'bg-gradient-to-t',
                    'from-stone-200 via-stone-200/80 to-stone-200/30',
                    'dark:from-black dark:via-black/80 dark:to-black/30',
                  )}
                />
                <div
                  className={cn(
                    'absolute inset-x-0 top-0 h-0.5 opacity-30',
                    'bg-gradient-to-r',
                    'from-neutral-400/30 via-neutral-400/80 to-neutral-400/30',
                  )}
                />
                <div className="container relative mx-auto flex max-w-md items-center justify-center px-5 py-4">
                  {bottomAction}
                </div>
              </>
            ) : null}
          </div>
        </motion.div>

        <div
          className="relative flex flex-grow"
          style={{
            paddingBottom: `${bottom}px`,
            paddingTop: `${top}px`,
          }}
        >
          <main
            className={cn(
              fullWidth ? 'w-full' : 'container mx-auto',
              'min-h-full px-5 pt-28',
              !tabBarHidden || bottomAction !== undefined ? 'pb-28' : 'pb-5',
            )}
          >
            {content}
          </main>
        </div>
      </div>
    </Surface>
  );
}
