import { forwardRef } from 'react';
import { motion } from 'motion/react';
import { cn } from '@notacami/core/css';
import { NoteStatus } from '@notacami/core/fretboard';
import { getNeckCellBaseClassnames } from './get-neck-cell-base-classnames';
import { getNoteColorClassnamesByStatus } from './get-note-color-classnames-by-status';

type NeckCellStatusProps = {
  isOpenString: boolean;
  isVertical: boolean;
  label: string;
  status: NoteStatus;
};

export const NeckCellStatus = forwardRef<HTMLDivElement, NeckCellStatusProps>(
  ({ isOpenString, isVertical, label, status }, ref) => {
    const colors = getNoteColorClassnamesByStatus(status);

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        ref={ref}
        className={cn(
          getNeckCellBaseClassnames(isVertical, isOpenString),
          colors.text,
          colors.border,
          'border-4',
          !isOpenString && colors.backgroundTransparent,
          isOpenString && colors.background,
        )}
      >
        <div className={isOpenString ? 'text-xs' : 'text-base'}>{label}</div>
      </motion.div>
    );
  },
);
NeckCellStatus.displayName = 'NeckCellStatus';
