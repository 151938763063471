import { createContext, useRef } from 'react';
import { createExerciseStore } from '../../../../services/exercise/exercise.store';
import {
  ExerciseProviderProps,
  ExerciseStoreApi,
} from '../../../../services/exercise/exercise.types';
import {
  FindTheIntervalOnTheNeckAnswer,
  FindTheIntervalOnTheNeckConfig,
  FindTheIntervalOnTheNeckQuestion,
  FindTheIntervalOnTheNeckQuestionMeta,
  FindTheIntervalOnTheNeckResultMeta,
} from '../types';
import { FindTheIntervalOnTheNeckProgressPayload } from '../../../../services/progress/find-the-interval-on-the-neck/types';

export const FindTheIntervalOnTheNeckExerciseContext = createContext<
  ExerciseStoreApi<
    FindTheIntervalOnTheNeckConfig,
    FindTheIntervalOnTheNeckQuestion,
    FindTheIntervalOnTheNeckAnswer,
    FindTheIntervalOnTheNeckQuestionMeta,
    FindTheIntervalOnTheNeckResultMeta,
    FindTheIntervalOnTheNeckProgressPayload
  >
>(
  {} as ExerciseStoreApi<
    FindTheIntervalOnTheNeckConfig,
    FindTheIntervalOnTheNeckQuestion,
    FindTheIntervalOnTheNeckAnswer,
    FindTheIntervalOnTheNeckQuestionMeta,
    FindTheIntervalOnTheNeckResultMeta,
    FindTheIntervalOnTheNeckProgressPayload
  >,
);

export function FindTheIntervalOnTheNeckExerciseProvider({
  children,
  ...props
}: ExerciseProviderProps<
  FindTheIntervalOnTheNeckConfig,
  FindTheIntervalOnTheNeckQuestion,
  FindTheIntervalOnTheNeckAnswer,
  FindTheIntervalOnTheNeckQuestionMeta,
  FindTheIntervalOnTheNeckResultMeta,
  FindTheIntervalOnTheNeckProgressPayload
>) {
  const storeRef = useRef(createExerciseStore(props));

  return (
    <FindTheIntervalOnTheNeckExerciseContext.Provider value={storeRef.current}>
      {children}
    </FindTheIntervalOnTheNeckExerciseContext.Provider>
  );
}
