import { Mic } from 'lucide-react';
import { Thematic } from '@notacami/core/thematic';
import { getColorVariantByThematic } from '@notacami/core/design';
import { Button } from '@notacami/ui';
import { SinewavePreview } from './sinewave-preview';
import { Note } from './note';

type RecordingButtonProps = {
  onClick: () => void;
  thematic: Thematic;
};

export function RecordingButton({ onClick, thematic }: RecordingButtonProps) {
  const colorVariant = getColorVariantByThematic(thematic);

  return (
    <div className="relative z-10 pl-8">
      <div className="absolute bottom-0 left-0 right-2 top-0 overflow-hidden rounded-3xl bg-neutral-200 shadow-inner shadow-neutral-300 dark:bg-neutral-600 dark:shadow-neutral-700">
        <SinewavePreview />
      </div>
      <div className="relative">
        <Button
          colorVariant={colorVariant}
          onClick={onClick}
          size="icon"
          variant="rounded"
        >
          <Mic />
        </Button>
      </div>
      <div className="absolute -left-6 bottom-2 top-2 flex w-14 items-center justify-end">
        <Note />
      </div>
    </div>
  );
}
