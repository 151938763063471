import { createContext, ReactNode } from 'react';
import { DeviceId } from '@notacami/core/device';
import { DeviceIdContextData } from './device-id.types';

export const DeviceIdFromUrlContext = createContext<DeviceIdContextData>(null);

type SafeAreaProviderProps = {
  children: ReactNode;
  deviceId: DeviceId | null;
};

export function DeviceIdFromUrlProvider({
  children,
  deviceId,
}: SafeAreaProviderProps) {
  return (
    <DeviceIdFromUrlContext.Provider value={deviceId}>
      {children}
    </DeviceIdFromUrlContext.Provider>
  );
}
