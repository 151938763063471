import { getColorVariantByThematic } from '@notacami/core/design';
import { Button } from '@notacami/ui';
import { useTranslation } from '../../../hooks/use-translation';
import { ScalePosition } from '../../../services/fretboard';

type VisualizeScalePositionsPositionSelectorProps = {
  scalePositionsByStringIndex: ScalePosition[][];
  positionIndex: number;
  stringIndex: number;
  onPositionButtonClick: (value: number) => void;
};

export function VisualizeScalePositionsPositionSelector({
  scalePositionsByStringIndex,
  stringIndex,
  positionIndex,
  onPositionButtonClick,
}: VisualizeScalePositionsPositionSelectorProps) {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between gap-4">
      <div className="flex items-center gap-4 pl-2">
        <span className="text-sm">
          {t(
            'components.visualize-scale-positions-position-selector.positions',
          )}
        </span>
        <div className="flex gap-2">
          {scalePositionsByStringIndex[stringIndex].map(({ type }, index) => {
            const id = `${type}-${index}`;

            return (
              <div key={id} className="relative flex flex-col items-center">
                <Button
                  onClick={() => onPositionButtonClick(index)}
                  colorVariant={
                    positionIndex === index
                      ? getColorVariantByThematic('scales')
                      : 'neutral-invert'
                  }
                  variant="rounded"
                  size="icon"
                >
                  {index + 1}
                </Button>
                {type !== 'other' && positionIndex === index ? (
                  <div className="text-dark absolute -bottom-2 rounded-full bg-neutral-100 px-1 text-xs">
                    {t(`scale-position-type.${type}`)}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
