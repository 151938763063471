import { useContext } from 'react';
import { FormFieldContainerLayout, Select } from '@notacami/ui';
import {
  NOTE_NOTATIONS_LIST,
  NoteNotation,
} from '@notacami/core/note-notation';
import { usePreferencesStore } from '../../preferences/use-preferences-context';
import { useTranslation } from '../../../../hooks/use-translation';
import { ServicesContext } from '../../../../services/services.context';

export function NoteNotationSection() {
  const { preferenceNoteNotationStore } = useContext(ServicesContext);
  const { t } = useTranslation();
  const changeNoteNotation = usePreferencesStore(
    (state) => state.setNoteNotation,
  );
  const noteNotation = usePreferencesStore((state) => state.noteNotation);

  const handleChange = (value: NoteNotation) => {
    changeNoteNotation(value);
    preferenceNoteNotationStore.set(value);
  };

  const options = NOTE_NOTATIONS_LIST.map((noteNotation) => ({
    id: noteNotation,
    label: t(`pages.settings.note-notation.option.${noteNotation}`),
    value: noteNotation,
  }));

  return (
    <FormFieldContainerLayout
      id="note-notation-setting-field"
      label={t('pages.settings.note-notation.title')}
      input={
        <Select
          options={options}
          name="note-notation-setting"
          onChange={handleChange}
          value={noteNotation}
          className="w-36"
        />
      }
    />
  );
}
