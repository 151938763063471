import { Guitar, Music4, Smartphone, Bug, User } from 'lucide-react';
import { useEffect } from 'react';
import { useTranslation } from '../../../hooks/use-translation';
import { NavigationButtonContainer } from '../../ui/navigation-button-container';
import { PageUrls } from '../../../utils/routes.contants';
import { usePreferencesStore } from '../preferences/use-preferences-context';
import { useSettingsStore } from './settings.store';
import { VersionSection } from './version-section';
import { LegalsSection } from './legals-section';
import { MicSection } from './mic/mic-section';

export function Settings() {
  const { t } = useTranslation();
  const reset = useSettingsStore((state) => state.reset);
  const admin = usePreferencesStore((state) => state.admin);

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <div className="vertical-content-distribution-lg">
      <div className="vertical-content-distribution-sm">
        <NavigationButtonContainer to={PageUrls.SETTINGS_USER_ACCOUNT}>
          <>
            <User size={22} />
            {t('pages.settings.user-account.title')}
          </>
        </NavigationButtonContainer>
        <NavigationButtonContainer to={PageUrls.SETTINGS_GENERAL}>
          <>
            <Smartphone size={22} />
            {t('pages.settings.general.title')}
          </>
        </NavigationButtonContainer>
        <MicSection />
        <NavigationButtonContainer to={PageUrls.SETTINGS_MUSIC}>
          <>
            <Music4 size={22} />
            {t('pages.settings.music.title')}
          </>
        </NavigationButtonContainer>
        <NavigationButtonContainer to={PageUrls.SETTINGS_INSTRUMENT}>
          <>
            <Guitar size={22} />
            {t('pages.settings.instrument.title')}
          </>
        </NavigationButtonContainer>
        {admin ? (
          <NavigationButtonContainer to={PageUrls.SETTINGS_ADMIN}>
            <>
              <Bug size={22} />
              {t('pages.settings.admin.title')}
            </>
          </NavigationButtonContainer>
        ) : null}
      </div>
      <LegalsSection />
      <VersionSection />
    </div>
  );
}
